import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import RoleList from "./components/role-list";
import AddRole from "./components/role-forms";
import EditRole from "./components/role-forms";
import { RouteComponentProps } from "react-router-dom";

const ManageRoles: React.FC = () => {
    const match = useRouteMatch();
    return (
        <Switch>
            <Route
                exact
                path={`${match.path}`}
                render={(props: any) => <RoleList {...props} />}
            />
            <Route 
                exact 
                path={`${match.path}/add`} 
                render={(props: RouteComponentProps) => <AddRole {...props} toggleIsAdd={true} />} 
            />
            <Route 
                exact 
                path={`${match.path}/role/:roleId`} 
                render={(props: RouteComponentProps) => <EditRole {...props} toggleIsAdd={false} />} 
            />
        </Switch>
    );
};

export default ManageRoles;
