import * as yup from "yup";
import validateUrl from "../../../../../util/validate-url";

const SUPPORTED_FORMATS = [".jpg", ".jpeg", ".png", ".JPG", ".JPEG", ".PNG", ".webp"];

const imageSchema = yup
    .object()
    .shape({
        status: yup.string().matches(/done/, { message: "file is still uploading" }),
        url: yup.string(),
        keyObj: yup.string().when("url", {
            is: (val) => validateUrl(val),
            then: yup.string(),
            otherwise: yup
                .string()
                .nullable()
                .test("fileFormat", "Invalid format.", (value) => {
                    const pattern = /\.[0-9a-z]+$/i;
                    if (value) {
                        const val: any = value;
                        const acceptedFormat = val.match(pattern)[0];
                        return SUPPORTED_FORMATS.includes(acceptedFormat);
                    } else {
                        return true;
                    }
                }),
        }),
        altText: yup.string(),
    })
    .nullable();

export const validationSchema = () => {
    const shape = {
        overviewDefault: yup.string().nullable(),
        projectName: yup.string().required("Project Name is required."),
        propertyType: yup.string().required("Property Type is required."),
        propertySizeLotArea: yup
            .number()
            .nullable()
            .typeError("Property Size (Lot Area) must be a number."),
        propertySizeFloorArea: yup
            .number()
            .nullable()
            .typeError("Property Size (Floor Area) must be a number."),
        propertyPriceRangeHighest: yup
            .number()
            .nullable()
            .transform((obj, values) => {
                if (values?.trim() !== "") {
                    const newValue = parseFloat(values?.trim().replace(/,/g, ""));
                    return !isNaN(newValue) ? newValue : 0;
                }
                return null;
            }),
        propertyPriceRangeLowest: yup
            .number()
            .nullable()
            .transform((obj, values) => {
                if (values?.trim() !== "") {
                    const newValue = parseFloat(values?.trim().replace(/,/g, ""));
                    return !isNaN(newValue) ? newValue : 0;
                }
                return null;
            })
            .when("propertyPriceRangeHighest", (propertyPriceRangeHighest, schema) => {
                if (propertyPriceRangeHighest && propertyPriceRangeHighest !== "") {
                    return schema
                        .nullable()
                        .max(
                            propertyPriceRangeHighest,
                            `Property Price Range (Lowest) must be less than or equal to ${propertyPriceRangeHighest}.`
                        );
                }
                return schema;
            }),
        image: imageSchema.required("Image logo is required."),
        ltsAndApprovalNo: yup.string().nullable(),
        image2: imageSchema,
    };
    return yup.object().shape(shape);
};

export const propertyTypeList = [
    {
        label: "Condominium",
        slug: "condominium",
        value: "Condominium",
    },
    {
        label: "Office",
        slug: "office",
        value: "Office",
    },
    {
        label: "Lots",
        slug: "lots",
        value: "Lots",
    },
    {
        label: "Commercial",
        slug: "commercial",
        value: "Commercial",
    },
    {
        label: "Townhouse",
        slug: "townhouse",
        value: "Townhouse",
    },
    {
        label: "Leisure",
        slug: "leisure",
        value: "Leisure",
    },
    {
        label: "Apartments",
        slug: "apartments",
        value: "Apartments",
    },
    {
        label: "House and Lot",
        slug: "house-and-lot",
        value: "House and Lot",
    },
    {
        label: "Estate",
        slug: "estate",
        value: "Estate",
    },
];

export const subcategory = [
    {
        label: "Vertical Communities",
        value: "Vertical Communities",
    },
    {
        label: "Neighborhoods",
        value: "Neighborhoods",
    },
    {
        label: "Leisure",
        value: "Leisure",
    },
];

export const OverDetailsTemp = {
    order: 0,
    name: "OVERVIEW-DETAILS",
    fields: [
        {
            order: 0,
            label: "Property Status",
            name: "PROPERTY-STATUS",
            value: null,
        },
        {
            order: 1,
            label: "Title",
            name: "PROPERTY-TITLE",
            value: null,
        },
        {
            order: 2,
            label: "Property Details",
            name: "PROPERTY-DETAILS",
            value: null,
        },
    ],
    children: [
        {
            order: 0,
            name: "IMAGES-GALLERY",
        },
        {
            order: 1,
            name: "VIDEOS",
            fields: [
                {
                    order: 0,
                    label: "video",
                    name: "VIDEO",
                    value: null,
                },
            ],
        },
        {
            order: 2,
            name: "PANORAMAS",
            fields: [
                {
                    order: 0,
                    label: "panorama",
                    name: "PANORAMA",
                    value: null,
                },
            ],
        },
    ],
};

export const LocationLandmarkTemp = {
    order: 1,
    name: "LOCATION-LANDMARKS",
    fields: [
        {
            order: 0,
            label: "Location (Default)",
            name: "LOCATION-DEFAULT",
            value: null,
        },
        {
            order: 1,
            label: "Location (Specific)",
            name: "LOCATION-SPECIFIC",
            value: null,
        },
        {
            order: 2,
            label: "Location (Brief)",
            name: "LOCATION-BRIEF",
            value: null,
        },
        {
            order: 3,
            label: "Region",
            name: "REGION",
            value: null,
        },
        {
            order: 4,
            label: "Longitude",
            name: "LONGITUDE",
            value: null,
        },
        {
            order: 5,
            label: "Longitude",
            name: "LATITUDE",
            value: null,
        },
        {
            order: 6,
            label: "Landmarks (Default)",
            name: "LANDMARKDS-DEFAULT",
            value: null,
        },
        {
            order: 7,
            label: "Seo Title",
            name: "SEO-LOCATION-TITLE",
            value: null,
        },
        {
            order: 8,
            label: "Seo Description",
            name: "SEO-LOCATION-DESCRIPTION",
            value: null,
        },
    ],
    children: [
        {
            order: 0,
            name: "LANDMARKS-ESTABLISHMENT",
        },
        {
            order: 1,
            name: "LOCATION-IMAGES",
        },
        {
            order: 2,
            name: "LOCATION-VIDEOS",
        },
        {
            order: 3,
            name: "LOCATION-PANORAMAS",
        },
    ],
};

export const AmenitiesTemp = {
    order: 2,
    name: "AMENITIES",
    fields: [
        {
            order: 0,
            label: "Amenities (Default)",
            name: "AMENITIES-DEFAULT",
            value: null,
        },
        {
            order: 1,
            label: "Title",
            name: "TITLE",
            value: null,
        },
        {
            order: 2,
            label: "Details",
            name: "PROPERTY-DETAILS",
            value: null,
        },
        {
            label: "Seo Title",
            name: "SEO-AMENITIES-TITLE",
            value: null,
            order: 3,
        },
        {
            label: "Seo Description",
            name: "SEO-AMENITIES-DESCRIPTION",
            value: null,
            order: 4,
        },
    ],
    children: [
        {
            order: 0,
            name: "AMENITY",
            fields: [
                {
                    order: 0,
                    label: "Amenity Name",
                    name: "AMENITY-NAME",
                    value: null,
                },
            ],
            children: [
                {
                    order: 0,
                    name: "IMAGES",
                },
                {
                    order: 1,
                    name: "VIDEOS",
                    fields: [
                        {
                            order: 0,
                            label: "video",
                            name: "VIDEO",
                            value: null,
                        },
                    ],
                },
                {
                    order: 2,
                    name: "PANORAMAS",
                    fields: [
                        {
                            order: 0,
                            label: "panorama",
                            name: "PANORAMA",
                            value: null,
                        },
                    ],
                },
            ],
        },
    ],
};

export const FloorPlansTemp = {
    order: 3,
    name: "FLOOR-PLANS",
    fields: [
        {
            order: 0,
            label: "Unit & Floor Plans (Default)",
            name: "UNIT-FLOOR-PLANS-DEFAULT",
            value: null,
        },
        {
            order: 1,
            label: "Image Gallery of Unit & Floor Plans (Default)",
            name: "IMAGE-GALLERY-DEFAULT",
            value: null,
        },
        {
            order: 2,
            label: "Unit Size Range (Lowest)",
            name: "UNIT-SIZE-RANGE-LOWEST",
            value: null,
        },
        {
            order: 3,
            label: "Unit Size Range (Highest)",
            name: "UNIT-SIZE-RANGE-HIGHEST",
            value: null,
        },
        {
            order: 4,
            label: "No. of Floors",
            name: "NO-OF-FLOORS",
            value: null,
        },
        {
            order: 5,
            label: "No. of Residential Units",
            name: "NO-OF-RESIDENTIAL-UNITS",
            value: null,
        },
        {
            order: 6,
            label: "Body",
            name: "BODY",
            value: "",
        },
        {
            label: "Seo Title",
            name: "SEO-FLOOR-PLAN-TITLE",
            value: null,
            order: 6,
        },
        {
            label: "Seo Description",
            name: "SEO-FLOOR-PLAN-DESCRIPTION",
            value: null,
            order: 7,
        },
    ],
    children: [
        {
            order: 0,
            name: "IMAGE-GALLERY",
        },
        {
            order: 1,
            name: "UNITS-FLOOR-PLANS",
            children: [
                {
                    order: 0,
                    name: "UNIT-PLAN",
                    fields: [
                        {
                            order: 0,
                            label: "Unit Floor Plan",
                            name: "UNIT-PLAN-FLOOR-PLAN",
                            value: null,
                        },
                        {
                            order: 1,
                            label: "Unit Floor Plan Details",
                            name: "DETAILS",
                            value: "",
                        },
                        {
                            order: 2,
                            label: "Unit Floor Plan Total Area",
                            name: "TOTAL-AREA",
                            value: null,
                        },
                    ],
                    children: [
                        {
                            order: 0,
                            name: "IMAGE-GALLERY",
                        },
                        {
                            order: 1,
                            name: "AREA",
                            fields: [
                                {
                                    order: 0,
                                    label: "Unit Floor Plan Area Name",
                                    name: "AREA-NAME",
                                    value: null,
                                },
                                {
                                    order: 1,
                                    label: "Unit Floor Plan In sqm",
                                    name: "IN-SQM",
                                    value: null,
                                },
                                {
                                    order: 2,
                                    label: "Unit Floor Plan In sqft",
                                    name: "IN-SQFT",
                                    value: null,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            order: 2,
            name: "VIDEOS",
            fields: [
                {
                    order: 0,
                    label: "video",
                    name: "VIDEO",
                    value: null,
                },
            ],
        },
        {
            order: 3,
            name: "PANORAMAS",
            fields: [
                {
                    order: 0,
                    label: "panorama",
                    name: "PANORAMA",
                    value: null,
                },
            ],
        },
    ],
};

export const ConstructionPlansTemp = {
    order: 4,
    name: "CONSTRUCTION-UPDATES",
    fields: [
        {
            order: 0,
            label: "Construction Updates (Default)",
            name: "CONSTRUCTION-UPDATES-DEFAULT",
            value: null,
        },
        {
            order: 1,
            label: "Construction Start Date",
            name: "START-DATE",
            value: null,
        },
        {
            order: 2,
            label: "Percentage",
            name: "PERCENTAGE",
            value: null,
        },
        {
            order: 3,
            label: "Construction End Date",
            name: "CONSTRUCTION-END-DATE",
            value: null,
        },
        {
            order: 4,
            label: "Details",
            name: "DETAILS",
            value: null,
        },
        {
            order: 5,
            label: "Seo Title",
            name: "SEO-CONSTRUCTION-PLAN-TITLE",
            value: null,
        },
        {
            order: 6,
            label: "Seo Description",
            name: "SEO-CONSTRUCTION-PLAN-DESCRIPTION",
            value: null,
        },
    ],
    children: [
        {
            order: 0,
            name: "IMAGE-GALLERY",
        },
        {
            order: 1,
            name: "VIDEOS",
            fields: [
                {
                    order: 0,
                    label: "video",
                    name: "VIDEO",
                    value: null,
                },
            ],
        },
        {
            order: 2,
            name: "PANORAMAS",
            fields: [
                {
                    order: 0,
                    label: "panorama",
                    name: "PANORAMA",
                    value: null,
                },
            ],
        },
        {
            order: 3,
            name: "CONSTRUCTIONS",
            fields: [
                {
                    order: 0,
                    label: "Construction Update",
                    name: "CONSTRUCTION-UPDATE",
                    value: null,
                },
                {
                    order: 1,
                    label: "Details",
                    name: "DETAILS",
                    value: null,
                },
            ],
        },
    ],
};

export const towerContainer = [
    {
        order: 0,
        name: "NEW-UNIT",
        children: [
            {
                ...OverDetailsTemp,
            },
            {
                ...LocationLandmarkTemp,
            },
            {
                ...AmenitiesTemp,
            },
            {
                ...FloorPlansTemp,
            },
            {
                ...ConstructionPlansTemp,
            },
        ],
    },
];
