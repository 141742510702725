import React, { useState } from "react";

import { Container } from "./styled";
import { Modal } from "antd";

import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface Props {
    src: string;
    visible: boolean;
    onCancel?: () => void;
    tool?: string;
}

const PdfViewerModal: React.FC<Props> = ({ src, visible, onCancel }) => {
    const [numPages, setNumPages] = useState<number>(0)

    const mdorFile = {
        url: src,
        httpHeaders: { 'brand': `AYALA` }
    }

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    }

    return (
        <Modal
            title={false}
            visible={visible}
            footer={false}
            bodyStyle={{ padding: 0, minHeight: '700px' }}
            width={1000}
            centered={true}
            destroyOnClose={true}
            onCancel={onCancel}
        >
            <Container>
                <Document
                    file={mdorFile}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {[...Array(numPages).keys()].map((page) => (
                        <Page pageNumber={page + 1} renderTextLayer={false} />
                    ))}
                </Document>
            </Container>
        </Modal>
    );
};

export default PdfViewerModal;
