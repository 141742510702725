import React from "react";

import { FormItem, Input } from "formik-antd";
import { CloseOutlined } from "@ant-design/icons";

import { WrapperForm } from "./styled";
import { ComponentFormType } from "../content-forms.types";

const ComponentNine = ({ index, fieldsArrayEvent }: ComponentFormType): JSX.Element => {
    return (
        <WrapperForm>
            <FormItem
                label={"Title"}
                name={`components[${index}].fields.title`}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Input name={`components[${index}].fields.title`} />
            </FormItem>
            <FormItem
                label={"Description"}
                name={`components[${index}].fields.description`}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Input.TextArea name={`components[${index}].fields.description`} rows={7} />
            </FormItem>
            <CloseOutlined
                className="remove-btn"
                onClick={() => {
                    fieldsArrayEvent.remove(index);
                }}
            />
        </WrapperForm>
    );
};

export default ComponentNine;
