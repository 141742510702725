import { FormValueType, FieldInfoChildrenType, FieldInfoType } from "./floor-plans-types";

export const INIT_VALUES: FormValueType = {
    unitFloorPlansDefault: "",
    unitSizeRangeLowest: "",
    unitSizeRangeHighest: "",
    noOfFloors: "",
    noOfResidentialUnits: "",
    seoFloorPlanTitle: "",
    seoFloorPlanDescription: "",
    floorPlanHeadingTag: "",
    subHeading: "",
    body: "",
    unitsFloorPlans: [
        {
            id: "",
            unitPlanFloorPlan: "",
            details: "",
            totalArea: "",
            area: [
                {
                    id: "",
                    areaName: "",
                    inSqm: "",
                    inSqft: "",
                },
            ],
            imageGallery: [],
            imagesCaptions: [],
        },
    ],
    imageGalleryDefault: "",
    imageGallery: [],
    imagesCaptions: [],
    videos: [""],
    panoramas: [""],
};

export const INIT_CHILDREN_VALUES: FieldInfoChildrenType = {
    unitsFloorPlansId: "",
    unitsFloorPlans: [],
    imageGallery: [],
    imageGalleryId: "",
    videosId: "",
    videos: [],
    panoramasId: "",
    panoramas: [],
};

export const INIT_FIELD_INFO: FieldInfoType = {
    fields: [],
    children: INIT_CHILDREN_VALUES,
};
