import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import { Spin, message } from "antd";
import { v4 } from "uuid";
import ContentContainer from "../../../../../../components/ContentContainer";
import Forms from "./form";

import { Context } from "../../../../../../context";
import { MODIFY_FC_FIELDS } from "../../../../graphql";
import { validationSchema } from "./constant";

import { processImageFields } from "../unit-tabs-utils";
import { Props } from "../unit-tabs-types";
import { INIT_FIELD_INFO, INIT_VALUES, INIT_CHILDREN_VALUES } from "./construction-plans.utils";
import { FieldInfoType, FormValueType, InfoFieldChildType } from "./construction-plans.types";

const UnitConstructionPlansForms = ({ handleRefresh }: Props): JSX.Element => {
    const {
        state: { propertyUnitForms, brand: brandData },
    } = useContext(Context);
    const brandName = brandData.name;
    const [modifyFCFields] = useMutation(MODIFY_FC_FIELDS);

    const [initialValues, setInitialValues] = useState<FormValueType>(INIT_VALUES);

    const [fieldInfo, setFieldInfo] = useState<FieldInfoType>(INIT_FIELD_INFO);

    const [reinitialize, setReinitialize] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        setLoading(true);
        const data = propertyUnitForms.payload.constructionPlans;
        if (_.has(data, "fields") && data.fields) {
            const sectionsFC: any = [];

            const children: InfoFieldChildType = INIT_CHILDREN_VALUES;

            const fieldItem: any = [];

            const fields: FormValueType = INIT_VALUES;

            const sectionItems: any = [];

            if (data.children) {
                data.children.forEach((res, i) => {
                    children[`${_.camelCase(res.name)}Id`] = res.id;

                    if (res.name === "IMAGE-GALLERY") {
                        const { imageFields, imageChildren } = processImageFields(res);
                        children["imageGalleryId"] = res.id;
                        fields[_.camelCase(res.name)] = imageFields;
                        fields["imagesCaptions"] = imageFields.map(({ caption }) => caption);
                        children[_.camelCase(res.name)] = imageChildren;
                    } else if (res.name === "CONSTRUCTIONS") {
                        const sectionUpdatedFields: any = [];
                        children["constructionsId"] = res.id;

                        const constructionItems = {
                            id: "",
                            constructionUpdate: "",
                            details: "",
                        };
                        constructionItems.id = res.id;
                        res.fields.forEach((item) => {
                            constructionItems[_.camelCase(item.name)] = item.value;
                            sectionUpdatedFields.push(item);
                        });
                        sectionsFC.push({ id: res.id, fields: sectionUpdatedFields });
                        sectionItems.push(constructionItems);
                    } else if (res.name === "VIDEOS" || res.name === "PANORAMAS") {
                        children[`${_.camelCase(res.name)}Id`] = res.id;
                        children[_.camelCase(res.name)] = res.fields.map((f) => ({
                            value: f.value ? f.value : "",
                            id: f.id,
                        }));
                        fields[_.camelCase(res.name)] = res.fields.map((f) => f.value);
                    } else {
                        children[res.name] = [];
                    }
                });
            }
            fields["constructions"] = sectionItems;
            fieldItem.push({ name: "constructions", fields: sectionsFC });
            data.fields.forEach((res) => {
                fieldItem.push(res);
                fields[_.camelCase(res.name)] = res.value;
            });

            setFieldInfo({
                fields: fieldItem,
                children: children,
            });
            setInitialValues(fields);
            setReinitialize(true);
        }
        setLoading(false);
    }, [propertyUnitForms.payload]);

    const getFieldID = (fields, targetKey) => {
        if (fields) {
            const filter = fields.filter((item: any) => item.name === targetKey);
            if (filter.length > 0) {
                return filter[0].id;
            }
        }
        return v4();
    };

    const fieldIdChecker = (hit, name) => {
        return hit && hit.length > 0 ? getFieldID(hit[0], name) : null;
    };

    const handleSubmit = async (values) => {
        setLoading(true);

        try {
            const filter: any = fieldInfo.fields;

            const constructionPlansFc = {
                id: propertyUnitForms.payload.constructionPlans.id,
                name: "CONSTRUCTION-UPDATES",
                order: 4,
                fields: [
                    {
                        order: 0,
                        id: getFieldID(filter, "CONSTRUCTION-UPDATES-DEFAULT"),
                        label: "Construction Updates (Default)",
                        name: "CONSTRUCTION-UPDATES-DEFAULT",
                        value: !_.isEmpty(values.constructionUpdatesDefault)
                            ? values.constructionUpdatesDefault
                            : null,
                    },
                    {
                        order: 1,
                        id: getFieldID(filter, "START-DATE"),
                        label: "Construction Start Date",
                        name: "START-DATE",
                        value: !_.isEmpty(values.startDate) ? values.startDate : null,
                    },
                    {
                        order: 2,
                        id: getFieldID(filter, "PERCENTAGE"),
                        label: "Percentage",
                        name: "PERCENTAGE",
                        value: !_.isEmpty(values.percentage) ? values.percentage : null,
                    },
                    {
                        order: 3,
                        id: getFieldID(filter, "CONSTRUCTION-END-DATE"),
                        label: "Construction End Date",
                        name: "CONSTRUCTION-END-DATE",
                        value: !_.isEmpty(values.constructionEndDate)
                            ? values.constructionEndDate
                            : null,
                    },
                    {
                        order: 4,
                        id: getFieldID(filter, "DETAILS"),
                        label: "Details",
                        name: "DETAILS",
                        value: !_.isEmpty(values.details) ? values.details : null,
                    },
                    {
                        order: 5,
                        id: getFieldID(filter, "SEO-CONSTRUCTION-PLAN-TITLE"),
                        label: "Seo Title",
                        name: "SEO-CONSTRUCTION-PLAN-TITLE",
                        value: !_.isEmpty(values.seoConstructionPlanTitle)
                            ? values.seoConstructionPlanTitle
                            : null,
                    },
                    {
                        order: 6,
                        id: getFieldID(filter, "SEO-CONSTRUCTION-PLAN-DESCRIPTION"),
                        label: "Seo Description",
                        name: "SEO-CONSTRUCTION-PLAN-DESCRIPTION",
                        value: !_.isEmpty(values.seoConstructionPlanDescription)
                            ? values.seoConstructionPlanDescription
                            : null,
                    },
                    {
                        order: 7,
                        id: getFieldID(filter, "CONSTRUCTION-PLAN-HEADING-TAG"),
                        label: "H1 Tag",
                        name: "CONSTRUCTION-PLAN-HEADING-TAG",
                        value: !_.isEmpty(values.constructionPlanHeadingTag)
                            ? values.constructionPlanHeadingTag
                            : null,
                    },
                    brandName === "ALP" && {
                        order: 8,
                        id: getFieldID(filter, "SUB-HEADING"),
                        label: "H2 Tag",
                        name: "SUB-HEADING",
                        value: !_.isEmpty(values.subHeading) ? values.subHeading : null,
                    },
                ].filter((item) => item !== false && item !== null),
                children: [
                    {
                        id: fieldInfo.children["imageGalleryId"],
                        order: 0,
                        name: "IMAGE-GALLERY",
                        fields: imageGalleryMapper(
                            values.imageGallery,
                            fieldInfo.children.imageGallery
                        ),
                    },

                    {
                        id: fieldInfo.children["videosId"],
                        order: 1,
                        name: "VIDEOS",
                        fields: linksMapper(values.videos, fieldInfo.children.videos, "video"),
                    },
                    {
                        id: fieldInfo.children["panoramasId"],
                        order: 2,
                        name: "PANORAMAS",
                        fields: linksMapper(
                            values.panoramas,
                            fieldInfo.children.panoramas,
                            "panorama"
                        ),
                    },
                    ...constructionsMapper(values.constructions, filter[0].fields),
                ],
            };

            await modifyFCFields({
                variables: {
                    data: {
                        collections: [constructionPlansFc],
                    },
                },
            });
            message.success("Update Success.");
        } catch (error) {
            message.error("Something went wrong!");
        } finally {
            handleRefresh();
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    const imageGalleryMapper = (values, fields) => {
        const imageGalleryItems = values.map((item, index) => {
            return {
                ...(!_.isEmpty(item.id) && { id: item.id }),
                order: index,
                name: "Image",
                label: "Image",
                value: item.keyObj,
                meta_data: {
                    featured: item.isChecked,
                    description: item.caption,
                    key: item.keyObj,
                    alt_text: item.altText,
                },
            };
        });
        return imageGalleryItems;
    };

    const constructionsMapper = (values, fields) => {
        const constructionsFields = values.map((item, index) => {
            const filter = fields.filter((f) => f.id === item.id);
            const constuctionUpdateId = fieldIdChecker(filter[0], "CONSTRUCTION-UPDATE");
            const detailsId = fieldIdChecker(filter[0], "DETAILS");
            return {
                ...(!_.isEmpty(item.id) && { id: item.id }),
                name: "CONSTRUCTIONS",
                order: index + 3,
                fields: [
                    {
                        ...(!_.isEmpty(constuctionUpdateId) && { id: constuctionUpdateId }),
                        name: "CONSTRUCTION-UPDATE",
                        label: "Construction Update",
                        value: !_.isEmpty(item.constructionUpdate) ? item.constructionUpdate : null,
                        order: 0,
                    },
                    {
                        ...(!_.isEmpty(detailsId) && { id: detailsId }),
                        name: "DETAILS",
                        label: "Details",
                        value: !_.isEmpty(item.details) ? item.details : null,
                        order: 2,
                    },
                ],
            };
        });
        return constructionsFields;
    };

    const linksMapper = (values, fields, name) => {
        const linkItems = values.map((item, index) => {
            const fieldId = fields.length - 1 > index ? fields[index].id : null;
            return {
                ...(!_.isEmpty(fieldId) && { id: fieldId }),
                order: index,
                name: _.upperCase(name),
                label: name,
                value: item,
            };
        });
        return linkItems;
    };

    return (
        <ContentContainer
            style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1rem",
                position: "relative",
            }}
        >
            <Spin spinning={loading}>
                {!reinitialize && (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={reinitialize}
                        onSubmit={(values: FormValueType) => {
                            handleSubmit(values);
                        }}
                    >
                        {(formikBag) => (
                            <Forms
                                {...{
                                    formikBag,
                                    brandName,
                                }}
                            />
                        )}
                    </Formik>
                )}
            </Spin>
        </ContentContainer>
    );
};

export default UnitConstructionPlansForms;
