import createDataContext from "./createDataContext";
import mainReducer from "./mainReducer";
import mainAction from "./mainAction";

export const { Context, Provider } = createDataContext(mainReducer, mainAction, {
    userDetails: {
        payload: {
            accessToken: "",
        }
    },
    pageDetails: {
        payload: {
            bradId: "",
            brandName: "",
            // aboutUs: null,
            // facts: null,
            // awards: null,
            // missionVission: null,
            // termsAndCondition: null,
        },
    },
    propertyDetails: {
        payload: {
            overviewId: "",
            unitId: "",
            propertyType: "",
            mainOverview: [],
            brandId: "",
        },
    },
    propertyUnitForms: {
        payload: {
            propertyOverview: [],
            overviewDetails: [],
            locationsLandmarks: [],
            constructionPlans: [],
            floorPlans: [],
        },
    },
});
