import React from "react";
import upperFirst from "lodash/upperFirst";

import { Modal, Row, Col } from "antd";

import CustomButton from "../../../components/CustomButton";
import { WrapperModalComponent } from "./styled";
import { componentList } from "../utils";
import { ModalComponentType } from "../types";

const ModalComponent = ({ visible, onChange, push, onCancel }: ModalComponentType): JSX.Element => (
    <Modal
        visible={visible}
        footer={false}
        width={1000}
        bodyStyle={{ paddingTop: 40 }}
        onCancel={onCancel}
    >
        <Row gutter={24} justify="center">
            {componentList.map((res) => (
                <Col span={6} key={res.label}>
                    <CustomButton
                        type="text"
                        onClick={() =>
                            onChange(
                                {
                                    type: res.type,
                                    fields: res.fields,
                                },
                                push
                            )
                        }
                    >
                        <WrapperModalComponent>
                            <img src={res.image} alt={res.label} />
                        </WrapperModalComponent>
                        {upperFirst(res.label)}
                    </CustomButton>
                </Col>
            ))}
        </Row>
    </Modal>
);
export default ModalComponent;
