import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Login from "./feature/login";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "./context";
import { ApolloProvider } from "@apollo/client";

import apolloClient from "./util/apollo-client";

const { REACT_APP_IAM_URL,  REACT_APP_IAM_REDIRECT_URL } = process.env;

ReactDOM.render(
    <React.StrictMode>
        <head>
            <title>ALI RBG CMS</title>
            <meta property="og:title" content="ALI RBG CMS" />
        </head>
        <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap"
            rel="stylesheet"
        />
        <ApolloProvider client={apolloClient}>
            <Provider>
                <Router>
                    <Switch>
                        <Route exact path={["/", "/admin/login"]} render={() => <Login />} />
                        <Route
                            path="/social/azure/login"
                            render={() => {
                                window.location.href = `${REACT_APP_IAM_URL}/social/azure/login?redirect=${REACT_APP_IAM_REDIRECT_URL}`;

                                return null;
                            }}
                        />
                        <Route path={["/:page/:subPage", "/:page"]} render={() => <App />} />
                    </Switch>
                </Router>
            </Provider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
