/* eslint-disable react/prop-types */
import { InputWrapper, BodyWrapper } from "./styled";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ContentContainer from "../../../../../components/ContentContainer";

import { FieldArray } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, Modal, Radio } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import CustomButton from "../../../../../components/CustomButton";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const renderBodyField = ({ event, values, errors, setFieldValue }) => {
    return (
        <Col span={24} style={{ marginBottom: 25 }}>
            <h4>Body</h4>
            {values.items.map(({ isLink, text }, index) => {
                return (
                    <BodyWrapper key={text}>
                        <p className="label">Text</p>
                        <FormItem
                            name={`items[${index}].text`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <InputWrapper>
                                <Input name={`items[${index}].text`} placeholder="Enter text" />
                            </InputWrapper>
                        </FormItem>

                        <p className="label">Link or Contact</p>
                        <FormItem
                            name={`items[${index}].link`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <InputWrapper>
                                <Input
                                    name={`items[${index}].link`}
                                    placeholder="Enter Link Or Contact"
                                />
                            </InputWrapper>
                        </FormItem>
                        <Radio.Group
                            onChange={(e) =>
                                setFieldValue(
                                    "items",
                                    values.items.map((item, i) => {
                                        if (i === index) {
                                            return { ...item, isLink: e.target.value };
                                        }
                                        return item;
                                    })
                                )
                            }
                            value={isLink}
                        >
                            <Radio value={"1"}>Contact Number</Radio>
                            <Radio value={"2"}>Website Link</Radio>
                        </Radio.Group>
                        <CloseOutlined
                            className="remove-btn"
                            onClick={() => {
                                setFieldValue(
                                    "items",
                                    values.items.filter((el, i) => i !== index)
                                );
                            }}
                        />
                    </BodyWrapper>
                );
            })}
            <CustomButton
                type="dashed"
                style={{ width: "100%", fontSize: 13, fontWeight: 300 }}
                onClick={() => {
                    setFieldValue("items", [...values.items, { link: "", text: "", isLink: true }]);
                }}
            >
                <p style={{ margin: 0 }}>+ Add Body</p>
            </CustomButton>
        </Col>
    );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Forms = ({ formikBag }) => {
    const history = useHistory();
    const [modalVisible, setModalVisible] = useState(false);
    const [preview, setPreview] = useState("");

    const { values, submitForm, errors, setFieldValue, dirty, isValid } = formikBag;

    const handleClosePreview = () => {
        setPreview("");
        setModalVisible(false);
    };

    return (
        <Form>
            <ContentContainer style={{ display: "flex", flexDirection: "column" }}>
                <Row justify="start">
                    <FieldArray
                        name="body"
                        render={(event) =>
                            renderBodyField({ event, values, errors, setFieldValue })
                        }
                    />
                </Row>
            </ContentContainer>

            <Row justify="end" gutter={24} style={{ padding: "0 25px", marginBottom: 25 }}>
                <Col span={3}>
                    <CustomButton
                        disabled={!dirty || !isValid}
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={() => submitForm()}
                    >
                        SAVE
                    </CustomButton>
                </Col>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%" }}
                        onClick={() => history.push(`/manage-company-information`)}
                    >
                        CANCEL
                    </CustomButton>
                </Col>
            </Row>
            <Modal
                visible={modalVisible}
                centered
                footer={null}
                bodyStyle={{ padding: 0, height: 400 }}
                onCancel={handleClosePreview}
                width={700}
                closable={false}
                maskClosable={true}
                destroyOnClose={true}
            >
                <video width="100%" height="100%" style={{ objectFit: "cover" }} controls>
                    <source src={preview} />
                </video>
            </Modal>
        </Form>
    );
};
export default Forms;
