import axios from "axios";

const {
    REACT_APP_IAM_URL,
    REACT_APP_CONTENT_URL,
    REACT_APP_IAM_CLIENT_ID,
    REACT_APP_IAM_CLIENT_SECRET,
} = process.env;

const handleRefreshToken = async () => {
    const refreshToken = localStorage.getItem("REFRESH_TOKEN");
    try {
        const res = await axios({
            method: "POST",
            url: `${REACT_APP_IAM_URL}/oauth/token`,
            data: {
                grantType: "refresh_token",
                clientId: REACT_APP_IAM_CLIENT_ID,
                clientSecret: REACT_APP_IAM_CLIENT_SECRET,
                refreshToken: refreshToken,
            },
        });

        localStorage.setItem("ACCESS_TOKEN", res.data.data.accessToken);
        localStorage.setItem("REFRESH_TOKEN", res.data.data.refreshToken);
    } catch (error) {
        console.log(error);
        localStorage.removeItem("ACCESS_TOKEN");
        localStorage.removeItem("REFRESH_TOKEN");
    }
};

const validateUrl = (url) => {
    if (url.includes(REACT_APP_IAM_URL)) {
        return url.replace(REACT_APP_IAM_URL, "");
    }
    if (url.includes(REACT_APP_CONTENT_URL)) {
        return url.replace(REACT_APP_CONTENT_URL, "");
    }
};

// Add a request interceptor
axios.interceptors.request.use(
    (req) => {
        const accessToken = localStorage.getItem("ACCESS_TOKEN");
        let url = req.url ? validateUrl(req.url) : "";

        if (url !== "/oauth/token" && url !== "/upload/objects")
            req.headers.Authorization = `Bearer ${accessToken}`;

        return req;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    (res) => {
        // Do something with response data
        return res;
    },
    async (error) => {
        const refreshToken = localStorage.getItem("REFRESH_TOKEN");
        const accessToken = localStorage.getItem("ACCESS_TOKEN");

        const originalRequest = error.config;

        if (error.response.status === 401) {
            const {
                data: { message },
            } = error.response;

            if (message !== "Client authentication failed." && refreshToken && accessToken) {
                await handleRefreshToken();
                return await axios(originalRequest);
            }
        }
        return Promise.reject(error);
    }
);

export default axios;
