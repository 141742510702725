import { FormValueTypes, FieldInfoType } from "./manage-properties.types";

export const tabList = [
    {
        label: "Condominium",
        key: "Condominium",
        scope: "create:property-category-condominium",
    },
    {
        label: "Office",
        key: "Office",
        scope: "create:property-category-office",
    },
    {
        label: "Lots",
        key: "Lots",
        scope: "create:property-category-lots",
    },
    {
        label: "Commercial",
        key: "Commercial",
        scope: "create:property-category-commercial",
    },
    {
        label: "Townhouse",
        key: "Townhouse",
        scope: "create:property-category-townhouse",
    },
    {
        label: "Leisure",
        key: "Leisure",
        scope: "create:property-category-leisure",
    },
    {
        label: "Apartments",
        key: "Apartments",
        scope: "create:property-category-apartments",
    },
    {
        label: "House & Lot",
        key: "House and Lot",
        scope: "create:property-category-house-and-lot",
    },
    {
        label: "Lots",
        key: "Estate",
        scope: "create:property-category-estate",
    },
];

export const cardText = [
    {
        htmlEl: "h2",
        varName: "name",
    },
    {
        htmlEl: "span",
        varName: "location",
    },
    {
        htmlEl: "span",
        className: "small",
        varName: "price",
    },
];

export const INIT_VALUES = (propertyType, DefaultPropertyType): FormValueTypes => {
    return {
        overviewDefault: "",
        image: null,
        image2: null,
        projectName: "",
        propertyType: propertyType ? DefaultPropertyType() : "",
        propertyPrice: "",
        propertyPriceRangeLowest: "",
        propertyPriceRangeHighest: "",
        propertySizeLotArea: "",
        propertySizeFloorArea: "",
        terrain: "",
        location: "",
        unitLinkBtn: null,
        gtmCode: "",
        ltsAndApprovalNo: "",
        subcategory: "",
    };
};

export const INIT_FIELD_INFO_VALUES: FieldInfoType = {
    fields: [],
    containerId: "",
};
