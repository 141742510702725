import { EDIT_PAGE, GET_PAGES, REMOVE_PAGE } from "../graphql";
import React, { useEffect, useState, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Context } from "../../../context";
import { ContentMapper } from "../content-mapper";
import CustomDashboard from "@/components/CustomDashboard";
import { userPermission } from "../../../util/user-access";
import kebabCase from "lodash/kebabCase";
import lowerCase from "lodash/lowerCase";
import { cardText } from "../manage-awards.utils";
import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const ContentList = () => {
    const history = useHistory();
    const match = useRouteMatch();

    const {
        state: { brand: brandData, user },
    } = useContext(Context);
    const brandId = brandData?.id;
    const brandName = brandData?.name;

    const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [search, setSearch] = useState<string>("");
    const [contentList, setContentList] = useState<any>(null);
    const permissions = user?.roles[0].permissions;
    const [editPage] = useMutation(EDIT_PAGE);
    const [removePage] = useMutation(REMOVE_PAGE);

    const createAccess = userPermission(permissions, "create:awards_content");
    const updateAccess = userPermission(permissions, "update:awards_content");
    const deleteAccess = userPermission(permissions, "delete:awards_content");
    const [loader, setLoader] = useState<boolean>(false);

    const handleAddBtn = () => {
        history.push(`${match.path}/add`);
    };

    const { error, refetch, loading, data } = useQuery(GET_PAGES, {
        skip: !brandId,
        variables: {
            brand_id: brandId,
            tag: `AWARDS-${brandName}`,
            search: search,
            page: page,
            limit: pageSize,
        },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (data && data.searchPages) {
            data.searchPages.totalCount && setTotalPages(data.searchPages.totalCount);

            const { pages } = data.searchPages;
            const res = pages.map((info) => Object.assign(ContentMapper.map({ ...info })));
            setContentList(res);
        }
        if (error) {
            message.error("Something went wrong.");
        }

        setLoader(false);
    }, [data, error]);

    const handleDelete = (id: string) => {
        const { confirm } = Modal;

        confirm({
            title: "Delete Page?",
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to delete this page?",
            async onOk() {
                try {
                    await removePage({
                        variables: {
                            id: id,
                        },
                    });
                    setLoader(true);
                    refetch();
                    message.success("Successfully deleted page");
                } catch {
                    message.error("Something went wrong");
                } finally {
                    return;
                }
            },
            onCancel() {
                return;
            },
        });
    };

    const handlePageSize = async (pageSize) => {
        setPageSize(pageSize);
    };

    const handlePagination = async (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const handleContent = (menuItem: string, slug: string, id: string) => {
        if (menuItem === "edit") {
            history.push(`${match.path}/edit/${slug}`, { pageId: id });
        }
        if (menuItem === "delete") {
            handleDelete(id);
        }
    };

    return (
        <CustomDashboard
            createAccess={createAccess}
            updateAccess={updateAccess}
            deleteAccess={deleteAccess}
            handleMenu={handleContent}
            cardText={cardText}
            dashboard="Awards"
            loading={loading || loader}
            handleAddBtn={handleAddBtn}
            data={contentList}
            totalPages={totalPages}
            page={page}
            handlePagination={handlePagination}
            pageSize={pageSize}
            handlePageSize={handlePageSize}
        />
    );
};

export default ContentList;
