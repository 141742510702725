import React from "react";
import { useHistory, useParams } from "react-router-dom";

import { FieldArray } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, TimePicker } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import CustomButton from "../../../../components/CustomButton";
import ContentContainer from "../../../../components/ContentContainer";
import FeaturedImages from "../../../../components/FeaturedImages";
import { Title, ListWrapper } from "./styled";
import SelectDay from "./components/SelectDay";
import SeoForm from "@/components/SeoForm";

import { PropType } from "../../manage-showroom.types";
import { rangeDay } from "../../manage-showroom.utils";

const { RangePicker } = TimePicker;

const renderListField = ({ event, values, errors, setFieldValue, letterNumber }) => {
    return (
        <Col span={24}>
            <h1>
                <b>Showroom List</b>
            </h1>
            {values.map((res, index) => {
                const name = `list[${index}]`;

                return (
                    <ListWrapper key={`${name}.title`}>
                        {/* Title */}
                        <FormItem
                            label={"Title"}
                            name={`${name}.title`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name={`${name}.title`} />
                        </FormItem>

                        {/* Day and Time */}
                        <Row justify="start">
                            <Col span={12}>
                                <FormItem
                                    label={"Range of Day"}
                                    name={`${name}.rangeDay`}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    style={{ marginRight: "1em" }}
                                >
                                    <SelectDay
                                        value={res.rangeDay}
                                        options={rangeDay}
                                        name={`${name}.rangeDay`}
                                        mode="tags"
                                        style={{ width: "100%" }}
                                        placeholder="Select day"
                                        setFieldValue={setFieldValue}
                                    />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    label={"Range of Time"}
                                    name={`${name}.timeRange`}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    style={{ marginLeft: "1em" }}
                                >
                                    <RangePicker
                                        value={
                                            res.timeStartRange && res.timeEndRange
                                                ? [res.timeStartRange, res.timeEndRange]
                                                : null
                                        }
                                        name={`${name}.timeRange`}
                                        picker="time"
                                        format="hh:mm a"
                                        use12Hours
                                        style={{ width: "100%" }}
                                        onChange={(e) => {
                                            if (e) {
                                                setFieldValue(`${name}.timeStartRange`, e[0]);
                                                setFieldValue(`${name}.timeEndRange`, e[1]);
                                            } else {
                                                setFieldValue(`${name}.timeStartRange`, null);
                                                setFieldValue(`${name}.timeEndRange`, null);
                                            }
                                        }}
                                    />
                                </FormItem>
                            </Col>
                        </Row>

                        {/* Address */}
                        <FormItem
                            label={"Address"}
                            name={`${name}.address`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name={`${name}.address`} />
                        </FormItem>

                        {/* Email */}
                        <FormItem
                            label={"Email"}
                            name={`${name}.email`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name={`${name}.email`} />
                        </FormItem>

                        {/* Contact Information */}
                        <Row justify="start">
                            <Col span={12}>
                                <FormItem
                                    label={"Telephone Hotline"}
                                    name={`${name}.telHotline`}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    style={{ marginRight: "1em" }}
                                >
                                    <Input name={`${name}.telHotline`} autoComplete={"off"} />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    label={"Telephone Number"}
                                    name={`${name}.telNumber`}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    style={{ marginLeft: "1em" }}
                                >
                                    <Input name={`${name}.telNumber`} autoComplete={"off"} />
                                </FormItem>
                            </Col>
                        </Row>

                        <CloseOutlined
                            className="remove-btn"
                            onClick={() => {
                                event.remove(index);
                            }}
                        />
                    </ListWrapper>
                );
            })}
            <CustomButton
                type="dashed"
                style={{
                    width: "100%",
                    marginBottom: "24px",
                    background: "#fafafa",
                    fontSize: 12,
                }}
                onClick={() => {
                    event.push({
                        title: "",
                        address: "",
                        telHotline: "",
                        telNumber: "",
                        email: "",
                        rangeDay: [],
                        timeStartRange: null,
                        timeEndRange: null,
                    });
                }}
            >
                <p style={{ margin: 0 }}>+ Add Row</p>
            </CustomButton>
        </Col>
    );
};

const Forms = ({ formikBag, brandName }: PropType): JSX.Element => {
    const history = useHistory();
    const params = useParams();
    const letterNumber = /[a-zA-Z]/;

    const title = params.slug ? "Edit" : "Add";

    const { values, submitForm, errors, setFieldValue, isValid, dirty } = formikBag;

    return (
        <Form>
            <Title>{`${title} Showroom`}</Title>
            <ContentContainer style={{ display: "flex", flexDirection: "column", padding: "30px" }}>
                <SeoForm
                    category={"Showroom"}
                    showHeading={false}
                    displayed={brandName === "AVIDA"}
                />
                <Row justify="start">
                    <Col span={24}>
                        <FormItem
                            label={
                                <h1>
                                    <b>Showroom</b> (Default)
                                </h1>
                            }
                            name={"label"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name="label" placeholder="Override Label" />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem
                            label={"Title"}
                            name={"title"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name={"title"} />
                        </FormItem>
                    </Col>
                </Row>

                <FieldArray
                    name={"list"}
                    render={(event) =>
                        renderListField({
                            event,
                            values: values.list,
                            errors,
                            setFieldValue,
                            letterNumber,
                        })
                    }
                />

                <h1>
                    <b>Image Gallery</b>
                </h1>

                <FeaturedImages
                    captionList={values.imagesCaptions}
                    onChange={(e) => {
                        const imagesCaptions = e.map(({ caption }) => caption);
                        const imagesAltTexts = e.map(({ altText }) => altText);
                        setFieldValue(`imagesCaptions`, imagesCaptions);
                        setFieldValue(`images.altText`, imagesAltTexts);
                        setFieldValue(`images`, e);
                    }}
                    path={"showroom"}
                    fileList={values.images}
                    validate={true}
                    imageAltText={brandName === "ALP"}
                />
            </ContentContainer>

            <Row justify="end" gutter={24} style={{ padding: "0 25px 25px 25px" }}>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%" }}
                        onClick={() => history.push(`/manage-showroom`)}
                    >
                        CANCEL
                    </CustomButton>
                </Col>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={() => submitForm()}
                        disabled={!dirty || !isValid}
                    >
                        SAVE
                    </CustomButton>
                </Col>
            </Row>
        </Form>
    );
};

export default Forms;
