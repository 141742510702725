import styled from "styled-components";
import { Tabs } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import UploadSingleFile from "../../../../components/UploadSingleFile";

export const StyledTabs = styled(Tabs)`
    .ant-tabs-nav-list > .ant-tabs-tab {
        padding: 12px 30px;
    }

    .ant-tabs-content {
        flex-direction: column;
    }
`;

export const Title = styled.h1`
    margin-left: 24px;
    font-weight: bold;
`;

export const ListWrapper = styled.div`
    border: 1px solid #ddd;
    padding: 15px 25px 0;
    border-radius: 5px;
    position: relative;
    margin-bottom: 15px;

    .add-row {
        background: #fafafa;
        margin-bottom: 25px;

        p {
            font-size: 12px;
            font-weight: 300;
        }
    }

    .remove-btn {
    }
`;

export const RemoveButton = styled(CloseOutlined)`
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
`;

export const StyledUploadImage = styled(UploadSingleFile)`
    width: 90%;
    height: 220px;
    display: block;
    overflow: hidden;
    background: #fff;

    .ant-upload {
        display: block;

        .upload-button {
            height: 220px;
            width: 100%;
            display: block;
        }
    }

    .upload-button {
        height: 220px;
        width: 100%;
        display: block;
    }

    .uploaded-file {
        height: 220px;
        width: 100%;

        img {
            height: 220px;
            width: 100%;
        }
    }

    .remove-file {
        top: 6px;
        right: 10px;
    }
`;
