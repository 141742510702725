import * as yup from "yup";
import moment from "moment";

export const validationSchema = yup.object().shape({
    careerTitle: yup.string().required("Career Title is required"),
    employmentType: yup.string().required("Select Department").nullable(),
    location: yup.string().required("Location is required"),
    status: yup.boolean(),
});

export const validationSchemaNoDepartment = yup.object().shape({
    careerTitle: yup.string().required("Career Title is required"),
    location: yup.string().required("Location is required"),
    status: yup.boolean(),
});

export const columns = [
    {
        title: "Title",
        dataIndex: "title",
        sorter: true,
    },
    {
        title: "Description",
        dataIndex: "job_description",
        ellipsis: true,
    },
    {
        title: "Department",
        dataIndex: "empType",
        filterMultiple: false,
    },
    {
        title: "Location",
        dataIndex: "location",
        filterMultiple: false,
    },
];

export const defaultInitialValue = {
    careerTitle: "",
    employmentType: "",
    applyBefore: moment(),
    location: "",
    jobDescriptionDefault: "",
    jobDescriptionBody: "",
    qualificationDefault: "",
    qualificationBody: "",
    tags: [],
    status: true,
    seoCareersTitle: "",
    seoCareersDescription: "",
    careersHeadingTag: "",
};
