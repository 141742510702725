import styled from "styled-components";

import { Card, Button } from "antd";
import { Checkbox } from "formik-antd";

export const InputWrapper = styled.div`
    position: relative;

    .remove-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
`;

export const StyledCard = styled(Card)`
    position: relative;
    margin-bottom: 15px;
    width: 200px;
    border: none;

    .featuredCont {
        width: 200px;
        height: 200px;
        // background: #d3d3d3;
        position: relative;

        .imgCont {
            width: 200px;
        }
        img {
            width: 200px;
            height: 200px;
            object-fit: cover;
        }
    }
    .checkbox {
        position: absolute;
        top: 0;
        left: 0;

        color: ${(props) => (props.checked ? "white" : "#000")};
    }

    .ant-card-head {
        position: absolute;
        top: 0.15rem;
        right: 0;
        color: ${(props) => (props.checked ? "white" : "#000")};
        z-index: 500;
        min-height: 0;
        padding: 0 10px;
        border: 0;
    }

    .ant-card-cover {
        background-color: #c2c2c2;
    }

    .ant-card-extra {
        padding: 0;
        float: none;
        a {
            color: #fff;
        }
    }
    .ant-card-body {
        padding: 0px;
        padding-top: 5px;
        overflow: hidden;

        .ant-input {
            max-height: 80px;
            min-height: 80px;
            height: 80px;
        }
    }
`;

export const StyledFeaturedCheckbox = styled(Checkbox)`
    width: 200px;
    padding: 2px 8px;
    background: ${(props) => (props.checked ? "#147d56" : "rgba(255, 255, 255, 0.8)")};

    .ant-checkbox {
        outline: 1px solid #fff;
    }
    .ant-checkbox + span {
        color: ${(props) => (props.checked ? "#fff" : "#000")};
    }
`;

export const AddBtn = styled(Button)`
    width: 200px;
    height: 200px;
`;

export const DeleteBtn = styled(Button)`
    height: 20px;
    padding: 0;
    margin-top: -3px;

    .remove-btn.active-checked {
        color: #000;
    }
`;

export const BrokenImageContainer = styled.div`
    position: relative;

    img {
        width: 100%;
        opacity: 10%;
    }

    p {
        position: absolute;
        top: 115px;
        background: darkgrey;
        width: 100%;
        text-align: center;
        padding: 12px;
        font-weight: 500;
        color: #000;
    }
`;

export const ImageNotScannedContainer = styled.div`
    position: relative;

    p {
        position: absolute;
        top: 70px;
        background: darkgrey;
        width: 100%;
        text-align: center;
        padding: 12px;
        font-weight: 500;
        color: #000;
        opacity: 80%;
    }
`;

export const VideoWrapper = styled.div`
    position: relative;

    .video-banner {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(50%);
    }
`;

export const AltTextInput = styled.input`
    max-height: 40px;
    min-height: 40px;
    height: 40px;
    width: 100%;
    padding: 0 10px;
    margin: 5px 0;
`;
