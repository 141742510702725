import styled from "styled-components";
import { Tabs } from "antd";

// tabs
export const TabWrapper = styled.div`
    margin: 0 24px 0;

    .ant-tabs > .ant-tabs-nav {
        margin-bottom: 0;
    }

    .ant-tabs-nav-wrap {
        background-color: #d0d0d0;
    }

    .ant-tabs-nav-list > .ant-tabs-tab-with-remove {
        margin: 0 !important;
    }

    .ant-tabs-nav-list > .ant-tabs-tab-with-remove.ant-tabs-tab-active {
        border: 2px solid #00714b;
        margin: 0;
    }
    .ant-tabs-nav-add {
        background: #d0d0d0 !important;
        color: #fff;
        font-weight: 500;
        outline: none;
        border: 0 !important;
        border-radius: 0 !important;
        height: 3.15rem;
    }
`;

export const StyledTabs = styled(Tabs)`
    margin-bottom: 25px;

    .ant-tabs-nav-list > .ant-tabs-tab {
        padding: 12px 30px;
    }

    .ant-tabs-content {
        flex-direction: column;
    }
`;
