import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import isEmpty from "lodash/isEmpty";

import { User } from "../../entities/User";
import { UserMapper } from "./dto/UserMapper";
import { UserPaginationResponse } from "./dto/UserPaginationResponse";
import { UserActivity } from "../../entities/UserActivity";
import { UserActivityMapper } from "./dto/UserActivityMapper";
import { UserActivityPaginationResponse } from "./dto/UserActivityPaginationResponse";
import apiClient from "../../util/api-client";

const BASE_URL = process.env.REACT_APP_IAM_URL;

export const addUser = (data: []): any => {
    return apiClient({
        method: "POST",
        url: `${BASE_URL}/users`,
        data: data,
    })
        .then((res) => {
            const { id } = res.data.data;

            return id;
        })
        .catch((error) => {
            console.log(error);
        });
};

// Handle response data mapping here
export const getUsers = async (query: any): Promise<any> => {
    return apiClient
        .get(`${BASE_URL}/users`, {
            params: query,
        })
        .then((response: AxiosResponse) => {
            // Alias data variable to apiResponse
            const { data: apiResponse } = response;

            // Map user using UserMapper
            apiResponse.data = apiResponse.data.map((user: User) => UserMapper.map(user));

            return apiResponse;
        })
        .catch((error: AxiosError) => {
            throw new Error(error.response?.data.message);
        }) as Promise<UserPaginationResponse>;
};

export const userDetails = (userId: string): any => {
    return apiClient({
        method: "get",
        url: `${BASE_URL}/users/${userId}`,
    })
        .then((response) => {
            const { roles, tenants, createdAt } = response.data.data;

            const roleName = !isEmpty(roles) ? roles[0].name : "";
            const brandName = !isEmpty(tenants) ? tenants[0].name : "";
            const convertedDate = createdAt ? moment(createdAt).format("MMM DD, YYYY") : null;

            return {
                ...response.data.data,
                createdAt: convertedDate,
                roleName,
                brandName,
            };
        })
        .catch((error: AxiosError) => {
            throw new Error(error.response?.data.message);
        });
};

export const updateUser = (userId: string, data: object): any => {
    return apiClient({
        method: "put",
        url: `${BASE_URL}/users/${userId}`,
        data,
    })
        .then((response) => {
            const { roles, tenants, createdAt } = response.data.data;

            const roleName = !isEmpty(roles) ? roles[0].name : "";
            const brandName = !isEmpty(tenants) ? tenants[0].name : "";
            const convertedDate = createdAt ? moment(createdAt).format("MMM d, YYYY") : null;

            return {
                ...response.data.data,
                createdAt: convertedDate,
                roleName,
                brandName,
            };
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getRole = (id: string): any => {
    return apiClient({
        method: "GET",
        url: `${BASE_URL}/roles?tenantId=${id}`,
    })
        .then((res) => {
            const data = res.data.data;

            return data.map(({ id, name }) => ({
                label: name,
                value: id,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getBrand = (): any => {
    return apiClient({
        method: "GET",
        url: `${BASE_URL}/tenants`,
    })
        .then((res) => {
            const data = res.data.data;

            return data.map(({ id, name }) => ({
                label: name,
                value: id,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getUserActivityLogs = async (query: any, userId): Promise<any> => {
    return apiClient
        .get(`${BASE_URL}/users/${userId}/activities`, {
            params: query,
        })
        .then((response: AxiosResponse) => {
            // Alias data variable to apiResponse
            const { data: apiResponse } = response;

            // Map user using UserActivityMapper
            apiResponse.data = apiResponse.data.map((userActivity: UserActivity) =>
                UserActivityMapper.map(userActivity)
            );
            return apiResponse;
        })
        .catch((error: AxiosError) => {
            throw new Error(error.response?.data.message);
        }) as Promise<UserActivityPaginationResponse>;
};
