import { onError } from "@apollo/client/link/error";
import reToken from "./refresh-token";
import { message } from "antd";

import {
    ApolloClient,
    HttpLink,
    NormalizedCacheObject,
    InMemoryCache,
    ApolloLink,
    Observable,
} from "@apollo/client";
const { REACT_APP_CONTENT_URL } = process.env;

const cache = new InMemoryCache();

const httpLink = new HttpLink({ uri:  `${REACT_APP_CONTENT_URL}/graphql` });

const authMiddleware = new ApolloLink((operation, forward) => {
   operation.setContext(({headers}) => ({
     headers: {
       authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}` || null,
      ...headers
     }
   }));
   return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions) {
        const errStatus = err.extensions.exception.status;
        const sessionErrCode = [401];
        if (sessionErrCode.includes(errStatus)) {
          return new Observable(observer => {
            reToken()
              .then(refreshResponse => {
                message.info('Refreshing token and trying again');
                console.log('Refreshing token and trying again');
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    ...headers,
                    authorization: `Bearer ${refreshResponse}` || null,
                  }
                }));
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer)
                };
                forward(operation).subscribe(subscriber);
              })
              .catch(error => {
                console.log(error, 'REFETCH ERROR')
                observer.error(error);
              });
          });
        }
      }
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const link =  ApolloLink.from([
  errorLink,
  authMiddleware,
  httpLink
]);

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache,
    link : link
});

export default client;
