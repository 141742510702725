import React from "react";
import _ from "lodash";

import { Form, FormItem, Input } from "formik-antd";
import { FieldArray } from "formik";
import { Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import CustomButton from "../../../../components/CustomButton";
import CustomSelect from "../../../../components/CustomSelect";

import { Title, SectionWrapper } from "./styled";
import { emailCategory } from "./constant";

interface Props {
    formikBag: any;
    createAccess?: boolean;
    updateAccess?: boolean;
    deleteAccess?: boolean;
}

const Forms: React.FC<Props> = ({ formikBag, createAccess, updateAccess, deleteAccess }) => {
    const { values, submitForm, errors, setFieldValue, isValid, dirty } = formikBag;

    const renderEmail = ({ values, event }) => {
        return (
            <>
                {values.emails?.map((item, i) => (
                    <SectionWrapper key={`emails_${i}`}>
                        <CloseOutlined
                            style={{ display: !deleteAccess ? "none" : "block" }}
                            className="remove-btn section-remove-btn"
                            onClick={() => {
                                values.emails.length > 1 && event.remove(i);
                                if (values.emails.length === 1) {
                                    event.replace(0, {
                                        id: "",
                                        email: undefined,
                                        category: "",
                                    });
                                    setFieldValue("validateEmail", false);
                                }
                            }}
                        />
                        <Row gutter={10}>
                            <Col span={12}>
                                <FormItem
                                    label={"Email Category"}
                                    name={`emails[${i}].category`}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <CustomSelect
                                        name={`emails[${i}].category`}
                                        placeholder={"Select Email Category"}
                                        options={emailCategory}
                                        disabled={
                                            !updateAccess ? (!item.category ? false : true) : false
                                        }
                                    />
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem
                                    label={"Email"}
                                    name={`emails[${i}].email`}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <Input
                                        name={`emails[${i}].email`}
                                        disabled={
                                            !updateAccess ? (!item.email ? false : true) : false
                                        }
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                    </SectionWrapper>
                ))}
                <CustomButton
                    type="dashed"
                    className="add-section"
                    style={{
                        width: "100%",
                        fontSize: 13,
                        fontWeight: 300,
                        display: !createAccess ? "none" : "block",
                    }}
                    onClick={() => {
                        event.push({
                            id: "",
                            email: "",
                            category: "",
                        });
                    }}
                >
                    <p style={{ margin: 0 }}>+ Add Row</p>
                </CustomButton>
            </>
        );
    };

    return (
        <Form>
            <Title>Email</Title>
            <div style={{ display: "flex", flexDirection: "column", padding: "30px" }}>
                <FieldArray name="emails" render={(event) => renderEmail({ values, event })} />
            </div>

            <Row justify="end" gutter={24} style={{ padding: "0 25px 25px 25px" }}>
                <Col span={3}>
                    <CustomButton
                        style={{
                            width: "100%",
                            marginRight: 15,
                            display: !createAccess && !updateAccess ? "none" : "block",
                        }}
                        type="primary"
                        onClick={() => submitForm()}
                        disabled={!isValid || !dirty}
                    >
                        SAVE
                    </CustomButton>
                </Col>
            </Row>
        </Form>
    );
};

export default Forms;
