import isEmpty from "lodash/isEmpty";
import numeral from "numeral";

import { Property } from "../../../entities/Property";

export class PropertyMapper {
    static map(property: Property): Property {
        property.location = "";

        if (!isEmpty(property.containers)) {
            const overviewDefaultContainer =
                !isEmpty(property.containers.filter(container => container.name === `${property.name} Overview Default Container`)) &&
                property.containers.filter(container => container.name === `${property.name} Overview Default Container`)[0];

            const overviewDefaultFields =
                overviewDefaultContainer &&
                !isEmpty(overviewDefaultContainer.field_collections) &&
                !isEmpty(overviewDefaultContainer.field_collections[0].fields) &&
                overviewDefaultContainer.field_collections[0].fields;

            if (overviewDefaultFields) {
                let priceLow = 0;
                let priceHigh = 0;

                overviewDefaultFields.forEach(field => {
                    if (field.value) {
                        if (field.name === "PROJECT-NAME") {

                            property.name = field.value;

                        } else if (field.name === "PROPERTY-PRICE") {

                            property.price = field.value;

                        } else if (field.name === "PROPERTY-PRICE-RANGE-LOWEST") {

                            priceLow = numeral(field.value).format("0.0a");

                        } else if (field.name === "PROPERTY-PRICE-RANGE-HIGHEST") {

                            priceHigh = numeral(field.value).format("0.0a");

                        } else if (field.name === "LOCATION") {

                            property.location = field.value;

                        } else if (field.name === "Image" && field.order === 1) {

                            property.imgURL = field.value;

                        }         
                    }
                });

                property.price = priceLow !== 0 && priceHigh !== 0 ? `PHP ${priceLow} - ${priceHigh}` : property.price &&  `PHP ${property.price}`;
            }

            const unitsContainer =
                !isEmpty(property.containers.filter(container => container.name === `${property.name} Units`)) &&
                property.containers.filter(container => container.name === `${property.name} Units`)[0];

            const overviewDetails = 
                unitsContainer &&
                !isEmpty(unitsContainer.field_collections) &&
                !isEmpty(unitsContainer.field_collections[0].children.filter(child => child.name === "OVERVIEW-DETAILS")) &&
                unitsContainer.field_collections[0].children.filter(child => child.name === "OVERVIEW-DETAILS")[0];

            property.status =
                overviewDetails &&
                !isEmpty(overviewDetails.fields) &&
                !isEmpty(overviewDetails.fields.filter(field => field.name === "PROPERTY-STATUS")) &&
                !isEmpty(overviewDetails.fields.filter(field => field.name === "PROPERTY-STATUS")[0].value) &&
                overviewDetails.fields.filter(field => field.name === "PROPERTY-STATUS")[0].value;
        }
        
        delete property.containers;
        delete property.__typename;

        return property;
    }
}