import styled from "styled-components";
import { Upload, Tag, Select } from "antd";

export const UploadStyled = styled(Upload)`
    .ant-upload {
        display: block;
        width: 100%;
    }
`;

export const ErrorMessage = styled.p`
    color: red;
`;

export const UploadList = styled(Tag)`
    position: relative;
    display: block;
    width: 100%;
    color: black;
    font-size: 1em;

    .file-name {
        display: block;
        padding-right: 15px;
        margin: 0;
        line-height: 1.5715;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .anticon-close {
        position: absolute;
        top: 50%;
        right: 7px;
        transform: translateY(-50%);
        color: black;
    }
`;

export const StyledClipboard = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #bebebe;
    border-radius: 3px;
    width: 100%;
    margin-top: 15px;

    span {
        word-break: break-all;
        max-width: 300px;
    }

    svg {
        font-size: 20px;
    }
`;
