import styled from "styled-components";

export const InputWrapper = styled.div`
    position: relative;

    .remove-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
`;

export const SectionWrapper = styled.div`
    border: 1px solid #ddd;
    padding: 35px 25px 25px;
    border-radius: 5px;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;

    .add-row {
        background: #fafafa;
        margin-bottom: 25px;

        p {
            font-size: 13px;
            font-weight: 300;
        }
    }

    .add-section {
        p {
            font-size: 13px;
            font-weight: 300;
        }
    }
    .remove-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
`;

export const ContentWrapper = styled.div`
    border: 1px solid #ddd;
    padding: 15px 25px 0;
    border-radius: 5px;
    position: relative;
    margin-bottom: 15px;

    .remove-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
`;

export const Indicator = styled.div`
    position: absolute;
    top: -55px;
    left: -35px;
    width: 60px;
    height: 100px;
    background: #d8d8d8;
    transform: rotate(45deg);
`;
