import { CompanyInfo } from "../../../entities/CompanyInfo";
import moment from "moment";

export class CompanyInfoMapper {
    static map(companyInfo: CompanyInfo, brand: any): CompanyInfo {
        companyInfo.brand = typeof brand === "string" ? brand : brand.filter(brand => brand.id === companyInfo.brand_id)[0];
        companyInfo.created_at = companyInfo.created_at
            ? moment(companyInfo.created_at).format("MMM DD, YYYY")
            : null;
        companyInfo.updated_at = companyInfo.updated_at
            ? moment(companyInfo.updated_at).format("MMM DD, YYYY")
            : null;

        return companyInfo;
    }
}
