import React from "react";
import moment from "moment";

import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, Spin, DatePicker } from "antd";

import CustomButton from "../../../../components/CustomButton";
import CustomSelect from "../../../../components/CustomSelect";
import CustomTag from "../../../../components/CustomTag";
import Richtext from "../../../../components/Richtext";
import SeoForm from "@/components/SeoForm";

import { TagWrapper, RichtextStyled } from "./styled";

const monthDayYearFormat = "MM/DD/YYYY";

interface Props {
    formikBag: any;
    handleSlider: () => void;
    employmentTypeData: any;
    handleResetForm?: (val: any) => void;
    loading: boolean;
    handleAddOption: (val: any) => void;
    handleRemoveOption: (val: any) => void;
    brandName?: string;
}

const Forms = ({
    formikBag,
    handleSlider,
    employmentTypeData,
    handleResetForm,
    loading,
    handleAddOption,
    handleRemoveOption,
    brandName,
}: Props): JSX.Element => {
    const { submitForm, values, setFieldValue, resetForm, isValid, dirty } = formikBag;

    return (
        <Spin spinning={loading}>
            <SeoForm category={"Careers"} />
            <Form>
                {/* Career Title */}
                <FormItem
                    label={"Career Title"}
                    name={"careerTitle"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input name="careerTitle" placeholder="Enter Career Title" />
                </FormItem>

                {/* Employment Type / Department */}
                {brandName !== "ALP" && (
                    <FormItem
                        label={"Department"}
                        name={"employmentType"}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <CustomSelect
                            name={"employmentType"}
                            placeholder={"Select Department"}
                            options={employmentTypeData}
                        />
                    </FormItem>
                )}

                {/* Apply Before */}
                <FormItem
                    label={"Apply Before"}
                    name={"applyBefore"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <DatePicker
                        style={{ width: "100%" }}
                        format={monthDayYearFormat}
                        onChange={(date, dateString) => {
                            setFieldValue("applyBefore", dateString ? dateString : null);
                        }}
                        value={
                            values.applyBefore
                                ? moment(values.applyBefore, monthDayYearFormat)
                                : null
                        }
                    />
                </FormItem>

                {/* Location */}
                <FormItem
                    label={"Location"}
                    name={"location"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input name="location" placeholder="Enter Location" />
                </FormItem>

                {/* Job Description */}
                <FormItem
                    label={"Job Description (Default)"}
                    name={"jobDescriptionDefault"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input name="jobDescriptionDefault" placeholder="Override Label" />
                </FormItem>

                <RichtextStyled>
                    <Richtext
                        toolbar={["bold", "italic", "link", "bulletedList"]}
                        data={values.jobDescriptionBody}
                        onChange={(e) => setFieldValue("jobDescriptionBody", e)}
                    />
                </RichtextStyled>

                {/* Qualifications */}
                <FormItem
                    label={"Qualifications (Default)"}
                    name={"qualificationDefault"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input name="qualificationDefault" placeholder="Override Label" />
                </FormItem>

                <RichtextStyled>
                    <Richtext
                        toolbar={["bold", "italic", "link", "bulletedList"]}
                        data={values.qualificationBody}
                        onChange={(e) => setFieldValue("qualificationBody", e)}
                    />
                </RichtextStyled>

                {/* Tags */}
                <TagWrapper>
                    <h3>Tags</h3>
                    <div className={"wrapper"}>
                        <CustomTag name="tags" />
                    </div>
                </TagWrapper>

                {/* Career Visibility */}
                <FormItem
                    label={"Career Visibility"}
                    name={"status"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <CustomSelect
                        name={"status"}
                        placeholder={"Select Status"}
                        options={[
                            {
                                value: true,
                                label: "Show",
                            },
                            {
                                value: false,
                                label: "Hidden",
                            },
                        ]}
                    />
                </FormItem>

                <Row justify={"space-between"} style={{ marginBottom: 15 }}>
                    <Col span={11}>
                        <CustomButton
                            style={{ width: "100%", marginRight: 15 }}
                            type="primary"
                            onClick={() => submitForm()}
                            disabled={!dirty || !isValid}
                        >
                            SAVE
                        </CustomButton>
                    </Col>
                    <Col span={11}>
                        <CustomButton
                            style={{ width: "100%" }}
                            onClick={() => {
                                handleResetForm && handleResetForm(resetForm);
                                handleSlider();
                            }}
                        >
                            CANCEL
                        </CustomButton>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default Forms;
