import moment from "moment";
import _ from "lodash";

import { getThumbnail } from "../../../util/get-thumbnail";

import { HeroBanner } from "../../../entities/HeroBanner";

export class HeroBannerMapper {
    static map(banner: HeroBanner): HeroBanner {
        banner.slug = banner.page && banner.page.slug ? banner.page.slug : "";
        banner.category = _.startCase(_.toLower(banner.category));
        banner.imgURL =
            banner.video_link && getThumbnail(banner.video_link)
                ? getThumbnail(banner.video_link)
                : banner.image;
        banner.videoURL =
            banner.video_link && getThumbnail(banner.video_link) ? banner.video_link : undefined;
        banner.name = banner.title ? banner.title : banner.name;
        banner.excerpt = banner.description;
        banner.seo_title = banner.seo_title ? banner.seo_title : "";
        banner.seo_description = banner.seo_description ? banner.seo_description : "";
        banner.heading_tag = banner.heading_tag ? banner.heading_tag : "";
        banner.date = banner.updated_at && moment(banner.updated_at).format("MMM DD, YYYY");

        delete banner.featured;
        delete banner.__typename;
        delete banner.page;

        return banner;
    }
}
