import React, { useRef, useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";

import { Modal, Input, Tabs } from "antd";

import { TabWrapper } from "./styled";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;
const { TabPane } = Tabs;

interface Props {
    unitTabs: any[];
    onChange: (array: string[], actionType: string, key?: any) => void;
    onChangeTabs?: (array: string[]) => void;
    defaultActiveKey?: string;
    onTabClick?: any;
}

const UnitTabs: React.FC<Props> = ({
    unitTabs,
    onChange,
    onChangeTabs,
    defaultActiveKey,
    onTabClick,
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [editingTab, setEditingTab] = useState(null);
    const [tabWidth, setTabWidth] = useState<any>(0);

    useEffect(() => {
        const currentTab = ref.current;
        if (currentTab) {
            const _tabWidth = currentTab.children[0].children[0].clientWidth;
            setTabWidth(_tabWidth);
        }
    }, [ref, unitTabs]);

    const onAddUnitTabs = () => {
        let newUnitTabList = [...unitTabs, { id: "", tabName: "NEW-UNIT" }];
        const key = newUnitTabList.length - 1;
        onChange(newUnitTabList, "add", key);
    };

    const onRemoveUnitTabs = (key) => {
        const newUnitTabList = unitTabs.filter((item, index) => parseInt(key) !== index);
        onChange(newUnitTabList, "remove", key);
    };

    const handleEditTabName = (e, tabKey) => {
        let newList = unitTabs;

        if (!isEmpty(e.target.value)) {
            newList[tabKey].tabName = e.target.value;
            onChange(newList, "edit", tabKey);
            setEditingTab(null);
        }
    };

    const handleUnitTab = (e) => {
        onChangeTabs && onChangeTabs(e);
    };

    const showConfirm = (e, onCloseClick) => {
        const onClickHandle = onCloseClick;
        e.persist();
        confirm({
            title: "Do you want to delete these items?",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                onClickHandle(e);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const renderUnitTabBars = (props, DefaultTabBar) => {
        return (
            <>
                <DefaultTabBar ref={ref} {...props} className="site-custom-tab-bar">
                    {(node) => {
                        const tabDiv = node.props.children[0];
                        const closeButton = node.props.children[1];

                        return (
                            <div
                                ref={node.ref}
                                id={node.id}
                                onClick={node.props.onClick}
                                key={node.key}
                                className={node.props.className}
                            >
                                <div
                                    role={"tab"}
                                    id={tabDiv.props.id}
                                    ref={tabDiv.props.ref}
                                    onClick={() => {
                                        if (
                                            props.activeKey === node.key &&
                                            props.activeKey !== editingTab
                                        ) {
                                            setEditingTab(node.key);
                                        }
                                    }}
                                    className={tabDiv.props.className}
                                >
                                    {editingTab === node.key && props.activeKey === node.key ? (
                                        <Input
                                            name="unit-tab"
                                            onPressEnter={(e) => handleEditTabName(e, node.key)}
                                            onBlur={(e) => handleEditTabName(e, node.key)}
                                            defaultValue={tabDiv.props.children}
                                        />
                                    ) : tabDiv.props.children !== "" ? (
                                        tabDiv.props.children
                                    ) : (
                                        "[EMPTY]"
                                    )}
                                </div>
                                {unitTabs.length > 1 && props.activeKey === node.key && (
                                    <button
                                        className={closeButton.props.className}
                                        onClick={(e) => showConfirm(e, closeButton.props.onClick)}
                                    >
                                        {closeButton.props.children}
                                    </button>
                                )}
                            </div>
                        );
                    }}
                </DefaultTabBar>
            </>
        );
    };

    return (
        <TabWrapper style={{ marginBottom: "25px" }}>
            <Tabs
                defaultActiveKey={defaultActiveKey}
                type="editable-card"
                addIcon={
                    <div>
                        {tabWidth && ref.current && tabWidth > ref.current.clientWidth ? (
                            <PlusOutlined />
                        ) : (
                            <>Add Tower / Phase +</>
                        )}
                    </div>
                }
                style={{ width: "100%", backgroundColor: "#fff" }}
                onEdit={(key, action) => {
                    if (action === "add") {
                        onAddUnitTabs();
                    } else if (action === "remove") {
                        onRemoveUnitTabs(key);
                    }
                }}
                onTabClick={onTabClick}
                renderTabBar={(props, defaultTabBar) => renderUnitTabBars(props, defaultTabBar)}
                onChange={handleUnitTab}
            >
                {unitTabs.map((pane, index) => (
                    <TabPane key={index} tab={pane.tabName} />
                ))}
            </Tabs>
        </TabWrapper>
    );
};

export default UnitTabs;
