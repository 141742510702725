import React from "react";
import { Switch, Route, useRouteMatch, RouteComponentProps } from "react-router-dom";
import { DocumentRequirements, FAQ, PaymentChannel, ReservationProcess } from "./components";

const ManageHomeBuyingGuide = (): JSX.Element => {
    const match = useRouteMatch();

    return (
        <>
            <Switch>
                <Route
                    exact
                    path={`${match.path}/reservation-process`}
                    render={(props: RouteComponentProps) => <ReservationProcess />}
                />
                <Route
                    exact
                    path={`${match.path}/document-requirements`}
                    render={(props: RouteComponentProps) => <DocumentRequirements />}
                />
                <Route
                    exact
                    path={`${match.path}/payment-channel`}
                    render={(props: RouteComponentProps) => <PaymentChannel />}
                />
                <Route
                    exact
                    path={`${match.path}/faq`}
                    render={(props: RouteComponentProps) => <FAQ />}
                />
            </Switch>
        </>
    );
};

export default ManageHomeBuyingGuide;
