import React, { ReactNode } from "react";

import { Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { HeaderStyle, HeaderTitle } from "./styled";

interface Props {
    onClose?: () => void;
    children?: ReactNode;
    title: string;
    closable?: boolean;
    visible: boolean;
    width?: number;
}

const CustomDrawer: React.FC<Props> = ({ onClose, children, title, visible, width, closable }) => (
    <Drawer
        data-index="drawer"
        title={<HeaderTitle>{title}</HeaderTitle>}
        visible={visible}
        closable={closable}
        onClose={onClose}
        headerStyle={HeaderStyle}
        width={width ? width : 350}
        closeIcon={<CloseOutlined style={{ color: "#fff" }} />}
    >
        {children}
    </Drawer>
);

export default CustomDrawer;
