import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { Breadcrumb } from "antd";

import GalleryList from "./components/gallery-list";
import GalleryForms from "./components/gallery-forms";

export const StyledBreadcrumb = styled(Breadcrumb)`
    background: #fff;
    display: flex;
    padding: 16px 25px;
`;

const ManageGallery = (): JSX.Element => {
    const match = useRouteMatch();
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={`${match.path}`}
                    render={(props: any) => <GalleryList {...props} />}
                />
                <Route
                    exact
                    path={`${match.path}/add`}
                    render={(props: any) => <GalleryForms {...props} />}
                />
                <Route
                    exact
                    path={`${match.path}/:slug`}
                    render={(props: any) => <GalleryForms {...props} />}
                />
            </Switch>
        </>
    );
};

export default ManageGallery;
