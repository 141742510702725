import React from "react";

import { FormItem, Input } from "formik-antd";
import { Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { WrapperForm, MediaContainer } from "../styled";

import { ComponentPropTypes } from "../../types";

const ComponentTwo = ({
    index,
    setFieldValue,
    fieldsArrayEvent,
    values,
}: ComponentPropTypes): JSX.Element => {
    return (
        <WrapperForm>
            <Row gutter={24}>
                <Col span={12}>
                    <MediaContainer
                        value={values.components[index].fields.image}
                        accept={"image/jpeg,image/jpg,image/png"}
                        noteMessage={".jpeg, .jpg, .png (max 50 MB)"}
                        onChange={(e) => {
                            setFieldValue(`components[${index}].fields.image`, e);
                        }}
                    >
                        <h1>Upload Image +</h1>
                    </MediaContainer>
                </Col>
                <Col span={12}>
                    <FormItem
                        label={"Description"}
                        name={`components[${index}].fields.description`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input.TextArea
                            name={`components[${index}].fields.description`}
                            style={{ height: 258 }}
                        />
                    </FormItem>
                </Col>
            </Row>
            <CloseOutlined
                className="remove-btn"
                onClick={() => {
                    fieldsArrayEvent.remove(index);
                }}
            />
        </WrapperForm>
    );
};

export default ComponentTwo;
