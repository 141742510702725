import { Role } from "../../../entities/Role";
import moment from "moment";

export class RoleMapper {
    static map(role: Role): Role {
        role.brand = role.tenant && role.tenant.name ? role.tenant.name : null;
        role.createdAt = role.createdAt ? moment(role.createdAt).format("MMM DD, YYYY") : null;
        role.updatedAt = role.updatedAt ? moment(role.updatedAt).format("MMM DD, YYYY") : null;

        delete role.tenant;
        delete role.description;

        return role;
    }
}
