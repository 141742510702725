import React from "react";
import { Row, Col } from "antd";
import { FormItem, Input } from "formik-antd";
import { StyledForm } from "./style";

function SeoForm({ category, displayed = true, showHeading = true, brand = "" }) {
    return (
        <StyledForm style={{ display: displayed ? "block" : "none" }}>
            <span></span>
            <Row gutter={24}>
                <Col span={24}>
                    <FormItem
                        label={`Seo Title`}
                        name={`seo${category}Title`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input name={`seo${category}Title`} />
                    </FormItem>
                </Col>
                <Col span={24}>
                    <FormItem
                        label={`Seo Description`}
                        name={`seo${category}Description`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input.TextArea name={`seo${category}Description`} />
                    </FormItem>
                </Col>
                {showHeading ? (
                    <Col span={24}>
                        <FormItem
                            label={`H1 Tag`}
                            name={`${
                                category === "FloorPlan"
                                    ? "floorPlan"
                                    : category === "ConstructionPlan"
                                    ? "constructionPlan"
                                    : category.toLowerCase()
                            }HeadingTag`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className="h1-tag"
                        >
                            <Input
                                name={`${
                                    category === "FloorPlan"
                                        ? "floorPlan"
                                        : category === "ConstructionPlan"
                                        ? "constructionPlan"
                                        : category.toLowerCase()
                                }HeadingTag`}
                            />
                        </FormItem>
                    </Col>
                ) : null}
                {brand === "ALP" ? (
                    <Col span={24}>
                        <FormItem
                            label={`H2 Tag`}
                            name={`subHeading`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            className="h1-tag"
                        >
                            <Input name={`subHeading`} />
                        </FormItem>
                    </Col>
                ) : null}
            </Row>
        </StyledForm>
    );
}

export default SeoForm;
