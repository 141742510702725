import { gql } from "@apollo/client";

export const GET_DOCUMENT_LIST = gql`
    query getFilteredMdorDocuments(
        $brand_id: String!
        $search: String
        $page: Int
        $limit: Int
        $sortBy: String!
        $sortOrder: String!
    ) {
        getFilteredMdorDocuments(
            brand_id: $brand_id
            search: $search
            page: $page
            limit: $limit
            sort: { by: $sortBy, order: $sortOrder }
        ) {
            mdor_documents {
                id
                document_custom_id
                document_name
                assigned_project
                created_at
                file
            }
            total_count
        }
    }
`;

export const GET_PROJECTS_LIST = gql`
    mutation getProjectsList($brand: String) {
        mdorProjectListRequest(mdorProjectListRequestInput: { brand: $brand }) {
            ProjectName
            ProjectCode
            PropertyCode
            BldgCode
        }
    }
`;

export const GET_DOCUMENT = gql`
    query getMdorDocument($id: String!) {
        getMdorDocument(id: $id) {
            assigned_project
            document_name
            file
            meta_data {
                key
                file_name
                file_type
            }
        }
    }
`;

export const MODIFY_DOCUMENT = gql`
    mutation modifyDocument($data: SaveMdorDocumentInput!) {
        addOrUpdateMdorDocument(mdorDocumentData: $data) {
            id
        }
    }
`;

export const REMOVE_DOCUMENT = gql`
    mutation removeDocument($id: String!) {
        removeMdorDocument(id: $id)
    }
`;
