import _ from "lodash";
import * as yup from "yup";

const checkUnique = (self) => {
    const _unique: Array<any> = [];
    self.forEach((value) => {
        if (
            _unique.filter((item) => item.category === value.category && item.email === value.email)
                .length === 0
        ) {
            _unique.push(value);
        }
    });

    return _unique;
};

export const validationSchema = yup.object().shape({
    emails: yup
        .array()
        .test("Unique email", "both of fields must be unique", function (values: any) {
            const unique = checkUnique(values);
            if (values?.length === unique.length) {
                return true;
            }
            const idx = values.findIndex((l, i) => {
                const findLastIdx = _.findLastIndex(values, {
                    email: values[i].email,
                    category: values[i].category,
                });
                const count = values.filter(
                    (item) => item.email === values[i].email && item.category === values[i].category
                ).length;
                if (count > 1 && i === findLastIdx && i >= findLastIdx) {
                    return true;
                }
            });
            return this.createError({
                path: `emails[${idx}].email`,
                message: "Email must be unique per category.",
            });
        })
        .when(["validateEmail"], {
            is: (chk) => {
                return chk;
            },
            then: yup.array().of(
                yup.object().shape({
                    email: yup
                        .string()
                        .email("Invalid email format.")
                        .required("Email is required."),
                    category: yup.string().required("Email Category is required.").nullable(),
                })
            ),
            otherwise: yup.array().nullable(),
        }),
});

export const emailCategory = [
    {
        label: "Careers (Sales)",
        value: "Careers (Sales)",
    },
    {
        label: "Careers (Corporate)",
        value: "Careers (Corporate)",
    },
    {
        label: "Sales",
        value: "Sales",
    },
    {
        label: "After Sales",
        value: "After Sales",
    },
    {
        label: "Broker Accreditation",
        value: "Broker Accreditation",
    },
    {
        label: "Careers",
        value: "Careers",
    },
    {
        label: "Inquiry",
        value: "Inquiry",
    },
    {
        label: "Schedule a Visit",
        value: "Schedule a Visit",
    },
];
