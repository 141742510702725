import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import isEmpty from "lodash/isEmpty";

import { Formik } from "formik";
import { Spin, message } from "antd";

import Forms from "./forms";
import { Context } from "../../../../context";
import { GET_EMAILS, MODIFY_EMAIL_RECORD, REMOVE_EMAIL_RECORD } from "../../graphql";
import { validationSchema } from "./constant";
import { userPermission } from "../../../../util/user-access";

const EmailForms: React.FC = () => {
    const {
        state: { brand: brandData, user },
    } = useContext(Context);
    const brandId = brandData?.id;
    const permissions = user?.roles[0].permissions;
    const createAccess = userPermission(permissions, "create:email");
    const updateAccess = userPermission(permissions, "update:email");
    const deleteAccess = userPermission(permissions, "delete:email");
    const [modifyEmailRecord] = useMutation(MODIFY_EMAIL_RECORD);
    const [removeEmailRecord] = useMutation(REMOVE_EMAIL_RECORD);

    const {
        data: emailData,
        refetch,
        loading: getEmailRecordLoader,
    } = useQuery(GET_EMAILS, {
        skip: !brandId,
        fetchPolicy: "no-cache",
        variables: {
            brandId: brandId,
        },
    });

    const [loader, setLoader] = useState(false);
    const [reinitialize, setReinitialize] = useState(false);
    const [initialValue, setInitialValue] = useState<any>({
        emails: [
            {
                id: "",
                email: "",
                category: "",
            },
        ],
        validateEmail: true,
    });
    const [fieldInfo, setFieldInfo] = useState<any>({
        fields: [],
        emails: [
            {
                id: "",
                email: "",
                category: "",
            },
        ],
    });

    useEffect(() => {
        if (!reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        if (emailData) {
            const data = emailData.getListOfEmails;

            const emailList: any = [];
            const fields: any = {
                emails: [],
            };

            fields["emails"] =
                data.length === 0
                    ? [{ id: "", email: undefined, category: "" }]
                    : data.map((res) => {
                          emailList.push(res);
                          return {
                              id: res.id ? res.id : "",
                              email: res.email ? res.email : undefined,
                              category: res.category ? res.category : "",
                          };
                      });

            setFieldInfo(fields);
            setInitialValue({ ...fields, validateEmail: true });
            setTimeout(() => {
                setLoader(false);
            }, 1000);
            setReinitialize(true);
        }
    }, [emailData]);

    const handleSubmit = async (values) => {
        setLoader(true);
        await handleAddUpdateEmails(values);
    };

    const handleAddUpdateEmails = async (values: any) => {
        const deleteEmails = fieldInfo.emails.filter(
            (item) => values.emails.filter((formikItem) => formikItem.id === item.id).length === 0
        );

        const addUpdateEmails = values.emails.map(async (item) => {
            const oldData = fieldInfo.emails.filter((old) => old.id === item.id)[0];
            let isNewData = isEmpty(item.id) ? true : false;
            if (oldData && (oldData.email !== item.email || oldData.category !== item.category)) {
                isNewData = true;
            }

            if (isNewData && !isEmpty(item.email)) {
                await modifyEmailRecord({
                    variables: {
                        data: {
                            ...(!isEmpty(item.id) && { id: item.id }),
                            ...(isEmpty(item.id) && { brand_id: brandId }),
                            email: !isEmpty(item.email) ? item.email : undefined,
                            category: !isEmpty(item.category) ? item.category : "",
                        },
                    },
                });
            }
        });
        await Promise.all(addUpdateEmails);

        if (deleteEmails.length > 0) {
            await removeEmailRecord({
                variables: {
                    data: {
                        ids: deleteEmails.map((i) => i.id),
                    },
                },
            });
        }

        message.success("Update Success");
        setLoader(false);
        refetch();
    };

    return (
        <Spin spinning={getEmailRecordLoader || loader}>
            <Formik
                initialValues={initialValue}
                validationSchema={validationSchema}
                enableReinitialize={reinitialize}
                onSubmit={(values: any) => {
                    handleSubmit(values);
                }}
                render={(formikBag) => (
                    <Forms
                        {...{
                            formikBag,
                            createAccess,
                            updateAccess,
                            deleteAccess,
                        }}
                    />
                )}
            />
        </Spin>
    );
};

export default EmailForms;
