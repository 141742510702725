import styled from "styled-components";

import { Button } from "antd";

export const StyledButton = styled(Button)`
    font-weight: 400;
    height: inherit;
    font-size: 16px;
    border-raduis: 4px;
`;
