import * as yup from "yup";

const SUPPORTED_FORMATS = [".jpg", ".jpeg", ".png", ".JPG", ".JPEG", ".PNG", ".webp"];

export const validationSchema = yup.object({
    imgURL: yup
        .object({
            url: yup.string(),
            keyObject: yup.string().when("url", {
                is: true,
                then: yup.string().test("fileFormat", "Invalid format.", (value) => {
                    const pattern = /\.[0-9a-z]+$/i;
                    if (value) {
                        const val: any = value;
                        const acceptedFormat = val.match(pattern)[0];
                        return SUPPORTED_FORMATS.includes(acceptedFormat);
                    } else {
                        return false;
                    }
                }),
            }),
        })
        .nullable(),
    videoURL: yup.string().nullable(),
    seoBannerTitle: yup.string().nullable(),
    seoBannerDescription: yup.string().nullable(),
    bannerHeadingTag: yup.string().nullable(),
    bannerTitle: yup.string().nullable(),
    excerpt: yup.string().nullable(),
    linkType: yup.string().required("Please select a link type."),
    linkCategory: yup.string().required("Please select link category."),
});
