import userListReducer from "../feature/manage-users/reducer";
import companyInfoReducer from "../feature/manage-company-information/reducer";
import propertyReducer from "../feature/manage-properties/reducer";

const initialReducer = {
  REMOVE_USER: (state, action) => {
      return {};
  },
  STORE_USER: (state, action) => {
      return {
          ...state,
          user: action.payload,
      };
  },
  STORE_BRAND: (state, action) => {
      return {
          ...state,
          brand: action.payload,
      };
  },
  STORE_ALL_BRAND: (state, action) => {
      return {
          ...state,
          allBrands: action.payload,
      };
  },
  STORE_IS_UPLOAD: (state, action) => {
      return {
          ...state,
          isUpload: action.payload,
      };
  },
}


const initReducer = {
    ...initialReducer,
    ...userListReducer,
    ...companyInfoReducer,
    ...propertyReducer,
};

export default (state, action) => {
    return initReducer[action.type](state, action);
};
