import React, { useContext, useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom";

import UserList from "./components/user-list";
import UserDetails from "./components/user-details";
import { Context } from "../../context";

const ManageUsers = (): JSX.Element => {
    const match = useRouteMatch();
    const {
        state: { brand: brandData },
    } = useContext(Context);
    const history = useHistory();

    useEffect(() => {
        if (brandData) history.push("/manage-company-information");
    }, [brandData]);
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={`${match.path}`}
                    render={(props: any) => <UserList {...props} />}
                />
                <Route
                    exact
                    path={`${match.path}/user-details/:userId`}
                    render={(props: any) => <UserDetails />}
                />
            </Switch>
        </>
    );
};

export default ManageUsers;
