import React, { useEffect, useState, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Formik } from "formik";

import { message } from "antd";

import ContentContainer from "../../../../components/ContentContainer";
import CustomTable from "../../../../components/CustomTable";
import CustomDrawer from "../../../../components/CustomDrawer";
import Form from "../../components/user-forms";

import { Context } from "../../../../context";
import { activeColumns, inactiveColumns, validationSchema, initialValues } from "./constant";

import { addUser, getBrand, getRole, getUsers } from "../../axios";
import { User } from "../../../../entities/User";
import { UserPaginationResponse } from "../../dto/UserPaginationResponse";

const UserList: React.FC = () => {
    const history = useHistory();
    const match = useRouteMatch();

    const {
        state: { userDetails },
    } = useContext(Context);

    const [columns, setColumns] = useState<any[]>(activeColumns);
    const [data, setData] = useState<User[]>([]);
    const [showActive, setShowActive] = useState<boolean>(true);
    const [sortBy, setSortBy] = useState<string>("updatedAt");
    const [sortOrder, setSortOrder] = useState<string>("desc");
    const [filterBrands, setFilterBrands] = useState<string>("");
    const [filterStatus, setFilterStatus] = useState<boolean | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(10);
    const [search, setSearch] = useState<string>("");
    const [activeUsers, setActiveUsers] = useState<number>(0);
    const [inactiveUsers, setInactiveUsers] = useState<number>(0);
    const [showSlider, setShowSlider] = useState<boolean>(false);
    const [roleList, setRoleList] = useState([]);
    const [brandList, setBrandList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const setFilteredValue = (columns: any, value: string[] | string | null) => {
        const newColumns: any = columns;
        if (newColumns.find((col) => col.dataIndex === "status")) {
            newColumns.find((col) => col.dataIndex === "status").filteredValue = value;
        }
        setColumns(newColumns);
    };

    const handleStatusBtn = (status) => {
        setShowActive(status);
        setFilterStatus(null);
    };

    const handleSearch = (query) => {
        setSearch(query);
    };

    useEffect(() => {
        if (!data.length) {
            setLoading(true);
        }

        (async () => {
            try {
                const {
                    data: users,
                    meta: { data: metaData },
                }: UserPaginationResponse = await getUsers({
                    page: currentPage,
                    limit: currentPageSize,
                    isActive: showActive,
                    q: search,
                    sortBy: sortBy,
                    order: sortOrder,
                    tenantIds: filterBrands,
                    isVerified: filterStatus,
                });

                setData(users);
                setBrandList(await getBrand());
                setActiveUsers(metaData.activeUsers);
                setInactiveUsers(metaData.inactiveUsers);
                setLoading(false);
            } catch (error) {
                const errorObj = error as Error;
                console.log(errorObj.message);
                setLoading(false);
            }
        })();
        // eslint-disable-next-line
    }, [
        currentPage,
        currentPageSize,
        showActive,
        search,
        sortBy,
        sortOrder,
        filterBrands,
        filterStatus,
        userDetails.payload,
    ]);

    useEffect(() => {
        if (showActive) {
            setColumns(activeColumns);
        } else {
            setFilteredValue(inactiveColumns, null);
        }
    }, [showActive]);

    useEffect(() => {
        let brandFilters: any = [];

        if (brandList) {
            brandFilters = brandList.map((brand) => ({
                text: brand.label,
                value: brand.value,
            }));
        }

        const newColumns: any = columns;
        newColumns.find((brand) => brand.dataIndex === "brand").filters = brandFilters;
        setColumns(newColumns);

        // eslint-disable-next-line
    }, [brandList]);

    const handleSelectTenant = async (value: string) => {
        if (value) {
            const role = await getRole(value);
            setRoleList(role);
        }
    };

    const handleSlider = () => {
        setShowSlider(!showSlider);
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        const id = await addUser(values);

        if (id) {
            message.success("User has been created");
            history.push(`${match.path}/user-details/${id}`);
        }
        setLoading(false);
    };

    const handleSort = (field, order) => {
        setSortOrder(order);
        setSortBy(field === "fullName" ? "firstName" : field);
    };

    const handleFilter = (filters) => {
        filters.brand ? setFilterBrands(filters.brand.join()) : setFilterBrands("");

        let status: boolean | null = null;

        if (filters.status && filters.status.length === 1) {
            filters.status[0] === "Verified" ? (status = true) : (status = false);
            setFilteredValue(columns, filters.status);
        }

        setFilterStatus(status);
    };

    const handleSelectedRow = (record) => {
        history.push(`${match.path}/user-details/${record.id}`);
    };

    const handlePageSize = async (pageSize) => {
        setCurrentPageSize(pageSize);
    };

    const handlePagination = async (page, pageSize) => {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);
    };

    const handleResetForm = (resetForm) => {
        resetForm(initialValues);
    };

    return (
        <ContentContainer>
            <CustomTable
                updateAccess={true}
                headingText="Users"
                labelText="User"
                isActive={showActive}
                activeNum={activeUsers}
                inactiveNum={inactiveUsers}
                handleStatusBtn={handleStatusBtn}
                handleSearch={handleSearch}
                handleAddBtn={handleSlider}
                columns={columns}
                dataSource={data}
                handleSort={handleSort}
                handleFilter={handleFilter}
                handleSelectedRow={handleSelectedRow}
                currentPageSize={currentPageSize}
                handlePageSize={handlePageSize}
                handlePagination={handlePagination}
                loading={loading}
            />

            <CustomDrawer visible={showSlider} title={"Add User"} onClose={handleSlider}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={({
                        firstName,
                        middleName,
                        lastName,
                        email,
                        mobileNumber,
                        brand,
                        role,
                    }: any) => {
                        const formValues = {
                            firstName,
                            middleName,
                            lastName,
                            email,
                            contactNumber: mobileNumber,
                            isActive: false,
                            tenantId: brand,
                            roleId: role,
                        };

                        handleSubmit(formValues);
                    }}
                    render={(formikBag) => (
                        <Form
                            {...{
                                formikBag,
                                handleSlider,
                                roleList,
                                brandList,
                                formType: "ADD",
                                handleSelectTenant,
                                handleResetForm,
                                loading: false,
                            }}
                        />
                    )}
                />
            </CustomDrawer>
        </ContentContainer>
    );
};

export default UserList;
