import { gql } from "@apollo/client";

export const GET_BRANDS = gql`
    query {
        getAllBrands {
            id
            name
        }
    }
`;

export const GET_BRAND = gql`
    query getBrandName($id: String!) {
        getBrand(id: $id) {
            id
            name
        }
    }
`;

export const GET_PAGES_LIST = gql`
    query searchPages(
        $brand_id: String!
        $search: String!
        $tag: String
        $page: Int
        $limit: Int
        $sortBy: String
        $order: String
    ) {
        searchPages(
            brandId: $brand_id
            search: $search
            tag: $tag
            page: $page
            limit: $limit
            sortBy: $sortBy
            order: $order
        ) {
            pages {
                brand_id
                id
                name
                created_at
                updated_at
            }
            totalCount
        }
    }
`;

export const GET_PAGE = gql`
    query getPage($brand_id: String!, $slug: String!, $tag: String!) {
        getSpecificBrandPage(brand_id: $brand_id, slug: $slug, tag: $tag) {
            id
            name
            containers {
                id
                name
                field_collections {
                    id
                    name
                    children {
                        id
                        name
                        order
                        fields {
                            id
                            name
                            value
                            order
                            meta_data {
                                file_type
                                key
                                description
                            }
                        }
                        children {
                            id
                            name
                            order
                            fields {
                                id
                                name
                                value
                                order
                                meta_data {
                                    file_type
                                    key
                                    description
                                }
                            }
                        }
                    }
                    fields {
                        id
                        name
                        value
                        order
                        meta_data {
                            file_type
                            key
                            description
                        }
                    }
                }
            }
        }
    }
`;

export const CREATE_PAGE = gql`
    mutation createPage($brand_id: String!, $tag: String!, $name: String) {
        addOrUpdateBrandPage(brand_id: $brand_id, tag: $tag, item: { name: $name }) {
            id
            name
        }
    }
`;

export const CREATE_CONTAINER = gql`
    mutation createContainer($data: UpdateContainerInput!) {
        addOrUpdateContainers(containerData: $data) {
            id
            page_id
            name
            order
        }
    }
`;

export const REMOVE_CONTAINER = gql`
    mutation removeContainer($id: String!) {
        removeContainer(id: $id)
    }
`;

export const REMOVE_MULTI_CONTAINER = gql`
    mutation removeMultiContainer($data: DeleteContainerInput!) {
        removeMultipleContainers(removeContainerData: $data)
    }
`;

export const CREATE_MULTI_FC = gql`
    mutation createMultipleFC($data: UpdateFieldCollectionPageInput!) {
        addUpdateMultipleFieldCollection(collectionData: $data) {
            id
            name
            container {
                id
                name
                field_collections {
                    name
                    id
                }
            }
        }
    }
`;

export const REMOVE_FC = gql`
    mutation removeFC($id: String!) {
        removeFC(id: $id)
    }
`;

export const REMOVE_MULIT_FC = gql`
    mutation removeMultiFC($data: DeleteFieldCollectionInput!) {
        removeMultipleFieldCollection(removeFCData: $data)
    }
`;
export const MODIFY_FIELDS = gql`
    mutation modifyFields($data: UpdateFieldCategoryPageInput!) {
        addOrUpdateFields(fieldCategoryData: $data) {
            id
            name
            order
            value
        }
    }
`;

export const REMOVE_FIELD = gql`
    mutation removeField($data: DeleteFieldCategoryInput!) {
        removeFieldCategory(removeFieldData: $data)
    }
`;

export const GET_COMPONENT_CATEGORY = gql`
    query {
        getAllCategories {
            id
            name
            brand_id
        }
    }
`;

export const CREATE_COMPONENT = gql`
    mutation createComponent(
        $page_id: String!
        $component_category_id: String!
        $content: String!
        $content_name: String
        $id: String
        $order: Float
    ) {
        addOrUpdateComponents(
            page_id: $page_id
            componentData: {
                components: [
                    {
                        component_category_id: $component_category_id
                        content: $content
                        id: $id
                        order: $order
                        content_name: $content_name
                    }
                ]
            }
        ) {
            id
            content
            content_name
            order
        }
    }
`;

export const CREATE_SUBCOMPONENT = gql`
    mutation createSubComponent(
        $component_id: String!
        $component_category_id: String!
        $content: String!
        $id: String
        $order: Float!
    ) {
        addOrUpdateSubComponents(
            component_id: $component_id
            subComponentData: {
                subComponents: [
                    {
                        component_category_id: $component_category_id
                        content: $content
                        id: $id
                        order: $order
                    }
                ]
            }
        ) {
            id
            content
        }
    }
`;

export const REMOVE_COMPONENT = gql`
    mutation removeComponent($id: String!) {
        removeComponent(id: $id)
    }
`;

export const REMOVE_SUBCOMPONENT = gql`
    mutation removeSubComponent($id: String!) {
        removeSubComponent(id: $id)
    }
`;

export const CREATE_CONTENT = gql`
    mutation addOrUpdateNested($data: CreateUpdateContainerNestedInput!) {
        addOrUpdateContainersFCFields(containerData: $data) {
            id
        }
    }
`;
