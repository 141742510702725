/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import _ from "lodash";

import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, Select, message, Modal } from "antd";

import { GET_LINKED_PAGES } from "../../graphql";
import { Context } from "../../../../context";

import CustomButton from "@/components/CustomButton";
import ContentContainer from "@/components/ContentContainer";
import Richtext from "@/components/Richtext";
import SeoForm from "@/components/SeoForm";

import { Property } from "../../../../entities/Property";
import { Content } from "../../../../entities/Content";
import { LinkedPropertyMapper } from "../../dto/LinkedPropertyMapper";
import { LinkedContentMapper } from "../../dto/LinkedContentMapper";

import { UploadImage, styledCard as Card, ImageContainer, LoadingText } from "./styled";

import { categories } from "../../manage-hero-banner.utls";
import { getAcceptedFiles } from "../../../../util/get-accepted-filetypes";

import {
    PropType,
    PropertyType,
    ArticleType,
} from "feature/manage-hero-banner/manage-hero-banner.types";

const Forms = ({ formikBag }: PropType): JSX.Element => {
    const history = useHistory();
    const params = useParams();

    const {
        state: { brand: brandData },
    } = useContext(Context);
    const brandName = brandData?.name;
    const brandId = brandData?.id;
    const allowVideoUpload = brandName === "ALISI" || brandName === "AVIDA";

    const { values, submitForm, setFieldValue, dirty } = formikBag;
    const { Option } = Select;
    const { Meta } = Card;

    const [tag, setTag] = useState<string>("");
    const [pagesList, setPagesList] = useState<any>([]);
    const [categoryList, setCategoryList] = useState<string[]>([]);
    const [modal, setModal] = useState<boolean>(false);
    const [filter, setFilter] = useState<PropertyType | ArticleType>();

    const { data, loading, error } = useQuery(GET_LINKED_PAGES, {
        skip: !brandId || !tag || (!values.linkType && !values.linkCategory),
        variables: {
            brand_id: brandId,
            tag: tag ? tag : `${_.toUpper(params.type)}-${brandName}`,
            filter: filter,
        },
        fetchPolicy: "no-cache",
        partialRefetch: true,
    });

    useEffect(() => {
        if (data && data.searchPages) {
            const { searchPages } = data;
            const res =
                values.linkType === "PROPERTIES"
                    ? searchPages.pages.map((info: Property) =>
                          Object.assign(LinkedPropertyMapper.map({ ...info }))
                      )
                    : values.linkType === "CONTENT"
                    ? searchPages.pages.map((info: Content) =>
                          Object.assign(LinkedContentMapper.map({ ...info }))
                      )
                    : [];
            res && setPagesList(res.sort());
        }

        if (error) {
            message.error("Something went wrong.");
        }
        // eslint-disable-next-line
    }, [data, error]);

    const resetValues = (exceptions?: string[]) => {
        Object.keys(values) &&
            Object.keys(values).forEach((key) => {
                if (!exceptions || (exceptions && !exceptions.includes(key))) {
                    setFieldValue(key, "");
                }
            });
    };

    const handleLinkType = (value) => {
        resetValues(["imgURL", "videoURL"]);
        setCategoryList(categories[_.toLower(value)]);
        setFieldValue("linkType", value);
        setTag(`${value}-${brandName}`);
    };

    const handleLinkCategory = (value) => {
        resetValues(["imgURL", "videoURL", "linkType"]);
        setFieldValue("linkCategory", value);
        values.linkType === "PROPERTIES"
            ? setFilter({
                  property: {
                      type: value,
                  },
              })
            : setFilter({
                  content: {
                      type: value,
                  },
              });

        document.getElementById("linkCategoryEl")?.blur();
    };

    const handleLinkedPage = (value) => {
        const linkedPage =
            !_.isEmpty(pagesList.filter((page) => page.id === value)) &&
            !_.isEmpty(pagesList) &&
            pagesList.filter((page) => page.id === value)[0];
        if (linkedPage) {
            setFieldValue("linkedPage", linkedPage);
            setFieldValue("bannerTitle", linkedPage.name ? linkedPage.name : null);
            setFieldValue("excerpt", linkedPage.excerpt ? linkedPage.excerpt : null);
            document.getElementById("bannerTitleEl")?.focus();
        }
    };

    const handleSubmit = () => {
        submitForm();
        handleModal();
    };

    const handleModal = () => {
        setModal(!modal);
    };

    return (
        <Form>
            <ContentContainer style={{ display: "flex", flexDirection: "column", padding: "30px" }}>
                <Row justify="start">
                    {brandData.name !== "ALP" ? (
                        <Col span={24}>
                            <SeoForm category={"Banner"} />
                        </Col>
                    ) : null}

                    <Col span={24}>
                        <UploadImage
                            value={values.imgURL}
                            accept={getAcceptedFiles(brandName, allowVideoUpload)}
                            onChange={(file) => {
                                setFieldValue("imgURL", file);
                            }}
                            sizeLimit={50}
                        >
                            <span>+ Add Hero Banner</span>
                        </UploadImage>
                    </Col>
                    {brandName === "ALP" ? (
                        <Col span={24}>
                            <FormItem
                                label="Image Alt Text"
                                name="altText.altText"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input
                                    name="imgURL.altText"
                                    id="altText"
                                    onChange={({ target: { value } }) =>
                                        setFieldValue("imgURL.altText", value)
                                    }
                                    value={values?.imgURL?.altText}
                                />
                            </FormItem>
                        </Col>
                    ) : null}

                    <Col span={24}>
                        <FormItem
                            label={"Or a Video Link"}
                            name={"videoURL"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name={"videoURL"} disabled={allowVideoUpload} />
                        </FormItem>
                    </Col>
                </Row>
            </ContentContainer>

            <ContentContainer style={{ display: "flex", flexDirection: "column", padding: "30px" }}>
                <Row justify="start" gutter={24}>
                    <Col span={12}>
                        <FormItem
                            label={"Select type of link"}
                            name={"linkType"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                defaultValue=""
                                value={values.linkType && values.linkType}
                                onChange={handleLinkType}
                                disabled={params.id && true}
                            >
                                <Option value="PROPERTIES">Property</Option>
                                <Option value="CONTENT">Article</Option>
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <FormItem
                            label={"Select Category"}
                            name={"linkCategory"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                id={"linkCategoryEl"}
                                value={values.linkCategory}
                                onChange={handleLinkCategory}
                                disabled={!params.id && values.linkType ? false : true}
                            >
                                {!_.isEmpty(categoryList) &&
                                    categoryList.map((type) => (
                                        <Option value={type} key={type}>
                                            {type && _.startCase(_.toLower(type))}
                                        </Option>
                                    ))}
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span={24}>
                        {loading && <LoadingText>Please wait...</LoadingText>}
                        <FormItem
                            label={"Link an Article or Property"}
                            name={"linkedPage"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Select
                                value={
                                    values.linkedPage && values.linkedPage.name
                                        ? values.linkedPage.name
                                        : ""
                                }
                                onChange={handleLinkedPage}
                                disabled={
                                    !loading &&
                                    !error &&
                                    values.linkType &&
                                    values.linkCategory &&
                                    !params.id
                                        ? false
                                        : true
                                }
                            >
                                {!_.isEmpty(pagesList) &&
                                    pagesList.map((page) => (
                                        <Option value={page.id} key={page.id}>
                                            {page.name}
                                        </Option>
                                    ))}
                            </Select>
                        </FormItem>
                    </Col>

                    {values.linkedPage && (
                        <Col span={24} style={{ marginBottom: "15px" }}>
                            <Card>
                                <ImageContainer>
                                    {!loading && values.linkedPage && (
                                        <img
                                            alt={values.linkedPage.name && values.linkedPage.name}
                                            src={
                                                values.linkedPage.imgURL && values.linkedPage.imgURL
                                            }
                                        />
                                    )}
                                </ImageContainer>
                                <Meta
                                    title={values.linkedPage && values.linkedPage.name}
                                    description={
                                        <div className="description">
                                            <div className="excerpt">
                                                {values.linkedPage && values.linkedPage.excerpt
                                                    ? values.linkedPage.excerpt
                                                    : "No excerpt found"}
                                            </div>
                                            <div className="date">
                                                {values.linkedPage && values.linkedPage.date
                                                    ? values.linkedPage.date
                                                    : "No date found"}
                                            </div>
                                        </div>
                                    }
                                />
                            </Card>
                            <span style={{ fontStyle: "italic" }}>
                                Note: Your linked content will automatically fill up the essential
                                fields (Title, description, images). But you can override it by
                                changing all the values.
                            </span>
                        </Col>
                    )}

                    <Col span={24}>
                        <FormItem
                            label={"Banner Title"}
                            name={"bannerTitle"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name={"bannerTitle"} id={"bannerTitleEl"} />
                        </FormItem>
                    </Col>

                    <Col span={24}>
                        <FormItem
                            label={"Short Description"}
                            name={"excerpt"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input.TextArea name={"excerpt"} />
                        </FormItem>
                    </Col>

                    <Col span={24}>
                        <FormItem
                            label={"Short Description"}
                            name={"excerptMobile"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Richtext
                                heading={true}
                                data={values.excerptMobile}
                                onChange={(value: string) => setFieldValue("excerptMobile", value)}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </ContentContainer>

            <Row justify="end" gutter={24} style={{ padding: "0 25px 25px 25px" }}>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%" }}
                        onClick={() => history.push(`/manage-hero-banner`)}
                    >
                        CANCEL
                    </CustomButton>
                </Col>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={handleModal}
                        disabled={!dirty}
                    >
                        SAVE
                    </CustomButton>
                    <Modal
                        title="Save Hero Banner?"
                        visible={modal}
                        onOk={handleSubmit}
                        onCancel={handleModal}
                    >
                        Are you sure you want to save?
                    </Modal>
                </Col>
            </Row>
        </Form>
    );
};

export default Forms;
