import React, { ReactNode } from "react";

import { StyledButton as Button } from "./styled";

interface Props {
    onClick?: () => void;
    children: ReactNode;
    type?: string;
    style?: object;
    className?: string;
    disabled?: boolean;
}

const CustomButton: React.FC<Props> = ({ onClick, children, type, style, className, disabled }) => (
    <Button
        type={type}
        onClick={onClick}
        style={style}
        data-index="button"
        className={className}
        disabled={disabled}
    >
        {children}
    </Button>
);

export default CustomButton;
