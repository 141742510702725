import { gql } from "@apollo/client";

export const GET_PAGE = gql`
    query getSpecificBrandPage($brand_id: String!) {
        getSpecificBrandPage(
            brand_id: $brand_id
            slug: "buyers-guide-avida"
            tag: "BUYERS-GUIDE-AVIDA"
        ) {
            id
            brand_id
            name
            slug
            tag
            containers {
                id
                name
                field_collections {
                    id
                    name
                    order
                    parentId
                    fields {
                        id
                        label
                        name
                        order
                        value
                    }
                    children {
                        id
                        name
                        order
                        parentId
                        fields {
                            id
                            label
                            name
                            order
                            value
                        }
                    }
                }
            }
        }
    }
`;

export const CREATE_PAGE = gql`
    mutation createPage($brand_id: String!, $name: String!, $tag: String!) {
        addOrUpdateBrandPage(brand_id: $brand_id, tag: $tag, item: { name: $name }) {
            id
            name
            brand_id
            slug
        }
    }
`;

export const CREATE_CONTAINER = gql`
    mutation addUpdateContainerFCFieldsFAQ($data: CreateUpdateContainerNestedInput!) {
        addOrUpdateContainersFCFields(containerData: $data) {
            id
            name
            order
            page_id
            field_collections {
                id
                name
                order
                fields {
                    id
                    label
                    name
                    order
                    value
                }
                children {
                    id
                    name
                    order
                    parentId
                    fields {
                        name
                        label
                        order
                        value
                    }
                }
            }
        }
    }
`;

export const REMOVE_MULTIPLE_FC = gql`
    mutation removeMultiFC($data: DeleteFieldCollectionInput!) {
        removeMultipleFieldCollection(removeFCData: $data)
    }
`;

export const DELETE_CONTAINER = gql`
    mutation deleteContainer($id: String!) {
        removeContainer(id: $id)
    }
`;
