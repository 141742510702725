import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { validationSchema } from "../constant";
import { useHistory, useParams, useLocation } from "react-router-dom";

import Forms from "./forms";
import { Context } from "../../../context";
import { FormValueType, INIT_VALUES, fieldMapper } from "../manage-awards.utils";
import _ from "lodash";
import {
    INIT_FIELD_INFO_VALUES,
    ComponentType,
    FieldInfoTypes,
    imageMapper,
} from "../manage-awards.utils";
import { useQuery, useMutation } from "@apollo/client";
import {
    CREATE_CONTAINER,
    CREATE_PAGE,
    EDIT_PAGE_TITLE,
    GET_PAGE,
    REMOVE_MULTIPLE_FC,
} from "../graphql";
import { Spin, message } from "antd";
import generateId from "../../../util/generate-id";

function ManageAwards() {
    const [reinitialize, setReinitialize] = useState(false);
    const { slug } = useParams();
    const history = useHistory();

    const {
        state: { propertyDetails, brand: brandData },
    } = useContext(Context);

    const params = useParams();

    const brandId = brandData?.id;
    const brandName = brandData?.name;
    const title = slug ? "Edit" : "Add";
    const [createPage] = useMutation(CREATE_PAGE);
    const [editPage] = useMutation(EDIT_PAGE_TITLE);
    const [createContainer] = useMutation(CREATE_CONTAINER);
    const [removeFC] = useMutation(REMOVE_MULTIPLE_FC);

    const location = useLocation();
    const {
        data: pageData,
        refetch,
        loading,
    } = useQuery(GET_PAGE, {
        skip: !params.slug || !brandId,
        fetchPolicy: "no-cache",
        variables: {
            page_id: location?.state?.pageId || "",
        },
    });

    const [fieldInfo, setFieldInfo] = useState<FieldInfoTypes>(INIT_FIELD_INFO_VALUES);
    const [loader, setLoader] = useState(false);
    const [initialValue, setInitialValue] = useState<FormValueType>(INIT_VALUES);

    useEffect(() => {
        if (!reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        if (params.slug && _.has(pageData, "getSpecificBrandPageById")) {
            const data = pageData.getSpecificBrandPageById.containers;
            const topContainer = data[0];
            const fieldItem: ComponentType[] = [];
            const components: ComponentType[] = [];
            const fields: FormValueType = INIT_VALUES;

            topContainer.field_collections.forEach((fieldItems) => {
                if (fieldItems.fields || fieldItems.children) {
                    fieldItems.fields.forEach((items) => {
                        const name = _.camelCase(items.name);
                        if (name === "image") {
                            fields["image"] = {
                                keyObj: items.meta_data.key,
                                url: items.value,
                                uid: generateId(),
                                status: "done",
                                altText: items.meta_data.alt_text,
                            };
                        } else {
                            fields[_.camelCase(items.name)] = items.value;
                        }
                        fieldItem.push(items);
                    });
                }
            });

            setFieldInfo({
                fields: fieldItem,
                containerId: data[0].id,
                overviewFcId: data[0].field_collections[0].id,
                components,
            });

            setInitialValue(fields);
            setReinitialize(true);

            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    }, [pageData]);

    const containerMapper = (values, page_id) => {
        const filter: ComponentType[] = fieldInfo.fields;
        const containers: any = [
            {
                order: 0,
                ...(!_.isEmpty(fieldInfo.containerId) && { id: fieldInfo.containerId }),
                page_id: page_id,
                name: "AWARD",
                field_collections: [
                    {
                        ...(!_.isEmpty(fieldInfo.overviewFcId) && { id: fieldInfo.overviewFcId }),
                        name: "OVERVIEW",
                        order: 0,
                        fields: [
                            fieldMapper("Award", "AWARD", values.award, 0, filter),
                            fieldMapper("Year", "YEAR", values.year, 1, filter),
                            fieldMapper(
                                "Organization",
                                "ORGANIZATION",
                                values.organization,
                                2,
                                filter
                            ),
                            imageMapper(values.image, filter),
                            fieldMapper("Project", "PROJECT", values.project, 4, filter),
                        ].filter((item) => item !== null),
                    },
                ],
            },
        ];

        return containers;
    };

    const handleUpdatePageName = async (title, page_id) => {
        const page = await editPage({
            variables: {
                brand_id: brandId,
                id: page_id,
                name: title,
                tag: `AWARDS-${brandName}`,
            },
        });
        return page;
    };

    const handleAddUpdatePage = async (values) => {
        try {
            let update = false;
            let pageId = null;
            if (pageData && _.has(pageData, "getSpecificBrandPageById")) {
                pageId = pageData.getSpecificBrandPageById.id;
                update = true;
            } else {
                const page = await createPage({
                    variables: {
                        brand_id: brandData.id,
                        name: values.award,
                        tag: `AWARDS-${brandName}`,
                    },
                });
                const {
                    addOrUpdateBrandPage: { id: page_id },
                } = page.data;
                pageId = page_id;
            }

            if (pageId) {
                const containers = containerMapper(values, pageId);

                if (!_.isEmpty(fieldInfo.components)) {
                    removeFC({
                        variables: {
                            data: {
                                ids: fieldInfo.components,
                            },
                        },
                    });
                }

                if (update) {
                    const filter = fieldInfo?.fields.filter(
                        (item: ComponentType) => item.name === "TITLE"
                    );
                    if (filter[0]?.value !== values.title) {
                        const {
                            data: {
                                addOrUpdateBrandPage: { slug },
                            },
                        } = await handleUpdatePageName(values.title, pageId);
                        history.push(`/manage-awards`);
                    } else refetch();
                }

                await createContainer({
                    variables: {
                        data: {
                            containers: containers,
                        },
                    },
                });

                update && refetch();
                message.success(update ? "Update success." : "Award successfully created.");
                setTimeout(() => {
                    setLoader(false);
                }, 1000);
                history.push(`/manage-awards`);
            }
        } catch (error) {
            const errorObj = error as Error;

            const msg =
                errorObj.message === "Page name is already exists!"
                    ? "Page name is already exists!"
                    : "Something went wrong upon updating!";
            message.error(msg);
            setLoader(false);
        }
    };

    const handleSubmit = async (values) => {
        setLoader(true);
        await handleAddUpdatePage(values);
    };

    return (
        <Spin spinning={loading || loader}>
            <Formik
                initialValues={initialValue}
                validationSchema={validationSchema}
                enableReinitialize={reinitialize}
                onSubmit={(values: any) => {
                    handleSubmit(values);
                }}
                render={(formikBag) => (
                    <Forms
                        {...{
                            formikBag,
                            loading: loader,
                        }}
                    />
                )}
            />
        </Spin>
    );
}

export default ManageAwards;
