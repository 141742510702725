/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import moment from "moment";
import _ from "lodash";
import isEmpty from "lodash/isEmpty";

import { Spin, message } from "antd";
import { Formik } from "formik";

import Forms from "./forms";

import {
    CREATE_PAGE,
    EDIT_PAGE_TITLE,
    GET_PAGE,
    CREATE_CONTENT,
    REMOVE_MULTIPLE_FC,
    REMOVE_FIELD,
} from "../../graphql";

import generateId from "../../../../util/generate-id";
import { Context } from "../../../../context";
import { v4 } from "uuid";

import {
    FormsDefaultValueTypes,
    ListTypes,
    ImageContainerType,
    ImagesType,
} from "../../manage-showroom.types";
import {
    INIT_VALUES,
    listMapper,
    imageMapper,
    validationSchema,
    validationSchemaSpecialChar,
} from "../../manage-showroom.utils";

const ShowroomForms = (): JSX.Element => {
    const {
        state: { brand: brandData },
    } = useContext(Context);
    const brandId = brandData?.id;
    const brandName = brandData?.name;
    const history = useHistory();
    const tag = `SHOWROOM-${brandName}`;
    const location = useLocation();

    const params = useParams();
    const [loader, setLoader] = useState(false);

    const { data: getPage } = useQuery(GET_PAGE, {
        skip: !brandId,
        fetchPolicy: "no-cache",
        variables: {
            page_id: location?.state?.pageId || "",
        },
    });

    const [initialValue, setInitialValue] = useState<FormsDefaultValueTypes>(INIT_VALUES);

    const [pageDetails, setPageDetails] = useState<null | any>(null);
    const [listId, setListId] = useState<string[]>([]);
    const [imagesFieldsId, setImagesFieldsId] = useState<string[]>([]);

    const [createPage] = useMutation(CREATE_PAGE);
    const [editPage] = useMutation(EDIT_PAGE_TITLE);
    const [createContent] = useMutation(CREATE_CONTENT);
    const [removeFC] = useMutation(REMOVE_MULTIPLE_FC);
    const [removeFields] = useMutation(REMOVE_FIELD);

    const [reinitialize, setReinitialize] = useState(false);

    const containerMapper = (values, page_id) => {
        let listAsContainer: ListTypes[] = [];
        let imagesAsContainer: ImageContainerType[] = [];

        if (!_.isEmpty(values.list)) {
            listAsContainer = listMapper(values.list);
        }

        if (!_.isEmpty(values.images)) {
            imagesAsContainer = imageMapper(values.images);
        }

        const containers: any = {
            order: 0,
            page_id: page_id,
            name: "SHOWROOM-CONTAINER",
            field_collections: [
                {
                    name: "SHOWROOM-FC",
                    order: 0,
                    children: [
                        {
                            name: "SHOWROOM-LIST",
                            order: 0,
                            children: listAsContainer && listAsContainer,
                        },
                        {
                            name: "IMAGES",
                            order: 1,
                            fields: imagesAsContainer && imagesAsContainer,
                            // fields: null,
                            // fields - delete
                        },
                    ],
                    fields: [
                        {
                            // id:
                            label: "Showroom (Default)",
                            name: "SHOW-ROOM-DEFAULT",
                            value: values.label ? values.label : null,
                            order: 0,
                        },
                        {
                            // id:
                            label: "title",
                            name: "SHOW-ROOM-TITLE",
                            value: values.title ? values.title : null,
                            order: 1,
                        },
                        {
                            // id:
                            label: "Seo Title",
                            name: "SEO-SHOWROOM-TITLE",
                            value: values.seoShowroomTitle ? values.seoShowroomTitle : null,
                            order: 2,
                        },
                        {
                            // id:
                            label: "Seo Title",
                            name: "SEO-SHOWROOM-DESCRIPTION",
                            value: values.seoShowroomDescription
                                ? values.seoShowroomDescription
                                : null,
                            order: 3,
                        },
                    ],
                },
            ],
        };

        if (params.slug && pageDetails) {
            containers["id"] = pageDetails.containers.id;
            containers.field_collections[0]["id"] = pageDetails.containers.field_collections.id;

            // SHOWROOM-LIST
            containers.field_collections[0].children[0]["id"] =
                pageDetails.containers.field_collections.children[0].id;

            // IMAGES
            containers.field_collections[0].children[1]["id"] =
                pageDetails.containers.field_collections.children[1].id;

            // SHOW-ROOM-DEFAULT or LABEL
            containers.field_collections[0].fields[0]["id"] =
                pageDetails.containers.field_collections.fields[0].id;

            // SHOW-ROOM-TITLE
            containers.field_collections[0].fields[1]["id"] =
                pageDetails.containers.field_collections.fields[1].id;
        }

        return [containers];
    };

    const handleAddPage = async (title) => {
        const page = await createPage({
            variables: {
                tag: tag,
                brand_id: brandId,
                name: title,
            },
        });

        return [page];
    };

    const handleUpdatePage = async (values, page_id) => {
        const containers = await containerMapper(values, page_id);

        if (!_.isEmpty(listId)) {
            await removeFC({
                variables: {
                    data: {
                        ids: listId,
                    },
                },
            });
        }

        if (!_.isEmpty(imagesFieldsId)) {
            await removeFields({
                variables: {
                    data: {
                        ids: imagesFieldsId,
                    },
                },
            });
        }

        const content = await createContent({
            variables: {
                data: {
                    containers,
                },
            },
        });

        return [content];
    };

    const handleUpdatePageName = async (title, page_id) => {
        const page = await editPage({
            variables: {
                tag: tag,
                brand_id: brandId,
                id: page_id,
                name: title,
            },
        });

        return [page];
    };

    const handleSubmit = async (values) => {
        setLoader(true);

        if (params.slug && pageDetails) {
            try {
                const content = await handleUpdatePage(values, pageDetails.id);
                if (pageDetails.name !== values.title) {
                    const newPage = await handleUpdatePageName(values.title, pageDetails.id);
                }

                message.success("Showroom successfully updated!");
                setTimeout(() => {
                    setLoader(false);
                }, 1000);
                history.push(`/manage-showroom`, { pageId: pageDetails.id });
            } catch (err) {
                const errorObj = err as Error;

                const msg =
                    errorObj.message === "Page name is already exists!"
                        ? "Page name is already exists!"
                        : "Something went wrong upon updating!";
                message.error(msg);
                setTimeout(() => {
                    setLoader(false);
                }, 1000);
            }

            // update page slug then content
        } else {
            try {
                const page = await handleAddPage(values.title);

                const {
                    addOrUpdateBrandPage: { id },
                } = page[0].data;

                const content = await handleUpdatePage(values, id);

                message.success("Showroom successfully created!");
                setTimeout(() => {
                    setLoader(false);
                }, 1000);
                history.push(`/manage-showroom`);
            } catch (err) {
                const errorObj = err as Error;

                message.error(errorObj.message);
                setTimeout(() => {
                    setLoader(false);
                }, 1000);
            }
        }
    };

    useEffect(() => {
        if (!reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        if (params.slug) {
            setLoader(true);
            if (getPage) {
                const data = getPage.getSpecificBrandPageById;

                if (!isEmpty(data)) {
                    const field_collections = data.containers[0].field_collections[0];

                    const { fields, children } = field_collections;

                    setPageDetails({
                        id: data.id,
                        name: data.name,
                        containers: {
                            id: data.containers[0].id,
                            field_collections: {
                                id: field_collections.id,
                                children: [
                                    {
                                        order: 0,
                                        id: children[0].id,
                                    },
                                    {
                                        order: 1,
                                        id: children[1].id,
                                    },
                                ],
                                fields: [
                                    {
                                        order: 0,
                                        id: fields[0]?.id || v4(),
                                    },
                                    {
                                        order: 1,
                                        id: fields[1]?.id || v4(),
                                    },
                                    {
                                        order: 2,
                                        id: fields[2]?.id || v4(),
                                    },
                                    {
                                        order: 3,
                                        id: fields[3]?.id || v4(),
                                    },
                                ],
                            },
                        },
                    });

                    const tempFields: FormsDefaultValueTypes = {
                        label: fields[0]?.value ? fields[0].value : "",
                        title: fields[1]?.value ? fields[1].value : "",
                        seoShowroomTitle: fields[2]?.value ? fields[2].value : "",
                        seoShowroomDescription: fields[3]?.value ? fields[3].value : "",
                        list: [],
                        images: [],
                        imagesCaptions: [],
                    };

                    const list = children && children[0].children;
                    const images = children[1].fields;

                    list
                        ? list.map((res) => {
                              const tempList: ListTypes = {
                                  title: "",
                                  titleId: "",
                                  address: "",
                                  addreId: "",
                                  telHotline: "",
                                  telHotlineId: "",
                                  telNumber: "",
                                  telNumberId: "",
                                  email: "",
                                  emailId: "",
                                  timeStartRange: "",
                                  timeStartRangeId: "",
                                  timeEndRange: "",
                                  timeEndRangeId: "",
                                  rangeDay: [],
                              };

                              res.children[0].fields &&
                                  res.children[0].fields.forEach(({ value: day }) => {
                                      tempList.rangeDay?.push(day);
                                  });

                              res.fields &&
                                  res.fields.map((f, fi) => {
                                      switch (fi) {
                                          case 0:
                                              tempList["title"] = f.value ? f.value : "";
                                              tempList["titleId"] = f.id ? f.id : "";
                                          case 1:
                                              tempList["address"] = f.value ? f.value : "";
                                              tempList["addressId"] = f.id ? f.id : "";
                                          case 2:
                                              tempList["telHotline"] = f.value ? f.value : "";
                                              tempList["telHotlineId"] = f.id ? f.id : "";
                                          case 3:
                                              tempList["telNumber"] = f.value ? f.value : "";
                                              tempList["telNumberId"] = f.id ? f.id : "";
                                          case 4:
                                              tempList["email"] = f.value ? f.value : "";
                                              tempList["emailId"] = f.id ? f.id : "";
                                          case 5:
                                              tempList["timeStartRange"] = f.value
                                                  ? moment(f.value, "hh:mm a")
                                                  : "";
                                              tempList["timeStartRangeId"] = f.id ? f.id : "";
                                          case 6:
                                              tempList["timeEndRange"] = f.value
                                                  ? moment(f.value, "hh:mm a")
                                                  : "";
                                              tempList["timeEndRangeId"] = f.id ? f.id : "";
                                      }
                                  });

                              listId.push(res.id);
                              tempFields.list.push(tempList);
                          })
                        : tempFields.list.push({
                              title: "",
                              address: "",
                              email: "",
                              telHotline: "",
                              telNumber: "",
                              timeRangeStart: "",
                              timeRangeEnd: "",
                          });

                    images &&
                        images.forEach((res) => {
                            const tempImage: ImagesType = {
                                itemId: generateId(),
                                caption: res.meta_data.description,
                                isChecked: res.meta_data.featured,
                                url: res.value,
                                keyObj: res.meta_data.key,
                                id: res.id,
                                status: "done",
                                altText: res.meta_data.alt_text,
                            };

                            imagesFieldsId.push(res.id);

                            tempFields.images.push(tempImage);
                            tempFields.imagesCaptions.push(res.meta_data.description);
                        });

                    setInitialValue(tempFields);

                    setTimeout(() => {
                        setLoader(false);
                    }, 1000);

                    setReinitialize(true);
                }
            }
        }
    }, [getPage]);

    return (
        <Spin spinning={loader}>
            <Formik
                initialValues={initialValue}
                validationSchema={
                    brandName === "AMAIA" ? validationSchema : validationSchemaSpecialChar
                }
                enableReinitialize={reinitialize}
                onSubmit={(values: FormsDefaultValueTypes) => handleSubmit(values)}
            >
                {(formikBag) => (
                    <Forms
                        {...{
                            formikBag,
                            brandName,
                        }}
                    />
                )}
            </Formik>
        </Spin>
    );
};

export default ShowroomForms;
