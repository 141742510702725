import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import AyalaLandLogo from "../../assets/logo/ali-logo.png";
import BgImage from "../../assets/login-bg.jpg";
import { version } from "../../version";
import { Container, Content, Copyright, LoginBtn, Logo, Title, Version } from "./styled";
import { getAccessToken } from "./axios";

const Login: React.FC = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const onLogin = () => {
        setLoading(!loading);
        return history.push("/social/azure/login");
    };

    const checkAuthCode = async () => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get("code");

        if (code) {
            setLoading(true);
            await challengeAuth(code);
        }
    };

    const challengeAuth = async (code: string) => {
        if (loading) {
            try {
                await getAccessToken(code);
                return history.push("/redirect");
            } catch (error) {
                setLoading(false);
                return history.push("/admin/login");
            }
        }
    };

    useEffect(() => {
        document.title = "ALI RBG CMS";
        localStorage.removeItem("ACCESS_TOKEN");
        localStorage.removeItem("REFRESH_TOKEN");
    }, []);

    useEffect(() => {
        (async () => await checkAuthCode())();
    });

    return (
        <Container>
            <img src={BgImage} className="login-bg-image" alt="Log In Background" />
            <Content>
                <Logo src={AyalaLandLogo} alt="AyalaLand Logo" />
                <Title>Ayala RBG Content Management System</Title>
                <LoginBtn onClick={onLogin} loading={loading} type="primary">
                    Sign In
                </LoginBtn>
            </Content>
            <Copyright>
                Copyright © {new Date().getFullYear()} Ayala RBG Content Management System
            </Copyright>
            <Version>{version}</Version>
        </Container>
    );
};

export default Login;
