import React, { useState, useContext } from "react";
import _ from "lodash";
import axios from "axios";
import { useParams } from "react-router-dom";

import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, Spin, Button, message } from "antd";

import CustomButton from "../../../../components/CustomButton";
import CustomSelect from "../../../../components/CustomSelect/";
import { convertFileToBase64 } from "../../../../util/convert-to-base64";

import { Context } from "../../../../context";
import { BASE_URL, SUPPORTED_FORMATS, FILE_SIZE_LIMIT } from "./constant";
import { UploadStyled as Upload, UploadList, ErrorMessage } from "./styled";

interface Props {
    formikBag: any;
    handleSlider: () => void;
    loading: boolean;
    projectList: any;
}

const Forms = ({ formikBag, handleSlider, loading, projectList }: Props): JSX.Element => {
    const INTERNAL_SERVER_ERROR = 500;
    const UNPROCESSABLE_ENTITY = 422;
    const SCAN_ERROR_STATUS = 403;
    const BAD_REQUEST = 400;
    const { submitForm, values, setFieldValue, resetForm, dirty, isValid, errors } = formikBag;
    const { id } = useParams();
    const {
        state: { brand: brandData },
    } = useContext(Context);

    const {
        dispatch: { storeIsUploading },
    } = useContext(Context);

    const brandName = brandData?.name;

    const [noAssignedProjError, setNoAssignedProjError] = useState<boolean>(false);
    const [isBroken, setIsBroken] = useState<boolean>(false);
    const [isNotScanned, setIsNotScanned] = useState<boolean>(false);

    const handleRequest = (option) => {
        setIsBroken(false);
        setIsNotScanned(false);
        const formData = new FormData();
        formData.append("file", option.file);
        formData.append("brand", brandName);
        formData.append("project", values.assignedProject);

        axios({
            method: "POST",
            url: BASE_URL,
            data: formData,
        })
            .then((res) => {
                const { data } = res;
                option.onSuccess(res.data, option.file);

                if (data.scanErrorStatus && data.scanErrorStatus === SCAN_ERROR_STATUS) {
                    setIsNotScanned(true);
                }
            })
            .catch((err) => {
                const { status } = err.response;

                if (status === BAD_REQUEST) {
                    const { data } = err.response;
                    message.error(data.message);
                }

                if (status === INTERNAL_SERVER_ERROR) {
                    message.error("Oops, Something went wrong");
                }

                if (status === UNPROCESSABLE_ENTITY) {
                    setIsBroken(true);
                    message.error("Upload Failed, File is infected, Please upload another file.");
                }
            });
    };

    const handleOnChange = async ({ file, fileList: list }) => {
        if (file.status !== "uploading" && _.has(file, "response")) {
            const base64 = await convertFileToBase64(file.originFileObj);
            file["keyObj"] = file.response.key;
            file["thumbUrl"] = base64;
            setFieldValue("file", file);
            storeIsUploading(false);
        }

        if (file.status === "uploading") {
            file["thumbUrl"] = "";
            file["keyObj"] = "";
            file["url"] = "";
            setFieldValue("file", file);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleBeforeUpload = (file) => {
        const isLt2M = file.size / 1024 / 1024 < FILE_SIZE_LIMIT;
        storeIsUploading(true);
        if (!isLt2M) {
            message.error("File is too big");
            return false;
        }
        return file;
    };

    const handleUploadBtn = () => {
        values.assignedProject ? setNoAssignedProjError(false) : setNoAssignedProjError(true);
    };

    const handleAssignedProject = () => {
        setFieldValue("file", null);
    };

    return (
        <Spin spinning={loading}>
            <Form>
                <FormItem
                    label={"Document Name"}
                    name={"name"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input name="name" />
                </FormItem>

                <FormItem
                    label={"Assigned Project"}
                    name={"assignedProject"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <CustomSelect
                        name="assignedProject"
                        placeholder=""
                        options={projectList}
                        disabled={id ? true : false}
                        onChange={handleAssignedProject}
                        onSelect={() => setNoAssignedProjError(false)}
                    />
                </FormItem>

                <FormItem
                    label={"Attachments"}
                    name={"file"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    {values.file && values.file.name && (
                        <Spin spinning={values.file.status !== "done" && !isBroken}>
                            <UploadList
                                color="#F2F2F2"
                                style={{ margin: 0, marginBottom: 15 }}
                                onClose={() => setFieldValue("file", null)}
                                closable
                            >
                                <span className="file-name">{values.file.name}</span>
                            </UploadList>
                            {isBroken && (
                                <ErrorMessage>
                                    The file is infected, Saving this attachment is disabled.{" "}
                                </ErrorMessage>
                            )}
                            {isNotScanned && (
                                <ErrorMessage>
                                    File was uploaded but was not scanned, Please contact your
                                    system administrator.{" "}
                                </ErrorMessage>
                            )}
                        </Spin>
                    )}
                    <Upload
                        action={BASE_URL}
                        customRequest={handleRequest}
                        onChange={(event) => handleOnChange(event)}
                        accept={SUPPORTED_FORMATS}
                        showUploadList={false}
                        disabled={values.assignedProject ? false : true}
                    >
                        <Button type="dashed" block onClick={handleUploadBtn}>
                            Browse files
                        </Button>
                    </Upload>
                    {(errors?.file?.keyObj || noAssignedProjError) && (
                        <div style={{ color: "#ff4d4f" }}>
                            {noAssignedProjError
                                ? "Please assign a project first."
                                : errors.file.keyObj}
                        </div>
                    )}
                </FormItem>

                <Row justify={"space-between"}>
                    <Col span={11}>
                        <CustomButton
                            style={{ width: "100%", marginRight: 15 }}
                            type="primary"
                            disabled={!dirty || !isValid}
                            onClick={() => submitForm()}
                        >
                            SAVE
                        </CustomButton>
                    </Col>
                    <Col span={11}>
                        <CustomButton
                            style={{ width: "100%" }}
                            onClick={() => {
                                setNoAssignedProjError(false);
                                resetForm();
                                handleSlider();
                            }}
                        >
                            CANCEL
                        </CustomButton>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default Forms;
