import React, { useEffect, useContext, useState } from "react";
import { Switch, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

import "./App.less";
import axios from "axios";
import { gql } from "@apollo/client";
import MainLayout from "./components/Layout";
import { getRoute } from "./context/routers";
import { Context } from "./context";
import filter from "lodash/filter";
import upperCase from "lodash/upperCase";
import lowerCase from "lodash/lowerCase";
import find from "lodash/find";

import startCase from "lodash/startCase";
import { useQuery } from "@apollo/client";

import { getHomeLink } from "./util/get-home-link";

import Session from "./components/Session";

const userPermission = (userPermissions: any, access: string) => {
    const permissions = userPermissions ? userPermissions[0].permissions : null;
    if (!permissions) {
        return "none";
    }
    const findReadAccess = find(permissions, { scope: access });
    return findReadAccess ? true : false;
};

const BRAND = gql`
    query {
        getAllBrands {
            id
            name
            tenant_id
        }
    }
`;

const App: React.FC = () => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const history = useHistory();
    const {
        push,
        location: { pathname },
    } = history;
    const isAuthenticated = !!token;
    const [userPermissions, setUserPermissions] = useState([]);
    const { data: brandData, loading } = useQuery(BRAND);
    const {
        dispatch: { storeUser, storeBrand, storeAllBrand },
    } = useContext(Context);

    useEffect(() => {
        document.title = startCase(pathname);
    }, [pathname]);
    useEffect(() => {
        if (isAuthenticated && !loading) {
            const { getAllBrands } = brandData;
            const filteredBrands = filter(getAllBrands, (j) => j.name.includes("A"));
            storeAllBrand(filteredBrands);
            axios
                .get(`${process.env.REACT_APP_IAM_URL}/users/me`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    const {
                        data: { data },
                    } = response;
                    const brand = !data.tenants
                        ? []
                        : filter(getAllBrands, ["tenant_id", data.tenants[0].id]);
                    const constructBrandData = !brand.length
                        ? undefined
                        : {
                              ...brand[0],
                              name: upperCase(brand[0].name),
                          };
                    setUserPermissions(data.roles[0].permissions);
                    storeBrand(constructBrandData);
                    storeUser(data);
                    if (pathname === "/redirect") {
                        console.log(data.roles);
                        if (userPermission(data.roles, "read:contract_document_entry")) {
                            push("/manage-contracts");
                        } else {
                            push(getHomeLink(data.roles[0].permissions));
                        }
                    }

                    //   brand.length ?
                    //     push(getHomeLink(data.roles[0].permissions)) :
                    //     push('/manage-users')
                    return data;
                })
                .catch((error) => {
                    console.log(error, "ERROR");
                });
        }
    }, [isAuthenticated, loading]);

    return (
        <Session>
            <MainLayout data-index="layout">
                <Switch>
                    {isAuthenticated ? getRoute(userPermissions) : <Redirect to="/admin/login" />}
                </Switch>
            </MainLayout>
        </Session>
    );
};

export default App;
