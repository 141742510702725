import React from "react";
import isEmpty from "lodash/isEmpty";
import findIndex from "lodash/findIndex";

import { FormItem, Input } from "formik-antd";
import { Row, Col } from "antd";
import { CloseOutlined, PlayCircleOutlined } from "@ant-design/icons";

import { WrapperForm, PreviewFeatureVideo } from "./styled";
import FeaturedVideos from "../../../../../components/FeaturedVideos";

import { ComponentFormType } from "../content-forms.types";

const ComponentEleven = ({
    index,
    setFieldValue,
    fieldsArrayEvent,
    values,
}: ComponentFormType): JSX.Element => {
    const videoState = values.components[index].fields.videos;
    const featureIndex = findIndex(videoState, (res) => res.isChecked === true);

    return (
        <WrapperForm>
            <Row gutter={24}>
                <Col span={12}>
                    <PreviewFeatureVideo>
                        {!isEmpty(videoState) ? (
                            (videoState[featureIndex].thumbUrl || videoState[featureIndex].url) && (
                                <video
                                    src={
                                        videoState[featureIndex].url ||
                                        videoState[featureIndex].thumbUrl
                                    }
                                    controls
                                />
                            )
                        ) : (
                            <PlayCircleOutlined />
                        )}
                    </PreviewFeatureVideo>

                    <br />
                    <FeaturedVideos
                        fileList={videoState}
                        validate={videoState.length < 25}
                        onChange={(e) => setFieldValue(`components[${index}].fields.videos`, e)}
                    />
                </Col>
                <Col span={12}>
                    <FormItem
                        label={"Title"}
                        name={`components[${index}].fields.title`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input name={`components[${index}].fields.title`} />
                    </FormItem>
                    <FormItem
                        label={"Description"}
                        name={`components[${index}].fields.description`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input.TextArea name={`components[${index}].fields.description`} rows={7} />
                    </FormItem>
                </Col>
            </Row>
            <CloseOutlined
                className="remove-btn"
                onClick={() => {
                    fieldsArrayEvent.remove(index);
                }}
            />
        </WrapperForm>
    );
};

export default ComponentEleven;
