export const weekends = ["saturday", "sunday"];
export const weekdays = ["monday", "tuesday", "wednesday", "thursday", "friday"];

export const weekOrder = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
];
