import styled from "styled-components";
import { Button } from "antd";

export const Container = styled.div`
    position: relative;
    height: 100px;
    width: 160px;
`;

export const ButtonStyle = styled(Button)`
    height: 100px;
    width: 160px;
    padding: 0;
    overflow: hidden;
    color: #d8d8d8;
    background: #fafafa;

    img {
        height: 100px;
        width: 160px;
        object-fit: cover;
    }

    .anticon {
        svg {
            font-size: 24px;
        }
    }

    h1 {
        margin: 0;
        color: inherit;
    }
`;

export const WrapperPreview = styled.div`
    height: 100px;
    width: 160px;
    padding: 0;
    overflow: hidden;
    color: #d8d8d8;
    background: #fafafa;

    img {
        height: 100px;
        width: 160px;
        object-fit: cover;
    }

    .video-banner {
        width: 100%;
        object-fit: cover;
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        max-height: 100%;
    }

    & > div {
        height: 100%;
    }
`;

export const RemoveBtn = styled.div`
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 3px;

    .anticon {
        svg {
            font-size: 15px;
        }

        &:hover {
            color: #00714b;
        }
    }
`;

export const FileListStyled = styled.div`
    display: flex;
    margin-bottom: 5px;

    &:hover {
        background: #d8d8d8;
    }

    h4 {
        width: 100%;
        margin: 0;
    }

    .anticon {
        padding: 0 8px;
    }
`;

export const BrokenImageContainer = styled.div`
  position: relative;

   img {
    width: 100%;
    opacity: 10%;
   }

   p {
    position: absolute;
    top: 115px;
    background: darkgrey;
    width: 100%;
    text-align: center;
    padding: 12px;
    font-weight: 500;
    color: #000;
   }
`;

export const ImageNotScannedContainer = styled.div`
  position: relative;

   p {
    position: absolute;
    top: 70px;
    background: darkgrey;
    width: 100%;
    text-align: center;
    padding: 12px;
    font-weight: 500;
    color: #000;
    opacity: 80%;
   }
`;


export const ErrorMessage = styled.p`
   color: red;
`