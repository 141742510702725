import React from "react";
import { useParams } from "react-router-dom";

import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, Modal, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import CustomButton from "../../../../../components/CustomButton";
import CustomSelect from "../../../../../components/CustomSelect";
import ContentContainer from "../../../../../components/ContentContainer";
import Richtext from "../../../../../components/Richtext";

import { formatCurrency } from "../../../../../util/format-currency";
import { StyledUploadImage, Title } from "./styled";

import { propertyTypeList, subcategory } from "./constant";
import { getAcceptedFiles } from "../../../../../util/get-accepted-filetypes";
import { getUploadNote } from "../../../../../util/get-upload-note";

const { confirm } = Modal;

interface Props {
    formikBag: any;
    getDefaultPropertyType: any;
    loading?: boolean;
    brandName: string;
}

const Forms = ({ formikBag, getDefaultPropertyType, loading, brandName }: Props): JSX.Element => {
    const { slug } = useParams();
    const { values, submitForm, errors, setFieldValue, isValid, dirty } = formikBag;

    const title = slug ? "Edit" : "Add";

    const showConfirm = (onSubmitForm) => {
        const onClickHandle = onSubmitForm;
        confirm({
            title: `Are you sure you want to ${slug ? "save" : "publish"}?`,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                onClickHandle();
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    return (
        <Form>
            <Spin spinning={loading}>
                <Title>{`${title} Property`}</Title>
                <ContentContainer style={{ display: "flex", flexDirection: "column" }}>
                    <Row justify="start">
                        <Col span={24}>
                            <FormItem
                                label={
                                    <h1>
                                        <b>Overview </b>(Default)
                                    </h1>
                                }
                                name={"overviewDefault"}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input name="overviewDefault" placeholder="Override Label" />
                            </FormItem>
                        </Col>
                    </Row>

                    <Row gutter={20}>
                        <Col md={5} span={24}>
                            <StyledUploadImage
                                accept={getAcceptedFiles(brandName)}
                                value={values.image}
                                noteMessage={getUploadNote(brandName)}
                                onChange={(e) => {
                                    setFieldValue("image", e);
                                }}
                            >
                                <h1>+ Upload Logo</h1>
                            </StyledUploadImage>
                            {errors.image ? (
                                <>
                                    {typeof errors.image === "string" ? (
                                        <div style={{ color: "#ff4d4f" }}>{errors.image}</div>
                                    ) : (
                                        <div style={{ color: "#ff4d4f" }}>
                                            {errors.image.keyObj}
                                        </div>
                                    )}
                                </>
                            ) : null}
                            {brandName === "ALP" ? (
                                <FormItem
                                    label="Image Alt Text"
                                    name="image.altText"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <Input
                                        name="image.altText"
                                        suffix={<span style={{ color: "red" }}>*</span>}
                                    />
                                </FormItem>
                            ) : null}
                        </Col>
                        <Col md={19} span={24}>
                            <Row justify="start">
                                <Col span={24}>
                                    <FormItem
                                        label={"Project Name"}
                                        name={"projectName"}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                    >
                                        <Input
                                            name="projectName"
                                            suffix={<span style={{ color: "red" }}>*</span>}
                                        />
                                    </FormItem>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <FormItem
                                                label={"Property Type"}
                                                name={"propertyType"}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <CustomSelect
                                                    name={"propertyType"}
                                                    placeholder={"Select Property Type"}
                                                    options={propertyTypeList}
                                                    disabled={
                                                        getDefaultPropertyType() !== "" || slug
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(newVal) => {
                                                        newVal !== "Condominium" &&
                                                            newVal !== "Townhouse" &&
                                                            setFieldValue("subcategory", null);
                                                    }}
                                                />
                                            </FormItem>
                                        </Col>
                                        {brandName === "ALP" && (
                                            <Col span={12}>
                                                <FormItem
                                                    label={"Sub Category"}
                                                    name={"subcategory"}
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                >
                                                    <CustomSelect
                                                        name={"subcategory"}
                                                        placeholder={"Select Sub Category"}
                                                        options={subcategory}
                                                        disabled={
                                                            !["Condominium", "Townhouse"].includes(
                                                                values.propertyType
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                </FormItem>
                                            </Col>
                                        )}
                                        <Col span={12}>
                                            <FormItem
                                                label={"Property Price"}
                                                name={"propertyPrice"}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input
                                                    name="propertyPrice"
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            "propertyPrice",
                                                            formatCurrency(e)
                                                        );
                                                    }}
                                                    onPaste={(e) => {
                                                        setFieldValue(
                                                            "propertyPrice",
                                                            formatCurrency(e)
                                                        );
                                                    }}
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <FormItem
                                                label={"Property Price Range (Lowest)"}
                                                name={"propertyPriceRangeLowest"}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input
                                                    name="propertyPriceRangeLowest"
                                                    onChange={(e) => {
                                                        const _value = e.target.value.trim();
                                                        setFieldValue(
                                                            "propertyPriceRangeLowest",
                                                            _value !== "" ? formatCurrency(e) : null
                                                        );
                                                    }}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem
                                                label={"Property Price Range (Highest)"}
                                                name={"propertyPriceRangeHighest"}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input
                                                    name="propertyPriceRangeHighest"
                                                    onChange={(e) => {
                                                        const _value = e.target.value.trim();
                                                        setFieldValue(
                                                            "propertyPriceRangeHighest",
                                                            _value !== "" ? formatCurrency(e) : null
                                                        );
                                                    }}
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <FormItem
                                                label={"Location (Brief)"}
                                                name={"location"}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input name="location" />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem
                                                label={"Terrain"}
                                                name={"terrain"}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input name="terrain" />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <FormItem
                                                label={"Property Size (Lot Area)"}
                                                name={"propertySizeLotArea"}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input name="propertySizeLotArea" />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem
                                                label={"Property Size (Floor Area)"}
                                                name={"propertySizeFloorArea"}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input name="propertySizeFloorArea" />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    {brandName === "AVIDA" || brandName === "AMAIA" ? (
                                        <Row gutter={20}>
                                            <Col span={12}>
                                                <FormItem
                                                    label={"Available Unit Link/Lot Button"}
                                                    name={"unitLinkBtn"}
                                                    labelCol={{ span: 24 }}
                                                    wrapperCol={{ span: 24 }}
                                                >
                                                    <Input name="unitLinkBtn" />
                                                </FormItem>
                                            </Col>
                                            {brandName === "AVIDA" ? (
                                                <Col span={12}>
                                                    <FormItem
                                                        label={"Gtm Code"}
                                                        name={"gtmCode"}
                                                        labelCol={{ span: 24 }}
                                                        wrapperCol={{ span: 24 }}
                                                    >
                                                        <Input name="gtmCode" />
                                                    </FormItem>
                                                </Col>
                                            ) : null}
                                        </Row>
                                    ) : null}
                                    {brandName === "AMAIA" && (
                                        <>
                                            <Row gutter={20}>
                                                <Col span={24}>
                                                    <FormItem
                                                        label={"LTS and Approval No."}
                                                        name={"ltsAndApprovalNo"}
                                                        labelCol={{ span: 24 }}
                                                        wrapperCol={{ span: 24 }}
                                                    >
                                                        <Richtext
                                                            heading={true}
                                                            data={values.ltsAndApprovalNo}
                                                            onChange={(value: string) =>
                                                                setFieldValue(
                                                                    "ltsAndApprovalNo",
                                                                    value,
                                                                    false
                                                                )
                                                            }
                                                        />
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <h3>Map Image</h3>
                                            <Row>
                                                <Col md={6} span={24}>
                                                    <StyledUploadImage
                                                        accept={getAcceptedFiles(brandName)}
                                                        value={values.image2}
                                                        noteMessage={getUploadNote(brandName)}
                                                        onChange={(e) => {
                                                            setFieldValue("image2", e);
                                                        }}
                                                    >
                                                        <h1>+ Add Image</h1>
                                                    </StyledUploadImage>
                                                    {errors.image2 ? (
                                                        <>
                                                            {typeof errors.image2 === "string" ? (
                                                                <div style={{ color: "#ff4d4f" }}>
                                                                    {errors.image2}
                                                                </div>
                                                            ) : (
                                                                <div style={{ color: "#ff4d4f" }}>
                                                                    {errors.image2.keyObj}
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ContentContainer>
                <Row justify="end" style={{ margin: "0 24px" }}>
                    <Col span={3}>
                        <CustomButton
                            style={{ width: "100%", marginBottom: "24px" }}
                            type="primary"
                            disabled={!dirty || !isValid}
                            onClick={() => showConfirm(submitForm)}
                        >
                            {slug ? "Save" : "Publish"}
                        </CustomButton>
                    </Col>
                </Row>
            </Spin>
        </Form>
    );
};

export default Forms;
