import _ from "lodash";
import { FormValueTypes, LinksType } from "./manage-links.types";

export const INIT_LINKS_VALUE: FormValueTypes = {
    links: [
        {
            name: "",
            link: "",
            image: null,
        },
    ],
};

export const linksMapper = (links, category): LinksType[] =>
    links.map((link) => {
        const tempLink: LinksType = {
            name: link.name,
            link: link.link,
            category,
        };

        if (link.image) {
            tempLink["imageKey"] = link.image.keyObj;
        }

        return tempLink;
    });

export const containerMapper = (
    links,
    brandId,
    linkType
): { brandId: string; createManyLinksInput: FormValueTypes } => {
    let linksAsContainer: LinksType[] = [];

    if (!_.isEmpty(links)) {
        linksAsContainer = linksMapper(links, linkType);
    }

    const containers: { brandId: string; createManyLinksInput: FormValueTypes } = {
        brandId,
        createManyLinksInput: {
            links: linksAsContainer && linksAsContainer,
        },
    };

    return containers;
};
