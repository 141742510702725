import * as yup from "yup";
import componentOne from "../../assets/manage-content/components/component-13.svg";
import componentTwo from "../../assets/manage-content/components/component-1.svg";

import isEmpty from "lodash/isEmpty";
import kebabCase from "lodash/kebabCase";
import toUpper from "lodash/toUpper";

const imageSchema = yup
    .object()
    .shape({
        status: yup.string().matches(/done/, { message: "file is still uploading" }),
    })
    .nullable();

const fieldsValidation = yup.object().when("type", (value) => {
    switch (value) {
        case "component 1":
            return yup.object().shape({
                body: yup.string(),
            });
        case "component 2":
            return yup.object().shape({
                image: imageSchema,
                description: yup.string(),
            });
        default:
            return yup.object().nullable();
    }
});

const imageTitleDescription = {
    image: null,
    description: "",
};

export const componentList = [
    {
        image: componentOne,
        type: "component 1",
        label: "Rich Text Field",
        fields: {
            subtitle: "",
            text: "",
        },
    },
    {
        image: componentTwo,
        type: "component 2",
        label: "Text Field with Image",
        fields: imageTitleDescription,
    },
];

const componentType = yup.object().shape({
    type: yup.string(),
    fields: fieldsValidation,
});

export const validationSchema = yup.object().shape({
    homeBuyingGuideDefault: yup.string().nullable(),
    components: yup.array().of(componentType),
});

export const initialContainersData = (
    page_id: string | number,
    name?: string,
    label?: string
): any[] => {
    return [
        {
            order: 0,
            name: "HOME-BUYING-GUIDE",
            page_id: page_id,
            field_collections: [
                {
                    order: 0,
                    name: name || "HOME-BUYING-GUIDE",
                    children: [],
                    fields: [
                        {
                            order: 0,
                            name: name || "HOME-BUYING-GUIDE",
                            label: label || "Home Buying Guide (Default)",
                            value: null,
                        },
                    ],
                },
            ],
        },
    ];
};

export const FieldsMapper = (data) => {
    const fields: any = [];

    Object.keys(data).forEach((fieldKey, fieldIndex) => {
        const field = {
            order: fieldIndex,
            label: fieldKey,
            name: toUpper(kebabCase(fieldKey)),
        };

        if (!fieldKey.includes("image") && fieldKey !== "videos") {
            field["value"] = data[fieldKey] ? data[fieldKey] : null;
            fields.push(field);
        } else if (fieldKey === "videos") {
            if (!isEmpty(data[fieldKey])) {
                data[fieldKey].forEach((vid, vidIndex) => {
                    const vidField = {
                        name: "File",
                        label: "video",
                        order: vidIndex + fieldIndex,
                        value: vid.keyObj,
                        meta_data: {
                            featured: vid.isChecked,
                            description: "",
                            key: vid.keyObj,
                            file_type: vid.type,
                            file_name: vid.name,
                        },
                    };
                    fields.push(vidField);
                });
            }
        } else if (fieldKey.includes("image")) {
            if (data[fieldKey]) {
                field["name"] = "File";
                field["value"] = data[fieldKey].keyObj;
                field["meta_data"] = {
                    featured: false,
                    description: "",
                    key: data[fieldKey].keyObj,
                    file_type: data[fieldKey].type,
                    file_name: data[fieldKey].name,
                };
                fields.push(field);
            }
        }
    });

    return fields;
};

type ComponentMapperValuesType = {
    data: any[];
    parentId: string | number;
};

export const ComponentMapper = (data, parentId: ComponentMapperValuesType): any => {
    const result = data.map((component, componentIndex) => {
        const componentData = {
            parentId: parentId,
            order: componentIndex,
            name: toUpper(kebabCase(component.type)),
            fields: FieldsMapper(component.fields),
        };

        return componentData;
    });

    return result;
};
