import React, { useState } from "react";
import _ from "lodash";

import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, Modal } from "antd";

import CustomButton from "../../../../components/CustomButton";
import ContentContainer from "../../../../components/ContentContainer";
import FeaturedImages from "../../../../components/FeaturedImages";
import MultipleUrlFields from "../../../../components/MultipleUrlFields";

import { Title, StyledIFrame } from "./styled";

interface Props {
    formikBag: any;
    brandName: string;
}

const Forms = ({ formikBag, brandName }: Props): JSX.Element => {
    const { values, submitForm, setFieldValue, isValid, dirty } = formikBag;
    const [modalVisible, setModalVisible] = useState(false);
    const [preview, setPreview] = useState("");

    const handleClosePreview = () => {
        setPreview("");
        setModalVisible(false);
    };

    const handlePreview = (val) => {
        if (val) {
            setPreview(val);
            setModalVisible(true);
        }
    };

    return (
        <Form>
            <Title>Career Information Details</Title>
            <ContentContainer style={{ display: "flex", flexDirection: "column", padding: "30px" }}>
                <Row justify="start">
                    <Col span={24}>
                        <FormItem
                            label={
                                <h1>
                                    <b>Careers</b> (Default)
                                </h1>
                            }
                            name={"careersDefault"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name="careersDefault" placeholder="Override Label" />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem
                            label={"Description"}
                            name={"description"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input.TextArea name={"description"} />
                        </FormItem>
                    </Col>
                    <Col span={24} style={{ marginBottom: 25 }}>
                        <h3>Image Gallery</h3>
                        <FeaturedImages
                            onChange={(e) => {
                                const imagesCaptions = e.map(({ caption }) => caption);
                                setFieldValue(`imagesCaptions`, imagesCaptions);
                                setFieldValue("imageGallery", e);
                            }}
                            path={"career-information-details"}
                            captionList={values.imagesCaptions}
                            fileList={values.imageGallery}
                            validate={true}
                            imageAltText={brandName === "ALP"}
                        />
                    </Col>
                    <Col span={24}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <p>Video</p>
                                <MultipleUrlFields
                                    values={values.videos}
                                    placeholder="Video Link"
                                    name="videos"
                                    preview={handlePreview}
                                />
                            </Col>
                            <Col span={12}>
                                <p>Panorama</p>
                                <MultipleUrlFields
                                    values={values.panoramas}
                                    placeholder="Panorama Link"
                                    name="panoramas"
                                    preview={handlePreview}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ContentContainer>

            <Row justify="end" gutter={24} style={{ padding: "0 25px 25px 25px" }}>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={() => submitForm()}
                        disabled={!isValid || !dirty}
                    >
                        SAVE
                    </CustomButton>
                </Col>
            </Row>
            <Modal
                visible={modalVisible}
                centered
                footer={null}
                bodyStyle={{ padding: 0, height: 400 }}
                onCancel={handleClosePreview}
                width={700}
                closable={false}
                maskClosable={true}
                destroyOnClose={true}
            >
                <StyledIFrame src={preview} width="100%" height="100%"></StyledIFrame>
            </Modal>
        </Form>
    );
};

export default Forms;
