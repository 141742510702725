import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { Breadcrumb } from "antd";

import AwardList from "./list/index";
import AwardForms from "./forms/index";

export const StyledBreadcrumb = styled(Breadcrumb)`
    background: #fff;
    display: flex;
    padding: 16px 25px;
`;

const ManageContent = (): JSX.Element => {
    const match = useRouteMatch();
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={`${match.path}`}
                    render={(props: any) => <AwardList {...props} />}
                />
                <Route
                    exact
                    path={`${match.path}/edit/:slug`}
                    render={(props: any) => <AwardForms {...props} />}
                />
                <Route
                    exact
                    path={`${match.path}/add`}
                    render={(props: any) => <AwardForms {...props} />}
                />
            </Switch>
        </>
    );
};

export default ManageContent;
