import styled from "styled-components";
import { Tabs, Button } from "antd";

const { TabPane } = Tabs;

export const StyledTabs = styled(Tabs)`
    .ant-tabs-nav-list > .ant-tabs-tab {
        padding: 12px 30px;
    }

    .ant-select {
        display: block;
    }
`;

export const StyledTabPane = styled(TabPane)`
    padding: 24px 46px;
`;

export const AddButton = styled(Button)`
    height: 100%;
    background-color: transparent;
    color: #00714b;
    border-color: #00714b;
`;

export const NoPropertyAccess = styled.div`
    height: 100%;
    font-size: 5vh;
    text-align: center;
    padding-top: 10%;
`;
