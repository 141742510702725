import isEmpty from "lodash/isEmpty";
import moment from "moment";

import { Property } from "../../../entities/Property";
import { LinkedPage } from "./LinkedPage";

export class LinkedPropertyMapper {
    static map(page: LinkedPage): Property {
        const overviewFields = 
            !isEmpty(page.containers) &&
            !isEmpty(page.containers[0].field_collections) && // OVERVIEW-FC
            !isEmpty(page.containers[0].field_collections[0].fields) &&
            page.containers[0].field_collections[0].fields;

        page.type = 
            overviewFields &&
            !isEmpty(overviewFields.filter(field => field.name === "PROPERTY-TYPE")) &&
            overviewFields.filter(field => field.name === "PROPERTY-TYPE")[0].value ?
            overviewFields.filter(field => field.name === "PROPERTY-TYPE")[0].value : "No category";

        page.imgURL =
            overviewFields &&
            !isEmpty(overviewFields.filter(field => field.name === "IMAGE" || field.name === "Image")) &&
            overviewFields.filter(field => field.name === "IMAGE" || field.name === "Image")[0].value &&
            overviewFields.filter(field => field.name === "IMAGE" || field.name === "Image")[0].value;

        page.excerpt = 
            !isEmpty(page.containers) && // FIRST UNIT CONTAINER
            page.containers[1] &&
            !isEmpty(page.containers[1].field_collections) &&
            !isEmpty(page.containers[1].field_collections[0].children) && // OVERVIEW DEFAULT
            !isEmpty(page.containers[1].field_collections[0].children[0].fields) &&
            !isEmpty(page.containers[1].field_collections[0].children[0].fields.filter(field => field.name === "PROPERTY-DETAILS")) &&
            page.containers[1].field_collections[0].children[0].fields.filter(field => field.name === "PROPERTY-DETAILS")[0].value &&
            page.containers[1].field_collections[0].children[0].fields.filter(field => field.name === "PROPERTY-DETAILS")[0].value;
        
        page.date = page.updated_at ? moment(page.updated_at).format("MMM DD, YYYY") : moment(page.created_at).format("MMM DD, YYYY");

        delete page.containers;
        delete page.updated_at;
        delete page.created_at;
        delete page.__typename;
        
        return page;
    }
}