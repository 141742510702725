import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Careers from "./components/careers";
import CareersInformation from "./components/careers-information-forms";

const ManageCareers: React.FC = () => {
    const match = useRouteMatch();
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={[`${match.path}/careers/:id`, `${match.path}/careers`]}
                    render={(props: any) => <Careers {...props} />}
                />
                <Route
                    exact
                    path={`${match.path}/careers-information-details`}
                    render={(props: any) => <CareersInformation {...props} />}
                />
            </Switch>
        </>
    );
};

export default ManageCareers;
