import React, { useContext, useState } from "react";
import { Upload, Spin } from "antd";
import findIndex from "lodash/findIndex";
import has from "lodash/has";
import axios from "axios";
import { message } from "antd";
import { PlusOutlined, CloseOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { convertFileToBase64 } from "../../util/convert-to-base64";
import {
    ButtonStyle,
    RemoveBtn,
    Container,
    ImageWrapper,
    VideoPlayWrapper,
    BrokenImageContainer,
    ImageNotScannedContainer,
} from "./styled";
import ImageIcon from "../../assets/image-icon.png";
import { getAcceptedFiles } from "../../util/get-accepted-filetypes";
import { Context } from "../../context";

const INTERNAL_SERVER_ERROR = 500;
const UNPROCESSABLE_ENTITY = 422;
const SCAN_ERROR_STATUS = 403;
const INFECTED = "infected";
const NOT_SCANNED = "not_scanned";
const BAD_REQUEST = 400;
const BASE_URL = `${process.env.REACT_APP_CONTENT_URL}/uploads/images`;

interface Props {
    fileList: any;
    onChange: any;
    path?: string;
    className?: string;
    renameAddStr?: string;
    accept?: string;
    validate?: boolean;
    sizeLimit?: number;
    preview?: (value: string | undefined) => void;
}

const UploadMultipleImage = ({
    fileList,
    onChange,
    path,
    className,
    renameAddStr,
    accept,
    validate,
    sizeLimit,
    preview,
}: Props): JSX.Element => {
    const [infectedFileId, setInfectedFileId] = useState(null);
    const [isNotScannedId, setIsNotScannedId] = useState(null);

    const {
        state: { brand: brandData },
    } = useContext(Context);
    const brandName = brandData?.name;

    const deleteItem = async (key: number, list: any) => {
        const newList = list.filter((value, index) => index !== key);
        onChange(newList);
    };

    const handleOnChange = async ({ file, fileList: list }) => {
        const limit = !!sizeLimit ? sizeLimit : 49;
        const isLt2M = file.size / 1024 / 1024 < limit;

        if (file.status !== "uploading" && has(file, "response")) {
            const base64 = await convertFileToBase64(file.originFileObj);
            const index = await findIndex(list, (e) => e.uid === file.uid);

            list[index]["keyObj"] = file.response.key;
            list[index]["thumbUrl"] = base64;
            onChange(list);
        }

        if (file.status === "uploading" && isLt2M) {
            const index = await findIndex(list, (e) => e.uid === file.uid);
            list[index]["thumbUrl"] = "";
            list[index]["keyObj"] = "";
            onChange(list);
        }
    };

    const UploadImage = () => {
        return (
            <ButtonStyle type="dashed">
                <div>
                    <PlusOutlined />
                    <h1>{renameAddStr ? renameAddStr : "Upload"}</h1>
                </div>
            </ButtonStyle>
        );
    };

    const renderMediaType = (file, data, index) => {
        if (file.type && (file.url || file.thumbUrl)) {
            if (file.type.includes("image")) {
                return (
                    <ImageWrapper>
                        {data[index].status === INFECTED ? (
                            <BrokenImageContainer>
                                <img src={ImageIcon} alt="img" />
                                <p>The file is infected, Saving this image is disabled. </p>
                            </BrokenImageContainer>
                        ) : data[index].file_status === NOT_SCANNED ? (
                            <ImageNotScannedContainer>
                                <img src={file.url || file.thumbUrl} alt="img" />
                                <p>
                                    File was uploaded but was not scanned, Please contact your
                                    system administrator.{" "}
                                </p>
                            </ImageNotScannedContainer>
                        ) : (
                            <img src={file.url || file.thumbUrl} />
                        )}
                        <RemoveBtn onClick={() => deleteItem(index, data)}>
                            <CloseOutlined style={{ fontSize: 15 }} />
                        </RemoveBtn>
                    </ImageWrapper>
                );
            }
            if (file.type.includes("video")) {
                return (
                    <ImageWrapper>
                        <video>
                            <source src={file.url || file.thumbUrl} type={file.type} />
                        </video>
                        <VideoPlayWrapper
                            onClick={() => {
                                preview && preview(file.url || file.thumbUrl);
                            }}
                        >
                            <PlayCircleOutlined />
                        </VideoPlayWrapper>
                        <RemoveBtn onClick={() => deleteItem(index, data)}>
                            <CloseOutlined style={{ fontSize: 15 }} />
                        </RemoveBtn>
                    </ImageWrapper>
                );
            }
        }

        return (
            <ImageWrapper>
                <RemoveBtn onClick={() => deleteItem(index, data)}>
                    <CloseOutlined style={{ fontSize: 15 }} />
                </RemoveBtn>
            </ImageWrapper>
        );
    };

    const renderItems = (data) => {
        data = data.map((val) => {
            if (val.uid === infectedFileId && infectedFileId) {
                val.status = INFECTED;
            }

            if (val.uid === isNotScannedId && isNotScannedId) {
                val.file_status = NOT_SCANNED;
            }

            return val;
        });

        return data.map((file, index) => (
            <Spin key={file.status} spinning={file.status === "uploading"}>
                {renderMediaType(file, data, index)}
            </Spin>
        ));
    };

    const handleBeforeUpload = (file) => {
        // Check file type
        const acceptedFiles = getAcceptedFiles(brandName);
        const fileTypesList = [...acceptedFiles.split(",")];

        const errMessage = fileTypesList
            .map((file) => {
                return file.replace("image/", "").toUpperCase();
            })
            .join("/");

        if (!fileTypesList.includes(file.type)) {
            message.error(`You can only upload ${errMessage} file type`);
            return false;
        }

        const limit = !!sizeLimit ? sizeLimit : 49;
        const isLt2M = file.size / 1024 / 1024 < limit;

        if (!isLt2M) {
            message.error("File is too big");
            return false;
        }
        return file;
    };

    const handleRequest = (option) => {
        setInfectedFileId(null);
        setIsNotScannedId(null);
        const formData = new FormData();
        formData.append("file", option.file);

        axios({
            method: "POST",
            url: BASE_URL,
            data: formData,
        })
            .then((res) => {
                const { data } = res;
                option.onSuccess(res.data, option.file);

                if (data.scanErrorStatus && data.scanErrorStatus === SCAN_ERROR_STATUS) {
                    setIsNotScannedId(option.file.uid);
                }
            })
            .catch((err) => {
                const { status } = err.response;

                if (status === BAD_REQUEST) {
                    const { data } = err.response;
                    message.error(data.message);
                }

                if (status === INTERNAL_SERVER_ERROR) {
                    message.error("Oops, Something went wrong");
                }

                if (status === UNPROCESSABLE_ENTITY) {
                    setInfectedFileId(option.file.uid);
                    message.error("Upload Failed, File is infected, Please upload another file.");
                }
            });
    };

    return (
        <Container>
            {renderItems(fileList)}
            <Upload
                action={BASE_URL}
                showUploadList={false}
                customRequest={handleRequest}
                onChange={(event) => handleOnChange(event)}
                accept={accept}
                fileList={fileList}
                beforeUpload={handleBeforeUpload}
            >
                {validate && UploadImage()}
            </Upload>
        </Container>
    );
};

export default UploadMultipleImage;
