import { gql } from "@apollo/client";

export const GET_COMPONENT_CATEGORY = gql`
    query {
        getAllFieldCategories {
            id
            name
        }
    }
`;

export const GET_PAGES = gql`
    query searchPages(
        $brand_id: String!
        $search: String!
        $tag: String
        $page: Int
        $limit: Int
        $type: String
    ) {
        searchPages(
            brandId: $brand_id
            search: $search
            tag: $tag
            page: $page
            limit: $limit
            filters: { content: { type: $type } }
        ) {
            pages {
                id
                name
                tag
                slug
                featured
                updated_at
                created_at
                containers {
                    id
                    name
                    field_collections {
                        id
                        name
                        fields {
                            name
                            value
                        }
                    }
                }
            }
            totalCount
        }
    }
`;

export const CREATE_PAGE = gql`
    mutation createPage($brand_id: String!, $name: String!, $tag: String!) {
        addOrUpdateBrandPage(brand_id: $brand_id, tag: $tag, item: { name: $name }) {
            id
            name
            brand_id
            slug
        }
    }
`;

export const EDIT_PAGE = gql`
    mutation editPage($brand_id: String!, $id: String!, $featured: Boolean, $tag: String!) {
        addOrUpdateBrandPage(
            brand_id: $brand_id
            tag: $tag
            item: { id: $id, featured: $featured }
        ) {
            id
            featured
        }
    }
`;

export const REMOVE_PAGE = gql`
    mutation removePage($id: String!) {
        removePage(id: $id)
    }
`;

export const CREATE_FC = gql`
    mutation createFC($name: String!, $container_id: String!, $order: Float!) {
        createFC(collectionData: { name: $name, container_id: $container_id, order: $order }) {
            id
            name
            container_id
        }
    }
`;

export const CREATE_MULTI_FC = gql`
    mutation createMultipleFC($data: UpdateFieldCollectionPageInput!) {
        createMultipleFC(collectionData: $data) {
            id
            name
            container {
                id
                name
                field_collections {
                    name
                    id
                }
            }
        }
    }
`;

export const MODIFY_FIELDS = gql`
    mutation modifyFields($data: UpdateFieldCategoryPageInput!) {
        addOrUpdateFields(fieldCategoryData: $data) {
            id
            name
            label
            value
            order
        }
    }
`;

export const REMOVE_FIELD = gql`
    mutation removeField($data: DeleteFieldCategoryInput!) {
        removeFieldCategory(removeFieldData: $data)
    }
`;

export const GET_CONTAINER = gql`
    query getContainer($id: String!) {
        getContainer(id: $id) {
            name
            id
            field_collections {
                id
                name
                order
                fields {
                    id
                    name
                    label
                    name
                    value
                    order
                }
            }
        }
    }
`;

export const GET_ALL_CONTAINERS = gql`
    query allContainer {
        getAllContainers {
            id
            name
            deleted_at
        }
    }
`;

export const DELETE_CONTAINER = gql`
    mutation deleteContainer($id: String!) {
        removeContainer(id: $id)
    }
`;

export const DELETE_FC = gql`
    mutation deleteFC($id: String!) {
        removeFC(id: $id)
    }
`;

export const BRANDS = gql`
    query getAllbrands {
        getAllBrands {
            name
            id
        }
    }
`;

export const REMOVE_MULTIPLE_FC = gql`
    mutation removeMultiFC($data: DeleteFieldCollectionInput!) {
        removeMultipleFieldCollection(removeFCData: $data)
    }
`;

export const CREATE_CONTAINER = gql`
    mutation addOrUpdateNested($data: CreateUpdateContainerNestedInput!) {
        addOrUpdateContainersFCFields(containerData: $data) {
            id
        }
    }
`;

export const GET_PAGE = gql`
    query getPage($page_id: String!) {
        getSpecificBrandPageById(id: $page_id) {
            id
            name
            slug
            tag
            containers {
                id
                name
                field_collections {
                    id
                    name
                    fields {
                        id
                        label
                        name
                        value
                        order
                        meta_data {
                            key
                            file_type
                            file_name
                            featured
                            alt_text
                        }
                    }
                }
            }
        }
    }
`;

export const EDIT_PAGE_TITLE = gql`
    mutation editPage($brand_id: String!, $id: String!, $name: String, $tag: String!) {
        addOrUpdateBrandPage(brand_id: $brand_id, tag: $tag, item: { id: $id, name: $name }) {
            id
            name
            brand_id
            slug
            tag
        }
    }
`;

export const MODIFY_FC_FIELDS = gql`
    mutation modifyFCFields($data: UpdateFieldCollectionPageInput!) {
        addUpdateMultipleFieldCollection(collectionData: $data) {
            id
            order
            name
        }
    }
`;
