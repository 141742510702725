import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { FieldArray } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import CustomButton from "../../../../../components/CustomButton";
import ContentContainer from "../../../../../components/ContentContainer";
import UploadMultipleImage from "../../../../../components/UploadMultipleImage";
import { InputWrapper } from "./styled";
import { Context } from "../../../../../context";
import { getAcceptedFiles } from "../../../../../util/get-accepted-filetypes";

interface Props {
    formikBag: any;
}

const renderBodyField = ({ event, values, errors }, sectionName) => {
    return (
        <Col span={24} style={{ marginBottom: 25 }}>
            <h4>Body</h4>
            {values.body.map(({ content }, index) => {
                const name = `${sectionName}.body[${index}].content`;
                return (
                    <FormItem name={name} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                        <InputWrapper>
                            <Input.TextArea
                                name={name}
                                placeholder="Enter body"
                                rows={4}
                                style={{ paddingRight: 20 }}
                            />
                            <CloseOutlined
                                className="remove-btn"
                                onClick={() => {
                                    event.remove(index);
                                }}
                            />
                        </InputWrapper>
                    </FormItem>
                );
            })}
            <CustomButton
                type="dashed"
                style={{ width: "100%", fontSize: 13, fontWeight: 300 }}
                onClick={() => {
                    event.push({ content: "" });
                }}
            >
                <p style={{ margin: 0 }}>+ Add Body</p>
            </CustomButton>
        </Col>
    );
};

const Forms: React.SFC<Props> = ({ formikBag }) => {
    const history = useHistory();
    const [modalVisible, setModalVisible] = useState(false);
    const [preview, setPreview] = useState("");
    const { values, submitForm, errors, setFieldValue, dirty, isValid } = formikBag;

    const {
        state: { brand: brandData },
    } = useContext(Context);
    const brandName = brandData?.name;

    const handleClosePreview = () => {
        setPreview("");
        setModalVisible(false);
    };

    const handlePreview = (val) => {
        if (val) {
            setPreview(val);
            setModalVisible(true);
        }
    };

    return (
        <Form>
            <ContentContainer style={{ display: "flex", flexDirection: "column" }}>
                <Row justify="start">
                    <Col span={24}>
                        <FormItem
                            label={
                                <h1>
                                    <b>Vision </b>(Default)
                                </h1>
                            }
                            name={"vision.title"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name="vision.title" placeholder="Override Label" />
                        </FormItem>
                    </Col>
                    <FieldArray
                        name={"vision.body"}
                        render={(event) =>
                            renderBodyField({ event, values: values.vision, errors }, "vision")
                        }
                    />
                    <Col span={24}>
                        <Row gutter={24}>
                            <Col>
                                <UploadMultipleImage
                                    preview={handlePreview}
                                    accept={getAcceptedFiles(brandName, true)}
                                    validate={values.vision.images.length <= 3}
                                    fileList={values.vision.images}
                                    onChange={async (e) => setFieldValue(`vision.images`, e)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ContentContainer>
            <ContentContainer style={{ display: "flex", flexDirection: "column" }}>
                <Row justify="start">
                    <Col span={24}>
                        <FormItem
                            label={
                                <h1>
                                    <b>Mission </b>(Default)
                                </h1>
                            }
                            name={"mission.title"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name="mission.title" placeholder="Override Label" />
                        </FormItem>
                    </Col>
                    <FieldArray
                        name={"mission.body"}
                        render={(event) =>
                            renderBodyField({ event, values: values.mission, errors }, "mission")
                        }
                    />
                    <Col span={24}>
                        <Row gutter={24}>
                            <Col>
                                <UploadMultipleImage
                                    preview={handlePreview}
                                    accept={getAcceptedFiles(brandName, true)}
                                    validate={values.mission.images.length <= 3}
                                    fileList={values.mission.images}
                                    onChange={async (e) => setFieldValue(`mission.images`, e)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ContentContainer>

            <Row justify="end" gutter={24} style={{ padding: "0 25px", marginBottom: 25 }}>
                <Col span={3}>
                    <CustomButton
                        disabled={!dirty || !isValid}
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={() => submitForm()}
                    >
                        SAVE
                    </CustomButton>
                </Col>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%" }}
                        onClick={() => history.push(`/manage-company-information`)}
                    >
                        CANCEL
                    </CustomButton>
                </Col>
            </Row>
            <Modal
                visible={modalVisible}
                centered
                footer={null}
                bodyStyle={{ padding: 0, height: 400 }}
                onCancel={handleClosePreview}
                width={700}
                closable={false}
                maskClosable={true}
                destroyOnClose={true}
            >
                <video width="100%" height="100%" style={{ objectFit: "cover" }} controls>
                    <source src={preview} />
                </video>
            </Modal>
        </Form>
    );
};

export default Forms;
