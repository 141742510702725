import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import { Spin, message } from "antd";
import ContentContainer from "../../../../../../components/ContentContainer";
import Forms from "./form";
import { v4 } from "uuid";

import { Context } from "../../../../../../context";
import { validationSchema } from "./constant";
import { MODIFY_FC_FIELDS } from "../../../../graphql";

import { FormValueType, FieldInfoType, FieldInfoChildrenType } from "./floor-plans-types";
import { INIT_VALUES, INIT_FIELD_INFO, INIT_CHILDREN_VALUES } from "./floor-plans.utils";

import { processImageFields } from "../unit-tabs-utils";
import { Props } from "../unit-tabs-types";

const UnitFloorPlansForms = ({ handleRefresh }: Props): JSX.Element => {
    const {
        state: { propertyUnitForms, brand: brandData },
    } = useContext(Context);

    const brandName = brandData?.name;
    const [modifyFCFields] = useMutation(MODIFY_FC_FIELDS);

    const [initialValues, setInitialValues] = useState<FormValueType>(INIT_VALUES);
    const [fieldInfo, setFieldInfo] = useState<FieldInfoType>(INIT_FIELD_INFO);

    const [loading, setLoading] = useState(false);
    const [reinitialize, setReinitialize] = useState(false);

    useEffect(() => {
        if (reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        const data = propertyUnitForms.payload.floorPlans;
        if (_.has(data, "fields") && data.fields) {
            const sectionsFC: any = [];

            const children: FieldInfoChildrenType = INIT_CHILDREN_VALUES;

            const fieldItem: any = [];

            const fields: FormValueType = INIT_VALUES;

            const sectionItems: any = [];

            if (data.children) {
                data.children.forEach((res, i) => {
                    children[`${res.name}Id`] = res.id;

                    if (res.name === "UNITS-FLOOR-PLANS") {
                        children[`unitsFloorPlansId`] = res.id;
                        res.children?.forEach((unitPlan, unitPlanIndex) => {
                            const sectionUpdatedFields: any = [];
                            const sectionUpdatedChildren: any = [];

                            const item: any = {
                                id: "",
                                unitPlanFloorPlan: "",
                                details: "",
                                totalArea: "",
                                area: [],
                                imageGallery: [],
                                imagesCaptions: [],
                            };

                            if (unitPlan.name === "UNIT-PLAN") {
                                item.id = unitPlan.id;
                                if (unitPlan.fields) {
                                    unitPlan.fields.forEach((f) => {
                                        item[_.camelCase(f.name)] = f.value;
                                        sectionUpdatedFields.push(f);
                                    });
                                }
                                if (unitPlan.children) {
                                    unitPlan.children.forEach((unitPlanChildren, i) => {
                                        const areaFields: any = [];
                                        if (unitPlanChildren.name === "AREA") {
                                            const areaNameItems = {
                                                id: "",
                                                areaName: "",
                                                inSqm: "",
                                                inSqft: "",
                                            };
                                            areaNameItems.id = unitPlanChildren.id;
                                            unitPlanChildren.fields.forEach((areaItems) => {
                                                areaNameItems[_.camelCase(areaItems.name)] =
                                                    areaItems.value;
                                                areaFields.push(areaItems);
                                            });
                                            item.area.push(areaNameItems);
                                        } else {
                                            const { imageFields } =
                                                processImageFields(unitPlanChildren);
                                            item.imageGallery = imageFields;
                                            item.imagesCaptions = imageFields.map(
                                                ({ caption }) => caption
                                            );
                                        }

                                        sectionUpdatedChildren.push({
                                            id: unitPlanChildren.id,
                                            name: unitPlanChildren.name,
                                            fields: areaFields,
                                        });
                                    });
                                }
                            }
                            sectionItems.push(item);
                            sectionsFC.push({
                                id: unitPlan.id,
                                fields: sectionUpdatedFields,
                                children: sectionUpdatedChildren,
                                index: unitPlanIndex,
                            });
                        });
                    } else if (res.name === "IMAGE-GALLERY") {
                        const { imageFields, imageChildren } = processImageFields(res);
                        children["imageGalleryId"] = res.id;
                        fields[_.camelCase(res.name)] = imageFields;
                        fields["imagesCaptions"] = imageFields.map(({ caption }) => caption);
                        children[_.camelCase(res.name)] = imageChildren;
                    } else if (res.name === "VIDEOS" || res.name === "PANORAMAS") {
                        children[`${_.camelCase(res.name)}Id`] = res.id;
                        children[_.camelCase(res.name)] = res.fields.map((f) => ({
                            value: f.value ? f.value : "",
                            id: f.id,
                        }));
                        fields[_.camelCase(res.name)] = res.fields.map((f) => f.value);
                    } else {
                        children[res.name] = [];
                    }
                });
            }
            fields["unitsFloorPlans"] = sectionItems;
            fieldItem.push({ name: "unitsFloorPlans", fields: sectionsFC });
            data.fields.forEach((res) => {
                fieldItem.push(res);
                fields[_.camelCase(res.name)] = res.value;
            });

            setFieldInfo({
                fields: fieldItem,
                children: children,
            });
            setInitialValues(fields);
            setReinitialize(true);
        }
    }, [propertyUnitForms.payload]);

    const getFieldID = (fields, targetKey) => {
        if (fields) {
            const filter = fields.filter((item: any) => item.name === targetKey);
            if (filter.length > 0) {
                return filter[0].id;
            }
        }
        return v4();
    };

    const fieldIdChecker = (hit, name) => {
        return hit && hit.length > 0 ? getFieldID(hit[0], name) : null;
    };

    const handleSubmit = async (values) => {
        setLoading(true);
        try {
            const filter: any = fieldInfo.fields;

            const floorPlanFc = {
                id: propertyUnitForms.payload.floorPlans.id,
                name: "FLOOR-PLANS",
                order: 3,
                fields: [
                    {
                        id: getFieldID(filter, "UNIT-FLOOR-PLANS-DEFAULT"),
                        label: "Unit & Floor Plans (Default)",
                        name: "UNIT-FLOOR-PLANS-DEFAULT",
                        value: !_.isEmpty(values.unitFloorPlansDefault)
                            ? values.unitFloorPlansDefault
                            : null,
                        order: 0,
                    },
                    {
                        id: getFieldID(filter, "IMAGE-GALLERY-DEFAULT"),
                        label: "Image Gallery of Unit & Floor Plans (Default)",
                        name: "IMAGE-GALLERY-DEFAULT",
                        value: !_.isEmpty(values.imageGalleryDefault)
                            ? values.imageGalleryDefault
                            : null,
                        order: 1,
                    },
                    {
                        id: getFieldID(filter, "UNIT-SIZE-RANGE-LOWEST"),
                        label: "Unit Size Range (Lowest)",
                        name: "UNIT-SIZE-RANGE-LOWEST",
                        value: !_.isEmpty(values.unitSizeRangeLowest)
                            ? values.unitSizeRangeLowest
                            : null,
                        order: 2,
                    },
                    {
                        id: getFieldID(filter, "UNIT-SIZE-RANGE-HIGHEST"),
                        label: "Unit Size Range (Highest)",
                        name: "UNIT-SIZE-RANGE-HIGHEST",
                        value: !_.isEmpty(values.unitSizeRangeHighest)
                            ? values.unitSizeRangeHighest
                            : null,
                        order: 3,
                    },
                    {
                        id: getFieldID(filter, "NO-OF-FLOORS"),
                        label: "No. of Floors",
                        name: "NO-OF-FLOORS",
                        value: !_.isEmpty(values.noOfFloors) ? values.noOfFloors : null,
                        order: 4,
                    },
                    {
                        id: getFieldID(filter, "NO-OF-RESIDENTIAL-UNITS"),
                        label: "No. of Residential Units",
                        name: "NO-OF-RESIDENTIAL-UNITS",
                        value: !_.isEmpty(values.noOfResidentialUnits)
                            ? values.noOfResidentialUnits
                            : null,
                        order: 5,
                    },
                    {
                        id: getFieldID(filter, "SEO-FLOOR-PLAN-TITLE"),
                        label: "Seo Title",
                        name: "SEO-FLOOR-PLAN-TITLE",
                        value: !_.isEmpty(values.seoFloorPlanTitle)
                            ? values.seoFloorPlanTitle
                            : null,
                        order: 6,
                    },
                    {
                        id: getFieldID(filter, "SEO-FLOOR-PLAN-DESCRIPTION"),
                        label: "Seo Description",
                        name: "SEO-FLOOR-PLAN-DESCRIPTION",
                        value: !_.isEmpty(values.seoFloorPlanDescription)
                            ? values.seoFloorPlanDescription
                            : null,
                        order: 7,
                    },
                    {
                        id: getFieldID(filter, "FLOOR-PLAN-HEADING-TAG"),
                        label: "H1 Tag",
                        name: "FLOOR-PLAN-HEADING-TAG",
                        value: !_.isEmpty(values.floorPlanHeadingTag)
                            ? values.floorPlanHeadingTag
                            : null,
                        order: 8,
                    },
                    brandName === "ALP" && {
                        ...(!_.isEmpty(getFieldID(filter, "BODY")) && {
                            id: getFieldID(filter, "BODY"),
                        }),
                        label: "Body",
                        name: "BODY",
                        value: !_.isEmpty(values.body) ? values.body : "",
                        order: 9,
                    },
                    brandName === "ALP" && {
                        id: getFieldID(filter, "SUB-HEADING"),
                        label: "H2 Tag",
                        name: "SUB-HEADING",
                        value: !_.isEmpty(values.subHeading) ? values.subHeading : "",
                        order: 10,
                    },
                ].filter((item) => item !== false),
                children: [
                    {
                        id: fieldInfo.children["imageGalleryId"],
                        order: 0,
                        name: "IMAGE-GALLERY",
                        fields: imageGalleryMapper(
                            values.imageGallery,
                            fieldInfo.children.imageGallery
                        ),
                    },
                    {
                        id: fieldInfo.children["unitsFloorPlansId"],
                        name: "UNITS-FLOOR-PLANS",
                        order: 1,
                        children: [...unitFloorPlansMapper(values, filter[0].fields)],
                    },
                    {
                        id: fieldInfo.children["videosId"],
                        order: 2,
                        name: "VIDEOS",
                        fields: linksMapper(values.videos, fieldInfo.children.videos, "video"),
                    },
                    {
                        id: fieldInfo.children["panoramasId"],
                        order: 3,
                        name: "PANORAMAS",
                        fields: linksMapper(
                            values.panoramas,
                            fieldInfo.children.panoramas,
                            "panorama"
                        ),
                    },
                ],
            };

            await modifyFCFields({
                variables: {
                    data: {
                        collections: [floorPlanFc],
                    },
                },
            });

            message.success("Update Success.");
        } catch (error) {
            message.error("Something went wrong!");
        } finally {
            handleRefresh();
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    const unitFloorPlansMapper = (values, unitFloorFields) => {
        const unitFloorPlans = values.unitsFloorPlans.map((item, index) => {
            const unitItem = unitFloorFields.filter(
                (unitFloorField) => unitFloorField.id === item.id
            );
            const unitFloorPlanId = fieldIdChecker(unitItem[0], "UNIT-PLAN-FLOOR-PLAN");
            const detailsId = fieldIdChecker(unitItem[0], "DETAILS");
            const totalAreaId = fieldIdChecker(unitItem[0], "TOTAL-AREA");
            const areaItems = unitItem.length > 0 ? unitItem[0].children : [];
            const imgGalleryId =
                unitItem.length > 0 && unitItem[0].children ? unitItem[0].children[0].id : [];
            return {
                ...(!_.isEmpty(item.id) && { id: item.id }),
                parentId: fieldInfo.children["unitsFloorPlansId"],
                name: "UNIT-PLAN",
                order: index,
                fields: [
                    {
                        ...(!_.isEmpty(unitFloorPlanId) && { id: unitFloorPlanId }),
                        label: "Unit Floor Plan",
                        name: "UNIT-PLAN-FLOOR-PLAN",
                        value: !_.isEmpty(item.unitPlanFloorPlan) ? item.unitPlanFloorPlan : null,
                        order: 0,
                    },
                    {
                        ...(!_.isEmpty(detailsId) && { id: detailsId }),
                        label: "Unit Floor Plan Details",
                        name: "DETAILS",
                        value: !_.isEmpty(item.details) ? item.details : "",
                        order: 1,
                    },
                    {
                        ...(!_.isEmpty(totalAreaId) && { id: totalAreaId }),
                        label: "Unit Floor Plan Total Area",
                        name: "TOTAL-AREA",
                        value: !_.isEmpty(item.totalArea) ? item.totalArea : null,
                        order: 2,
                    },
                ],
                children: [
                    {
                        ...(!_.isEmpty(imgGalleryId) && { id: imgGalleryId }),
                        order: 0,
                        name: "IMAGE-GALLERY",
                        fields: imageGalleryMapper(item.imageGallery, areaItems),
                    },
                    ...areaMapper(item.area, areaItems, item.id),
                ],
            };
        });
        return unitFloorPlans;
    };

    const areaMapper = (values, areaFields, parentId) => {
        const areaFieldItems = values.map((item, index) => {
            const areaItem = areaFields.filter((areaFields) => areaFields.id === item.id);
            const areaFieldId = fieldIdChecker(areaItem[0], "AREA-NAME");
            const inSqmId = fieldIdChecker(areaItem[0], "IN-SQM");
            const inSqftId = fieldIdChecker(areaItem[0], "IN-SQFT");

            return {
                ...(!_.isEmpty(item.id) && { id: item.id }),
                ...(!_.isEmpty(parentId) && { parentId: parentId }),
                name: "AREA",
                order: index + 1,
                fields: [
                    {
                        ...(!_.isEmpty(areaFieldId) && { id: areaFieldId }),
                        label: "Unit Floor Plan Area Name",
                        name: "AREA-NAME",
                        value: !_.isEmpty(item.areaName) ? item.areaName : null,
                        order: 0,
                    },
                    {
                        ...(!_.isEmpty(inSqmId) && { id: inSqmId }),
                        label: "Unit Floor Plan In sqm",
                        name: "IN-SQM",
                        value: !_.isEmpty(item.inSqm) ? item.inSqm : null,
                        order: 1,
                    },
                    {
                        ...(!_.isEmpty(inSqftId) && { id: inSqftId }),
                        label: "Unit Floor Plan In sqft",
                        name: "IN-SQFT",
                        value: !_.isEmpty(item.inSqft) ? item.inSqft : null,
                        order: 2,
                    },
                ],
            };
        });
        return areaFieldItems;
    };

    const imageGalleryMapper = (values, fields) => {
        const imageGalleryItems = values.map((item, index) => {
            return {
                ...(!_.isEmpty(item.id) && { id: item.id }),
                order: index,
                name: "Image",
                label: "Image",
                value: item.keyObj,
                meta_data: {
                    featured: item.isChecked,
                    description: item.caption,
                    key: item.keyObj,
                    alt_text: item.altText,
                },
            };
        });
        return imageGalleryItems;
    };

    const linksMapper = (values, fields, name) => {
        const linkItems = values?.map((item, index) => {
            const fieldId = fields.length - 1 > index ? fields[index].id : null;
            return {
                ...(!_.isEmpty(fieldId) && { id: fieldId }),
                order: index,
                name: _.upperCase(name),
                label: name,
                value: item,
            };
        });
        return linkItems;
    };

    return (
        <ContentContainer
            style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1rem",
                position: "relative",
            }}
        >
            <Spin spinning={loading}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={reinitialize}
                    onSubmit={(values: any) => {
                        handleSubmit(values);
                    }}
                >
                    {(formikBag) => (
                        <Forms
                            {...{
                                formikBag,
                                brandName,
                            }}
                        />
                    )}
                </Formik>
            </Spin>
        </ContentContainer>
    );
};

export default UnitFloorPlansForms;
