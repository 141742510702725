import React, { useState, useContext, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import _ from "lodash";
import { Spin, message } from "antd";

import { Context } from "../../../../../context";
import { CREATE_PAGE, CREATE_CONTENT, GET_PAGE, REMOVE_MULTI_CONTAINER } from "../../../graphql";

import { validationSchema } from "./constant";
import Form from "./forms";
import generateId from "../../../../../util/generate-id";
import fileTranslate from "../../../../../util/file-translate";

interface Props {
    handleRefresh: () => void;
}

const MissionVisionForms: React.FC<Props> = ({ handleRefresh }) => {
    const { id } = useParams();
    const {
        state: { pageDetails: brandDetails },
    } = useContext(Context);
    const [getPage, { data: pageData }] = useLazyQuery(GET_PAGE, {
        fetchPolicy: "no-cache",
    });
    const [loader, setLoader] = useState(false);
    const [createPage] = useMutation(CREATE_PAGE);
    const [createContent] = useMutation(CREATE_CONTENT);
    const [removeMultiContainer] = useMutation(REMOVE_MULTI_CONTAINER);
    const [reinitialize, setReinitialize] = useState(false);

    const [initialValues, setInitialValue] = useState<object>({
        vision: {
            title: "",
            body: [
                {
                    content: "",
                },
            ],
            images: [],
        },
        mission: {
            title: "",
            body: [
                {
                    content: "",
                },
            ],
            images: [],
        },
    });

    useEffect(() => {
        const data = brandDetails.payload;
        if (data.brandId) {
            getPage({
                variables: {
                    brand_id: data.brandId,
                    tag: `COMPANY-INFO-${_.upperCase(data.brandName)}`,
                    slug: `mission-vision-${_.lowerCase(data.brandName)}`,
                },
            });
        }
    }, [brandDetails.payload]);

    useEffect(() => {
        if (pageData && _.has(pageData, "getSpecificBrandPage")) {
            const data = pageData.getSpecificBrandPage.containers;
            const currentValues = {
                vision: {},
                mission: {},
            };

            data.forEach((container, i) => {
                const containerPrefix = container.name.split("-")[0];
                container.field_collections.forEach((fieldItems) => {
                    if (fieldItems.name === "BODY") {
                        if (!_.isEmpty(fieldItems.fields)) {
                            const bodyArray = fieldItems.fields.map((item) => ({
                                content: item.value,
                            }));
                            currentValues[_.lowerCase(containerPrefix)].body = bodyArray;
                        } else {
                            currentValues[_.lowerCase(containerPrefix)].body = [];
                        }
                    } else if (fieldItems.name === `${containerPrefix}-DEFAULT`) {
                        currentValues[_.lowerCase(containerPrefix)].title =
                            fieldItems.fields[0].value;
                    } else if (fieldItems.name === "IMAGES") {
                        if (fieldItems.fields) {
                            currentValues[
                                _.lowerCase(containerPrefix)
                            ].images = fieldItems.fields.map((res) => ({
                                uid: generateId(),
                                url: res.value,
                                keyObj: res.meta_data.key,
                                status: "done",
                                id: res.id,
                                type: res.meta_data.file_type,
                            }));
                        } else {
                            currentValues[_.lowerCase(containerPrefix)].images = [];
                        }
                    }
                });
            });
            setInitialValue(currentValues);
            setReinitialize(true);
        }
    }, [pageData]);

    const containerMaker = (page_id, name, values, order) => {
        return {
            order: order,
            page_id: page_id,
            name: `${name}-CONTAINER`,
            field_collections: [
                {
                    order: 0,
                    name: `${name}-DEFAULT`,
                    fields: [
                        {
                            name: `${name}-TITLE`,
                            label: `${_.capitalize(name)} (default)`,
                            value: !_.isEmpty(values.title) ? values.title : null,
                            order: 0,
                        },
                    ],
                },
                {
                    order: 1,
                    name: "BODY",
                    fields: !_.isEmpty(values.body) ? bodyMapper(values.body) : [],
                },
                {
                    order: 2,
                    name: "IMAGES",
                    fields: !_.isEmpty(values.images) ? imageMapper(values.images) : [],
                },
            ],
        };
    };

    const containerMapper = (values, page_id) => {
        const containers = [
            containerMaker(page_id, "VISION", values.vision, 0),
            containerMaker(page_id, "MISSION", values.mission, 1),
        ];
        return containers;
    };

    const bodyMapper = (value) =>
        value.map((fields, fieldIndex) => ({
            order: fieldIndex,
            name: "BODY",
            label: "body",
            value: !_.isEmpty(fields.content) ? fields.content : null,
        }));

    const imageMapper = (value) =>
        value.map((fields, fieldIndex) => ({
            order: fieldIndex,
            label: fileTranslate(fields.type),
            name: "File",
            value: fields.keyObj,
            meta_data: {
                featured: false,
                description: "",
                key: fields.keyObj,
                file_type: fields.type,
            },
        }));

    const handleAddUpdatePage = async (values) => {
        setLoader(true);
        const brandInfo = brandDetails.payload;
        const pageTag = `COMPANY-INFO-${_.upperCase(brandInfo.brandName)}`;
        let pageId = null;
        let update = false;
        if (pageData && _.has(pageData, "getSpecificBrandPage")) {
            pageId = pageData.getSpecificBrandPage.id;
            update = true;
        } else {
            const page = await createPage({
                variables: {
                    brand_id: id,
                    name: `Mission Vision - ${brandInfo.brandName}`,
                    tag: pageTag,
                },
            });
            const {
                addOrUpdateBrandPage: { id: page_id },
            } = page.data;
            pageId = page_id;
        }
        if (pageId) {
            const containers = containerMapper(values, pageId);

            await createContent({
                variables: {
                    data: {
                        containers,
                    },
                },
            });

            if (update) {
                await removeMultiContainer({
                    variables: {
                        data: {
                            ids: pageData.getSpecificBrandPage.containers.map((item) => item.id),
                        },
                    },
                });
            }
            message.success(update ? "Update success." : "Page successfully created.");
            handleRefresh();
        }
        setLoader(false);
    };

    return (
        <div>
            <Spin spinning={loader}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={reinitialize}
                    onSubmit={(values: any) => {
                        handleAddUpdatePage(values);
                    }}
                    render={(formikBag) => (
                        <Form
                            {...{
                                formikBag,
                            }}
                        />
                    )}
                />
            </Spin>
        </div>
    );
};

export default MissionVisionForms;
