import styled from "styled-components";

export const InputWrapper = styled.div`
    position: relative;

    .remove-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }

    textarea.ant-input::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    textarea.ant-input::-webkit-scrollbar:vertical {
        width: 8px;
    }

    textarea.ant-input::-webkit-scrollbar:horizontal {
        height: 11px;
    }

    textarea.ant-input::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white;
        background-color: rgba(0, 0, 0, 0.5);
    }
`;
