import { FormValueTypes } from "./manage-hero-banner.types";

export const categories = {
    properties: [
        "No Category",
        "Condominium",
        "Office",
        "Lots",
        "Commercial",
        "Townhouse",
        "Leisure",
        "Apartments",
        "House and Lot",
        "Estate",
    ],
    content: [
        "No Category",
        "news",
        "events",
        "announcements",
        "destinations",
        "neighborhood",
        "sustainability",
        "home buying guide",
        "blogs",
        "vlogs",
    ],
};

export const INIT_VALUES: FormValueTypes = {
    linkedPage: null,
    linkType: "",
    linkCategory: "",
    seoBannerTitle: "",
    seoBannerDescription: "",
    bannerHeadingTag: "",
    imgURL: null,
    videoURL: "",
    bannerTitle: "",
    excerpt: "",
    excerptMobile: "",
};
