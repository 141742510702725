import * as yup from "yup";
import validateUrl from "../../../../../../util/validate-url";

const SUPPORTED_FORMATS = [".jpg", ".jpeg", ".png"];

const imageSchema = yup
    .array()
    .of(
        yup.object().shape({
            status: yup.string().matches(/done/, { message: "file is still uploading" }),
        })
    )
    .nullable();

export const validationSchema = yup.object().shape({
    unitSizeRangeLowest: yup
        .number()
        .nullable()
        .positive("Unit Size Range (Lowest) must be a positive number.")
        .typeError("Unit Size Range (Lowest) must be a number.")
        .when("unitSizeRangeHighest", (unitSizeRangeHighest, schema) => {
            if (unitSizeRangeHighest && unitSizeRangeHighest !== "") {
                return schema
                    .nullable()
                    .max(
                        unitSizeRangeHighest,
                        `Unit Size Range (Lowest) must be less than or equal to ${unitSizeRangeHighest}.`
                    );
            }
            return schema;
        }),
    unitSizeRangeHighest: yup
        .number()
        .nullable()
        .positive("Unit Size Range (Highest) must be a positive number.")
        .typeError("Unit Size Range (Highest) must be a number."),
    noOfFloors: yup
        .number()
        .nullable()
        .positive("No. of Floors must be a positive number.")
        .typeError("No. of Floors must be a number."),
    noOfResidentialUnits: yup
        .number()
        .nullable()
        .positive("No. of Residential Units must be a positive number.")
        .typeError("No. of Residential Units must be a number."),
    unitsFloorPlans: yup.array().of(
        yup.object().shape({
            totalArea: yup
                .number()
                .nullable()
                .positive("Total Area must be a positive number.")
                .typeError("Total Area must be a number."),
            area: yup.array().of(
                yup.object().shape({
                    id: yup.string(),
                    inSqm: yup
                        .number()
                        .nullable()
                        .positive("In sqm must be a positive number.")
                        .typeError("In sqm must be a number."),
                    inSqft: yup
                        .number()
                        .nullable()
                        .positive("In sqft must be a positive number.")
                        .typeError("In sqft must be a number."),
                })
            ),
            imageGallery: imageSchema,
        })
    ),
    imageGallery: imageSchema,
});
