import React from "react";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";
import lowerCase from "lodash/lowerCase";

import { Tabs, Spin, Row, Col, Select, Input, Tag, Menu, Pagination } from "antd";
import { SearchOutlined, PlayCircleFilled, EllipsisOutlined } from "@ant-design/icons";

import { Container, Content, AddButton, StyledCard as Card } from "./styled";
import ImageIcon from "../../assets/image-icon.png";

interface Option {
    label: string;
    value: string;
}

interface Filter {
    name: string;
    options?: string[];
    customOptions?: Option[];
    handle: (selection) => void;
    value?: string;
    disabled?: boolean;
}

interface CardText {
    htmlEl: string;
    className?: string;
    varName: string;
}

interface Brands {
    id: string;
    name: string;
}

interface Props {
    createAccess?: boolean;
    updateAccess?: boolean;
    deleteAccess?: boolean;
    brandSelections?: any;
    dashboard?: string;
    data?: any;
    tabList?: any;
    filters?: Filter[];
    cardText?: CardText[];
    handleMenu?: (
        menuItem: string,
        slug: string,
        id: string,
        featured: boolean | undefined,
        type?: string
    ) => void;
    handleAddBtn?: () => void;
    handleSearch?: (query) => void;
    handleTabs?: (activeKey) => void;
    handleBrandSelect?: (name) => void;
    loading?: boolean;
    totalPages?: number;
    pageSize?: number;
    page?: number;
    handlePagination?: (page, pageSize) => void;
    handlePageSize?: (pageSize) => void;
}

const CustomDashboard = ({
    createAccess,
    updateAccess,
    deleteAccess,
    brandSelections,
    handleBrandSelect,
    dashboard,
    data,
    tabList,
    filters,
    cardText,
    handleMenu,
    handleAddBtn,
    handleSearch,
    handleTabs,
    loading,
    totalPages,
    pageSize,
    page,
    handlePagination,
    handlePageSize,
}: Props): JSX.Element => {
    const { TabPane } = Tabs;
    const { Search } = Input;
    const { Option } = Select;
    const { SubMenu } = Menu;

    if (brandSelections) {
        const alisiSelected = brandSelections.selectedBrandName === "ALISI";
        createAccess = alisiSelected;
        updateAccess = alisiSelected;
        deleteAccess = alisiSelected;
    }

    const onSelect = (item: any, slug: string, id: string, featured?: boolean) => {
        handleMenu && handleMenu(item.key, slug, id, featured);
    };

    const onAddBtnClick = () => {
        handleAddBtn && handleAddBtn();
    };

    const onSearch = (query) => {
        handleSearch && handleSearch(query.trim());
    };

    const onTabChange = (activeKey) => {
        handleTabs && handleTabs(activeKey);
    };

    const onPageSizeChange = (pageSize) => {
        handlePageSize && handlePageSize(pageSize);
    };

    const onPaginate = (page, pageSize) => {
        handlePagination && handlePagination(page, pageSize);
    };

    const getOptions = (list: string[] | undefined) => {
        let options: any = list;

        if (options) {
            return (options = options
                .map((option) => ({
                    label: option,
                    value: option,
                }))
                .filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i));
        }
    };

    const renderOptions = (
        listName: string,
        list: Option[],
        handle: (selection) => void,
        value?: string,
        disabled?: boolean
    ) => {
        let options: any = list;

        if (options) {
            options = options.map(
                ({ label, value }) => value && <Option value={value}>{label}</Option>
            );

            return (
                <Select
                    defaultValue="All"
                    value={value && value}
                    onChange={handle}
                    disabled={disabled}
                >
                    <Option value="All">{`${startCase(listName)}: All`}</Option>
                    {options}
                </Select>
            );
        }
    };

    const renderCards = (data, label) => {
        let cards: any = data;

        if (!isEmpty(cards)) {
            cards = cards.map((data, idx) => {
                const regExp = /\.(mpeg|mp4|3gpp)$/i;
                const vidTypePattern = new RegExp(regExp);

                const featured = !brandSelections ? data.featured : data.alternative_featured;
                return (
                    <Col span={5} className="card-col" key={`${data.name} ${idx}`}>
                        <Card
                            loading={loading}
                            size="small"
                            cover={
                                <div className="img-container">
                                    {data.category && (
                                        <Tag className="category" color="#00714b">
                                            <span data-index="category">{data.category}</span>
                                        </Tag>
                                    )}
                                    {data.videoURL && <PlayCircleFilled />}
                                    {!loading && data.imgURL && (
                                        <>
                                            {vidTypePattern.test(data.imgURL) ? (
                                                <video
                                                    className="video-thumbnail"
                                                    src={data.imgURL}
                                                    controls
                                                />
                                            ) : (
                                                <img
                                                    alt={data.name}
                                                    src={data.imgURL}
                                                    onError={(e: any) => {
                                                        e.target.src = ImageIcon;
                                                        e.target.parentNode.innerHTML += `<p>We're sorry, The file is broken.</p>`;
                                                    }}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            }
                        >
                            {featured && featured === true && (
                                <Tag className="featured" color="#00714b">
                                    <span data-index="featured">Featured</span>
                                </Tag>
                            )}

                            <div className="description">
                                <Menu
                                    className="kebab-menu"
                                    onClick={(item) => onSelect(item, data.slug, data.id, featured)}
                                >
                                    <SubMenu
                                        icon={
                                            <EllipsisOutlined className="kebab-menu" rotate={90} />
                                        }
                                    >
                                        <Menu.Item
                                            style={{ display: !updateAccess ? "none" : "block" }}
                                            key="edit"
                                        >
                                            Edit
                                        </Menu.Item>
                                        {featured !== undefined && (
                                            <Menu.Item
                                                style={{
                                                    display: !updateAccess
                                                        ? !brandSelections
                                                            ? "none"
                                                            : "block"
                                                        : "block",
                                                }}
                                                key="featured"
                                            >
                                                {featured ? "Remove from" : "Add to"} Featured
                                            </Menu.Item>
                                        )}
                                        <Menu.Item
                                            style={{ display: !deleteAccess ? "none" : "block" }}
                                            key="delete"
                                        >
                                            Delete
                                        </Menu.Item>
                                    </SubMenu>
                                </Menu>

                                {cardText && (
                                    <div className="text">
                                        {cardText.map(({ htmlEl, className, varName }) => {
                                            if (htmlEl === "h2") {
                                                return (
                                                    <h2 className={className} key={data[varName]}>
                                                        {data[varName]
                                                            ? data[varName]
                                                            : `No ${varName} listed`}
                                                    </h2>
                                                );
                                            }

                                            if (htmlEl === "span") {
                                                return (
                                                    <span className={className}>
                                                        {data[varName]
                                                            ? data[varName]
                                                            : `No ${varName} listed`}
                                                    </span>
                                                );
                                            }
                                        })}
                                    </div>
                                )}
                            </div>
                        </Card>
                    </Col>
                );
            });
        } else {
            cards = (
                <Col span={5} className="card-col">
                    <div className="no-items">
                        <div className="content">
                            <SearchOutlined />
                            <span className="text">No results found</span>
                        </div>
                    </div>
                </Col>
            );
        }

        return (
            <Spin spinning={loading}>
                <Row gutter={[16, 16]}>
                    {!createAccess ? (
                        ""
                    ) : (
                        <Col span={5} className="card-col">
                            <AddButton type="dashed" block={true} onClick={onAddBtnClick}>
                                + Add {label}
                            </AddButton>
                        </Col>
                    )}
                    {cards}
                </Row>
                {totalPages && (
                    <Pagination
                        total={totalPages}
                        showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                        current={page}
                        pageSize={pageSize}
                        showSizeChanger={pageSize ? true : false}
                        onChange={onPaginate}
                        onShowSizeChange={onPageSizeChange}
                    />
                )}
            </Spin>
        );
    };

    const renderDashboard = (tabs?) => {
        if (!isEmpty(tabs)) {
            tabs = tabs.map(({ label, key }) => (
                <TabPane tab={label} key={key}>
                    <Content>
                        <Row gutter={[16, 16]}>
                            {handleSearch && (
                                <Col span={8}>
                                    <Search
                                        placeholder={`Search ${lowerCase(label)}`}
                                        onSearch={onSearch}
                                    />
                                </Col>
                            )}

                            {filters &&
                                filters.map((filter: Filter, index) => (
                                    <Col
                                        key={filter.name}
                                        span={4}
                                        offset={
                                            index === 0
                                                ? (handleSearch
                                                      ? !brandSelections
                                                          ? 16
                                                          : 12
                                                      : 24) -
                                                  4 * filters.length
                                                : 0
                                        }
                                    >
                                        {renderOptions(
                                            filter.name,
                                            filter.customOptions
                                                ? filter.customOptions
                                                : getOptions(filter.options),
                                            filter.handle,
                                            filter.value && filter.value,
                                            filter.disabled && filter.disabled
                                        )}
                                    </Col>
                                ))}
                            {brandSelections && (
                                <Col span={4}>
                                    <Select
                                        value={brandSelections.selectedBrandName}
                                        defaultValue="ALISI"
                                        onChange={handleBrandSelect}
                                    >
                                        {brandSelections.brands.map((res) => (
                                            <Option key={res.name} value={res.name}>
                                                {res.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            )}
                        </Row>
                        {renderCards(data, label)}
                    </Content>
                </TabPane>
            ));

            return (
                <Tabs
                    defaultActiveKey="0"
                    tabBarGutter={0}
                    tabBarStyle={{ backgroundColor: "white", padding: "15px 25px 0" }}
                    onChange={onTabChange}
                >
                    {tabs}
                </Tabs>
            );
        }

        return (
            <Content>
                <Row gutter={[16, 16]}>
                    {handleSearch && (
                        <Col span={8}>
                            <Search
                                placeholder={`Search ${dashboard ? lowerCase(dashboard) : ""}`}
                                onSearch={onSearch}
                            />
                        </Col>
                    )}
                    {filters &&
                        filters.map((filter: Filter, index) => (
                            <Col
                                key={filter.name}
                                span={4}
                                offset={
                                    index === 0 ? (handleSearch ? 16 : 24) - 4 * filters.length : 0
                                }
                            >
                                {renderOptions(
                                    filter.name,
                                    filter.customOptions
                                        ? filter.customOptions
                                        : getOptions(filter.options),
                                    filter.handle,
                                    filter.value && filter.value,
                                    filter.disabled && filter.disabled
                                )}
                            </Col>
                        ))}
                </Row>
                {dashboard ? renderCards(data, dashboard) : renderCards(data, "")}
            </Content>
        );
    };
    return <Container>{renderDashboard(tabList && tabList)}</Container>;
};

export default CustomDashboard;
