import styled from "styled-components";
import { Tabs } from "antd";

export const StyledTabs = styled(Tabs)`
    margin-bottom: 25px;

    .ant-tabs-nav-list > .ant-tabs-tab {
        padding: 12px 30px;
    }

    .ant-tabs-content {
        flex-direction: column;
    }
`;
