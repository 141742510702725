import React from "react";

import { FormItem, Input } from "formik-antd";
import { Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { WrapperForm, MediaContainer, MediaContainerLeft } from "./styled";
import Richtext from "../../../../../components/Richtext";
import { ComponentFormType } from "../content-forms.types";

const componentThirteen = ({
    index,
    setFieldValue,
    fieldsArrayEvent,
    values,
    uploadComplete,
    setUploadStatus,
    brandName,
}: ComponentFormType): JSX.Element => {
    return (
        <WrapperForm>
            <Row gutter={24}>
                <Col span={12}>
                    <MediaContainerLeft
                        value={values.components[index].fields.image1}
                        accept={"image/jpeg,image/jpg,image/png"}
                        noteMessage={".jpeg, .jpg, .png (max 50 MB)"}
                        onChange={(e) => {
                            setUploadStatus(e?.status);
                            setFieldValue(`components[${index}].fields.image1`, e);
                        }}
                    >
                        <h1>Upload Banner +</h1>
                    </MediaContainerLeft>
                    {brandName === "ALP" && (
                        <FormItem
                            label="Image alt text"
                            name={`components[${index}].fields.image1.altText`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name={`components[${index}].fields.image1.altText`} />
                        </FormItem>
                    )}
                </Col>
                <Col span={12}>
                    <FormItem
                        label={"Title"}
                        name={`components[${index}].fields.title`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input name={`components[${index}].fields.title1`} />
                    </FormItem>
                    <FormItem
                        label={"Description"}
                        name={`components[${index}].fields.description`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input.TextArea
                            name={`components[${index}].fields.description1`}
                            rows={7}
                        />
                    </FormItem>
                    <MediaContainer
                        value={values.components[index].fields.image2}
                        accept={"image/jpeg,image/jpg,image/png"}
                        noteMessage={".jpeg, .jpg, .png (max 50 MB)"}
                        onChange={(e) => {
                            setFieldValue(`components[${index}].fields.image2`, e);
                        }}
                    >
                        <h1>Upload Banner +</h1>
                    </MediaContainer>
                    {brandName === "ALP" && (
                        <FormItem
                            label="Image alt text"
                            name={`components[${index}].fields.image2.altText`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name={`components[${index}].fields.image2.altText`} />
                        </FormItem>
                    )}
                    <FormItem
                        label={"Title"}
                        name={`components[${index}].fields.title`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input name={`components[${index}].fields.title2`} />
                    </FormItem>
                    <FormItem
                        label={"Description"}
                        name={`components[${index}].fields.description`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Richtext
                            toolbar={["heading", "bold", "italic", "link", "insertTable"]}
                            data={values.components[index].fields.description2}
                            onChange={(value: string) =>
                                setFieldValue(
                                    `components[${index}].fields.description2`,
                                    value,
                                    false
                                )
                            }
                        />
                    </FormItem>
                </Col>
            </Row>
            <CloseOutlined
                className="remove-btn"
                onClick={() => {
                    uploadComplete !== "uploading" && fieldsArrayEvent.remove(index);
                }}
            />
        </WrapperForm>
    );
};

export default componentThirteen;
