import * as yup from "yup";
import { v4 } from "uuid";
import _ from "lodash";

import { FormValueType, ComponentType } from "./manage-content.types";

import componentOne from "../../assets/manage-content/components/component-1.svg";
import componentTwo from "../../assets/manage-content/components/component-2.svg";
import componentThree from "../../assets/manage-content/components/component-3.svg";
import componentFour from "../../assets/manage-content/components/component-4.svg";
import componentFive from "../../assets/manage-content/components/component-5.svg";
import componentSix from "../../assets/manage-content/components/component-6.svg";
import componentSeven from "../../assets/manage-content/components/component-7.svg";
import componentEight from "../../assets/manage-content/components/component-8.svg";
import componentNine from "../../assets/manage-content/components/component-9.svg";
import componentTen from "../../assets/manage-content/components/component-10.svg";
import componentEleven from "../../assets/manage-content/components/component-11.svg";
import componentTwelve from "../../assets/manage-content/components/component-12.svg";
import componentThirteen from "../../assets/manage-content/components/component-13-1.svg";
import componentFourteen from "../../assets/manage-content/components/component-14.svg";

const imageSchema = yup
    .object()
    .shape({
        status: yup.string().matches(/done/, { message: "file is still uploading" }),
    })
    .nullable();

const fieldsValidation = yup.object().when("type", (value) => {
    switch (value) {
        case "component 1":
        case "component 3":
        case "component 5":
            return yup.object().shape({
                image: imageSchema,
                title: yup.string(),
                description: yup.string(),
            });
        case "component 2":
        case "component 4":
        case "component 6":
            return yup.object().shape({
                video: yup.string(),
                title: yup.string(),
                description: yup.string(),
            });

        case "component 7":
            return yup.object().shape({
                image1: imageSchema,
                image2: imageSchema,
                image3: imageSchema,
            });

        case "component 8":
            return yup.object().shape({
                image1: imageSchema,
                image2: imageSchema,
                image3: imageSchema,
                image4: imageSchema,
            });

        case "component 9":
            return yup.object().shape({
                title: yup.string(),
                description: yup.string(),
            });

        case "component 10":
            return yup.object().shape({
                message: yup.string(),
                caption: yup.string(),
            });

        case "component 11":
        case "component 12":
            return yup.object().shape({
                title: yup.string(),
                description: yup.string(),
                videos: yup.array().of(imageSchema),
            });

        default:
            return yup.object().nullable();
    }
});

const componentType = yup.object().shape({
    type: yup.string(),
    fields: fieldsValidation,
});

export const validationSchema = yup.object().shape({
    title: yup.string().required("Title is required."),
    components: yup.array().of(componentType),
    image: imageSchema,
});

const imageTitleDesction = {
    image: null,
    title: "",
    description: "",
};

const videoTitleDesction = {
    video: "",
    title: "",
    description: "",
};

export const componentList = [
    {
        image: componentOne,
        type: "component 1",
        fields: imageTitleDesction,
    },
    {
        image: componentTwo,
        type: "component 2",
        fields: videoTitleDesction,
    },
    {
        image: componentThree,
        type: "component 3",
        fields: imageTitleDesction,
    },
    {
        image: componentFour,
        type: "component 4",
        fields: videoTitleDesction,
    },
    {
        image: componentFive,
        type: "component 5",
        fields: imageTitleDesction,
    },
    {
        image: componentSix,
        type: "component 6",
        fields: videoTitleDesction,
    },
    {
        image: componentSeven,
        type: "component 7",
        fields: {
            image1: null,
            caption1: "",
            image2: null,
            caption2: "",
            image3: null,
            caption3: "",
        },
    },
    {
        image: componentEight,
        type: "component 8",
        fields: {
            image1: null,
            caption1: "",
            image2: null,
            caption2: "",
            image3: null,
            caption3: "",
            image4: null,
            caption4: "",
        },
    },
    {
        image: componentNine,
        type: "component 9",
        fields: {
            title: "",
            description: "",
        },
    },
    {
        image: componentTen,
        type: "component 10",
        fields: {
            message: "",
            caption: "",
        },
    },
    {
        image: componentEleven,
        type: "component 11",
        fields: {
            title: "",
            description: "",
            videos: [],
        },
    },
    {
        image: componentTwelve,
        type: "component 12",
        fields: {
            title: "",
            description: "",
            videos: [],
        },
    },
    {
        image: componentThirteen,
        type: "component 13",
        fields: {
            image1: null,
            image2: null,
            title1: "",
            description1: "",
            title2: "",
            description2: "",
        },
    },
    {
        image: componentFourteen,
        type: "component 14",
        fields: {
            image: null,
            title: "",
            title1: "",
            description: "",
        },
    },
];

export const cardText = [
    {
        htmlEl: "h2",
        varName: "name",
    },
    {
        htmlEl: "span",
        varName: "excerpt",
    },
    {
        htmlEl: "span",
        className: "small",
        varName: "date",
    },
];

export const INIT_VALUES = (contentType, DefaultContentType): FormValueType => {
    return {
        seoTitle: "",
        seoDescription: "",
        HeadingTag: "",
        subHeading: "",
        contentDefault: "",
        videoLink: "",
        category: contentType ? DefaultContentType() : "",
        subcategory: "",
        location: "",
        longitude: "",
        latitude: "",
        datePublished: "",
        blogsInNeighborhood: [],
        blogsInProperty: [],
        neighborhoodInProperty: [],
        title: "",
        subtitle: "",
        shortDescription: "",
        components: [],
        image: null,
        tags: [],
    };
};

export const INIT_FIELD_INFO_VALUES = {
    fields: [],
    containerId: "",
    overviewFcId: "",
    componentFcId: "",
    tagFcId: "",
};

const getFieldID = (fields, targetKey): string => {
    if (fields) {
        const filter = fields.filter((item: ComponentType) => item.name === targetKey);

        if (filter.length > 0) {
            return filter[0].id;
        }
    }
    return v4();
};

export const imageMapper = (image, filter) => {
    const fieldId = getFieldID(filter, "Image");

    if (!image) {
        return null;
    }

    return {
        ...(!_.isEmpty(fieldId) && { id: fieldId }),
        order: 1,
        label: "Logo",
        name: "Image",
        value: image.keyObj,
        meta_data: {
            featured: false,
            description: "",
            key: image.keyObj,
            alt_text: image.altText,
        },
    };
};

export const tagMapper = (values, fields): ComponentType[] => {
    const filter = fields.filter((item: ComponentType) => item.name === "TAG");

    return values.tags
        .map((item, i) => {
            const tagId = i <= filter.length - 1 ? filter[i].id : null;
            return {
                ...(!_.isEmpty(tagId) && { id: tagId }),
                order: i,
                name: "TAG",
                label: "tag",
                value: item,
            };
        })
        .filter((item) => item.value.trim() !== "");
};

export const fieldMapper = (label, name, newValue, order, filter) => {
    const fieldId = getFieldID(filter, name);
    return {
        ...(!_.isEmpty(fieldId) && { id: fieldId }),
        label: label,
        name: name,
        value: !_.isEmpty(newValue) ? newValue : null,
        order: order,
    };
};
