import React from "react";

import { FormItem, Input } from "formik-antd";
import { CloseOutlined } from "@ant-design/icons";

import { WrapperForm } from "./styled";
import { ComponentFormType } from "../content-forms.types";

const ComponentTen = ({ index, fieldsArrayEvent }: ComponentFormType): JSX.Element => {
    return (
        <WrapperForm>
            <FormItem
                label={"Message"}
                name={`components[${index}].fields.message`}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Input.TextArea name={`components[${index}].fields.message`} rows={7} />
            </FormItem>
            <FormItem
                label={"Caption"}
                name={`components[${index}].fields.caption`}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Input name={`components[${index}].fields.caption`} />
            </FormItem>
            <CloseOutlined
                className="remove-btn"
                onClick={() => {
                    fieldsArrayEvent.remove(index);
                }}
            />
        </WrapperForm>
    );
};

export default ComponentTen;
