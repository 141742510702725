import React, { useEffect, useState, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import toLower from "lodash/toLower";

import { GET_BANNERS_LIST, REMOVE_HOME_BANNERS } from "../../graphql";
import { Context } from "../../../../context";

import { HeroBanner } from "../../../../entities/HeroBanner";
import { HeroBannerMapper } from "../../dto/HeroBannerMapper";
import CustomDashboard from "../../../../components/CustomDashboard";

import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { userPermission } from "../../../../util/user-access";

import { linkTypes, categories, cardText } from "./constant";

const HeroBannerList = (): JSX.Element => {
    const history = useHistory();
    const match = useRouteMatch();

    const {
        state: { brand: brandData, user },
    } = useContext(Context);
    const brandId = brandData?.id;
    const permissions = user?.roles[0].permissions;
    const createAccess = userPermission(permissions, "create:banner");
    const updateAccess = userPermission(permissions, "update:banner");
    const deleteAccess = userPermission(permissions, "delete:banner");
    const [heroBannerList, setHeroBannerList] = useState<any>(null);
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [search, setSearch] = useState<string>("");
    const [linkType, setLinkType] = useState<string>("");
    const [disableCategory, setDisableCategory] = useState<boolean>(true);
    const [category, setCategory] = useState<string>("");
    const [filters, setFilters] = useState<any>(undefined);

    const [loader, setLoader] = useState<boolean>(true);

    const [removeBanners] = useMutation(REMOVE_HOME_BANNERS);

    const { error, refetch, loading, data } = useQuery(GET_BANNERS_LIST, {
        skip: !brandId,
        variables: {
            brand_id: brandId,
            page: page,
            limit: pageSize,
            search: search,
            link_type: linkType,
            category: category,
        },
        fetchPolicy: "no-cache",
        partialRefetch: true,
    });

    const handleSearch = (query) => {
        query !== search && setSearch(query);
    };

    const handleLinkType = (selection) => {
        if (selection === "All") {
            setLinkType("");
            setDisableCategory(true);
        } else {
            setLinkType(selection);
            setDisableCategory(false);
        }
        setCategory("");
    };

    const handleCategory = (selection) => {
        selection === "All" ? setCategory("") : setCategory(selection);
    };

    const handleAddBtn = () => {
        history.push(`${match.path}/add`);
    };

    const handleDelete = (id: string) => {
        const { confirm } = Modal;

        confirm({
            title: "Delete Banner?",
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to delete this banner?",
            async onOk() {
                try {
                    const ids = [id];
                    await removeBanners({
                        variables: {
                            id: ids,
                        },
                    });
                    setLoader(true);
                    refetch();
                    message.success("Successfully deleted banner");
                } catch {
                    message.error("Something went wrong");
                } finally {
                    return;
                }
            },
            onCancel() {
                return;
            },
        });
    };

    const handleCard = (menuItem: string, slug: string, id: string) => {
        if (menuItem === "edit") {
            history.push(`${match.path}/${id}`);
        }
        if (menuItem === "delete") {
            handleDelete(id);
        }
    };

    const handlePageSize = async (pageSize) => {
        setPageSize(pageSize);
    };

    const handlePagination = async (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    useEffect(() => {
        const filterList = [
            {
                name: "Type of Link",
                customOptions: linkTypes,
                handle: handleLinkType,
            },
            {
                name: "Category",
                customOptions: linkType ? categories[toLower(linkType)] : [],
                handle: handleCategory,
                value: category ? category : "All",
                disabled: disableCategory,
            },
        ];
        setFilters(filterList);
    }, [linkType, category]);

    useEffect(() => {
        setHeroBannerList([]);

        if (!isEmpty(data) && !isEmpty(data.getFilteredHomeBanners)) {
            setLoader(true);

            data.getFilteredHomeBanners.total_count &&
                setTotalPages(data.getFilteredHomeBanners.total_count);

            const { home_banners } = data.getFilteredHomeBanners;
            const res = home_banners.map((info: HeroBanner) =>
                Object.assign([], HeroBannerMapper.map({ ...info }))
            );
            setHeroBannerList(res);
        }

        if (error) {
            message.error("Something went wrong");
        }

        setLoader(false);
    }, [data, error]);

    return (
        <CustomDashboard
            createAccess={createAccess}
            updateAccess={updateAccess}
            deleteAccess={deleteAccess}
            dashboard="Hero Banner"
            filters={filters}
            handleSearch={handleSearch}
            handleMenu={handleCard}
            loading={loading || loader}
            handleAddBtn={handleAddBtn}
            data={heroBannerList}
            cardText={cardText}
            totalPages={totalPages}
            page={page}
            handlePagination={handlePagination}
            pageSize={pageSize}
            handlePageSize={handlePageSize}
        />
    );
};

export default HeroBannerList;
