import isEmpty from "lodash/isEmpty";

import { Gallery } from "../../../entities/Gallery";

export class GalleryMapper {
    static map(gallery: Gallery): Gallery {

        const imagesFields = 
            !isEmpty(gallery.containers) && // GALLERY-CONTAINER
            !isEmpty(gallery.containers[0].field_collections) && // GALLERY-FC
            !isEmpty(gallery.containers[0].field_collections[0].children) &&
            !isEmpty(gallery.containers[0].field_collections[0].children.filter(child => child.name === "IMAGES")) && // IMAGES
            !isEmpty(gallery.containers[0].field_collections[0].children.filter(child => child.name === "IMAGES")[0].fields) && // IMAGES FIELDS
            gallery.containers[0].field_collections[0].children.filter(child => child.name === "IMAGES")[0].fields;

        gallery.imgURL =
            imagesFields &&
            !isEmpty(imagesFields.filter(field => field.meta_data.featured)) && // FEATURED IMAGE
            !isEmpty(imagesFields.filter(field => field.meta_data.featured)[0].value) &&
            imagesFields.filter(field => field.meta_data.featured)[0].value

        delete gallery.containers;

        return gallery;
    }
}