import { AxiosError, AxiosResponse } from "axios";

import apiClient from "../../util/api-client";

import { Role } from "../../entities/Role";
import { RoleMapper } from "./dto/RoleMapper";
import { RolePaginationResponse } from "./dto/RolePaginationResponse";

const BASE_URL = process.env.REACT_APP_IAM_URL;

export const getRoles = async (query: any, token?: any): Promise<any> => {
    return apiClient
        .get(`${BASE_URL}/roles`, {
            params: query,
        })
        .then((response: AxiosResponse) => {
            // Alias data variable to apiResponse
            const { data: apiResponse } = response;

            // Map user using UserMapper
            apiResponse.data = apiResponse.data.map((role: Role) => RoleMapper.map(role));

            return apiResponse;
        })
        .catch((error: AxiosError) => {
            throw new Error(error.response?.data.message);
        }) as Promise<RolePaginationResponse>;
};

export const addRole = (data): any => {
    return apiClient({
        method: "post",
        url: `${BASE_URL}/roles`,
        data: {
            name: data.name,
            description: data.description,
            isActive: data.isActive === 1 ? true : false,
            tenantId: data.tenant,
            permissionIds: Object.keys(data.permissionIds),
        },
    })
        .then((response) => {
            return {success: true, response}
        })
        .catch((error: AxiosError) => {
            return {success: false, error: error?.response}
        });
};

export const editRole = (data, roleId): Promise<any> => {
    const keys = Object.keys(data.permissionIds);
    const values = Object.values(data.permissionIds);
    const permissionIds: string[] = [];
    keys.forEach((item, index) => {
        if (values[index] === true) {
            permissionIds.push(item);
        }
    });
    return apiClient({
        method: "put",
        url: `${BASE_URL}/roles/${roleId}`,
        data: {
            name: data.name,
            description: data.description,
            isActive: data.isActive === 1 ? true : false,
            tenant: data.tenant,
            permissionIds: permissionIds,
        },
    })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getRoleDetails = (roleId): Promise<any> => {
    return apiClient({
        method: "get",
        url: `${BASE_URL}/roles/${roleId}`,
    })
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getTenantsList = (): Promise<any> => {
    return apiClient({
        method: "get",
        url: `${BASE_URL}/tenants`,
    })
        .then((response) => {
            return response.data.data.map((tenant) => ({
                label: tenant.name,
                value: tenant.id,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getFeatureList = (tenantId): Promise<any> => {
    return apiClient({
        method: "get",
        url: `${BASE_URL}/features?tenantId=${tenantId}&limit=20`,
    })
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getRolePermissionList = (roleId): Promise<any> => {
    return apiClient({
        method: "get",
        url: `${BASE_URL}/roles/${roleId}/permissions`,
    })
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const getBrand = (token): Promise<any> => {
    return apiClient({
        method: "GET",
        url: `${BASE_URL}/tenants`,
    })
        .then((res) => {
            const data = res.data.data;

            return data.map(({ id, name }) => ({
                text: name,
                value: id,
            }));
        })
        .catch((error) => {
            console.log(error);
        });
};
