import React, { useState, useEffect, useRef } from "react";

import { FormItem, Input, AutoComplete } from "formik-antd";
import { Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import throttle from "lodash/throttle";
import filter from "lodash/find";
import CustomButton from "../../../../../../components/CustomButton";
import { QUERY_LOCATION_AC, QUERY_LOCATION_GEOCODE } from "../../../../graphql";

import FeaturedImages from "../../../../../../components/FeaturedImages";
import { ContentWrapper, DynamicFormContainer } from "./styled";

interface LandmarksProps {
    events: any;
    values: any;
    setFieldValue: any;
    brandId: string;
}

const LandmarksForm = ({ events, values, setFieldValue, brandId }: LandmarksProps): JSX.Element => {
    const [locationAC, setLocationAC] = useState("");
    const [landmarkIndex, setLandmarkIndex] = useState<number>();
    const [locationSearchable, setlocationSearchable] = useState("");
    const [placeId, setPlaceId] = useState("");
    const [options, setOptions] = useState<{ value: string }[]>([]);

    const throttled = useRef(throttle((newValue) => setlocationSearchable(newValue), 4000));
    useEffect(() => throttled.current(locationAC), [locationAC]);
    const { data: locationData, error: locationDataError } = useQuery(QUERY_LOCATION_AC, {
        skip: !locationSearchable,
        variables: {
            brand_id: brandId,
            location: locationSearchable,
        },
    });
    useEffect(() => {
        const onCompleted = (locationData) => {
            if (!locationData) {
                setOptions([]);
            } else {
                const mappedData = locationData.getLocations.places.map((res) => {
                    return { value: res.name };
                });
                setOptions(mappedData);
            }
        };
        const onError = (locationDataError) => {
            console.log(locationDataError, "QUERY ERROR");
        };
        if (onCompleted || onError) {
            if (onCompleted && !locationDataError) {
                onCompleted(locationData);
            } else if (onError && locationDataError) {
                onError(locationDataError);
            }
        }
    }, [locationData, locationDataError]);

    const { data: geoCodeData, error: geoCodeError } = useQuery(QUERY_LOCATION_GEOCODE, {
        skip: !placeId,
        variables: {
            brand_id: brandId,
            place_id: placeId,
        },
    });
    useEffect(() => {
        const onCompleted = (geoCodeData) => {
            if (geoCodeData) {
                setFieldValue(
                    `landmarksContents[${landmarkIndex}].latitude`,
                    String(geoCodeData.getLocationGeocode.latitude)
                );
                setFieldValue(
                    `landmarksContents[${landmarkIndex}].longitude`,
                    String(geoCodeData.getLocationGeocode.longitude)
                );
            }
        };
        const onError = (geoCodeError) => {
            console.log(geoCodeError, "QUERY ERROR");
        };
        if (onCompleted || onError) {
            if (onCompleted && !geoCodeError) {
                onCompleted(geoCodeData);
            } else if (onError && geoCodeError) {
                onError(geoCodeError);
            }
        }
        // eslint-disable-next-line
    }, [geoCodeData, geoCodeError, landmarkIndex]);

    const onSelect = (selectedData: string) => {
        const selectedLocationData = filter(locationData.getLocations.places, {
            name: selectedData,
        });
        setPlaceId(selectedLocationData.place_id);
    };

    const onChange = (e: string, name: string, index: number) => {
        setLandmarkIndex(index);
        setLocationAC(e);
    };

    const validateNumericInput = (event: any) => {
        const key = String.fromCharCode(event.which || event.charCode);
        const reg = /[^0-9\b\.]/;

        if (reg.test(key)) {
            event.preventDefault();
        }
    };

    return (
        <DynamicFormContainer>
            {values.map((res, index) => (
                <ContentWrapper key={res.establishmentName}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem
                                label={"Establishment Name"}
                                name={`landmarksContents[${index}].establishmentName`}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input
                                    name={`landmarksContents[${index}].establishmentName`}
                                    placeholder="Enter Establishment Name"
                                />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"Distance (km)"}
                                name={`landmarksContents[${index}].distance`}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input
                                    name={`landmarksContents[${index}].distance`}
                                    placeholder="Enter Distance"
                                    suffix={"km"}
                                />
                            </FormItem>
                        </Col>
                    </Row>

                    <FormItem
                        label={"Location"}
                        name={`landmarksContents[${index}].location`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <AutoComplete
                            name={`landmarksContents[${index}].location`}
                            options={options}
                            onSelect={onSelect}
                            onChange={(e) =>
                                onChange(e, `landmarksContents[${index}].location`, index)
                            }
                        />
                    </FormItem>
                    <Row gutter={24}>
                        <Col span={12}>
                            <FormItem
                                label={"Longitude"}
                                name={`landmarksContents[${index}].longitude`}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input
                                    onKeyPress={validateNumericInput}
                                    name={`landmarksContents[${index}].longitude`}
                                />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"Latitude"}
                                name={`landmarksContents[${index}].latitude`}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input
                                    onKeyPress={validateNumericInput}
                                    name={`landmarksContents[${index}].latitude`}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <FormItem
                        label={"Body"}
                        name={`landmarksContents[${index}].body`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input.TextArea name={`landmarksContents[${index}].body`} />
                    </FormItem>

                    <FeaturedImages
                        onChange={(e) => {
                            const imagesCaptions = e.map(({ caption }) => caption);
                            const imagesAltTexts = e.map(({ altText }) => altText);
                            setFieldValue(`landmarksContents[${index}].images`, e);
                            setFieldValue(
                                `landmarksContents[${index}].imagesCaption`,
                                imagesCaptions
                            );
                            setFieldValue(
                                `landmarksContents[${index}].images.altText`,
                                imagesAltTexts
                            );
                        }}
                        path={"properties-locations"}
                        captionList={values.imagesCaption}
                        fileList={res.images}
                        validate={true}
                        imageAltText={true}
                    />

                    <CloseOutlined
                        className="remove-btn"
                        onClick={() => {
                            events.remove(index);
                        }}
                    />
                </ContentWrapper>
            ))}

            <CustomButton
                type="dashed"
                className="add-row"
                style={{ width: "100%" }}
                onClick={() => {
                    events.push({
                        establishmentName: "",
                        distance: "",
                        location: "",
                        body: "",
                        images: [],
                    });
                }}
            >
                <p style={{ margin: 0 }}>+ Add Row</p>
            </CustomButton>
        </DynamicFormContainer>
    );
};

export default LandmarksForm;
