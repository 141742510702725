import styled from "styled-components";
import { Button } from "antd";

export const SectionWrapper = styled.div`
    border: 1px solid #ddd;
    padding: 35px 25px 25px;
    border-radius: 5px;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;

    .add-row {
        background: #fafafa;
        margin-bottom: 25px;

        p {
            font-size: 13px;
            font-weight: 300;
        }
    }

    .add-section {
        p {
            font-size: 13px;
            font-weight: 300;
        }
    }
    .section-remove-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
`;

export const ActionButton = styled(Button)`
    color: #ddd;
    padding: 0;
    height: 31px;
    width: 31px;
    margin-right: 5px;

    &:last-child {
        margin-right: 0;
    }

    .anticon {
        height: 15px;
        width: 15px;
    }
`;

export const StyledIFrame = styled.embed`
    border: 0;
`;
