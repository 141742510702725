import styled from "styled-components";

export const Title = styled.h1`
    margin-left: 24px;
    margin-top: 24px;
    font-weight: bold;
`;

export const ListWrapper = styled.div`
    border: 1px solid #ddd;
    padding: 15px 25px 0;
    border-radius: 5px;
    position: relative;
    margin-bottom: 15px;

    .add-row {
        background: #fafafa;
        margin-bottom: 25px;

        p {
            font-size: 12px;
            font-weight: 300;
        }
    }

    .remove-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
`;
