import { FormValueType, AmenityListType, FieldInfoType } from "./amenities.types";

export const AMENITY_LIST_INIT: AmenityListType = {
    amenityName: "",
    images: [],
    videos: [""],
    panoramas: [""],
};

export const INIT_VALUES: FormValueType = {
    amenitiesDefault: "",
    title: "",
    propertyDetails: "",
    seoAmenitiesTitle: "",
    seoAmenitiesDescription: "",
    amenitiesHeadingTag: "",
    amenityList: [
        {
            amenityName: "",
            images: [],
            videos: [""],
            panoramas: [""],
        },
    ],
};

export const INIT_FIELD_INFO: FieldInfoType = {
    fields: [],
    amenityID: "",
    amenityList: [],
};
