/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";

import ComponentOne from "./component1";
import ComponentTwo from "./component2";
import ComponentThree from "./component3";
import ComponentFour from "./component4";
import ComponentFive from "./component5";
import ComponentSix from "./component6";
import ComponentSeven from "./component7";
import ComponentEight from "./component8";
import ComponentNine from "./component9";
import ComponentTen from "./component10";
import ComponentEleven from "./component11";
import ComponentTwelve from "./component12";
import ComponentThirteen from "./component13";
import ComponentFourteen from "./component14";

import { ComponentFormType } from "../content-forms.types";

const ComponentTemplate = (
    { fields, type },
    index,
    setFieldValue,
    fieldsArrayEvent,
    values,
    uploadComplete,
    setUploadStatus,
    brandName
): JSX.Element | string => {
    const props: ComponentFormType = {
        index,
        setFieldValue,
        fields,
        fieldsArrayEvent,
        values,
        uploadComplete,
        setUploadStatus,
        brandName,
    };

    switch (type) {
        case "component 1":
            return <ComponentOne {...props} />;
        case "component 2":
            return <ComponentTwo {...props} />;
        case "component 3":
            return <ComponentThree {...props} />;
        case "component 4":
            return <ComponentFour {...props} />;
        case "component 5":
            return <ComponentFive {...props} />;
        case "component 6":
            return <ComponentSix {...props} />;
        case "component 7":
            return <ComponentSeven {...props} />;
        case "component 8":
            return <ComponentEight {...props} />;
        case "component 9":
            return <ComponentNine {...props} />;
        case "component 10":
            return <ComponentTen {...props} />;
        case "component 11":
            return <ComponentEleven {...props} />;
        case "component 12":
            return <ComponentTwelve {...props} />;
        case "component 13":
            return <ComponentThirteen {...props} />;
        case "component 14":
            return <ComponentFourteen {...props} />;
        default:
            return "";
    }
};

export default ComponentTemplate;
