import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import _ from "lodash";

import { Spin, message } from "antd";

import ContentContainer from "../../../../../../components/ContentContainer";
import Forms from "./form";

import { Context } from "../../../../../../context";
import { REPLACE_FIELD_COLLECTION } from "../../../../graphql";
import { validationSchema } from "./constant";
import generateId from "../../../../../../util/generate-id";

import {
    Props,
    FormValueType,
    FieldInfoType,
    FieldInfoChildrenType,
    FileType,
    ImageType,
    FieldCollectionType,
    FieldItemType,
} from "../unit-tabs-types";

import {
    INIT_VALUES,
    INIT_FIELD_INFO,
    INIT_CHILDREN,
    imageMapper,
    textLinkMapper,
} from "../unit-tabs-utils";

const UnitOverviewForms = ({ handleRefresh }: Props): JSX.Element => {
    const {
        state: { propertyUnitForms, brand: brandData },
    } = useContext(Context);
    const brandName = brandData?.name;

    const defaultInitialValues: FormValueType = INIT_VALUES;
    const defaultFieldInfo: FieldInfoType = INIT_FIELD_INFO;

    const [initialValues, setInitialValues] = useState<FormValueType>(defaultInitialValues);
    const [fieldInfo, setFieldInfo] = useState<FieldInfoType>(defaultFieldInfo);

    const [replaceFCFields] = useMutation(REPLACE_FIELD_COLLECTION);
    const [loader, setLoader] = useState(false);

    const [reinitialize, setReinitialize] = useState(false);

    useEffect(() => {
        if (reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        const data = propertyUnitForms.payload.overviewDetails;

        if (_.has(data, "fields") && data.fields) {
            const children: FieldInfoChildrenType = INIT_CHILDREN;

            const fieldItem: FieldItemType[] = [];

            const fields: FormValueType = INIT_VALUES;

            let fileId = "";

            if (data.children) {
                data.children.forEach((res) => {
                    children[`${_.camelCase(res.name)}Id`] = res.id;

                    if (res.fields) {
                        if (_.camelCase(res.name) === "imagesGallery") {
                            fields[_.camelCase(res.name)] = res.fields.map(
                                (f): ImageType => ({
                                    uid: generateId(),
                                    caption: f.meta_data.description,
                                    isChecked: f.meta_data.featured,
                                    url: f.value,
                                    keyObj: f.meta_data.key,
                                    id: f.id,
                                    status: "done",
                                    altText: f.meta_data.alt_text,
                                })
                            );

                            fields["imagesCaptions"] = res.fields.map(
                                (f) => f.meta_data.description
                            );

                            children[_.camelCase(res.name)] = res.fields.map((f) => ({
                                id: f.id,
                                value: f.value,
                                caption: f.meta_data.description,
                                isChecked: f.meta_data.featured,
                                order: f.order,
                                key: f.meta_data.key,
                            }));
                        } else {
                            children[_.camelCase(res.name)] = res.fields.map((f) => ({
                                value: f.value ? f.value : "",
                                id: f.id,
                            }));

                            fields[_.camelCase(res.name)] = res.fields.map((f) => f.value);
                        }
                    } else {
                        children[_.camelCase(res.name)] = [];
                    }
                });
            }

            data.fields.forEach((res) => {
                if (res.label === "File") {
                    const fileData: FileType = {
                        uid: generateId(),
                        url: res.value,
                        keyObj: res.meta_data.key,
                        id: res.id,
                        status: "done",
                        name: res.meta_data.file_name,
                        type: res.meta_data.file_type,
                    };

                    fileId = res.id;
                    fieldItem.push(fileData);
                    fields["file"] = fileData;
                } else {
                    fieldItem.push(res);
                    if (res.name === "TAGS") {
                        fields[_.camelCase(res.name)] = res.value ? res.value.split(",") : "";
                    } else {
                        fields[_.camelCase(res.name)] = res.value ? res.value : "";
                    }
                }
            });

            setFieldInfo({
                fields: fieldItem,
                children: children,
                fcId: data.id,
                parentId: data.parentId,
                fileId,
            });

            setInitialValues(fields);
            setReinitialize(true);
        }
    }, [propertyUnitForms.payload]);

    const handleSubmit = async (values) => {
        setLoader(true);

        const field_collection: any = {
            order: 0,
            parentId: fieldInfo.parentId,
            name: "OVERVIEW-DETAILS",
            fields: [
                {
                    label: "Property Status",
                    name: "PROPERTY-STATUS",
                    order: 0,
                    value: values.propertyStatus ? values.propertyStatus : null,
                },
                {
                    label: "Title",
                    name: "PROPERTY-TITLE",
                    order: 1,
                    value: values.propertyTitle ? values.propertyTitle : null,
                },
                {
                    label: "Property Details",
                    name: "PROPERTY-DETAILS",
                    order: 2,
                    value: values.propertyDetails ? values.propertyDetails : null,
                },
                {
                    label: "Document Title",
                    name: "DOCUMENT-TITLE",
                    order: 3,
                    value: values.documentTitle ? values.documentTitle : null,
                },
                {
                    label: "Seo Title",
                    name: "SEO-OVERVIEW-TITLE",
                    order: 4,
                    value: values.seoOverviewTitle ? values.seoOverviewTitle : null,
                },
                {
                    label: "Seo Description",
                    name: "SEO-OVERVIEW-DESCRIPTION",
                    order: 5,
                    value: values.seoOverviewDescription ? values.seoOverviewDescription : null,
                },
                {
                    label: "H1 Tag",
                    name: "OVERVIEW-HEADING-TAG",
                    order: 6,
                    value: values.overviewHeadingTag ? values.overviewHeadingTag : null,
                },
                brandName === "AVIDA" && {
                    label: "Description",
                    name: "DESCRIPTION",
                    order: 7,
                    value: values.description ? values.description : null,
                },
                brandName === "AVIDA" && {
                    label: "Tags",
                    name: "TAGS",
                    order: 8,
                    value: values.tags ? values.tags.join(",") : null,
                },
                ...(brandName === "ALP" && values.subHeading
                    ? [
                          {
                              label: "H2 Tag",
                              name: "SUB-HEADING",
                              order: 7,
                              value: values.subHeading,
                          },
                      ]
                    : []),
            ].filter((el) => el !== false),
            children: [
                {
                    name: "IMAGES-GALLERY",
                    order: 0,
                },
                {
                    name: "VIDEOS",
                    order: 1,
                },
                {
                    name: "PANORAMAS",
                    order: 2,
                },
            ],
        };

        if (!_.isEmpty(values.imagesGallery)) {
            field_collection["children"][0]["fields"] = imageMapper(values.imagesGallery);
        }

        if (!_.isEmpty(values.videos)) {
            field_collection["children"][1]["fields"] = textLinkMapper(values.videos, "videos");
        }

        if (!_.isEmpty(values.panoramas)) {
            field_collection["children"][2]["fields"] = textLinkMapper(
                values.panoramas,
                "panoramas"
            );
        }

        if (values.file) {
            field_collection.fields.push({
                label: "File",
                name: "File",
                value: values.file.keyObj,
                order: 4,
                meta_data: {
                    featured: false,
                    description: "",
                    key: values.file.keyObj,
                    file_type: values.file.type,
                    file_name: values.file.name,
                },
            });
        }

        await replaceFCFields({
            variables: {
                collectionData: {
                    collections: [field_collection],
                },
                oldFcIds: {
                    ids: [fieldInfo.fcId],
                },
            },
        });

        handleRefresh();
        setTimeout(() => {
            message.success("Update Success");
            setLoader(false);
        }, 1000);
    };

    return (
        <ContentContainer
            style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1rem",
                position: "relative",
            }}
        >
            <Spin spinning={loader}>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={reinitialize}
                    validationSchema={validationSchema}
                    onSubmit={async (values: FormValueType) => {
                        await handleSubmit(values);
                    }}
                >
                    {(formikBag) => (
                        <Forms
                            {...{
                                formikBag,
                                brandName,
                            }}
                        />
                    )}
                </Formik>
            </Spin>
        </ContentContainer>
    );
};

export default UnitOverviewForms;
