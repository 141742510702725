import * as Yup from "yup";
import moment from "moment";

const phoneRegex = /^(09|639)\d{9}$/;
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    brand: "",
    role: "",
};

export const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Enter First Name!"),
    middleName: Yup.string(),
    lastName: Yup.string().required("Enter Last Name!"),
    mobileNumber: Yup.string()
        .matches(phoneRegex, "Phone No. is invalid")
        .required("Enter Mobile Number!"),
    email: Yup.string().matches(emailRegex, "Email is invalid").required("Enter Email Address"),
    brand: Yup.string().required("Select Brand"),
    role: Yup.string().required("Select Role"),
});

export const activeColumns = [
    {
        title: "UserID",
        dataIndex: "id",
        sorter: true,
    },
    {
        title: "Full Name",
        dataIndex: "fullName",
        sorter: true,
    },
    {
        title: "Email",
        dataIndex: "email",
        sorter: true,
    },
    {
        title: "Role",
        dataIndex: "role",
    },
    {
        title: "Brand",
        dataIndex: "brand",
    },
    {
        title: "Date Created",
        dataIndex: "createdAt",
        sorter: true,
    },
    {
        title: "Date Modified",
        dataIndex: "updatedAt",
        sorter: true,
    },
];

export const inactiveColumns = [
    {
        title: "UserID",
        dataIndex: "id",
        sorter: true,
    },
    {
        title: "Full Name",
        dataIndex: "fullName",
        sorter: true,
    },
    {
        title: "Email",
        dataIndex: "email",
        sorter: true,
    },
    {
        title: "Role",
        dataIndex: "role",
    },
    {
        title: "Brand",
        dataIndex: "brand",
    },
    {
        title: "Date Created",
        dataIndex: "createdAt",
        sorter: true,
    },
    {
        title: "Date Modified",
        dataIndex: "updatedAt",
        sorter: true,
    },
    {
        title: "Status",
        dataIndex: "status",
        filters: [
            {
                text: "Verified",
                value: "Verified",
            },
            {
                text: "Pending",
                value: "Pending",
            },
        ],
        filterMultiple: false
    },
];
