import * as yup from "yup";

const imageSchema = yup.object().shape({
    status: yup.string().matches(/done/, { message: "file is still uploading" }),
});

export const validationSchema = yup.object().shape({
    title: yup.string(),
    imagesGallery: yup.array().of(imageSchema).nullable(),
    file: imageSchema.nullable(),
});

export const propertyStatusList = [
    {
        label: "Pre-Selling",
        value: "Pre-Selling",
    },
    {
        label: "Currently Selling",
        value: "Currently Selling",
    },
    {
        label: "New Launches",
        value: "New Launches",
    },
    {
        label: "Sold out",
        value: "Sold out",
    },
    {
        label: "RFO (Ready for Occupancy)",
        value: "RFO (Ready for Occupancy)",
    },
    {
        label: "Pre-Selling and RFO (Ready for Occupancy)",
        value: "Pre-Selling and RFO (Ready for Occupancy)",
    },
];

export const fieldTemp = {
    name: "Overview Details",
    fields: [
        {
            label: "Property Status",
            name: "PROPERTY-STATUS",
            value: "Pre-Selling",
        },
        {
            label: "Title",
            name: "PROPERTY-TITLE",
            value: "",
        },
        {
            label: "Property Details",
            name: "PROPERTY-DETAILS",
            value: "",
        },
        {
            label: "Seo Title",
            name: "SEO-OVERVIEW-TITLE",
            value: "",
        },
        {
            label: "Seo Description",
            name: "SEO-OVERVIEW-DESCRIPTION",
            value: "",
        },
    ],
    children: [
        {
            name: "images",
        },
        {
            name: "videos",
            fields: [
                {
                    label: "video",
                    name: "VIDEO",
                    value: "",
                },
            ],
        },
        {
            name: "panoramas",
            fields: [
                {
                    label: "panorama",
                    name: "PANORAMA",
                    value: "",
                },
            ],
        },
    ],
};
