import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, Modal } from "antd";

import ContentContainer from "../../../../components/ContentContainer";
import CustomButton from "../../../../components/CustomButton";
import MultipleUrlFields from "../../../../components/MultipleUrlFields";
import FeaturedImages from "../../../../components/FeaturedImages";

import { Title, StyledIFrame } from "./styled";

interface Props {
    formikBag: any;
    brandName: string;
}

const Forms = ({ formikBag, brandName }: Props): JSX.Element => {
    const history = useHistory();
    const { slug } = useParams();

    const [modalVisible, setModalVisible] = useState(false);
    const [preview, setPreview] = useState("");
    const { values, submitForm, setFieldValue, isValid, dirty } = formikBag;

    const handleClosePreview = () => {
        setPreview("");
        setModalVisible(false);
    };

    const handlePreview = (val) => {
        if (val) {
            setPreview(val);
            setModalVisible(true);
        }
    };

    return (
        <Form>
            <Title>Gallery</Title>
            <ContentContainer style={{ display: "flex", flexDirection: "column", padding: "30px" }}>
                <Row justify="start">
                    <Col span={24}>
                        <FormItem
                            label={
                                <h1>
                                    <b>Gallery</b> (Default)
                                </h1>
                            }
                            name={"label"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name="label" placeholder="Override Label" />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem
                            label={"Title"}
                            name={"title"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input
                                name={"title"}
                                placeholder="Enter Title"
                                disabled={brandName === "ALP" && slug && true}
                            />
                        </FormItem>
                    </Col>

                    <FeaturedImages
                        onChange={(e) => {
                            const imagesCaptions = e.map(({ caption }) => caption);
                            const imagesAltTexts = e.map(({ altText }) => altText);
                            setFieldValue(`imagesCaptions`, imagesCaptions);
                            setFieldValue(`images.altText`, imagesAltTexts);
                            setFieldValue(`images`, e);
                        }}
                        path={"gallery"}
                        fileList={values.images}
                        captionList={values.imagesCaptions}
                        validate={true}
                        imageAltText={brandName === "ALP"}
                    />

                    <Col span={24}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <p>Video</p>
                                <MultipleUrlFields
                                    values={values.videos}
                                    placeholder="Video Link"
                                    name="videos"
                                    preview={handlePreview}
                                />
                            </Col>
                            <Col span={12}>
                                <p>Panorama</p>
                                <MultipleUrlFields
                                    values={values.panoramas}
                                    placeholder="Panorama Link"
                                    name="panoramas"
                                    preview={handlePreview}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Modal
                    visible={modalVisible}
                    centered
                    footer={null}
                    bodyStyle={{ padding: 0, height: 400 }}
                    onCancel={handleClosePreview}
                    width={700}
                    closable={false}
                    maskClosable={true}
                    destroyOnClose={true}
                >
                    <StyledIFrame src={preview} width="100%" height="100%"></StyledIFrame>
                </Modal>
            </ContentContainer>

            <Row justify="end" gutter={24} style={{ padding: "0 25px 25px 25px" }}>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%" }}
                        onClick={() => history.push(`/manage-gallery`)}
                    >
                        CANCEL
                    </CustomButton>
                </Col>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={() => submitForm()}
                        disabled={!dirty || !isValid}
                    >
                        SAVE
                    </CustomButton>
                </Col>
            </Row>
        </Form>
    );
};

export default Forms;
