import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import { useLocation, Link } from "react-router-dom";
import { Breadcrumb, Button } from "antd";
import styled from "styled-components";
import { Context } from "../../context";
import ScreenLoader from "../ScreenLoader";
import axios from "axios";
import { Modal } from "antd";
import { getHomeLink } from "../../util/get-home-link";

const BreadcrumbItem = Breadcrumb.Item;

const modalSuccess = () => {
    Modal.success({
        title: "Estimated Deployment Duration : 5 to 10 minutes",
        content: "Deployment times may vary",
    });
};

const modalFailed = () => {
    Modal.error({
        content: "Something went wrong",
    });
};

const StyledBreadcrumb = styled(Breadcrumb)`
    display: flex;
    background: #fff;

    span:last-child a {
        pointer-events: none;
    }
`;

const DeployButton = styled(Button)`
    background-color: #00714b;
    color: #ffff;
    border-color: #00714b;
    align-self: center;
    width: 140px;
    border-radius: 4px;
    p {
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        line-height: 22px;
    }
`;

const StyledHeader = styled.div`
    background: #fff;
    padding: 16px 25px;
    display: flex;
    justify-content: space-between;
    .deploy-button {
        background: #fff;
    }
`;

const LayoutBreadcrumb: React.FC = () => {
    const location = useLocation();
    const {
        state: { brand: brandData, user },
    } = useContext(Context);
    const [loading, setLoading] = useState(false);
    const minutes = 240000;

    const deployedTime = localStorage.getItem("DEPLOYED_TIME");

    const isPast = new Date().getTime() - Number(deployedTime) < minutes ? false : true;
    const [deployTimeOut, setDeployTimeOut] = useState(!isPast ? true : false);

    const permissions = user?.roles[0].permissions;

    const handleDeployButton = () => {
        setLoading(true);
        const token = localStorage.getItem("ACCESS_TOKEN");
        axios
            .post(`${process.env.REACT_APP_CONTENT_URL}/deploy`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                localStorage.setItem("DEPLOYED_TIME", new Date().getTime().toString());
                setLoading(false);
                setDeployTimeOut(true);
                modalSuccess();
            })
            .catch((error) => {
                modalFailed();
            });
    };

    useEffect(() => {
        if (deployedTime) {
            const past = Number(deployedTime);
            const newInterval = new Date().getTime() - past;
            if (newInterval > minutes) {
                setDeployTimeOut(false);
            } else {
                const newMinutes = minutes - newInterval;
                setTimeout(() => {
                    setDeployTimeOut(false);
                }, newMinutes);
            }
        }
    }, [deployedTime]);

    const renderBreadcrumbItem = (location) => {
        const uuidPattern =
            /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        if (location) {
            const breadcrumbItems = location.pathname
                .split("/")
                .filter((item) => item !== "home")
                .map((item, index, originalArr) => {
                    let title = item;
                    let skipLast = false;
                    const hasNumber = uuidPattern.test(item);
                    const manage = originalArr.filter((m) => m.startsWith("manage-"));
                    if (manage.length > 0) {
                        if (index > 0 && [manage[0], "add"].every((s) => originalArr.includes(s))) {
                            if (item === "add") {
                                let featureName = manage[0]
                                    .split("-")
                                    .filter((_f, i) => i !== 0)
                                    .join(" ");
                                if (featureName === "properties") {
                                    featureName = "Property";
                                } else if (featureName === "content") {
                                    featureName = "Content";
                                }
                                title = `Add ${_.capitalize(featureName)}`;
                            } else if (index === originalArr.length - 1) {
                                skipLast = true;
                            }
                        }
                    }

                    return (
                        !hasNumber &&
                        !skipLast && (
                            <BreadcrumbItem key={title}>
                                {![
                                    "news",
                                    "events",
                                    "destinations",
                                    "neighborhood",
                                    "sustainability",
                                    "home-buying-guide",
                                    "blogs",
                                    "vlogs",
                                ].includes(item) ? (
                                    <Link
                                        to={
                                            index === 0
                                                ? () => getHomeLink(permissions)
                                                : originalArr.slice(0, index + 1).join("/")
                                        }
                                    >
                                        {index === 0
                                            ? "Home"
                                            : title === "manage-mddor-documents"
                                            ? "Manage MDDOR Documents"
                                            : _.startCase(_.toLower(title.replace(/-/g, " ")))}
                                    </Link>
                                ) : index === 0 ? (
                                    "Home"
                                ) : (
                                    _.startCase(_.toLower(title.replace(/-/g, " ")))
                                )}
                            </BreadcrumbItem>
                        )
                    );
                });
            return breadcrumbItems;
        }
    };

    return (
        <StyledHeader>
            <StyledBreadcrumb>{renderBreadcrumbItem(location)}</StyledBreadcrumb>
            {brandData && (
                <div className="deploy-button">
                    <DeployButton disabled={deployTimeOut} onClick={handleDeployButton}>
                        <p>Deploy</p>
                    </DeployButton>
                </div>
            )}
            <ScreenLoader visible={loading} />
        </StyledHeader>
    );
};

export default LayoutBreadcrumb;
