export const formatCurrency = (e) => {
    const { value } = e.target;
    const regExp = /^[0-9.,]+$/;
    const pattern = new RegExp(regExp);
    if (pattern.test(value?.trim())) {
        return numberFormat(e, value);
    } else {
        if (value?.trim() === "" || value === undefined) {
            return null;
        }
        const reparsed = value?.trim().replace(/ /g,'').replace(/[^\d.]/g, "");
        if(isNaN(value)) {
            if(reparsed === "") {
                return null;
            }
            return numberFormat(e,reparsed);
        }
        return numberFormat(e,reparsed);
    }
};

const numberFormat = (e,value) => {
    const addDecimal = value.replace(/,/g, "");
    if (!addDecimal.endsWith(".") && !addDecimal.endsWith(".0")) {
        const decimalCheck = addDecimal.split(".");

        const parsed = decimalCheck && decimalCheck.length > 1 && decimalCheck[1].length > 2
                ? parseFloat(addDecimal.slice(0, -1))
                : parseFloat(addDecimal);

        const formattedNumber = parsed.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits:
                (decimalCheck && decimalCheck.length > 1 && decimalCheck[1].length === 2) ? 2 : undefined
        });
        e.target.value = formattedNumber;
        return formattedNumber;
    } else {
        return value;
    }
};