import styled from "styled-components";

export const Title = styled.h1`
    margin-left: 24px;
    margin-top: 24px;
    font-weight: bold;
`;

export const SectionWrapper = styled.div`
    background-color: #fff;
    padding: 35px 25px 25px;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
    .add-row {
        background: #fafafa;
        margin-bottom: 25px;

        p {
            font-size: 13px;
            font-weight: 300;
        }
    }

    .add-section {
        p {
            font-size: 13px;
            font-weight: 300;
        }
    }
    .section-remove-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
`;
