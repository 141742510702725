import React, { ReactNode, useState, useEffect, useLayoutEffect, useContext } from "react";
import worker from "./worker.js";
import WebWorker from "./workerSetup";

import { Context } from "../../context";

interface Props {
    onMouseMove?: () => void;
    children: ReactNode;
}

const Session: React.FC<Props> = ({ onMouseMove, children }) => {
    let webWorker;
    const MAXIMUM_MINUTES = 600;
    const [webWorkerState, setWebWorkerState] = useState<any>(webWorker);

    const { state } = useContext(Context);

    // On page load
    useEffect(() => {
        webWorker = new WebWorker(worker);
        setWebWorkerState(webWorker);
    }, []);

    useEffect(() => {
        if (state) {
            // Stop the current web worker when uploading a file
            if (state?.isUpload === true) {
                stopWorker();
            }

            // Reinstantiate the web worker after uploading
            if (state?.isUpload === false) {
                webWorker = new WebWorker(worker);
                setWebWorkerState(webWorker);
            }
        }
    }, [state]);

    // If the webworker is initiated
    useLayoutEffect(() => {
        if (typeof webWorkerState === "object") {
            webWorkerState.postMessage("start");
            webWorkerState.addEventListener("message", (event) => {
                let currentCount = event.data;
                if (currentCount === MAXIMUM_MINUTES) {
                    stopWorker();
                    logoutHandler();
                }
            });
        }
    }, [webWorkerState]);

    const handleMouseMove = (e) => {
        if (state?.isUpload === true) {
            return;
        }
        webWorkerState.postMessage("reset");
    };

    const stopWorker = () => {
        webWorkerState.terminate();
    };

    const logoutHandler = () => {
        localStorage.removeItem("ACCESS_TOKEN");
        localStorage.removeItem("REFRESH_TOKEN");
        window.location.replace(`
      https://login.windows.net/common/oauth2/logout?post_logout_redirect_uri=${process.env.REACT_APP_IAM_REDIRECT_URL}
    `);
    };

    return <div onMouseMove={handleMouseMove}>{children}</div>;
};

export default Session;
