import React, { useState } from "react";

import { Form, FormItem, Input } from "formik-antd";
import { FieldArray } from "formik";
import { Row, Col, DatePicker, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";
import SeoForm from "@/components/SeoForm";
import CustomButton from "../../../../../../components/CustomButton";
import FeaturedImages from "../../../../../../components/FeaturedImages";
import MultipleUrlFields from "../../../../../../components/MultipleUrlFields";
import { SectionWrapper, StyledIFrame } from "./styled";

interface Props {
    formikBag: any;
    brandName: string;
}

const monthYearFormat = "MM/YYYY";

const Forms = ({ formikBag, brandName }: Props): JSX.Element => {
    const { values, setFieldValue, submitForm, errors, isValid, dirty } = formikBag;
    const [modalVisible, setModalVisible] = useState(false);
    const [preview, setPreview] = useState("");

    const handleClosePreview = () => {
        setPreview("");
        setModalVisible(false);
    };

    const handlePreview = (val) => {
        if (val) {
            setPreview(val);
            setModalVisible(true);
        }
    };

    const renderSection = ({ event, values }) => {
        return (
            <>
                {values.constructions.map((item, index) => (
                    <SectionWrapper key={`constructions_${index}`}>
                        <CloseOutlined
                            className="remove-btn section-remove-btn"
                            onClick={() => {
                                event.remove(index);
                            }}
                        />
                        <Col span={24}>
                            <FormItem
                                label={"Construction Update"}
                                name={`constructions[${index}].constructionUpdate`}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input name={`constructions[${index}].constructionUpdate`} />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem
                                label={"Details"}
                                name={`constructions[${index}].details`}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input.TextArea name={`constructions[${index}].details`} />
                            </FormItem>
                        </Col>
                    </SectionWrapper>
                ))}

                <CustomButton
                    type="dashed"
                    className="add-section"
                    style={{ width: "100%", fontSize: 13, fontWeight: 300 }}
                    onClick={() => {
                        event.push({
                            constructionUpdate: "",
                            details: "",
                        });
                    }}
                >
                    <p style={{ margin: 0 }}>+ Add Row</p>
                </CustomButton>
            </>
        );
    };

    return (
        <Form>
            <SeoForm category={"ConstructionPlan"} brand={brandName} />
            <Row justify="start">
                <Col span={24}>
                    <FormItem
                        label={
                            <h1>
                                <b>Construction Updates </b>(Default)
                            </h1>
                        }
                        name={"constructionUpdatesDefault"}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input name="constructionUpdatesDefault" placeholder="Override Label" />
                    </FormItem>
                </Col>
                <Col span={24}>
                    <Row gutter={10}>
                        <Col span={8}>
                            <FormItem
                                label={"Construction Start Date"}
                                name={"startDate"}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    format={monthYearFormat}
                                    picker="month"
                                    onChange={(date, dateString) =>
                                        setFieldValue("startDate", dateString)
                                    }
                                    value={
                                        !_.isEmpty(values.startDate)
                                            ? moment(values.startDate, monthYearFormat)
                                            : null
                                    }
                                />
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem
                                label={"Percentage"}
                                name={"percentage"}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input name="percentage" />
                            </FormItem>
                        </Col>
                        <Col span={8}>
                            <FormItem
                                label={"Construction End Date"}
                                name={"constructionEndDate"}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    format={monthYearFormat}
                                    picker="month"
                                    onChange={(date, dateString) =>
                                        setFieldValue("constructionEndDate", dateString)
                                    }
                                    value={
                                        !_.isEmpty(values.constructionEndDate)
                                            ? moment(values.constructionEndDate, monthYearFormat)
                                            : null
                                    }
                                />
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <FormItem
                        label={"Details"}
                        name={"details"}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input.TextArea name="details" />
                    </FormItem>
                </Col>

                <Col span={24} style={{ marginBottom: "25px" }}>
                    <FieldArray
                        name="constructions"
                        render={(event) => renderSection({ event, values })}
                    />
                </Col>

                <Col span={24} style={{ marginBottom: 24 }}>
                    <FeaturedImages
                        onChange={(e) => {
                            const imagesCaptions = e.map(({ caption }) => caption);
                            const imagesAltTexts = e.map(({ altText }) => altText);
                            setFieldValue(`imagesCaptions`, imagesCaptions);
                            setFieldValue(`images.altText`, imagesAltTexts);
                            setFieldValue("imageGallery", e);
                        }}
                        path={"properties-contruction-updates"}
                        fileList={values.imageGallery}
                        captionList={values.imagesCaptions}
                        validate={true}
                        imageAltText={brandName === "ALP"}
                    />
                    {errors.imageGallery
                        ? errors.imageGallery.map((item, i) => {
                              return (
                                  item?.keyObj && (
                                      <div
                                          key={`construction_error_${i}`}
                                          style={{ color: "#ff4d4f" }}
                                      >
                                          {item.keyObj}
                                      </div>
                                  )
                              );
                          })
                        : null}
                </Col>
                <Col span={24}>
                    <h3>Virtual Tour</h3>

                    <Row gutter={24}>
                        <Col span={12}>
                            <p>Video</p>
                            <MultipleUrlFields
                                values={values.videos}
                                placeholder="Video Link"
                                name="videos"
                                preview={handlePreview}
                            />
                        </Col>
                        <Col span={12}>
                            <p>Panorama</p>
                            <MultipleUrlFields
                                values={values.panoramas}
                                placeholder="Panorama Link"
                                name="panoramas"
                                preview={handlePreview}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="end">
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={() => submitForm()}
                        disabled={!dirty || !isValid}
                    >
                        SAVE
                    </CustomButton>
                </Col>
            </Row>

            <Modal
                visible={modalVisible}
                centered
                footer={null}
                bodyStyle={{ padding: 0, height: 400 }}
                onCancel={handleClosePreview}
                width={700}
                closable={false}
                maskClosable={true}
                destroyOnClose={true}
            >
                <StyledIFrame src={preview} width="100%" height="100%"></StyledIFrame>
            </Modal>
        </Form>
    );
};

export default Forms;
