import { gql } from "@apollo/client";

export const GET_EMAILS = gql`
    query getListOfEmails($brandId: String!) {
        getListOfEmails(brandId: $brandId) {
            id
            email
            category
        }
    }
`;

export const MODIFY_EMAIL_RECORD = gql`
    mutation addUpdateEmailRecord($data: AddUpdateEmailRecordInput!) {
        addUpdateEmailRecord(emailRecordInput: $data) {
            id
            email
            category
        }
    }
`;

export const REMOVE_EMAIL_RECORD = gql`
    mutation removeEmailRecords($data: DeleteEmailInput!) {
        removeEmailRecords(removeEmailData: $data)
    }
`;
