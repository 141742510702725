import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import words from "lodash/words";

import { Spin } from "antd";

import PropertiesOverviewForms from "./properties-overview";
import { Context } from "../../../../context";
import { GET_PROPERTY } from "../../graphql";
import UnitTabsForms from "./unit-tab-forms";

const PropertiesForms = (): JSX.Element => {
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    const [loader, setLoader] = useState(location.pathname.includes("add") ? false : true);

    const {
        state: { propertyDetails, brand: brandData },
        dispatch: { storePropertiesUnitId },
    } = useContext(Context);

    const brandId = brandData?.id;

    const {
        data: getPages,
        refetch,
        error,
        loading,
    } = useQuery(GET_PROPERTY, {
        skip: !brandId,
        fetchPolicy: "no-cache",
        variables: {
            page_id: location?.state?.pageId || "",
        },
    });

    useEffect(() => {
        const pathName = history.location.pathname;
        if (error?.message === "Page not found." && !words(pathName, "/manage-properties/add")[0]) {
            history.push("/manage-properties");
        }
        if (params.slug && getPages) {
            const data = getPages.getSpecificBrandPageById;
            const state = propertyDetails.payload;

            if (!isEmpty(data)) {
                const containers = data.containers;

                if (containers) {
                    storePropertiesUnitId({
                        type: "UNIT_ID",
                        payload: {
                            ...state,
                            overviewId: containers[0].id,
                            unitId: containers[1].id,
                            propertyType: containers[0].field_collections[0].fields.filter(
                                (item) => item.name === "PROPERTY-TYPE"
                            )[0].value,
                            mainOverview: containers[0].field_collections[0].fields,
                            mainOverviewId: containers[0].field_collections[0].id,
                            unitData: containers[1],
                            brandId,
                        },
                    });
                }
            }
            setLoader(false);
        } else {
            const state = propertyDetails.payload;

            storePropertiesUnitId({
                type: "UNIT_ID",
                payload: {
                    ...state,
                    brandId,
                },
            });
        }

        return () => {
            const state = propertyDetails.payload;

            storePropertiesUnitId({
                type: "UNIT_ID",
                payload: {
                    ...state,
                    mainOverview: [],
                    overviewId: "",
                    unitId: "",
                    propertyType: "",
                    unitData: [],
                },
            });
        };
    }, [getPages, error]);

    const handleRefresh = () => {
        setTimeout(() => {
            refetch();
        }, 500);
    };

    return (
        <div>
            <Spin spinning={loader || loading}>
                <PropertiesOverviewForms handleRefresh={handleRefresh} />

                {params.slug && <UnitTabsForms handleRefresh={handleRefresh} />}
            </Spin>
        </div>
    );
};

export default PropertiesForms;
