export const OverDetailsTemp = {
    order: 0,
    name: "OVERVIEW-DETAILS",
    fields: [
        {
            order: 0,
            label: "Property Status",
            name: "PROPERTY-STATUS",
            value: null,
        },
        {
            order: 1,
            label: "Title",
            name: "PROPERTY-TITLE",
            value: null,
        },
        {
            order: 2,
            label: "Property Details",
            name: "PROPERTY-DETAILS",
            value: null,
        },
    ],
    children: [
        {
            order: 0,
            name: "IMAGES-GALLERY",
        },
        {
            order: 1,
            name: "VIDEOS",
            fields: [
                {
                    order: 0,
                    label: "video",
                    name: "VIDEO",
                    value: null,
                },
            ],
        },
        {
            order: 2,
            name: "PANORAMAS",
            fields: [
                {
                    order: 0,
                    label: "panorama",
                    name: "PANORAMA",
                    value: null,
                },
            ],
        },
    ],
};

export const LocationLandmarkTemp = {
    order: 1,
    name: "LOCATION-LANDMARKS",
    fields: [
        {
            order: 0,
            label: "Location (Default)",
            name: "LOCATION-DEFAULT",
            value: null,
        },
        {
            order: 1,
            label: "Location (Specific)",
            name: "LOCATION-SPECIFIC",
            value: null,
        },
        {
            order: 2,
            label: "Location (Brief)",
            name: "LOCATION-BRIEF",
            value: null,
        },
        {
            order: 3,
            label: "Region",
            name: "REGION",
            value: null,
        },
        {
            order: 4,
            label: "Longitude",
            name: "LONGITUDE",
            value: null,
        },
        {
            order: 5,
            label: "Longitude",
            name: "LATITUDE",
            value: null,
        },
        {
            order: 6,
            label: "Landmarks (Default)",
            name: "LANDMARKDS-DEFAULT",
            value: null,
        },
    ],
    children: [
        {
            order: 0,
            name: "LANDMARKS-ESTABLISHMENT",
        },
        {
            order: 1,
            name: "LOCATION-IMAGES",
        },
        {
            order: 2,
            name: "LOCATION-VIDEOS",
        },
        {
            order: 3,
            name: "LOCATION-PANORAMAS",
        },
    ],
};

export const AmenitiesTemp = {
    order: 2,
    name: "AMENITIES",
    fields: [
        {
            order: 0,
            label: "Amenities (Default)",
            name: "AMENITIES-DEFAULT",
            value: null,
        },
        {
            order: 1,
            label: "Title",
            name: "TITLE",
            value: null,
        },
        {
            order: 2,
            label: "Details",
            name: "PROPERTY-DETAILS",
            value: null,
        },
        {
            order: 3,
            label: "SEO Amenities Title",
            name: "SEO-AMENITIES-TITLE",
            value: null,
        },
        {
            order: 4,
            label: "SEO Amenities Description",
            name: "SEO-AMENITIES-DESCRIPTION",
            value: null,
        },
    ],
    children: [
        {
            order: 0,
            name: "AMENITY",
            fields: [
                {
                    order: 0,
                    label: "Amenity Name",
                    name: "AMENITY-NAME",
                    value: null,
                },
            ],
            children: [
                {
                    order: 0,
                    name: "IMAGES",
                },
            ],
        },
    ],
};

export const FloorPlansTemp = {
    order: 3,
    name: "FLOOR-PLANS",
    fields: [
        {
            order: 0,
            label: "Unit & Floor Plans (Default)",
            name: "UNIT-FLOOR-PLANS-DEFAULT",
            value: null,
        },
        {
            order: 1,
            label: "Image Gallery of Unit & Floor Plans (Default)",
            name: "IMAGE-GALLERY-DEFAULT",
            value: null,
        },
        {
            order: 2,
            label: "Unit Size Range (Lowest)",
            name: "UNIT-SIZE-RANGE-LOWEST",
            value: null,
        },
        {
            order: 3,
            label: "Unit Size Range (Highest)",
            name: "UNIT-SIZE-RANGE-HIGHEST",
            value: null,
        },
        {
            order: 4,
            label: "No. of Floors",
            name: "NO-OF-FLOORS",
            value: null,
        },
        {
            order: 5,
            label: "No. of Residential Units",
            name: "NO-OF-RESIDENTIAL-UNITS",
            value: null,
        },
        {
            order: 6,
            label: "Body",
            name: "BODY",
            value: "",
        },
    ],
    children: [
        {
            order: 0,
            name: "IMAGE-GALLERY",
        },
        {
            order: 1,
            name: "UNITS-FLOOR-PLANS",
            children: [
                {
                    order: 0,
                    name: "UNIT-PLAN",
                    fields: [
                        {
                            order: 0,
                            label: "Unit Floor Plan",
                            name: "UNIT-PLAN-FLOOR-PLAN",
                            value: null,
                        },
                        {
                            order: 1,
                            label: "Unit Floor Plan Details",
                            name: "DETAILS",
                            value: "",
                        },
                        {
                            order: 2,
                            label: "Unit Floor Plan Total Area",
                            name: "TOTAL-AREA",
                            value: null,
                        },
                    ],
                    children: [
                        {
                            order: 0,
                            name: "IMAGE-GALLERY",
                        },
                        {
                            order: 1,
                            name: "AREA",
                            fields: [
                                {
                                    order: 0,
                                    label: "Unit Floor Plan Area Name",
                                    name: "AREA-NAME",
                                    value: null,
                                },
                                {
                                    order: 1,
                                    label: "Unit Floor Plan In sqm",
                                    name: "IN-SQM",
                                    value: null,
                                },
                                {
                                    order: 2,
                                    label: "Unit Floor Plan In sqft",
                                    name: "IN-SQFT",
                                    value: null,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            order: 2,
            name: "VIDEOS",
            fields: [
                {
                    order: 0,
                    label: "video",
                    name: "VIDEO",
                    value: null,
                },
            ],
        },
        {
            order: 3,
            name: "PANORAMAS",
            fields: [
                {
                    order: 0,
                    label: "panorama",
                    name: "PANORAMA",
                    value: null,
                },
            ],
        },
    ],
};

export const ConstructionPlansTemp = {
    order: 4,
    name: "CONSTRUCTION-UPDATES",
    fields: [
        {
            order: 0,
            label: "Construction Updates (Default)",
            name: "CONSTRUCTION-UPDATES-DEFAULT",
            value: null,
        },
        {
            order: 1,
            label: "Construction Start Date",
            name: "START-DATE",
            value: null,
        },
        {
            order: 2,
            label: "Percentage",
            name: "PERCENTAGE",
            value: null,
        },
        {
            order: 3,
            label: "Construction End Date",
            name: "CONSTRUCTION-END-DATE",
            value: null,
        },
        {
            order: 4,
            label: "Details",
            name: "DETAILS",
            value: null,
        },
    ],
    children: [
        {
            order: 0,
            name: "IMAGE-GALLERY",
        },
        {
            order: 1,
            name: "VIDEOS",
            fields: [
                {
                    order: 0,
                    label: "video",
                    name: "VIDEO",
                    value: null,
                },
            ],
        },
        {
            order: 2,
            name: "PANORAMAS",
            fields: [
                {
                    order: 0,
                    label: "panorama",
                    name: "PANORAMA",
                    value: null,
                },
            ],
        },
        {
            order: 3,
            name: "CONSTRUCTIONS",
            fields: [
                {
                    order: 0,
                    label: "Construction Update",
                    name: "CONSTRUCTION-UPDATE",
                    value: null,
                },
                {
                    order: 1,
                    label: "Details",
                    name: "DETAILS",
                    value: null,
                },
            ],
        },
    ],
};

export const towerContainer = [
    {
        order: 0,
        name: "NEW-UNIT",
        children: [
            {
                ...OverDetailsTemp,
            },
            {
                ...LocationLandmarkTemp,
            },
            {
                ...AmenitiesTemp,
            },
            {
                ...FloorPlansTemp,
            },
            {
                ...ConstructionPlansTemp,
            },
        ],
    },
];
