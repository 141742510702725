export const columns = [
    {
        title: "RoleID",
        dataIndex: "id",
        sorter: true,
    },
    {
        title: "Role Name",
        dataIndex: "name",
        sorter: true,
    },
    {
        title: "Brand",
        dataIndex: "brand",
    },
    {
        title: "Date Created",
        dataIndex: "createdAt",
        sorter: true,
    },
    {
        title: "Date Modified",
        dataIndex: "updatedAt",
        sorter: true,
    },
];
