import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import _ from "lodash";

import { message } from "antd";

import ContentContainer from "../../../../../../components/ContentContainer";
import Forms from "./form";

import { Context } from "../../../../../../context";

import { REMOVE_MULTIPLE_FC, REPLACE_FIELD_COLLECTION } from "../../../../graphql";
import { defaultFields, validationSchema } from "./constant";
import generateId from "../../../../../../util/generate-id";

import { imageMapper, textLinkMapper } from "../unit-tabs-utils";

interface Props {
    handleRefresh: () => void;
}

const LocationsForms = ({ handleRefresh }: Props): JSX.Element => {
    const [replaceFCFields] = useMutation(REPLACE_FIELD_COLLECTION);
    const [removeFC] = useMutation(REMOVE_MULTIPLE_FC);
    const [loader, setLoader] = useState(false);
    const [fieldInfo, setFieldInfo] = useState({
        parentId: "",
        fcId: "",
    });
    const [initialValues, setInitialValues] = useState(defaultFields);
    const [reinitialize, setReinitialize] = useState(false);
    const {
        state: { propertyUnitForms, brand: brandData },
    } = useContext(Context);

    useEffect(() => {
        if (reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        const data = propertyUnitForms.payload.locationsLandmarks;
        if (_.has(data, "fields") && data.fields) {
            const fields: any = defaultFields;

            data.fields.forEach((res) => {
                fields[_.camelCase(res.name)] = res.value ? res.value : "";
            });

            if (!_.isEmpty(data.children[0].children)) {
                const fieldList: any = [];
                data.children[0].children.forEach((f) => {
                    const fieldData = f.fields;
                    const data = {
                        establishmentName: fieldData[0].value ? fieldData[0].value : "",
                        distance: fieldData[1].value ? fieldData[1].value : "",
                        location: fieldData[2].value ? fieldData[2].value : "",
                        latitude: fieldData[3].value ? fieldData[3].value : "",
                        longitude: fieldData[4].value ? fieldData[4].value : "",
                        body: fieldData[5].value ? fieldData[5].value : "",
                        images: [],
                        imagesCaption: [],
                    };

                    if (!_.isEmpty(f.children[0].fields)) {
                        data["images"] = f.children[0].fields.map((img) => ({
                            itemId: generateId(),
                            caption: img.meta_data.description,
                            isChecked: img.meta_data.featured,
                            url: img.value,
                            keyObj: img.meta_data.key,
                            status: "done",
                            altText: img.meta_data.alt_text,
                        }));
                        data["imagesCaption"] = f.children[0].fields.map(
                            (img) => img.meta_data.description
                        );
                    }

                    fieldList.push(data);
                });
                fields["landmarksContents"] = fieldList;
            } else {
                fields["landmarksContents"] = [
                    {
                        establishmentName: "",
                        distance: "",
                        location: "",
                        latitude: "",
                        longitude: "",
                        body: "",
                        images: [],
                    },
                ];
            }

            if (!_.isEmpty(data.children[1].fields)) {
                fields["locationImages"] = data.children[1].fields.map((img) => {
                    fields.locationImagesCaptions.push(img.meta_data.description);
                    return {
                        itemId: generateId(),
                        caption: img.meta_data.description,
                        isChecked: img.meta_data.featured,
                        url: img.value,
                        keyObj: img.meta_data.key,
                        status: "done",
                        altText: img.meta_data.alt_text,
                    };
                });
            } else {
                fields["locationImages"] = [];
                fields["locationImagesCaptions"] = [];
            }

            if (!_.isEmpty(data.children[2].fields)) {
                fields["videos"] = data.children[2].fields.map((media) => media.value);
            } else {
                fields["videos"] = [""];
            }

            if (!_.isEmpty(data.children[3].fields)) {
                fields["panoramas"] = data.children[3].fields.map((media) => media.value);
            } else {
                fields["panoramas"] = [""];
            }

            setFieldInfo({
                parentId: data.parentId,
                fcId: data.id,
            });
            setInitialValues(fields);
            setReinitialize(true);
        }
    }, [propertyUnitForms.payload]);

    const contentMapper = (values) =>
        values.map((formItem, formItemIndex) => {
            const data = {
                order: formItemIndex,
                name: "ESTABLISHMENT",
                fields: [
                    {
                        order: 0,
                        label: "establishment name",
                        name: "ESTABLISHMENT-NAME",
                        value: formItem.establishmentName ? formItem.establishmentName : null,
                    },
                    {
                        order: 1,
                        label: "Distance (km)",
                        name: "ESTABLISHMENT-DISTANCE",
                        value: formItem.distance ? formItem.distance : null,
                    },
                    {
                        order: 2,
                        label: "Location",
                        name: "ESTABLISHMENT-LOCATION",
                        value: formItem.location ? formItem.location : null,
                    },
                    {
                        order: 3,
                        label: "Latitude",
                        name: "ESTABLISHMENT-LATITUDE",
                        value: formItem.latitude ? _.toString(formItem.latitude) : null,
                    },
                    {
                        order: 4,
                        label: "Longitude",
                        name: "ESTABLISHMENT-LONGITUDE",
                        value: formItem.longitude ? _.toString(formItem.longitude) : null,
                    },
                    {
                        order: 5,
                        label: "Body",
                        name: "ESTABLISHMENT-BODY",
                        value: formItem.body ? formItem.body : null,
                    },
                ].filter(Boolean),
                children: [
                    {
                        order: 0,
                        name: "IMAGES-GALLERY",
                    },
                ],
            };

            if (!_.isEmpty(formItem.images)) {
                data["children"][0]["fields"] = imageMapper(formItem.images);
            }

            return data;
        });

    const handleSubmit = async (values) => {
        setLoader(true);

        const field_collection: any = {
            order: 1,
            parentId: fieldInfo.parentId,
            name: "LOCATION-LANDMARKS",
            fields: [
                {
                    order: 0,
                    label: "Location (Default)",
                    name: "LOCATION-DEFAULT",
                    value: values.locationDefault ? values.locationDefault : null,
                },
                {
                    order: 1,
                    label: "Location (Specific)",
                    name: "LOCATION-SPECIFIC",
                    value: values.locationSpecific ? values.locationSpecific : null,
                },
                {
                    order: 2,
                    label: "Location (Brief)",
                    name: "LOCATION-BRIEF",
                    value: values.locationBrief ? values.locationBrief : null,
                },
                {
                    order: 3,
                    label: "Region",
                    name: "REGION",
                    value: values.region ? values.region : null,
                },
                {
                    order: 4,
                    label: "Longitude",
                    name: "LONGITUDE",
                    value: values.longitude ? _.toString(values.longitude) : null,
                },
                {
                    order: 5,
                    label: "Longitude",
                    name: "LATITUDE",
                    value: values.latitude ? _.toString(values.latitude) : null,
                },
                {
                    order: 6,
                    label: "Landmarks (Default)",
                    name: "LANDMARKDS-DEFAULT",
                    value: values.landmarkdsDefault ? values.landmarkdsDefault : null,
                },
                {
                    order: 7,
                    label: "Seo Title",
                    name: "SEO-LOCATION-TITLE",
                    value: values.seoLocationTitle ? values.seoLocationTitle : null,
                },
                {
                    order: 8,
                    label: "Seo Description",
                    name: "SEO-LOCATION-DESCRIPTION",
                    value: values.seoLocationDescription ? values.seoLocationDescription : null,
                },
                {
                    order: 9,
                    label: "H1 Tag",
                    name: "LOCATION-HEADING-TAG",
                    value: values.locationHeadingTag ? values.locationHeadingTag : null,
                },
                brandData?.name === "ALP" && {
                    order: 10,
                    label: "H2 Tag",
                    name: "SUB-HEADING",
                    value: values.subHeading ? values.subHeading : null,
                },
            ].filter(Boolean),
            children: [
                {
                    order: 0,
                    name: "LANDMARKS-ESTABLISHMENT",
                },
                {
                    order: 1,
                    name: "LOCATION-IMAGES",
                },
                {
                    order: 2,
                    name: "LOCATION-VIDEOS",
                },
                {
                    order: 3,
                    name: "LOCATION-PANORAMAS",
                },
            ],
        };

        if (!_.isEmpty(values.landmarksContents)) {
            field_collection["children"][0]["children"] = contentMapper(values.landmarksContents);
        }

        if (!_.isEmpty(values.locationImages)) {
            field_collection["children"][1]["fields"] = imageMapper(values.locationImages);
        }

        if (!_.isEmpty(values.videos)) {
            field_collection["children"][2]["fields"] = textLinkMapper(values.videos, "videos");
        }

        if (!_.isEmpty(values.panoramas)) {
            field_collection["children"][3]["fields"] = textLinkMapper(values.videos, "panoramas");
        }

        await replaceFCFields({
            variables: {
                collectionData: {
                    collections: [field_collection],
                },
                oldFcIds: {
                    ids: [fieldInfo.fcId],
                },
            },
        });

        handleRefresh();
        setTimeout(() => {
            message.success("Update Success");
            setLoader(false);
        }, 1000);
    };

    return (
        <ContentContainer
            style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1rem",
                position: "relative",
            }}
        >
            {!reinitialize && (
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={reinitialize}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleSubmit(values)}
                >
                    {(formikBag) => (
                        <Forms
                            {...{
                                formikBag,
                                loading: loader,
                            }}
                        />
                    )}
                </Formik>
            )}
        </ContentContainer>
    );
};

export default LocationsForms;
