import React, { useContext, useState } from "react";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import findIndex from "lodash/findIndex";
import has from "lodash/has";

import { Col, Row, Upload, Input, Spin, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { convertFileToBase64 } from "../../util/convert-to-base64";

import {
    StyledCard,
    StyledFeaturedCheckbox,
    AddBtn,
    DeleteBtn,
    BrokenImageContainer,
    ImageNotScannedContainer,
    VideoWrapper,
    AltTextInput,
} from "./styled";

import ImageIcon from "../../assets/image-icon.png";
import { Context } from "../../context";
import { getAcceptedFiles } from "../../util/get-accepted-filetypes";

const INTERNAL_SERVER_ERROR = 500;
const UNPROCESSABLE_ENTITY = 422;
const SCAN_ERROR_STATUS = 403;
const INFECTED = "infected";
const NOT_SCANNED = "not_scanned";
const BAD_REQUEST = 400;
let BASE_URL = `${process.env.REACT_APP_CONTENT_URL}/uploads/images`;

interface Props {
    fileList: any;
    captionList?: string[];
    onChange?: any;
    validate?: boolean;
    path?: string;
    sizeLimit?: number;
    isVideoAllowed?: boolean;
    imageAltText?: boolean;
}

const FeaturedImages = ({
    fileList,
    onChange,
    validate,
    path,
    sizeLimit,
    captionList,
    isVideoAllowed,
    imageAltText,
}: Props): JSX.Element => {
    const {
        state: { brand: brandData },
    } = useContext(Context);
    const brandName = brandData?.name;

    const deleteItem = (key: number, list: any) => {
        const newList = list.filter((value, index) => index !== key);
        const findCheck = newList.filter((res) => res.isChecked === true);

        if (isEmpty(findCheck) && !isEmpty(newList)) {
            newList[0].isChecked = true;
        }
        onChange(newList);
    };

    const handleChecked = (value: number) => {
        const newList = fileList.map((res, key) => {
            if (key === value) {
                res["isChecked"] = true;
                return res;
            } else {
                res["isChecked"] = false;
                return res;
            }
        });
        onChange(newList);
    };

    const handleOnChange = async ({ file, fileList: list }) => {
        const limit = !!sizeLimit ? sizeLimit : 49;
        const isLt2M = file.size / 1024 / 1024 < limit;

        if (file.status !== "uploading" && has(file, "response")) {
            const base64 = await convertFileToBase64(file.originFileObj);
            const index = await findIndex(list, (e) => e.uid === file.uid);
            list[index]["keyObj"] = file.response.key;
            list[index]["thumbUrl"] = base64;
            onChange(list);
        }

        if (file.status === "uploading" && isLt2M) {
            const index = await findIndex(list, (e) => e.uid === file.uid);
            list[index]["isChecked"] = list.length === 1 && true;
            list[index]["caption"] = "";
            list[index]["altText"] = "";
            list[index]["thumbUrl"] = "";
            list[index]["keyObj"] = "";
            list[index]["id"] = "";
            onChange(list);
        }
    };

    const [infectedFileId, setInfectedFileId] = useState(null);
    const [isNotScannedId, setIsNotScannedId] = useState(null);

    const imgOrVidElelement = (item) => {
        const defaultRegExp = /\.(gif|jpe?g|png|gif)$/i;
        const avidaRegExp = /\.(gif|jpe?g|png|gif|webp)$/i;

        const regExp = brandName === "AVIDA" ? avidaRegExp : defaultRegExp;
        const imgTypePattern = new RegExp(regExp);

        const imgFileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

        if (brandName === "AVIDA") {
            const avidaAdditionalTypes = [
                "image/webp",
                // , "image/avif"
            ];
            imgFileTypes.push(...avidaAdditionalTypes);

            return (
                <>
                    {imgTypePattern.test(item.url) || imgFileTypes.includes(item?.type) ? (
                        <img src={item.url || item.thumbUrl} alt="img" />
                    ) : (
                        <VideoWrapper>
                            <video className="video-banner" src={item.url} controls />
                        </VideoWrapper>
                    )}
                </>
            );
        }

        return <img src={item.url || item.thumbUrl} />;
    };

    const handleRequest = (option) => {
        setInfectedFileId(null);
        setIsNotScannedId(null);
        const formData = new FormData();
        formData.append("file", option.file);

        axios({
            method: "POST",
            url: BASE_URL,
            data: formData,
        })
            .then((res) => {
                const { data } = res;
                option.onSuccess(res.data, option.file);

                if (data.scanErrorStatus && data.scanErrorStatus === SCAN_ERROR_STATUS) {
                    setIsNotScannedId(option.file.uid);
                }
            })
            .catch((err) => {
                const { status } = err.response;

                if (status === BAD_REQUEST) {
                    const { data } = err.response;
                    message.error(data.message);
                }

                if (status === INTERNAL_SERVER_ERROR) {
                    message.error("Oops, Something went wrong");
                }

                if (status === UNPROCESSABLE_ENTITY) {
                    setInfectedFileId(option.file.uid);
                    message.error("Upload Failed, File is infected, Please upload another file.");
                }
            });
    };

    const handleBeforeUpload = (file) => {
        // Check file type
        const acceptedFiles = getAcceptedFiles(brandName, isVideoAllowed);
        const fileTypesList = [...acceptedFiles.split(",")];

        if (fileTypesList.includes("video/mp4")) {
            BASE_URL = `${process.env.REACT_APP_CONTENT_URL}/upload/objects`;
        }

        if (!fileTypesList.includes(file.type)) {
            const errMessage = fileTypesList
                .map((file) => {
                    return file.replace("image/", "").toUpperCase();
                })
                .join("/");

            message.error(`You can only upload ${errMessage} file type`);
            return false;
        }

        const limit = !!sizeLimit ? sizeLimit : 49;
        const isLt2M = file.size / 1024 / 1024 < limit;

        if (!isLt2M) {
            message.error("File is too big");
            return false;
        }
        return file;
    };

    const renderItems = (list) => {
        list = list.map((val) => {
            if (val.uid === infectedFileId && infectedFileId) {
                val.status = INFECTED;
            }

            if (val.uid === isNotScannedId && isNotScannedId) {
                val.file_status = NOT_SCANNED;
            }

            return val;
        });

        return list.map((item, index) => (
            <Col key={`feature_images_${index}`}>
                <Spin spinning={item.status === "uploading"}>
                    <StyledCard
                        key={index}
                        checked={item.isChecked}
                        cover={
                            <div className="featuredCont">
                                <div className="imgCont">
                                    {list[index].status === INFECTED ? (
                                        <BrokenImageContainer>
                                            <img src={ImageIcon} alt="img" />
                                            <p>
                                                The file is infected, Saving this image is disabled.{" "}
                                            </p>
                                        </BrokenImageContainer>
                                    ) : list[index].file_status === NOT_SCANNED ? (
                                        <ImageNotScannedContainer>
                                            {imgOrVidElelement(item)}
                                            <p>
                                                File was uploaded but was not scanned, Please
                                                contact your system administrator.{" "}
                                            </p>
                                        </ImageNotScannedContainer>
                                    ) : (
                                        imgOrVidElelement(item)
                                    )}
                                </div>
                                {list[index].status !== INFECTED && (
                                    <StyledFeaturedCheckbox
                                        name="featuredCheck"
                                        className="checkbox"
                                        checked={item.isChecked}
                                        onChange={() => handleChecked(index)}
                                    >
                                        Featured
                                    </StyledFeaturedCheckbox>
                                )}
                            </div>
                        }
                        extra={
                            <DeleteBtn type="link" onClick={(e) => deleteItem(index, list)}>
                                <CloseOutlined
                                    className={`remove-btn ${
                                        item.isChecked ? "active-checked" : ""
                                    }`}
                                />
                            </DeleteBtn>
                        }
                    >
                        {imageAltText && (
                            <AltTextInput
                                id="altText"
                                onChange={(input) => {
                                    list[index]["altText"] = input.target.value;
                                    onChange(list);
                                }}
                                value={item.altText}
                                placeholder="Alt text"
                            />
                        )}
                        <Input.TextArea
                            disabled={list[index].status === INFECTED}
                            onResize={() => false}
                            autoSize={false}
                            value={captionList ? captionList[index] : list[index].caption}
                            onChange={(input) => {
                                list[index]["caption"] = input.target.value;
                                onChange(list);
                            }}
                            placeholder="Description"
                        />
                    </StyledCard>
                </Spin>
            </Col>
        ));
    };

    return (
        <Row gutter={16}>
            {renderItems(fileList)}
            <Upload
                action={BASE_URL}
                showUploadList={false}
                customRequest={handleRequest}
                onChange={handleOnChange}
                beforeUpload={handleBeforeUpload}
                accept={getAcceptedFiles(brandName, isVideoAllowed)}
                fileList={fileList}
            >
                {validate && (
                    <Col>
                        <AddBtn type="dashed">
                            + Add Image {isVideoAllowed ? " / Video" : ""}
                        </AddBtn>
                    </Col>
                )}
            </Upload>
        </Row>
    );
};

export default FeaturedImages;
