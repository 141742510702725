import { useHistory, useParams } from "react-router-dom";

import React, { useContext, useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import _ from "lodash";
import upperCase from "lodash/upperCase";
import lowerCase from "lodash/lowerCase";
import { Formik } from "formik";
import { Context } from "../../../../../context";

import { CREATE_PAGE, CREATE_CONTENT, GET_PAGE, REMOVE_MULTI_CONTAINER } from "../../../graphql";
import Form from "./forms";
import { Spin, message } from "antd";
import * as yup from "yup";

const validationSchema = yup.object().shape({
    seoHomeTitle: yup.string(),
    seoHomeDescription: yup.string(),
    homeHeadingTag: yup.string(),
});

function FooterLinkForm({ category, brandName, handleRefresh }) {
    const history = useHistory();
    const { id } = useParams();

    const {
        state: { pageDetails },
    } = useContext(Context);

    const [initalValue, setInitialValue] = useState<object>({
        items: [{ text: "", link: "", isLink: "2" }],
    });
    const [createPage] = useMutation(CREATE_PAGE);
    const [createContent] = useMutation(CREATE_CONTENT);
    const [removeMultiContainer] = useMutation(REMOVE_MULTI_CONTAINER);

    const [reinitialize, setReinitialize] = useState(false);
    const [loader, setLoader] = useState(false);
    const [fieldInfo, setFieldInfo] = useState<any>(null);
    const [getPage, { data: pageData }] = useLazyQuery(GET_PAGE, {
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (!reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        const data = pageDetails.payload;
        if (data.brandId) {
            getPage({
                variables: {
                    brand_id: data.brandId,
                    tag: `COMPANY-INFO-${upperCase(data.brandName)}`,
                    slug: `footer-links-${lowerCase(data.brandName)}`,
                },
            });
        }
    }, [pageDetails.payload, getPage]);

    useEffect(() => {
        if (pageData && !_.isEmpty(pageData.getSpecificBrandPage)) {
            const data = pageData.getSpecificBrandPage.containers[0].field_collections;

            const containers = pageData.getSpecificBrandPage.containers
                ? pageData.getSpecificBrandPage.containers
                : null;
            const containerIds = containers ? containers.map(({ id }) => id) : [];

            let fields: any = {
                items: [],
            };

            if (data[0].fields) {
                fields["items"] = JSON.parse(data[0].fields[0]?.value) || "";
            }

            setFieldInfo({
                pageId: pageData.getSpecificBrandPage.id,
                containerId: containerIds,
                items: fields.items,
            });
            setInitialValue(fields);
            setReinitialize(true);
        }
    }, [pageData]);

    const handleSubmit = async (values) => {
        setLoader(true);

        const data = pageDetails.payload;

        if (data.brandId && data.brandName && fieldInfo) {
            await handleUpdatePage(values);
        } else {
            await handleAddPage(values);
        }

        setTimeout(() => {
            handleRefresh();
            setLoader(false);
        }, 500);
    };

    const handleAddPage = async (values) => {
        const brandInfo = pageDetails.payload;
        const pageTag = `COMPANY-INFO-${upperCase(brandInfo.brandName)}`;
        const page = await createPage({
            variables: {
                brand_id: id,
                name: `Footer Links - ${brandInfo.brandName}`,
                tag: pageTag,
            },
        });
        if (page) {
            const {
                addOrUpdateBrandPage: { id: page_id },
            } = page.data;

            const containerData = {
                page_id,
                name: `Footer Links Container - ${brandInfo.brandName}`,
                order: 0,
                field_collections: [
                    {
                        name: "Footer Links FC",
                        order: 0,
                        fields: [
                            {
                                order: 0,
                                label: "items",
                                name: "ITEMS",
                                value: JSON.stringify(values.items)
                                    ? JSON.stringify(values.items)
                                    : "",
                            },
                        ],
                    },
                ],
            };

            const container = await createContent({
                variables: {
                    data: {
                        containers: [containerData],
                    },
                },
            });

            container && message.success("SAVED!");
        }
    };

    const handleUpdatePage = async (values) => {
        const brandInfo = pageDetails.payload;
        const containerData = {
            page_id: fieldInfo.pageId,
            name: `Footer Links Container - ${brandInfo.brandName}`,
            order: 0,
            field_collections: [
                {
                    name: "Footer Links FC",
                    order: 0,
                    fields: [
                        {
                            order: 0,
                            label: "items",
                            name: "ITEMS",
                            value: JSON.stringify(values.items) ? JSON.stringify(values.items) : "",
                        },
                    ],
                },
            ],
        };

        const container = await createContent({
            variables: {
                data: {
                    containers: [containerData],
                },
            },
        });

        if (!_.isEmpty(fieldInfo.containerId)) {
            await removeMultiContainer({
                variables: {
                    data: {
                        ids: fieldInfo.containerId,
                    },
                },
            });
        }

        container && message.success("Update success.");
    };
    return (
        <Spin spinning={loader}>
            <Formik
                initialValues={initalValue}
                validationSchema={validationSchema}
                enableReinitialize={reinitialize}
                onSubmit={(values: any) => {
                    handleSubmit(values);
                }}
                render={(formikBag) => (
                    <Form
                        {...{
                            formikBag,
                        }}
                    />
                )}
            />
        </Spin>
    );
}

export default FooterLinkForm;
