import React, { useEffect, useState, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import lowerCase from "lodash/lowerCase";
import upperCase from "lodash/upperCase";
import kebabCase from "lodash/kebabCase";
import find from "lodash/find";

import filter from "lodash/filter";


// import isEmpty from "lodash/isEmpty";

import { CompanyInfo } from "../../../../entities/CompanyInfo";
import { CompanyInfoMapper } from "../../dto/CompanyInfoMapper";
import ContentContainer from "../../../../components/ContentContainer";
import CustomTable from "../../../../components/CustomTable";
import { userPermission } from "../../../../util/user-access";


import { columns as columnsConstant } from "./constant";

import { Context } from "../../../../context";

import { GET_PAGES_LIST, GET_BRANDS } from "../../graphql";
import { message } from "antd";

const CompanyInfoList: React.FC = () => {
    const history = useHistory();
    let match = useRouteMatch();

    const [columns, setColumns] = useState<object[]>(columnsConstant);
    const [pageList, setPageList] = useState<object[]>([]);
    // const [brandsList, setBrandsList] = useState<any>([]);
    const [cascaderOptions, setCascaderOptions] = useState<any>([]);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const { state : { brand: brandData, user } }= useContext(Context);
    const brandId = brandData?.id;
    const brandName = brandData?.name;
    const permissions = user?.roles[0].permissions;
    const createAccess = userPermission(permissions, "create:company-information");
    const updateAccess = userPermission(permissions, "update:company-information");
    const [brand, setBrand] = useState<string>(brandId);
    const [tag, setTag] = useState<string>(`COMPANY-INFO-${brandName}`);
    const [search, setSearch] = useState<string>("");
    const [sortBy, setSortBy] = useState<string>("updated_at");
    const [sortOrder, setSortOrder] = useState<string>("DESC");

    const { data: data, loading, error } = useQuery(GET_PAGES_LIST, {
        skip: !brandId,
        variables: {
            brand_id: brand,
            search: search,
            tag: tag,
            page: page,
            limit: pageSize,
            sortBy: sortBy,
            order: sortOrder
        },
        fetchPolicy: "no-cache",
    });

    // const [getBrands, {loading: brandsLoading, data: brandsData}] = useLazyQuery(GET_BRANDS);

    const handleAddBtn = (e: any) => {
        history.push(`${match.path}/about-us/${e[0]}`);
    };

    const handleSearch = (query) => {
        setSearch(query);
    };

    const handleSort = (field, order) => {
        setSortBy(field);
        setSortOrder(upperCase(order));
    };

    // const handleFilter = (filters) => {
    //     filters.brand && setBrand(filters.brand[0]);
    //     filters.brand && setTag(brandsList[brandsList.findIndex(brand => brand.id === filters.brand[0])].tag);
    // }

    const handleSelectedRow = (record) => {
        // const tab = kebabCase(lowerCase(record.name.replace(` - ${record.brand}`, "")));
        const constructName =  record.name.split('-');
        const tab =  kebabCase(constructName[0]);
        history.push(`${match.path}/${tab}/${brandId}`);
    };

    const handlePageSize = async (pageSize) => {
        setPageSize(pageSize);
    };

    const handlePagination = async (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    useEffect(() => {
        if (data) {
            const { pages } = data.searchPages;
            // const brand = brandName === "ALISI" ? brandsList : brandName;
            const res = pages.map((info: CompanyInfo) => CompanyInfoMapper.map(info, brandName));
            const dashboardPage = res.map(e => ({...e, permissionName: e.name.split(" ")[0]}));
            const companyInfoPermission = filter(permissions,  (e) => (e.scope.includes('manage:company-information')));
            const dashboardList  = filter(dashboardPage, (result) => (
              find(companyInfoPermission, (e) => (e.scope.includes(lowerCase(result.permissionName)))))
            )
            setPageList(dashboardList);
        }

        setCascaderOptions([{
          label: brandName,
          value: brandId
        }]);

        if (error) {
            message.error("Something went wrong.");
        }
    }, [data, error]);

    // useEffect(() => {
    //     if (brandName === "ALISI" && isEmpty(brandsList)) {
    //         !brandsData && getBrands();

    //         if (!brandsLoading && brandsData) {
    //             const { getAllBrands } = brandsData;

    //             const brands = getAllBrands.map((brand) => ({
    //                 name: brand.name,
    //                 id: brand.id
    //             }));
    //             setBrandsList(brands);

    //             const filters = getAllBrands.map((brand) => ({
    //                 text: brand.name,
    //                 value: brand.id
    //             }));
    //             const newColumns: any = columns;
    //             newColumns.find((brand) => brand.dataIndex === "brand").filters = filters;
    //             setColumns(newColumns);
    //         }
    //     }
    // }, [brandsData]);

    return (
        <ContentContainer style={{ flexDirection: "column" }}>
            <CustomTable
                updateAccess={updateAccess}
                headingText="Company Information"
                labelText="Information"
                noStatusBtn={true}
                handleSearch={handleSearch}
                addBtnCascader={true}
                addBtnCascaderOptions={cascaderOptions}
                handleAddBtn={handleAddBtn}
                columns={columns}
                noAddBtn={!createAccess}
                dataSource={pageList}
                currentPageSize={pageSize}
                handlePageSize={handlePageSize}
                handlePagination={handlePagination}
                handleSort={handleSort}
                handleSelectedRow={handleSelectedRow}
                loading={loading}
            />
        </ContentContainer>
    );
};

export default CompanyInfoList;
