import { FormValueType, FieldInfoType, InfoFieldChildType } from "./construction-plans.types";

export const INIT_VALUES: FormValueType = {
    constructionUpdatesDefault: "",
    startDate: "",
    percentage: "",
    constructionEndDate: "",
    details: "",
    seoConstructionPlanTitle: "",
    seoConstructionPlanDescription: "",
    constructionPlanHeadingTag: "",
    subHeading: "",
    imageGallery: [],
    imagesCaptions: [],
    constructions: [
        {
            id: "",
            constructionUpdate: "",
            details: "",
        },
    ],
    videos: [""],
    panoramas: [""],
};

export const INIT_CHILDREN_VALUES: InfoFieldChildType = {
    imageGalleryId: "",
    imageGallery: [],
    videosId: "",
    videos: [],
    panoramasId: "",
    panoramas: [],
    constructionsId: "",
    constructions: [],
};

export const INIT_FIELD_INFO: FieldInfoType = {
    fields: [],
    children: INIT_CHILDREN_VALUES,
};
