import styled from "styled-components";

import { Button } from "antd";

export const StyledButton = styled(Button)`
    color: #ddd;
    padding: 0;
    height: 31px;
    width: 31px;
    margin-right: 5px;

    &:last-child {
        margin-right: 0;
    }

    .anticon {
        height: 15px;
        width: 15px;
    }
`;
