import find from "lodash/find";
import includes from "lodash/includes";



export const userNavigationReadPermission = (userPermissions: any, access: string) => {
    const permissions = userPermissions ? userPermissions[0].permissions : null;
    if (!permissions) {
      return "none";
    }
    const findReadAccess = find(permissions, {'scope' : access});
    return findReadAccess ? "block" : "none";
};


export const userRoutePermission = (permissions: any, access: string) => {
  const findReadAccess = find(permissions, {'scope' : access});
  return findReadAccess ? true : false;
}


export const userPermission = (permissions: any, access: string) => {
  const findAccess = find(permissions, {'scope' : access});
  return findAccess ? true : false;
}
