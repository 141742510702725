import styled from "styled-components";
import { Button } from "antd";

export const Container = styled.div`
    position: relative;
    height: 100px;
    width: 160px;
`;

export const ButtonStyle = styled(Button)`
    height: 100px;
    width: 160px;
    padding: 0;
    overflow: hidden;
    color: #d8d8d8;
    background: #fafafa;

    img {
        height: 100px;
        width: 160px;
        object-fit: cover;
    }

    .anticon {
        svg {
            font-size: 24px;
        }
    }

    h1 {
        margin: 0;
        color: inherit;
    }
`;

export const WrapperPreview = styled.div`
    height: 100px;
    width: 160px;
    padding: 0;
    overflow: hidden;
    color: #d8d8d8;
    background: #fafafa;

    embed {
        height: 100px;
        width: 160px;
        object-fit: cover;
    }
`;

export const RemoveBtn = styled.div`
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 3px;

    .anticon {
        svg {
            font-size: 15px;
        }

        &:hover {
            color: #00714b;
        }
    }
`;
