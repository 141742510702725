import { Career } from "../../../entities/Career";

export class CareerMapper {
    static map(career: Career): Career {
        const htmlTagRegEx = /(<([^>]+)>)/gi;

        career.empType = career.employmentType && career.employmentType.name ? career.employmentType.name : "";
        career.job_description = career.job_description ? career.job_description.replace(htmlTagRegEx, " ") : "";
        
        delete career.employmentType;
        delete career.__typename;

        return career;
    }
}