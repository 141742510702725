import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { useMutation, useLazyQuery } from "@apollo/client";
import upperCase from "lodash/upperCase";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import lowerCase from "lodash/lowerCase";

import { Spin, message } from "antd";

import Form from "./forms";
import { Context } from "../../../../../context";
import { validationSchema } from "./constant";

import {
    CREATE_PAGE,
    REMOVE_FIELD,
    GET_PAGE,
    REMOVE_MULIT_FC,
    CREATE_CONTENT,
    REMOVE_MULTI_CONTAINER,
} from "../../../graphql";

import generateId from "../../../../../util/generate-id";
import fileTranslate from "../../../../../util/file-translate";

interface Props {
    handleRefresh: () => void;
}

const AwardsForm: React.FC<Props> = ({ handleRefresh }) => {
    const { id } = useParams();
    const {
        state: { pageDetails },
    } = useContext(Context);
    const [getPage, { loading: pageLoading, data: pageData }] = useLazyQuery(GET_PAGE, {
        fetchPolicy: "no-cache",
    });
    const [createPage] = useMutation(CREATE_PAGE);
    const [createContent] = useMutation(CREATE_CONTENT);
    const [removeMultiContainer] = useMutation(REMOVE_MULTI_CONTAINER);
    const [removeMultiFC] = useMutation(REMOVE_MULIT_FC);
    const [removeField] = useMutation(REMOVE_FIELD);

    const [fieldInfo, setFieldInfo] = useState<any>({
        pageId: "",
        containers: [],
        section: [],
    });
    const [loader, setLoader] = useState(false);
    const [initalValue, setInitialValue] = useState<object>({
        title: "",
        section: [
            {
                sectionId: "",
                contentFCId: "",
                imageFCId: "",
                content: [
                    {
                        id: "",
                        title: "",
                        body: "",
                    },
                ],
                images: [],
            },
        ],
    });

    useEffect(() => {
        if (pageData && has(pageData, "getSpecificBrandPage")) {
            const data = pageData.getSpecificBrandPage.containers;
            const pageId = pageData.getSpecificBrandPage.id;
            const section: any = [];
            const prevSection: any = [];
            const containers: string[] = [];
            let title: any;

            data.forEach((res, i) => {
                containers.push(res.id);
                if (i === 0) {
                    const data = res.field_collections[0].fields[0];
                    title = {
                        id: data.id,
                        value: data.value,
                    };
                } else {
                    const content: any = [];
                    const prevContent: any = [];
                    const images: any = [];

                    if (res.field_collections[0].children) {
                        res.field_collections[0].children.forEach((f) => {
                            content.push({
                                contentItemId: f.id,
                                title: f.fields[0].value ? f.fields[0].value : "",
                                body: f.fields[1].value ? f.fields[1].value : "",
                            });
                            prevContent.push({
                                contentItemId: f.id,
                                title: {
                                    id: f.fields[0].id,
                                    value: f.fields[0].value ? f.fields[0].value : "",
                                },
                                body: {
                                    id: f.fields[1].id,
                                    value: f.fields[1].value ? f.fields[1].value : "",
                                },
                            });
                        });
                    }
                    if (res.field_collections[1].fields) {
                        res.field_collections[1].fields.forEach((f) => {
                            images.push({
                                uid: generateId(),
                                url: f.value,
                                keyObj: f.meta_data.key,
                                status: "done",
                                type: f.meta_data.file_type,
                            });
                        });
                    }

                    prevSection.push({
                        sectionId: res.id,
                        contentFCId: res.field_collections[0].id,
                        imageFCId: res.field_collections[1].id,
                        content: prevContent,
                        images,
                    });
                    section.push({
                        sectionId: res.id,
                        contentFCId: res.field_collections[0].id,
                        imageFCId: res.field_collections[1].id,
                        content,
                        images,
                    });
                }
            });

            setFieldInfo({
                pageId,
                containers,
                section: prevSection,
            });
            setInitialValue({
                title: title.value,
                section,
            });
        }
    }, [pageData]);

    useEffect(() => {
        const data = pageDetails.payload;
        if (data.brandId) {
            getPage({
                variables: {
                    brand_id: data.brandId,
                    tag: `COMPANY-INFO-${upperCase(data.brandName)}`,
                    slug: `awards-${lowerCase(data.brandName)}`,
                },
            });
        }
    }, [pageDetails.payload]);

    const handleSubmit = async (values) => {
        setLoader(true);
        const data = pageDetails.payload;

        if (data.brandId && data.brandName && has(pageData, "getSpecificBrandPage")) {
            await handleUpdatePage(values);
            message.success("Update success");
        } else {
            await handleAddPage(values);
            message.success("Page successfully created");
        }

        setTimeout(() => {
            handleRefresh();
            setLoader(false);
        }, 1000);
    };

    const contentMapper = (value) =>
        value.map((field, fieldIndex) => ({
            order: fieldIndex,
            name: "CONTENT-ITEM",
            fields: [
                {
                    order: 0,
                    label: "title",
                    name: "TITLE",
                    value: field.title ? field.title : null,
                },
                {
                    order: 1,
                    label: "body",
                    name: "BODY",
                    value: field.body ? field.body : null,
                },
            ],
        }));

    const imageMapper = (value) =>
        value.map((fields, fieldIndex) => ({
            order: fieldIndex,
            label: fileTranslate(fields.type),
            name: "File",
            value: fields.keyObj,
            meta_data: {
                featured: false,
                description: "",
                key: fields.keyObj,
                file_type: fields.type,
            },
        }));

    const sectionMapper = (values, page_id) => {
        let data: any = [];

        if (!isEmpty(values)) {
            data = values.map((section, sectionIndex) => {
                let sectionData: any = {
                    order: 1 + sectionIndex,
                    page_id: page_id,
                    name: "SECTION",
                    field_collections: [
                        {
                            order: 0,
                            name: "CONTENT",
                        },
                        {
                            order: 1,
                            name: "IMAGES",
                        },
                    ],
                };

                if (!isEmpty(section.content)) {
                    sectionData.field_collections[0]["children"] = contentMapper(section.content);
                }

                if (!isEmpty(section.images)) {
                    sectionData.field_collections[1]["fields"] = imageMapper(section.images);
                }

                return sectionData;
            });
        }

        return data;
    };

    const handleAddPage = async (values) => {
        const brandInfo = pageDetails.payload;
        const pageTag = `COMPANY-INFO-${upperCase(brandInfo.brandName)}`;
        const page = await createPage({
            variables: { brand_id: id, name: `Awards - ${brandInfo.brandName}`, tag: pageTag },
        });
        let sectionData = [];

        if (page) {
            const {
                addOrUpdateBrandPage: { id: page_id },
            } = page.data;

            let containers: any = [
                {
                    order: 0,
                    page_id: page_id,
                    name: "AWARDS-DEFAULT",
                    field_collections: [
                        {
                            order: 0,
                            name: "AWARDS-DEFAULT-FC",
                            fields: [
                                {
                                    order: 0,
                                    name: "AWARDS-TITLE",
                                    label: "awards title",
                                    value: values.title ? values.title : null,
                                },
                            ],
                        },
                    ],
                },
            ];

            if (!isEmpty(values.section)) {
                sectionData = sectionMapper(values.section, page_id);
                containers = containers.concat(sectionData);
            }

            await createContent({
                variables: {
                    data: {
                        containers,
                    },
                },
            });
        }
    };

    const handleUpdatePage = async (values) => {
        const page_id = fieldInfo.pageId;
        let sectionData = [];

        let containers: any = [
            {
                order: 0,
                page_id: page_id,
                name: "AWARDS-DEFAULT",
                field_collections: [
                    {
                        order: 0,
                        name: "AWARDS-DEFAULT-FC",
                        fields: [
                            {
                                order: 0,
                                name: "AWARDS-TITLE",
                                label: "awards title",
                                value: values.title ? values.title : null,
                            },
                        ],
                    },
                ],
            },
        ];

        if (!isEmpty(values.section)) {
            sectionData = sectionMapper(values.section, page_id);
            containers = containers.concat(sectionData);
        }

        await createContent({
            variables: {
                data: {
                    containers,
                },
            },
        });

        if (!isEmpty(fieldInfo.containers)) {
            await removeMultiContainer({
                variables: {
                    data: {
                        ids: fieldInfo.containers,
                    },
                },
            });
        }
    };

    return (
        <Spin spinning={loader}>
            <Formik
                initialValues={initalValue}
                enableReinitialize={pageDetails}
                validationSchema={validationSchema}
                onSubmit={(values: any) => {
                    handleSubmit(values);
                }}
                render={(formikBag) => (
                    <Form
                        {...{
                            formikBag,
                        }}
                    />
                )}
            />
        </Spin>
    );
};

export default AwardsForm;
