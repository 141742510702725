import styled from "styled-components";
import { Pagination } from "antd";

export const LayoutContainer = styled.div`
    padding: 24px;
`;

export const StyledUserDetails = styled.div`
    width: 100%;
    text-align: left;
    p,
    h1 {
        margin-bottom: 0;
    }

    .profile {
        margin-top: 20px;
        text-align: center;
        .user-name {
            font-size: 1.25rem;
        }
    }

    .userDetails {
        margin: 30px 0 24px;
        p {
            line-height: 2.1;
            word-break: break-word;
        }
        button {
            margin-top: 24px;
            width: 100%;
            font-size: 13px;
            white-space: normal;
        }
    }
`;
