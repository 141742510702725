import styled from "styled-components";

import { Card, Button } from "antd";
import { Checkbox } from "formik-antd";

import UploadSingleImage from "../UploadSingleImage";

export const InputWrapper = styled.div`
    position: relative;
`;

export const StyledUploadImage = styled(UploadSingleImage)`
    width: 100%;
    .ant-upload {
        width: 100%;
        button {
            height: 10rem;
            width: 100%;
        }
    }
`;

export const StyledCard = styled(Card)`
    position: relative;
    margin-bottom: 15px;
    width: 100px;
    border: none;

    .featuredCont {
        width: 100px;
        height: 80px;
        background: #d3d3d3;
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        .video-content {
            width: 100px;
            height: 80px;
            object-fit: cover;
        }
    }
    .checkbox {
        position: absolute;
        top: 0;
        left: 0;
        color: ${(props) => (props.checked ? "white" : "#000")};
    }

    .ant-card-head {
        position: absolute;
        top: 0.15rem;
        right: 0;
        color: ${(props) => (props.checked ? "white" : "#000")};
        z-index: 500;
        min-height: 0;
        padding: 0 10px;
        border: 0;
    }

    .ant-card-extra {
        padding: 0;
        float: none;
        a {
            color: #fff;
        }
    }
    .ant-card-body {
        padding: 0px;
        overflow: hidden;

        .ant-input {
            max-height: 80px;
            min-height: 80px;
            height: 80px;
        }
    }
`;

export const StyledFeaturedCheckbox = styled(Checkbox)`
    width: 100px;
    padding: 6px 4px 4px;
    background: ${(props) => (props.checked ? "#147d56" : "rgba(255, 255, 255, 0.8)")};
    font-size: 10px !important;

    .ant-checkbox {
        outline: 1px solid #fff;
    }
    .ant-checkbox + span {
        color: ${(props) => (props.checked ? "#fff" : "#000")};
    }
`;

export const AddBtn = styled(Button)`
    width: 100px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const DeleteBtn = styled(Button)`
    height: 20px;
    padding: 0;
    position: absolute;
    right: -5px;
    top: -7px;

    .remove-btn {
        cursor: pointer;

        &.active-checked {
            color: #000;
        }
    }
`;
