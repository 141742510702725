import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Documents from "./components/documents";

const ManageDocuments: React.FC = () => {
    let match = useRouteMatch();
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={[`${match.path}/:id`, `${match.path}`]}
                    render={(props: any) => <Documents {...props} />}
                />
            </Switch>
        </>
    );
};

export default ManageDocuments;
