import React, { useState, useEffect, useRef, useContext } from "react";
import { FieldArray } from "formik";
import { Form, FormItem, Input, AutoComplete } from "formik-antd";
import { Row, Col, Spin, Modal } from "antd";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import throttle from "lodash/throttle";
import filter from "lodash/find";
import CustomButton from "../../../../../../components/CustomButton";
import { QUERY_LOCATION_AC, QUERY_LOCATION_GEOCODE } from "../../../../graphql";
import { Context } from "../../../../../../context";
import CustomSelect from "../../../../../../components/CustomSelect";

import FeaturedImages from "../../../../../../components/FeaturedImages";
import LandmarksForm from "./landmarks";
import { locationRegion } from "./constant";
import SeoForm from "@/components/SeoForm";
import { LabelTitle, StyledIFrame } from "./styled";
import MultipleUrlFields from "../../../../../../components/MultipleUrlFields";

interface Props {
    formikBag: any;
    loading: boolean;
}

const Forms = ({ formikBag, loading }: Props): JSX.Element => {
    const [modalVisible, setModalVisible] = useState(false);
    const [preview, setPreview] = useState("");
    const { values, setFieldValue, submitForm, isValid, dirty } = formikBag;
    const [locationAC, setlocationAC] = useState("");
    const [locationSearchable, setlocationSearchable] = useState("");
    const [placeId, setPlaceId] = useState("");
    const [lockLatLong, setLockLatLong] = useState(true);

    const [options, setOptions] = useState<{ value: string }[]>([]);
    const {
        state: { brand: brandData },
    } = useContext(Context);

    const brandId = brandData?.id;
    const brandName = brandData?.name;

    const throttled = useRef(throttle((newValue) => setlocationSearchable(newValue), 4000));
    useEffect(() => throttled.current(locationAC), [locationAC]);
    const { data: locationData, error: locationDataError } = useQuery(QUERY_LOCATION_AC, {
        skip: !locationSearchable,
        variables: {
            brand_id: brandId,
            location: locationSearchable,
        },
    });
    useEffect(() => {
        const onCompleted = (locationData) => {
            if (!locationData) {
                setOptions([]);
            } else {
                const mappedData = locationData.getLocations.places.map((res) => {
                    return { value: res.name };
                });
                setOptions(mappedData);
            }
        };
        const onError = (locationDataError) => {
            console.log(locationDataError, "QUERY ERROR");
        };
        if (onCompleted || onError) {
            if (onCompleted && !locationDataError) {
                onCompleted(locationData);
            } else if (onError && locationDataError) {
                onError(locationDataError);
            }
        }
    }, [locationData, locationDataError]);

    const { data: geoCodeData, error: geoCodeError } = useQuery(QUERY_LOCATION_GEOCODE, {
        skip: !placeId,
        variables: {
            brand_id: brandId,
            place_id: placeId,
        },
    });
    useEffect(() => {
        const onCompleted = (geoCodeData) => {
            if (geoCodeData) {
                setFieldValue("latitude", geoCodeData.getLocationGeocode.latitude);
                setFieldValue("longitude", geoCodeData.getLocationGeocode.longitude);
            }
        };
        const onError = (geoCodeError) => {
            console.log(geoCodeError, "QUERY ERROR");
        };
        if (onCompleted || onError) {
            if (onCompleted && !geoCodeError) {
                onCompleted(geoCodeData);
            } else if (onError && geoCodeError) {
                onError(geoCodeError);
            }
        }
        // eslint-disable-next-line
    }, [geoCodeData, geoCodeError]);

    const handlePreview = (val) => {
        if (val) {
            setPreview(val);
            setModalVisible(true);
        }
    };

    const handleClosePreview = () => {
        setPreview("");
        setModalVisible(false);
    };

    const onSelect = (selectedData: string) => {
        const selectedLocationData = filter(locationData.getLocations.places, {
            name: selectedData,
        });
        setPlaceId(selectedLocationData.place_id);
    };

    const onChange = (e: string) => {
        setlocationAC(e);
    };

    const handleLatLongLock = () => {
        setLockLatLong(!lockLatLong);
    };

    return (
        <Form>
            <Spin spinning={loading}>
                <SeoForm category={"Location"} brand={brandName} />
                <LabelTitle>
                    <b>Location</b> (Default)
                </LabelTitle>
                <FormItem
                    name={"locationDefault"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input name="locationDefault" placeholder={"Override Label"} />
                </FormItem>
                <FormItem
                    label={"Locations (Specific)"}
                    name={"locationSpecific"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <AutoComplete
                        name="locationSpecific"
                        value={locationAC || values.locationSpecific}
                        options={options}
                        onSelect={onSelect}
                        onChange={onChange}
                    />
                </FormItem>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem
                            label={"Locations (Brief)"}
                            name={"locationBrief"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name="locationBrief" />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            label={"Region"}
                            name={"region"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <CustomSelect
                                name={"region"}
                                placeholder={"Region"}
                                options={locationRegion}
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem
                            label={"Longitude"}
                            name={"longitude"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name="longitude" disabled={lockLatLong} />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            label={"Latitude"}
                            name={"latitude"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Input name="latitude" disabled={lockLatLong} />
                                {!lockLatLong ? (
                                    <UnlockOutlined
                                        onClick={handleLatLongLock}
                                        style={{ fontSize: "26px" }}
                                    />
                                ) : (
                                    <LockOutlined
                                        onClick={handleLatLongLock}
                                        style={{ fontSize: "26px" }}
                                    />
                                )}
                            </div>
                        </FormItem>
                    </Col>
                </Row>

                <FeaturedImages
                    onChange={(e) => {
                        const imagesCaptions = e.map(({ caption }) => caption);
                        const imagesAltTexts = e.map(({ altText }) => altText);
                        setFieldValue(`locationImagesCaptions`, imagesCaptions);
                        setFieldValue(`locationImages.altText`, imagesAltTexts);
                        setFieldValue("locationImages", e);
                    }}
                    path={"properties-locations"}
                    fileList={values.locationImages}
                    validate={true}
                    imageAltText={brandName === "ALP"}
                />
                <br />

                <Col span={24}>
                    <h3>Virtual Tour</h3>

                    <Row gutter={24}>
                        <Col span={12}>
                            <p>Video</p>
                            <MultipleUrlFields
                                values={values.videos}
                                placeholder="Video Link"
                                name="videos"
                                preview={handlePreview}
                            />
                        </Col>
                        <Col span={12}>
                            <p>Panorama</p>
                            <MultipleUrlFields
                                values={values.panoramas}
                                placeholder="Panorama Link"
                                name="panoramas"
                                preview={handlePreview}
                            />
                        </Col>
                    </Row>
                </Col>

                <LabelTitle>
                    <b>Landmarks</b> (Default)
                </LabelTitle>
                <FormItem
                    name={"landmarkdsDefault"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input name="landmarkdsDefault" placeholder={"Override Label"} />
                </FormItem>

                <FieldArray
                    name="landmarksContents"
                    render={(event) => (
                        <LandmarksForm
                            events={event}
                            values={values.landmarksContents}
                            setFieldValue={setFieldValue}
                            brandId={brandId}
                        />
                    )}
                />

                <Row justify="end">
                    <Col span={3}>
                        <CustomButton
                            style={{ width: "100%", marginRight: 15 }}
                            type="primary"
                            onClick={() => submitForm()}
                            disabled={!dirty || !isValid}
                        >
                            SAVE
                        </CustomButton>
                    </Col>
                </Row>
            </Spin>
            <Modal
                visible={modalVisible}
                centered
                footer={null}
                bodyStyle={{ padding: 0, height: 400 }}
                onCancel={handleClosePreview}
                width={700}
                closable={false}
                maskClosable={true}
                destroyOnClose={true}
            >
                <StyledIFrame src={preview} width="100%" height="100%"></StyledIFrame>
            </Modal>
        </Form>
    );
};

export default Forms;
