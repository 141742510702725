import * as yup from "yup";

export const initialValues = {
    award: "",
    year: "",
    organization: "",
    image: "",
};

export const validationSchema = yup.object().shape({
    award: yup.string().required("This field is required."),
    year: yup.string().required("This field is required."),
    organization: yup.string().required("This field is required."),
    image: yup.string().required("This field is required."),
});
