export default {
    tenantDetail: (dispatch) => {
        return (data) => {
            dispatch({ type: "STORE_TENANT_INFO", payload: data });
        };
    },
    storeComponentCategory: (dispatch) => {
        return (data) => {
            dispatch({ type: "STORE_COMPONENT_CATEGORY", payload: data });
        };
    },
    pageDetails: (dispatch) => {
        return (data) => {
            dispatch({ type: "PAGE_DETAILS", payload: data });
        };
    },
};
