import * as yup from "yup";
import validateUrl from "../../../../util/validate-url";
import moment from "moment";
export const BASE_URL = `${process.env.REACT_APP_CONTENT_URL}/uploads/documents`;
export const FILE_SIZE_LIMIT = 49;

export const SUPPORTED_FORMATS = ".pdf";

export const validationSchema = yup.object().shape({
    name: yup.string(),
    type: yup.string().required("Please choose a document type."),
    usage_type: yup.string().required("Please choose a usage type."),
    file_link: yup.string().required("Please choose a usage type."),
    file_name: yup.string().required("Please choose a usage type."),
    file: yup.object().shape({
        name: yup.string(),
        status: yup
            .string()
            .matches(/done/, { message: "file is still uploading" })
            .required("Please upload a document."),
        url: yup.string(),
        keyObj: yup.string().when(["url", "status"], (url: string, status: string, schema) => {
            return schema.test("fileFormat", "Invalid format.", (value) => {
                url && validateUrl(url);
                if (status === "done" && value) {
                    const regExp = /\.[0-9a-z]+$/i;
                    const file = value.match(regExp)[0];
                    return SUPPORTED_FORMATS.includes(file);
                }
                return true;
            });
        }),
    }),
});

export const fileColumns = [
    {
        title: "Attachment",
        dataIndex: "file",
        key: "file_name",
        render: (text) => {
            return text.name;
        },
    },
    {
        title: "Date File Uploaded",
        dataIndex: "created_at",
        key: "created_at",
        render: (text) => {
            return `${new Date(text).toDateString()} ${moment(text).format("hh:mm A")}`;
        },
    },
    {
        title: "View File",
        key: "actions",
    },
];

export const columns = [
    {
        title: "DocumentID",
        key: "id",
        dataIndex: "id",
        sorter: function (a, b) {
            if (a.id < b.id) {
                return -1;
            }
            if (a.id > b.id) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: "Document Name",
        key: "name",
        dataIndex: "name",
        sorter: function (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: "Document Type",
        key: "type",
        dataIndex: "type",
        sorter: function (a, b) {
            if (a.type < b.type) {
                return -1;
            }
            if (a.type > b.type) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: "Usage Type",
        key: "usage_type",
        dataIndex: "usage_type",
        sorter: function (a, b) {
            if (a.usage_type < b.usage_type) {
                return -1;
            }
            if (a.usage_type > b.usage_type) {
                return 1;
            }
            return 0;
        },
    },
    {
        title: "File Name",
        key: "file_alias",
        dataIndex: "file_alias",
        render: (_, record) => {
            return record.file.file_alias;
        },
    },
    {
        title: "Version",
        key: "version",
        dataIndex: "version",
        render: (_, record) => {
            return record.file.version;
        },
    },
    {
        title: "File Uploaded [Date & Time]",
        dataIndex: "created_at",
        key: "created_at",
        render: (text) => {
            return `${new Date(text).toDateString()} ${moment(text).format("hh:mm A")}`;
        },
    },
    {
        title: "",
        key: "actions",
    },
];

export const brandsUrls = [
    {
        brand: "alp",
        url: process.env.REACT_APP_ALP_URL,
    },
    {
        brand: "avida",
        url: process.env.REACT_APP_AVIDA_URL,
    },
    {
        brand: "amaia",
        url: process.env.REACT_APP_AMAIA_URL,
    },
    {
        brand: "alveo",
        url: process.env.REACT_APP_ALVEO_URL,
    },
];

export const defaultInitialValue = {
    type: "",
    usage_type: "",
    name: "",
    file_name: "",
    file_link: "",
    file: {},
    version: "",
    file_alias: "",
};

export const usageTypeList = [
    "Residential Condo",
    "House & Lot",
    "Residential Lot",
    "Residential Condominium Parking",
    "Commercial Lot",
    "Hacienda Lot",
    "Farm Lot",
    "Office Condo",
    "Office Parking",
    "Storage",
    "Others-Usable",
    "Commercial Unit",
    "Commercial Parking",
    "Club Shares",
    "Golf Shares",
    "Leasehold parking",
    "Road Lots",
    "Amenities",
    "Utility Lot",
    "Open Spaces",
    "Easement",
    "Alley",
    "Condo Lot",
    "Common Improvements",
    "Condo Machinery",
    "PWD Parking",
    "Sales Office",
    "Unbuildable",
    "Future Development",
    "Creek",
];
