import React from "react";
import includes from "lodash/includes";
import indexOf from "lodash/indexOf";
import uniq from "lodash/uniq";
import filter from "lodash/filter";

import { Dropdown, Divider } from "antd";
import { Select } from "formik-antd";

import { DropdownWrapper, CheckboxStyled } from "./styled";
import { weekends, weekdays, weekOrder } from "./constant";

interface OptionsType {
    value: string;
    label: string;
}

type SelectDayPropType = {
    value: string[];
    options: OptionsType[];
    name: string;
    mode: "multiple" | "tags" | undefined;
    style?: React.CSSProperties;
    placeholder: string;
    setFieldValue: (val1, val2) => void;
};

const SelectDay = ({
    name,
    mode,
    style,
    placeholder,
    options,
    value,
    setFieldValue,
}: SelectDayPropType): JSX.Element => {
    const daySorter = (a, b) => weekOrder.indexOf(a) - weekOrder.indexOf(b);

    const handleClickDay = (val) => {
        if (includes(value, val)) {
            const newArray: any = [];
            const index = indexOf(value, val);

            value.forEach((res, i) => {
                if (i !== index) {
                    newArray.push(res);
                }
            });
            newArray.sort(daySorter);
            setFieldValue(name, newArray);
        } else {
            let newArray = value;
            newArray = newArray.concat(val);
            newArray.sort(daySorter);
            setFieldValue(name, newArray);
        }
    };

    const handleWeek = (week) => {
        let newArray: any = value;
        const weekData = week === "ends" ? weekends : weekdays;
        const weekendsChecked = weekends.every((v) => value.includes(v));
        const weekdaysChecked = weekdays.every((v) => value.includes(v));

        newArray = newArray.concat(weekData);
        newArray = uniq(newArray);

        if (week === "ends" && weekendsChecked) {
            newArray = filter(newArray, (res) => !weekends.includes(res));
        } else if (week === "days" && weekdaysChecked) {
            newArray = filter(newArray, (res) => !weekdays.includes(res));
        }

        newArray.sort(daySorter);
        setFieldValue(name, newArray);
    };

    const menu = () => {
        const weekendsChecked = weekends.every((v) => value.includes(v));
        const weekdaysChecked = weekdays.every((v) => value.includes(v));

        return (
            <DropdownWrapper>
                <CheckboxStyled checked={weekdaysChecked} onClick={() => handleWeek("days")}>
                    Weekdays
                </CheckboxStyled>
                <CheckboxStyled checked={weekendsChecked} onClick={() => handleWeek("ends")}>
                    Weekends
                </CheckboxStyled>
                <Divider className={"divider-day"} />
                {options.map(({ value: val, label }) => {
                    const checked = includes(value, val) ? true : false;
                    return (
                        <CheckboxStyled
                            key={label}
                            checked={checked}
                            onClick={() => handleClickDay(val)}
                        >
                            {label}
                        </CheckboxStyled>
                    );
                })}
            </DropdownWrapper>
        );
    };

    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            <Select
                name={name}
                mode={mode}
                style={style}
                showSearch={false}
                placeholder={placeholder}
                showArrow
                dropdownRender={(e) => <div />}
            />
        </Dropdown>
    );
};

export default SelectDay;
