export const linkTypes = [
    {
        label: "Property",
        value: "PROPERTIES"
    },
    {
        label: "Article",
        value: "CONTENT"
    }
];

export const categories = {
    properties: [
        {
            label: "Condominium",
            value: "Condominium"
    
        },
        {
            label: "Office",
            value: "Office"
    
        },
        {
            label: "Lots",
            value: "Lots"
    
        },
        {
            label: "Commercial",
            value: "Commercial"
    
        },
        {
            label: "Townhouse",
            value: "Townhouse"
    
        },
        {
            label: "Leisure",
            value: "Leisure"
    
        },
        {
            label: "Apartments",
            value: "Apartments"
    
        },
        {
            label: "House & Lot",
            value: "House and Lot"
    
        },
        {
            label: "Estate",
            value: "Estate"
    
        },
    ],
    content: [
        {
            label: "News",
            value: "news"
    
        },
        {
            label: "Events",
            value: "events"
    
        },
        {
            label: "Announcements",
            value: "announcements"
    
        },
        {
            label: "Destinations",
            value: "destinations"
    
        },
        {
            label: "Neighborhood",
            value: "neighborhood"
    
        },
        {
            label: "Sustainability",
            value: "sustainability"
    
        },
        {
            label: "Home Buying Guide",
            value: "home buying guide"
    
        },
        {
            label: "Blogs",
            value: "blogs"
    
        },
        {
            label: "Vlogs",
            value: "vlogs"
    
        },
    ]
};

export const cardText = [
    {
        htmlEl: "h2",
        varName: "name",
    },
    {
        htmlEl: "span",
        varName: "excerpt",
    },
    {
        htmlEl: "span",
        className: "small",
        varName: "date"
    }
]