import React, { useState, useEffect, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import ContentContainer from "../../../../components/ContentContainer";
import CustomTable from "@/components/CustomTable";

import { Context } from "../../../../context";
import { columns as columnsConstant } from "./constant";

import { getRoles, getBrand } from "../../axios";

import { RolePaginationResponse } from "../../dto/RolePaginationResponse";

const RoleList: React.FC = () => {
    const history = useHistory();
    const match = useRouteMatch();

    const {
        state: { userDetails },
    } = useContext(Context);

    const [columns, setColumns] = useState<any[]>(columnsConstant);
    const [data, setData] = useState<any[]>([]);
    const [brandList, setBrandList] = useState<any>([]);
    const [showActive, setShowActive] = useState<boolean>(true);
    const [sortBy, setSortBy] = useState<string>("updatedAt");
    const [sortOrder, setSortOrder] = useState<string>("desc");
    const [filterBrands, setFilterBrands] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(10);
    const [search, setSearch] = useState<string>("");
    const [activeRoles, setActiveRoles] = useState<number>(0);
    const [inactiveRoles, setInactiveRoles] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const handleStatusBtn = (status) => {
        setShowActive(status);
    };

    const handleSearch = (query) => {
        setSearch(query);
    };

    const handleAddBtn = () => {
        history.push(`${match.path}/add`);
    };

    const handleFilter = (filters) => {
        filters.brand ? setFilterBrands(filters.brand.join()) : setFilterBrands("");
    };

    const handleSort = (field: string, order: string) => {
        setSortBy(field);
        setSortOrder(order);
    };

    const handleSelectedRow = (record) => {
        history.push(`${match.path}/role/${record.id}`);
    };

    const handlePageSize = async (pageSize) => {
        setCurrentPageSize(pageSize);
    };

    const handlePagination = async (page, pageSize) => {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);
    };

    useEffect(() => {
        if (!data.length) {
            setLoading(true);
        }

        (async () => {
            try {
                const {
                    data: role,
                    meta: { data: metaData },
                }: RolePaginationResponse = await getRoles(
                    {
                        page: currentPage,
                        limit: currentPageSize,
                        isActive: showActive,
                        q: search,
                        sortBy: sortBy,
                        order: sortOrder,
                        tenantIds: filterBrands,
                    },
                    userDetails.payload.accessToken
                );

                setData(role);
                setBrandList(await getBrand(userDetails.payload.accessToken));
                setActiveRoles(metaData.activeRoles);
                setInactiveRoles(metaData.inactiveRoles);
                setLoading(false);
            } catch (error) {
                const errorObj = error as Error;

                setLoading(false);
            }
        })();
        // eslint-disable-next-line
    }, [
        currentPage,
        currentPageSize,
        showActive,
        search,
        sortBy,
        sortOrder,
        filterBrands,
        userDetails.payload,
    ]);

    useEffect(() => {
        const newColumns: any = columns;
        newColumns.find((brand) => brand.dataIndex === "brand").filters = brandList;
        setColumns(newColumns);
        // eslint-disable-next-line
    }, [brandList]);

    return (
        <ContentContainer style={{ flexDirection: "column" }}>
            <CustomTable
                updateAccess={true}
                headingText="Roles"
                labelText="Role"
                isActive={showActive}
                activeNum={activeRoles}
                inactiveNum={inactiveRoles}
                handleStatusBtn={handleStatusBtn}
                handleSearch={handleSearch}
                handleAddBtn={handleAddBtn}
                columns={columns}
                dataSource={data}
                handleSort={handleSort}
                handleFilter={handleFilter}
                handleSelectedRow={handleSelectedRow}
                currentPageSize={currentPageSize}
                handlePageSize={handlePageSize}
                handlePagination={handlePagination}
                loading={loading}
            />
        </ContentContainer>
    );
};

export default RoleList;
