import * as yup from "yup";

const imageSchema = yup.object().shape({
    status: yup.string().matches(/done/, { message: "file is still uploading" }),
});

export const validationSchema = yup.object().shape({
    title: yup.string().required(),
    images: yup.array().of(imageSchema).nullable(),
});
