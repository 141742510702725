import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { Breadcrumb } from "antd";

import LinksForms from "./components/links-forms";

export const StyledBreadcrumb = styled(Breadcrumb)`
    background: #fff;
    display: flex;
    padding: 16px 25px;
`;

const ManageProperties = (): JSX.Element => {
    const match = useRouteMatch();
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={`${match.path}`}
                    render={(props: any) => <LinksForms {...props} />}
                />
            </Switch>
        </>
    );
};

export default ManageProperties;
