import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { Layout, Menu } from "antd";
import { FormOutlined } from "@ant-design/icons";
import { version } from "../../version";
import logoWhite from "../../assets/logo/ayala-white.svg";
import logo from "../../assets/logo/ayala-white-logo.svg";

import { userNavigationReadPermission } from "../../util/user-access";
import { Context } from "../../context";

const { Sider } = Layout;
const { SubMenu } = Menu;

const LogoWrapper = styled.div`
    height: 64px;
    background: #00714b;
    padding: 15px 0;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
`;

const StyleLogo = styled.img`
    width: 157px;
`;

const StyledLogoCollapse = styled.img`
    width: 32px;
`;

const StyledSider = styled(Sider)`
    .ant-layout-sider-children::-webkit-scrollbar {
        width: 6px;
    }

    .ant-layout-sider-children::-webkit-scrollbar-thumb {
        background-color: #555; /* Color of the thumb */
        border-radius: 5px; /* Rounded corners for the thumb */
    }

    .ant-layout-sider-children::-webkit-scrollbar-thumb:hover {
        background-color: #333; /* Color when hovered over */
    }
    .ant-layout-sider-children {
        scrollbar-width: thin;
        overflow: auto;

        .ant-menu {
            .ant-menu-item {
                color: #dbdbdb;
                font-size: 14px;
                font-weight: 400;

                &.ant-menu-item-selected {
                    background-color: #333;
                }
            }
        }
    }
`;

const LogoutWrapper = styled(Menu)`
    position: absolute;
    bottom: 0px;
`;

const VersionContainer = styled.div`
    position: absolute;
    bottom: 10px;
    display: flex;
    font-size: 12px;
    width: 100%;
    justify-content: flex-end;
`;

interface Props {
    collapsed: boolean;
}

interface PageProps {
    page: string;
}

interface AllBrands {
    name: string;
}

interface UserPageProps {
    page: string;
    role: string;
    currentBrand: string;
    allBrands: AllBrands[];
}

interface RootPageProps {
    page: string;
    role: string;
}

const RootNavigation = (props: RootPageProps): JSX.Element => {
    const { page, role } = props;
    return !role ? (
        <div />
    ) : (
        <Menu theme="dark" mode="inline" selectedKeys={[page]}>
            <Menu.Item key="manage-users" icon={<FormOutlined />}>
                <Link to={"/manage-users"}>Manage Users</Link>
            </Menu.Item>
            <Menu.Item key="manage-roles" icon={<FormOutlined />}>
                <Link to={"/manage-roles"}>Manage Roles</Link>
            </Menu.Item>
        </Menu>
    );
};

const ContractNavigation = (props: RootPageProps): JSX.Element => {
    const { page } = props;
    return (
        <Menu theme="dark" mode="inline" selectedKeys={[page]}>
            <Menu.Item key="manage-contracts" icon={<FormOutlined />}>
                <Link to={"/manage-contracts"}>Manage Contracts</Link>
            </Menu.Item>
        </Menu>
    );
};

const UserNavigation: React.FC<UserPageProps> = (props: UserPageProps) => {
    const { page, role, currentBrand, allBrands } = props;

    const renderPropertyMenu = () => {
        if (currentBrand !== "ALISI") {
            return (
                <Menu.Item
                    style={{ display: userNavigationReadPermission(role, "read:property") }}
                    key="manage-properties"
                    icon={<FormOutlined />}
                >
                    <Link to={"/manage-properties"}>Manage Properties</Link>
                </Menu.Item>
            );
        }
        return (
            <SubMenu
                style={{ display: userNavigationReadPermission(role, "read:property") }}
                key="manage-properties"
                icon={<FormOutlined />}
                title="Manage Properties"
            >
                {allBrands.map((res) => {
                    return (
                        <Menu.Item key={`manage-properties-${res.name}`}>
                            <Link to={`/manage-properties?brand=${res.name}`}>{res.name}</Link>
                        </Menu.Item>
                    );
                })}
            </SubMenu>
        );
    };

    return (
        <Menu theme="dark" mode="inline" selectedKeys={[page]}>
            <Menu.Item
                style={{
                    display: userNavigationReadPermission(role, "read:company-information"),
                }}
                key="manage-company-information"
                icon={<FormOutlined />}
            >
                <Link to={"/manage-company-information"}>Manage Company Information</Link>
            </Menu.Item>

            {renderPropertyMenu()}
            <Menu.Item
                style={{ display: userNavigationReadPermission(role, "read:showroom") }}
                key="manage-showroom"
                icon={<FormOutlined />}
            >
                <Link to={"/manage-showroom"}>Manage Showroom</Link>
            </Menu.Item>
            <Menu.Item
                style={{ display: userNavigationReadPermission(role, "read:content") }}
                key="manage-content"
                icon={<FormOutlined />}
            >
                <Link to={"/manage-content"}>Manage Content</Link>
            </Menu.Item>
            <Menu.Item
                style={{ display: userNavigationReadPermission(role, "read:banner") }}
                key="manage-hero-banner"
                icon={<FormOutlined />}
            >
                <Link to={"/manage-hero-banner"}>Manage Hero Banner</Link>
            </Menu.Item>
            <Menu.Item
                style={{ display: userNavigationReadPermission(role, "read:gallery") }}
                key="manage-gallery"
                icon={<FormOutlined />}
            >
                <Link to={"/manage-gallery"}>Manage Gallery</Link>
            </Menu.Item>
            <SubMenu
                style={{ display: userNavigationReadPermission(role, "read:careers") }}
                key="manage-careers"
                icon={<FormOutlined />}
                title="Manage Careers"
            >
                <Menu.Item key="manage-careers/careers-information-details">
                    <Link to={"/manage-careers/careers-information-details"}>
                        Career Information Details
                    </Link>
                </Menu.Item>
                <Menu.Item key="manage-careers/careers">
                    <Link to={"/manage-careers/careers"}>Careers</Link>
                </Menu.Item>
            </SubMenu>
            <Menu.Item
                style={{ display: userNavigationReadPermission(role, "read:links") }}
                key="manage-links"
                icon={<FormOutlined />}
            >
                <Link to={"/manage-links"}>Manage Links</Link>
            </Menu.Item>
            {currentBrand !== "ALISI" && (
                <Menu.Item
                    style={{ display: userNavigationReadPermission(role, "read:mdor") }}
                    key="manage-mddor-documents"
                    icon={<FormOutlined />}
                >
                    <Link to={"/manage-mddor-documents"}>Manage MDDOR Documents</Link>
                </Menu.Item>
            )}
            <Menu.Item
                style={{ display: userNavigationReadPermission(role, "read:email") }}
                key="manage-email"
                icon={<FormOutlined />}
            >
                <Link to={"/manage-email"}>Manage Email</Link>
            </Menu.Item>
            <SubMenu
                style={{ display: userNavigationReadPermission(role, "read:hbg") }}
                key="manage-home-buying-guide"
                icon={<FormOutlined />}
                title="Manage Home Buying Guide"
            >
                <Menu.Item key="manage-home-buying-guide/reservation-process">
                    <Link to="/manage-home-buying-guide/reservation-process">
                        Reservation Process
                    </Link>
                </Menu.Item>
                <Menu.Item key="manage-home-buying-guide/document-requirements">
                    <Link to="/manage-home-buying-guide/document-requirements">
                        Document Requirements
                    </Link>
                </Menu.Item>

                <Menu.Item key="manage-home-buying-guide/payment-channel">
                    <Link to="/manage-home-buying-guide/payment-channel">Payment Channel</Link>
                </Menu.Item>

                <Menu.Item key="manage-home-buying-guide/faq">
                    <Link to="/manage-home-buying-guide/faq">Frequently Asked Questions</Link>
                </Menu.Item>

                <Menu.Item key="manage-home-buying-guide">
                    <Link to="/" />
                </Menu.Item>
            </SubMenu>

            <Menu.Item
                style={{ display: userNavigationReadPermission(role, "sample") }}
                key="sample-feature"
                icon={<FormOutlined />}
            >
                <Link to={"/sample-feature"}>Sample Feature</Link>
            </Menu.Item>
            <Menu.Item
                style={{ display: userNavigationReadPermission(role, "read:awards_content") }}
                key="sample-feature"
                icon={<FormOutlined />}
            >
                <Link to={"/manage-awards"}>Manage Awards</Link>
            </Menu.Item>
        </Menu>
    );
};

const Navigation = ({ collapsed }: Props): JSX.Element => {
    const { page } = useParams();

    const {
        state: { brand: brandData, user, allBrands },
    } = useContext(Context);
    const logoutHandler = () => {
        localStorage.removeItem("ACCESS_TOKEN");
        localStorage.removeItem("REFRESH_TOKEN");
        window.location.replace(`
          https://login.windows.net/common/oauth2/logout?post_logout_redirect_uri=${process.env.REACT_APP_IAM_REDIRECT_URL}
        `);
    };
    return (
        <StyledSider trigger={null} width="250" collapsible collapsed={collapsed}>
            <LogoWrapper>
                {collapsed ? <StyledLogoCollapse src={logo} /> : <StyleLogo src={logoWhite} />}
            </LogoWrapper>
            {userNavigationReadPermission(user?.roles, "read:contract_document_entry") ===
            "block" ? (
                <ContractNavigation page={page} role={user?.roles} />
            ) : !brandData ? (
                <RootNavigation page={page} role={user?.roles} />
            ) : (
                <UserNavigation
                    page={page}
                    role={user?.roles}
                    currentBrand={brandData?.name}
                    allBrands={allBrands}
                />
            )}
            <LogoutWrapper theme="dark" mode="inline">
                <Menu.Item icon={<FormOutlined />}>
                    <Link onClick={logoutHandler} to="">
                        Logout
                    </Link>
                </Menu.Item>
                <VersionContainer>{version}</VersionContainer>
            </LogoutWrapper>
        </StyledSider>
    );
};

export default Navigation;
