import validateUrl from "./validate-url";

export const getThumbnail = (url) => {
    if (validateUrl(url)) {
        const ytRegExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;        
        const vimeoRegExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;

        const youTube = url.match(ytRegExp) && url.match(ytRegExp)[7].length === 11 && url.match(ytRegExp)[7];
        if (youTube) {
            return `https://img.youtube.com/vi/${youTube}/0.jpg`;
        }

        const vimeo = url.match(vimeoRegExp) && url.match(vimeoRegExp)[5].length === 7 && url.match(vimeoRegExp)[5];
        if (vimeo) {
            return; 
        }       
    }
    return;
}