import * as yup from "yup";

const imageSchema = yup
    .array()
    .of(
        yup.object().shape({
            status: yup.string().matches(/done/, { message: "file is still uploading" }),
        })
    )
    .nullable();

const amenityListSchema = yup.array().of(
    yup.object().shape({
        amenityName: yup.string(),
        images: imageSchema,
    })
);

export const validationSchema = yup.object().shape({
    amenitiesDefault: yup.string(),
    title: yup.string(),
    propertyDetails: yup.string(),
    seoAmenitiesTitle: yup.string(),
    seoAmenitiesDescription: yup.string(),
    amenityList: amenityListSchema,
});
