import React, { useContext } from "react";

import { FieldArray } from "formik";
import { Form } from "formik-antd";
import { Row, Col } from "antd";

import { Title } from "../styled";
import CustomButton from "../../../../../components/CustomButton";
import { Context } from "../../../../../context";
import { renderLinkField } from "../renderLinkFIeld";

interface Props {
    formikBag: any;
}

const Forms = ({ formikBag }: Props): JSX.Element => {
    const { values, submitForm, errors, setFieldValue, isValid, dirty } = formikBag;

    const {
        state: { brand: brandData },
    } = useContext(Context);
    const brandName = brandData?.name;

    return (
        <Form>
            <Title>Links</Title>
            <FieldArray
                name={"links"}
                render={(event) =>
                    renderLinkField({
                        event,
                        values: values.links,
                        errors,
                        setFieldValue,
                        brandName,
                    })
                }
            />
            <Row justify="end" gutter={24} style={{ padding: "0 25px 25px 25px" }}>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={() => submitForm()}
                        disabled={!dirty || !isValid}
                    >
                        SAVE
                    </CustomButton>
                </Col>
            </Row>
        </Form>
    );
};

export default Forms;
