import React, { useState } from "react";
import { Upload, Spin } from "antd";

import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import uploadFile from "../../util/upload-file";

import { convertFileToBase64 } from "../../util/convert-to-base64";
import { ButtonStyle, RemoveBtn, Container } from "./styled";

const BASE_URL = process.env.REACT_APP_CONTENT_URL;

interface Props {
    value: any;
    onChange: any;
    path?: string;
    className?: string;
    renameAddStr?: string;
    accept?: string;
}

const UploadImage = ({ file, renameAddStr }) => {
    return (
        <ButtonStyle type="dashed">
            {file.url || file.base64 ? (
                <img src={file.url} alt="" />
            ) : (
                <div>
                    <PlusOutlined />
                    <h1>{renameAddStr ? renameAddStr : "Upload"}</h1>
                </div>
            )}
        </ButtonStyle>
    );
};

const UploadSingleImage: React.FC<Props> = ({
    value,
    onChange,
    path,
    className,
    renameAddStr,
    accept,
}) => {
    const [loading, setLoader] = useState<boolean>(false);

    const handleOnChange = async (event) => {
        setLoader(true);
        const dir = path ? `${path}/` : "";
        const base64 = await convertFileToBase64(event.file);
        const res = await uploadFile(event.file, `${dir}${event.file.uid}${event.file.name}`);

        onChange({
            file: event.file,
            keyObject: res.key,
            url: base64,
            id: "",
        });
        setLoader(false);
    };
    return (
        <Spin spinning={loading} size="small">
            <Container className={className}>
                <Upload
                    data-index="upload"
                    showUploadList={false}
                    beforeUpload={() => false}
                    action={`${BASE_URL}/upload/objects`}
                    onChange={async (e) => handleOnChange(e)}
                    accept={accept}
                >
                    {UploadImage({ file: value, renameAddStr })}
                </Upload>

                {value.url || value.base64 ? (
                    <RemoveBtn
                        onClick={() =>
                            onChange({
                                url: "",
                                keyObject: "",
                                base64: "",
                                id: "",
                                containerId: "",
                            })
                        }
                    >
                        <CloseOutlined style={{ fontSize: 15 }} />
                    </RemoveBtn>
                ) : (
                    ""
                )}
            </Container>
        </Spin>
    );
};

export default UploadSingleImage;
