export default {
  storeUserDetails: (dispatch) => {
    return (data) => {
      dispatch({ type: 'STORE_USER_DETAILS', payload: data });
    };
  },
  removeUserDetails: (dispatch) => {
    return () => {
      dispatch({ type: 'REMOVE_USER_DETAILS' });
    };
  },
  userDetails: (dispatch) => {
    return (data) => {
        dispatch({ type: "USER_DETAILS", payload: data });
    };
  },
};
