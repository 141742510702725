import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { FieldArray } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import CustomButton from "../../../../../components/CustomButton";
import ContentContainer from "../../../../../components/ContentContainer";
import { SectionWrapper, ContentWrapper, Indicator } from "./styled";
import UploadMultipleImage from "../../../../../components/UploadMultipleImage";
import Richtext from "../../../../../components/Richtext";

interface Props {
    formikBag: any;
}

const Forms: React.SFC<Props> = ({ formikBag }) => {
    const history = useHistory();
    const [modalVisible, setModalVisible] = useState(false);
    const [preview, setPreview] = useState("");
    const { values, submitForm, errors, setFieldValue, dirty, isValid } = formikBag;

    const handleClosePreview = () => {
        setPreview("");
        setModalVisible(false);
    };

    const handlePreview = (val) => {
        if (val) {
            setPreview(val);
            setModalVisible(true);
        }
    };

    const renderContent = ({ event, values, sectionKey, setFieldValue }) => {
        return (
            <div>
                {values.map((result, key) => (
                    <ContentWrapper>
                        <FormItem
                            label={<b>Title</b>}
                            name={`section[${sectionKey}].content[${key}].title`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input
                                name={`section[${sectionKey}].content[${key}].title`}
                                placeholder="Enter title"
                            />
                        </FormItem>
                        <FormItem
                            label={<b>Body</b>}
                            name={`section[${sectionKey}].content[${key}].body`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            {/* <Input.TextArea
                                name={`section[${sectionKey}].content[${key}].body`}
                                placeholder="Enter body"
                            /> */}
                            <Richtext
                                data={result.body}
                                onChange={(e) =>
                                    setFieldValue(`section[${sectionKey}].content[${key}].body`, e)
                                }
                            />
                        </FormItem>

                        <CloseOutlined
                            className="remove-btn"
                            onClick={() => {
                                event.remove(key);
                            }}
                        />
                    </ContentWrapper>
                ))}
                <CustomButton
                    type="dashed"
                    className="add-row"
                    style={{ width: "100%" }}
                    onClick={() => {
                        event.push({ title: "", body: "" });
                    }}
                >
                    <p style={{ margin: 0 }}>+ Add Body</p>
                </CustomButton>
            </div>
        );
    };

    const renderSection = ({ event, values, errors, setFieldValue }) => {
        return (
            <Col span={24} style={{ marginBottom: 25 }}>
                {values.section.map((res: any, key) => (
                    <SectionWrapper>
                        <Indicator />
                        <CloseOutlined
                            className="remove-btn"
                            onClick={() => {
                                event.remove(key);
                            }}
                        />
                        <FieldArray
                            name={`section[${key}].content`}
                            render={(contentEvent) =>
                                renderContent({
                                    event: contentEvent,
                                    values: res.content,
                                    sectionKey: key,
                                    setFieldValue,
                                })
                            }
                        />
                        <UploadMultipleImage
                            preview={handlePreview}
                            accept={"image/jpeg,image/jpg,image/png,image/gif,image,video/mp4"}
                            validate={res.images.length <= 3}
                            fileList={res.images}
                            onChange={async (e) => setFieldValue(`section[${key}].images`, e)}
                        />
                    </SectionWrapper>
                ))}

                <CustomButton
                    type="dashed"
                    className="add-section"
                    style={{ width: "100%", fontSize: 13, fontWeight: 300 }}
                    onClick={() => {
                        event.push({
                            sectionId: "",
                            contentFCId: "",
                            content: [
                                {
                                    id: "",
                                    title: "",
                                    body: "",
                                },
                            ],
                            images: [],
                        });
                    }}
                >
                    <p style={{ margin: 0 }}>+ Add Section</p>
                </CustomButton>
            </Col>
        );
    };

    return (
        <Form>
            <ContentContainer style={{ display: "flex", flexDirection: "column" }}>
                <Row justify="start">
                    <Col span={24}>
                        <FormItem
                            label={
                                <h1>
                                    <b>Facts </b>(Default)
                                </h1>
                            }
                            name={"title"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name="title" placeholder="Override Label" />
                        </FormItem>
                    </Col>

                    <FieldArray
                        name="section"
                        render={(event) => renderSection({ event, values, errors, setFieldValue })}
                    />
                </Row>
            </ContentContainer>

            <Row justify="end" gutter={24} style={{ padding: "0 25px",marginBottom: 25}}>
                <Col span={3}>
                    <CustomButton
                        disabled={!dirty || !isValid}
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={() => submitForm()}
                    >
                        SAVE
                    </CustomButton>
                </Col>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%" }}
                        onClick={() => history.push(`/manage-company-information`)}
                    >
                        CANCEL
                    </CustomButton>
                </Col>
            </Row>
            <Modal
                visible={modalVisible}
                centered
                footer={null}
                bodyStyle={{ padding: 0, height: 400 }}
                onCancel={handleClosePreview}
                width={700}
                closable={false}
                maskClosable={true}
                destroyOnClose={true}
            >
                <video width="100%" height="100%" style={{ objectFit: "cover" }} controls>
                    <source src={preview} />
                </video>
            </Modal>
        </Form>
    );
};

export default Forms;
