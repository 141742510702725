import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { Breadcrumb } from "antd";

import HeroBannerList from "./components/hero-banner-list";
import HeroBannerForms from "./components/hero-banner-forms";

export const StyledBreadcrumb = styled(Breadcrumb)`
    background: #fff;
    display: flex;
    padding: 16px 25px;
`;

const ManageHeroBanner: React.FC = () => {
    const match = useRouteMatch();
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={`${match.path}`}
                    render={(props: any) => <HeroBannerList {...props} />}
                />
                <Route
                    exact
                    path={`${match.path}/add`}
                    render={(props: any) => <HeroBannerForms {...props} />}
                />
                <Route
                    exact
                    path={`${match.path}/:id`}
                    render={(props: any) => <HeroBannerForms {...props} />}
                />
            </Switch>
        </>
    );
};

export default ManageHeroBanner;
