export default {
    STORE_USER_DETAILS: (state, action) => {
        return {
            ...state,
            userDetails: action.payload,
        };
    },
    REMOVE_USER_DETAILS: (state, action) => {
        return {};
    },
    USER_DETAILS: (state, action) => {
        return {
            ...state,
            userDetails: action.payload,
        };
    }
};
