import styled from "styled-components";
import UploadSingleFile from "../../../../components/UploadSingleFile";

export const StyledUploadImage = styled(UploadSingleFile)`
    width: 100%;
    height: 350px;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;

    .ant-upload {
        display: block;

        .upload-button {
            height: 350px;
            width: 100%;
            display: block;
            border-radius: 10px;
        }
    }

    .upload-button {
        height: 350px;
        width: 100%;
        display: block;
        border-radius: 10px;
    }

    .uploaded-file {
        height: 350px;
        width: 100%;

        img {
            height: 350px;
            width: 100%;
        }
    }

    .remove-file {
        top: 6px;
        right: 10px;
    }
`;

export const Title = styled.h1`
    margin-left: 24px;
    margin-top: 24px;
    font-weight: bold;
`;

export const WrapperModalComponent = styled.div`
    width: 200px;
    height: 120px;
    background: rgba(242, 242, 242, 1);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(217, 217, 217, 1);
    border-radius: 2px;

    img {
        width: 200px;
        height: 118px;
    }
`;
