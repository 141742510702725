export default {
    STORE_TENANT_INFO: (state, action) => {
        return {
            ...state,
            tenantDetails: action.payload,
        };
    },
    STORE_COMPONENT_CATEGORY: (state, action) => {
        return {
            ...state,
            componentCategory: action.payload,
        };
    },
    PAGE_DETAILS: (state, action) => {
        return {
            ...state,
            pageDetails: action.payload,
        };
    },
};
