import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import _ from "lodash";
import upperCase from "lodash/upperCase";
import lowerCase from "lodash/lowerCase";

import { Spin, message } from "antd";

import Form from "./forms";
import { Context } from "../../../../../context";
import {
    CREATE_PAGE,
    CREATE_CONTAINER,
    CREATE_MULTI_FC,
    MODIFY_FIELDS,
    GET_PAGE
} from "../../../graphql";

import { validationSchema } from "./constant";

interface Props {
    handleRefresh: () => void;
}

const TermsAndConditionsForms: React.FC<Props> = ({ handleRefresh }) => {
    const { id } = useParams();

    const {
        state: { pageDetails },
    } = useContext(Context);
    const [createPage] = useMutation(CREATE_PAGE);
    const [getPage, { data: pageData }] = useLazyQuery(GET_PAGE);
    const [createContainer] = useMutation(CREATE_CONTAINER);
    const [createMultipleFC] = useMutation(CREATE_MULTI_FC);
    const [modifyFields] = useMutation(MODIFY_FIELDS);

    const [loader, setLoader] = useState(false);
    const [reinitialize, setReinitialize] = useState(false);
    const [fieldInfo, setFieldInfo] = useState<any>(null);
    const [initalValue, setInitialValue] = useState<object>({
        title: "",
        body: ""
    });

    useEffect(() => {
        if (!reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        if (pageData && !_.isEmpty(pageData.getSpecificBrandPage)) {
            const data = pageData.getSpecificBrandPage.containers[0].field_collections;
            let fields: any = {
                title: "",
                body: ""
            };

            fields["title"] = data[0].fields[0].value;
            fields["body"] = data[0].fields[1].value;

            setFieldInfo({
                title: data[0].fields[0],
                body: data[0].fields[1]
            });
            setInitialValue(fields);
            setReinitialize(true);
        }
    }, [pageData]);

    useEffect(() => {
        const data = pageDetails.payload;
        if (data.brandId) {
            getPage({
                variables: {
                    brand_id: data.brandId,
                    tag: `COMPANY-INFO-${upperCase(data.brandName)}`,
                    slug: `terms-and-conditions-${lowerCase(data.brandName)}`,
                },
            });
        }
    }, [pageDetails.payload, getPage]);

    const handleSubmit = async (values) => {
        setLoader(true);
        const data = pageDetails.payload;

        if (data.brandId && data.brandName && fieldInfo) {
            await handleUpdatePage(values);
        } else {
            await handleAddPage(values);
        }

        setTimeout(() => {
            handleRefresh();
            setLoader(false);
        }, 500);
    };

    const handleAddPage = async (values) => {
        const brandInfo = pageDetails.payload;
        const pageTag = `COMPANY-INFO-${upperCase(brandInfo.brandName)}`;
        const page = await createPage({
            variables: { brand_id: id, name: `Terms and Conditions - ${brandInfo.brandName}`, tag: pageTag },
        });

        const formInputs = [
            {
                label: "terms and conditions (default)",
                name: "TERMS-AND-CONDITIONS-TITLE",
                value: values.title ? values.title.trim() : "",
            },
            {
                label: "body",
                name: "BODY",
                value: values.body ? values.body.trim() : "",
            }
        ];

        if (page) {
            const {
                addOrUpdateBrandPage: { id: page_id },
            } = page.data;

            const containerData = [
                {
                    page_id,
                    name: `Terms and Conditions Container - ${brandInfo.brandName}`,
                    order: 0
                }
            ]

            const container = await createContainer({
                variables: {
                    data: {
                        containers: containerData
                    }
                },
            });

            const createdFC = await createMultipleFC({
                variables: {
                    data: {
                        collections: [
                            {
                                container_id: container.data.addOrUpdateContainers[0].id,
                                name: "Terms and Conditions FC",
                                order: 0
                            }
                        ],
                    },
                },
            });

            const fields = formInputs.map((f, fieldOrder) => ({
                collection_id: createdFC.data.addUpdateMultipleFieldCollection[0].id,
                label: f.label,
                name: f.name,
                value: f.value,
                order: fieldOrder,
            }));

            const res = await modifyFields({
                variables: {
                    data: {
                        fields: fields,
                    },
                },
            });

            res && message.success("SAVED!");
        }
    };

    const handleUpdatePage = async (values) => {
        let toUpdateFields: any = [];

        toUpdateFields.push({
            id: fieldInfo.title.id,
            label: "terms and conditions (default)",
            name: "TERMS-AND-CONDITIONS-TITLE",
            value: values.title ? values.title.trim() : "",
            order: 0,
        });

        toUpdateFields.push({
            id: fieldInfo.body.id,
            label: "body",
            name: "BODY",
            value: values.body ? values.body.trim() : "",
            order: 0,
        });

        const res = await modifyFields({
            variables: {
                data: {
                    fields: toUpdateFields,
                },
            },
        });

        res && message.success("Update success.");
    };

    return (
        <Spin spinning={loader}>
            <Formik
                initialValues={initalValue}
                validationSchema={validationSchema}
                enableReinitialize={reinitialize}
                onSubmit={(values: any) => {
                    handleSubmit(values);
                }}
                render={(formikBag) => (
                    <Form
                        {...{
                            formikBag,
                        }}
                    />
                )}
            />
        </Spin>
    );
};

export default TermsAndConditionsForms;
