import * as yup from "yup";

const imageSchema = yup.object().shape({
    status: yup.string().matches(/done/, { message: "file is still uploading" }),
});

export const validationSchema = yup.object().shape({
    title: yup.string(),
    body: yup.array().of(
        yup.object().shape({
            contentWeb: yup.string().required("Body Content is required"),
        })
    ),
    images: yup.array().of(imageSchema).nullable(),
});

export const defaultImageList = [
    {
        url: "",
        keyObject: "",
        base64: "",
        id: "",
        containerId: "",
    },
    {
        url: "",
        keyObject: "",
        base64: "",
        id: "",
        containerId: "",
    },
    {
        url: "",
        keyObject: "",
        base64: "",
        id: "",
        containerId: "",
    },
    {
        url: "",
        keyObject: "",
        base64: "",
        id: "",
        containerId: "",
    },
];
