import * as yup from "yup";
import validateUrl from "../../../../util/validate-url";

export const BASE_URL = `${process.env.REACT_APP_CONTENT_URL}/upload/mdor-docs`;
export const FILE_SIZE_LIMIT = 49;
export const SUPPORTED_FORMATS = ".pdf";

export const validationSchema = yup.object().shape({
    name: yup.string(),
    assignedProject: yup.string().required("Please choose a project."),
    file: yup.object().shape({
            name: yup.string(),
            status: yup.string().matches(/done/, { message: "file is still uploading" }).required("Please upload a document."),
            url: yup.string(),
            keyObj: yup.string().when(["url", "status"], (url: string, status: string, schema) => {
                return schema.test("fileFormat", "Invalid format.", (value) => {
                    url && validateUrl(url);
                    if (status === "done" && value) {
                        const regExp = /\.[0-9a-z]+$/i;
                        const file = value.match(regExp)[0];
                        return SUPPORTED_FORMATS.includes(file);
                    }
                    return true;
                })
            })
        })
});


export const columns = [
    {
        title: "DocumentID",
        key: "document_custom_id",
        dataIndex: "document_custom_id",
        sorter: true,
    },
    {
        title: "File Name",
        key: "document_name",
        dataIndex: "document_name",
        sorter: true
    },
    {
        title: "Assigned Project",
        key: "assigned_project",
        dataIndex: "assigned_project",
        sorter: true,
    },
    {
        title: "Date Created",
        key: "created_at",
        dataIndex: "created_at",
        sorter:true,
    },
    {
        title: "",
        key: "actions"
    }
];

export const defaultInitialValue = {
    name: "",
    assignedProject: ""
};