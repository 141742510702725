import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, FormItem, Input, Select, Checkbox } from "formik-antd";
import { Row, Col, Divider } from "antd";
import CustomButton from "../../../../components/CustomButton";
import ContentContainer from "../../../../components/ContentContainer";
import CustomSelect from "../../../../components/CustomSelect";
import { roleStatusList } from "./constant";
import { ITenantLists, IFeatures, IPermissions } from "./index";

interface Props {
    formikBag: any;
    tenantsList: ITenantLists[];
    featureList: IFeatures[];
    permissionList: IPermissions[];
    handleBrandChange: any;
    handleFeatureOnChange: any;
}


const Forms: React.SFC<Props> = ({
    formikBag,
    tenantsList,
    featureList,
    permissionList,
    handleBrandChange,
    handleFeatureOnChange,
}) => {
    const history = useHistory();
    const { roleId } = useParams();

    const { submitForm, setFieldValue, values } = formikBag;

    const onFeatureChange = (e) => {
        handleBrandChange(e);
        setFieldValue("feature", "");
    };

    return (
        <Form>
            <ContentContainer style={{ display: "flex", flexDirection: "column" }}>
                <Row justify="start">
                    <Col
                        span={8}
                        style={{
                            borderRight: "1px",
                            borderRightStyle: "solid",
                            borderColor: "#d9d9d9",
                            paddingRight: 16,
                        }}
                    >
                        <Row>
                            <Col span={24}>
                                <FormItem
                                    label={"Assign Brand"}
                                    name={"tenant"}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <CustomSelect
                                        name={"tenant"}
                                        placeholder={"Select Brand"}
                                        options={tenantsList}
                                        onChange={(e) => onFeatureChange(e)}
                                        disabled={!!roleId}

                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <FormItem
                                    label={"Role Name"}
                                    name={"name"}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <Input name="name" />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <FormItem
                                    label={"Role Description"}
                                    name={"description"}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <Input.TextArea
                                        name="description"
                                        style={{ height: "10rem" }}
                                    />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <FormItem
                                    label={"Role Status"}
                                    name={"isActive"}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <Select name={"isActive"} placeholder={"Select Status"}>
                                        {roleStatusList.map(({ label, value }, key) => (
                                            <Select.Option key={key} value={value}>
                                                {label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginTop: 30 }}>
                            <Col span={24} lg={12}>
                                <CustomButton
                                    type="primary"
                                    style={{ width: "100%" }}
                                    onClick={() => submitForm()}
                                >
                                    Save
                                </CustomButton>
                            </Col>
                            <Col span={24} lg={12}>
                                <CustomButton
                                    style={{ width: "100%" }}
                                    onClick={() => history.push(`/manage-roles`)}
                                >
                                    Cancel
                                </CustomButton>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={16} style={{ paddingLeft: 30 }}>
                        <Row>
                            <Col span={24}>
                                <FormItem
                                    label={"Select Features"}
                                    name={"feature"}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24, lg: 12 }}
                                >
                                    <Select
                                        name={"feature"}
                                        placeholder={"Select Features"}
                                        onChange={(e) => handleFeatureOnChange(e)}
                                    >
                                        {featureList &&
                                            featureList.map((item, key) => (
                                                <Select.Option key={key} value={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={24}>
                                <FormItem
                                    name={"featureArr"}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    {permissionList &&
                                        permissionList.map(({ id, description }, key) => (
                                            <React.Fragment key={key}>
                                                <Col span={24}>
                                                    <Checkbox
                                                        name={`permissionIds.${id}`}
                                                        value={id}
                                                    >
                                                        {description}
                                                    </Checkbox>
                                                </Col>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                </FormItem>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ContentContainer>
        </Form>
    );
};

export default Forms;
