import { UserActivity } from "../../../entities/UserActivity";
import moment from "moment";

export class UserActivityMapper {
    static map(userActivity: UserActivity): UserActivity {
        userActivity.createdAt = userActivity.createdAt
            ? moment(userActivity.createdAt).format("MMM DD, YYYY")
            : null;

        return userActivity;
    }
}
