import React from "react";
import { useHistory } from "react-router-dom";

import { Form, FormItem, Input } from "formik-antd";
import { Row, Col } from "antd";

import CustomButton from "../../../../../components/CustomButton";
import ContentContainer from "../../../../../components/ContentContainer";
import Richtext from "../../../../../components/Richtext";

interface Props {
    formikBag: any;
}

const Forms: React.FC<Props> = ({ formikBag }) => {
    const history = useHistory();
    const { submitForm, dirty, isValid, values, setFieldValue } = formikBag;

    return (
        <Form>
            <ContentContainer style={{ display: "flex", flexDirection: "column" }}>
                <Row justify="start">
                    <Col span={24}>
                        <FormItem
                            label={
                                <h1>
                                    <b>Terms and Conditions </b>(Default)
                                </h1>
                            }
                            name={"title"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name="title" placeholder="Override Label" />
                        </FormItem>
                    </Col>

                    <Col span={24}>
                        <FormItem
                            label={<b>Body</b>}
                            name={"body"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Richtext 
                                heading={true}
                                data={values.body}
                                onChange={(value: string) => setFieldValue("body", value)}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </ContentContainer>

            <Row justify="end" gutter={24} style={{ padding: "0 25px",marginBottom: 25 }}>
                <Col span={3}>
                    <CustomButton
                        disabled={!dirty || !isValid}
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={() => submitForm()}
                    >
                        SAVE
                    </CustomButton>
                </Col>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%" }}
                        onClick={() => history.push(`/manage-company-information`)}
                    >
                        CANCEL
                    </CustomButton>
                </Col>
            </Row>
        </Form>
    );
};

export default Forms;
