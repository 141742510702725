import styled from "styled-components";
import { Button, Card } from "antd";

export const Container = styled.div`
    .card-col {
        min-height: 200px;
        flex-basis: 20%;
        width: 20%;
    }

    .ant-tabs-nav-list > .ant-tabs-tab {
        padding: 12px 30px;
    }

    .ant-select {
        display: block;
    }

    .no-items {
        position: relative;
        height: 100%;
        width: 100%;

        .content {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #7F7F7F;

            & > * {
                display: block;
                width: 100%;
            }

            svg {
                width: 35px;
                height: 35px;
            }

            .text {
                text-align: center;
            }
        }
    }

    .ant-pagination {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .ant-pagination-total-text {
        margin-right: auto;
    }
`;

export const Content = styled.div`
    padding: 24px 46px;
`;

export const AddButton = styled(Button)`
    height: 100%;
    background-color: transparent;
    color: #00714b;
    border-color: #00714b;
`;

export const StyledCard = styled(Card)`
    height: 100%;

    .ant-tag {
        z-index: 999;
        padding: 0 24px;
    }

    .ant-card-cover {
        .img-container {
            position: relative;
            width: 100%;
            height: 150px;
            background-color: #AAA;

            img {
                object-fit: cover;
                width: 100%;
                height: 150px;
            }

            .video-thumbnail {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                min-height: 100%;
                min-width: 100%;
            }

            .category {
                position: absolute;
                right: 0;
                transform: translateY(50%);
                margin: 0; 
            }

            .anticon.anticon-play-circle {
                z-index: 99;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                font-size: 4em;
            }

            p {
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);
                background: #efeeee;
            }
        }
    }

    .ant-card-body {
        position: relative;
        padding-top: 0;
        padding-right: 12px;
        font-size: 0.85em;
        line-height: 1.5;

        
        h2 {
            margin: 0;
            font-size: 1.3em;
            font-weight: bold;
        }

        .featured {
            position: absolute;
            transform: translateY(-50%);
        }

        .description {
            display: flex;
            justify-content: space-between;
            padding-top: 12px;

            .ant-menu {
                order: 1;
                border: none;

                    .ant-menu-submenu {
                        border: none;

                        .ant-menu-submenu-title {
                            padding: 0;
                            margin: 0;
                        }

                        .anticon-ellipsis {
                            display: block;
                            margin: 0;
                            padding-left: 12px;
                            font-size: 1.5em;

                            svg {
                                display: block;
                            }
                        }
                
                        .ant-menu-submenu-arrow {
                            display: none;
                        }                  
                    }
            }

            .text {
                padding-top: 12px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                & > * {
                    display: block;
                }

                .name {
                    display: inline;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .small {
                    font-size: 0.75em;
                }            
            }
        }        
    }
`;