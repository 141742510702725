import React, { useEffect, useState, useContext } from "react";
import { Formik } from "formik";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import find from "lodash/find";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import upperCase from "lodash/upperCase";

import { Spin, message } from "antd";

import ContentContainer from "../../../../components/ContentContainer";
import CustomDrawer from "../../../../components/CustomDrawer";
import CustomTable from "../../../../components/CustomTable";
import Form from "./forms";
import { userPermission } from "../../../../util/user-access";

import { Context } from "../../../../context";
import {
    columns as cols,
    defaultInitialValue,
    validationSchema,
    validationSchemaNoDepartment,
} from "./constant";
import {
    GET_CAREER_LIST,
    GET_EMPLOYMENT_TYPES,
    MODIFY_EMPLOYMENT_TYPE,
    REMOVE_EMPLOYMENT_TYPE,
    GET_CAREER,
    MODIFY_CAREER,
} from "../../graphql";

import { Career } from "../../../../entities/Career";
import { CareerMapper } from "../../dto/CareerMapper";

const Careers: React.FC = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { id } = useParams();

    const {
        state: { brand: brandData, user },
    } = useContext(Context);
    const brandId = brandData?.id;
    const brandName = brandData?.name;
    const permissions = user?.roles[0].permissions;
    const createAccess = userPermission(permissions, "create:careers");
    const updateAccess = userPermission(permissions, "update:careers");
    const [columns, setColumns] = useState<any[]>(cols);
    const [careerList, setCareerList] = useState<Career[]>([]);
    const [showVisible, setShowVisible] = useState<boolean>(true);
    const [sortOrder, setSortOrder] = useState<string>("DESC");
    const [employmentType, setEmploymentType] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [search, setSearch] = useState<string>("");
    const [visibleCareers, setVisibleCareers] = useState<number>(0);
    const [hiddenCareers, setHiddenCareers] = useState<number>(0);
    const [showSlider, setShowSlider] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [employmentTypeDataState, setEmploymentTypeDataState] = useState([]);
    const [reinitialize, setReinitialize] = useState(false);
    const [initialValue, setInitialValue] = useState(defaultInitialValue);

    const {
        error,
        refetch,
        loading: getCareerListLoading,
        data,
    } = useQuery(GET_CAREER_LIST, {
        skip: !brandId,
        variables: {
            brand_id: brandId,
            page: page,
            limit: pageSize,
            search: search,
            is_visible: showVisible,
            employment_type: employmentType,
            location: location,
            sort: sortOrder,
        },
        fetchPolicy: "no-cache",
        partialRefetch: true,
    });

    const [removeEmploymentType, { loading: removeEmploymentTypeLoading }] =
        useMutation(REMOVE_EMPLOYMENT_TYPE);
    const [modifyEmploymentType, { loading: modifyEmploymentTypeLoading }] =
        useMutation(MODIFY_EMPLOYMENT_TYPE);
    const [modifyCareer, { loading: modifyCareerLoading }] = useMutation(MODIFY_CAREER);
    const {
        data: employmentTypeData,
        refetch: refetchEmploymentType,
        loading: getEmployementTypeLoader,
    } = useQuery(GET_EMPLOYMENT_TYPES, {
        skip: !brandId,
        variables: {
            brand_id: brandId,
        },
        fetchPolicy: "no-cache",
    });
    const { data: careerData, loading: getCareerLoading } = useQuery(GET_CAREER, {
        skip: !id || !employmentTypeData || !brandId,
        fetchPolicy: "no-cache",
        variables: {
            id: id,
        },
    });

    useEffect(() => {
        setLoading(true);

        if (data && data.getFilteredCareers) {
            const { getFilteredCareers } = data;

            if (getFilteredCareers.careers) {
                const { careers } = getFilteredCareers;
                const res = careers.map((info: Career) =>
                    Object.assign([], CareerMapper.map({ ...info }))
                );
                setCareerList(res);
            }

            getFilteredCareers.total_visible && setVisibleCareers(getFilteredCareers.total_visible);
            getFilteredCareers.total_hidden && setHiddenCareers(getFilteredCareers.total_hidden);

            if (getFilteredCareers.location_filter_opts) {
                const filters = getFilteredCareers.location_filter_opts.map((opt) => ({
                    text: opt,
                    value: opt,
                }));

                const newColumns: any = columns;
                newColumns.find((col) => col.dataIndex === "location").filters = filters;
                setColumns(newColumns);
            }
        }

        if (error) {
            message.error("Something went wrong.");
        }

        setLoading(false);
    }, [data, error]);

    useEffect(() => {
        if (has(careerData, "getCareer")) {
            const data = careerData.getCareer;
            const fields = {
                careerTitle: data.title ? data.title : "",
                employmentType: data.employmentType ? data.employmentType.id : null,
                applyBefore: moment(data.apply_before_at),
                location: data.location ? data.location : "",
                jobDescriptionDefault: data.job_description_label ? data.job_description_label : "",
                jobDescriptionBody: data.job_description ? data.job_description : "",
                qualificationDefault: data.qualifications_label ? data.qualifications_label : "",
                qualificationBody: data.qualifications ? data.qualifications : "",
                tags: data.tags ? data.tags : [],
                status: data.isVisible,
                seoCareersTitle: data.seo_careers_title ? data.seo_careers_title : "",
                seoCareersDescription: data.seo_careers_description
                    ? data.seo_careers_description
                    : "",
                careersHeadingTag: data.careers_heading_tag ? data.careers_heading_tag : "",
            };

            setInitialValue(fields);
            setReinitialize(true);
        }
    }, [careerData]);

    useEffect(() => {
        if (id) {
            setShowSlider(true);
        }
    }, [id]);

    useEffect(() => {
        if (reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        if (employmentTypeData) {
            const data = employmentTypeData.getEmploymentTypes;
            const mappedData = data.map((res) => ({
                id: res.id,
                label: res.name,
                value: res.id,
                description: res.description,
            }));
            setEmploymentTypeDataState(mappedData);

            const filters = mappedData.map(({ label }) => ({
                text: label,
                value: label,
            }));

            const newColumns: any = columns;
            newColumns.find((col) => col.dataIndex === "empType").filters = filters;
            setColumns(newColumns);
        }
    }, [employmentTypeData]);

    const handleAddOption = async (value) => {
        await modifyEmploymentType({
            variables: {
                name: value.value,
                description: value.description,
            },
        });
        refetchEmploymentType();
    };

    const handleRemoveOption = async ({ value, setFieldValue }) => {
        const id = find(employmentTypeDataState, (e, i) => i === value).id;
        await removeEmploymentType({
            variables: {
                data: {
                    ids: [id],
                },
            },
        });
        setFieldValue("employmentType", null);
        refetchEmploymentType();
    };

    const handleStatusBtn = (status) => {
        setShowVisible(status);
    };

    const handleSearch = (query) => {
        query.trim() !== search && setSearch(query.trim());
    };

    const resetForm = () => {
        setInitialValue(defaultInitialValue);
        setReinitialize(true);
    };

    const handleSlider = () => {
        setShowSlider(!showSlider);
        if (showSlider) {
            history.push("/manage-careers/careers");
            resetForm();
        }
    };

    const openSlider = () => {
        history.push("/manage-careers/careers");
        resetForm();
        setShowSlider(true);
    };

    const handleSort = (field, order) => {
        setSortOrder(upperCase(order));
    };

    const handleFilter = (filters) => {
        if (filters) {
            !isEmpty(filters.empType)
                ? setEmploymentType(filters.empType[0])
                : setEmploymentType("");
            !isEmpty(filters.location) ? setLocation(filters.location[0]) : setLocation("");
        }
    };

    const handleSelectedRow = (record) => {
        history.push(`${match.path}/${record.id}`);
    };

    const handlePageSize = async (pageSize) => {
        setPageSize(pageSize);
    };

    const handlePagination = async (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const handleSubmit = async (values) => {
        const msg = id ? "Update Success" : "Careers succesfully created";
        const fields = {
            brand_id: brandId,
            location: values.location ? values.location : null,
            title: values.careerTitle ? values.careerTitle : null,
            employment_type_id: values.employmentType ? values.employmentType : null,
            isVisible: values.status,
            apply_before_at: moment(values.applyBefore).format("l"),
            job_description_label: values.jobDescriptionDefault
                ? values.jobDescriptionDefault
                : null,
            job_description: values.jobDescriptionBody ? values.jobDescriptionBody : null,
            qualifications_label: values.qualificationDefault ? values.qualificationDefault : null,
            qualifications: values.qualificationBody ? values.qualificationBody : null,
            tags: values.tags,
            seo_careers_description: values.seoCareersDescription
                ? values.seoCareersDescription
                : null,
            seo_careers_title: values.seoCareersTitle ? values.seoCareersTitle : null,
            careers_heading_tag: values.careersHeadingTag ? values.careersHeadingTag : null,
        };

        if (id) {
            fields["id"] = id;
        }

        await modifyCareer({
            variables: {
                data: fields,
            },
        });

        resetForm();
        handleSlider();
        setLoading(true);
        message.success(msg);
        setTimeout(async () => {
            await refetch();
            setLoading(false);
        }, 1000);
    };

    return (
        <ContentContainer>
            <CustomTable
                updateAccess={updateAccess}
                headingText="Careers"
                labelText="Career"
                overrideActiveLabel={["Visible", "Hidden"]}
                isActive={showVisible}
                activeNum={visibleCareers}
                inactiveNum={hiddenCareers}
                handleStatusBtn={handleStatusBtn}
                handleSearch={handleSearch}
                handleAddBtn={openSlider}
                noAddBtn={!createAccess}
                columns={columns}
                dataSource={careerList}
                handleSort={handleSort}
                handleFilter={handleFilter}
                handleSelectedRow={handleSelectedRow}
                currentPageSize={pageSize}
                handlePageSize={handlePageSize}
                handlePagination={handlePagination}
                loading={loading || getCareerListLoading}
            />

            <CustomDrawer
                visible={showSlider}
                title={`${id ? "Edit" : "Add"} Career`}
                onClose={handleSlider}
            >
                <Spin
                    spinning={
                        getEmployementTypeLoader ||
                        modifyEmploymentTypeLoading ||
                        removeEmploymentTypeLoading ||
                        getCareerLoading ||
                        modifyCareerLoading
                    }
                >
                    {!reinitialize && (
                        <Formik
                            initialValues={initialValue}
                            validationSchema={
                                brandName !== "ALP"
                                    ? validationSchema
                                    : validationSchemaNoDepartment
                            }
                            enableReinitialize={reinitialize}
                            onSubmit={(values) => {
                                handleSubmit(values);
                            }}
                            render={(formikBag) => (
                                <Form
                                    {...{
                                        formikBag,
                                        handleSlider,
                                        employmentTypeData: employmentTypeDataState,
                                        loading: false,
                                        handleAddOption,
                                        handleRemoveOption,
                                        brandName,
                                    }}
                                />
                            )}
                        />
                    )}
                </Spin>
            </CustomDrawer>
        </ContentContainer>
    );
};

export default Careers;
