import styled from "styled-components";

export const StyledEditor = styled.div`
    width: 100%;
    .ck.ck-reset.ck-editor.ck-rounded-corners {
        .ck.ck-toolbar.ck-toolbar_grouping {
            background: #fff;

            .ck.ck-button {
                font-size: 12px;
            }
        }

        .ck.ck-editor__main {
            .ck.ck-content.ck-focused {
                border-color: rgba(0, 0, 0, 0.25);
            }
        }
    }
`;
