import React from "react";

import { FormItem, Input } from "formik-antd";
import { Row, Col } from "antd";

import { RemoveButton, StyledUploadImage } from "./styled";
import CustomButton from "../../../../components/CustomButton";
import ContentContainer from "../../../../components/ContentContainer";
import { getAcceptedFiles } from "../../../../util/get-accepted-filetypes";
import { getUploadNote } from "../../../../util/get-upload-note";

import { RenderLinkFieldTypes } from "../../manage-links.types";

export const renderLinkField = ({
    event,
    values,
    errors,
    setFieldValue,
    brandName,
}: RenderLinkFieldTypes): JSX.Element => {
    return (
        <>
            {values.map((res, index) => {
                const name = `links[${index}]`;

                return (
                    <ContentContainer
                        key={name}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "30px",
                            position: "relative",
                        }}
                    >
                        <Row justify="start">
                            <Col span={6}>
                                <StyledUploadImage
                                    accept={getAcceptedFiles(brandName)}
                                    value={res.image}
                                    noteMessage={getUploadNote(brandName)}
                                    onChange={(e) => {
                                        setFieldValue(`${name}.image`, e);
                                    }}
                                >
                                    <h1>+ Upload Image</h1>
                                </StyledUploadImage>
                                {brandName === "ALP" ? (
                                    <Row>
                                        <FormItem
                                            label="Image Alt Text"
                                            name={`${name}.image.altText`}
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            style={{ width: "100%" }}
                                        >
                                            <Input
                                                name={`${name}.image.altText`}
                                                style={{ width: "90%" }}
                                            />
                                        </FormItem>
                                    </Row>
                                ) : null}
                            </Col>
                            <Col
                                span={18}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <Row>
                                    <FormItem
                                        label={"Name"}
                                        name={`${name}.name`}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        style={{ width: "100%" }}
                                    >
                                        <Input name={`${name}.name`} style={{ width: "100%" }} />
                                    </FormItem>
                                </Row>
                                <Row>
                                    {" "}
                                    <FormItem
                                        label={"Link"}
                                        name={`${name}.link`}
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        style={{ width: "100%" }}
                                    >
                                        <Input name={`${name}.link`} />
                                    </FormItem>
                                </Row>
                            </Col>
                        </Row>
                        <RemoveButton
                            onClick={() => {
                                event.remove(index);
                            }}
                        />
                    </ContentContainer>
                );
            })}
            <div style={{ display: "flex", flexDirection: "column", padding: "0 30px 30px" }}>
                <CustomButton
                    type="dashed"
                    style={{
                        width: "100%",
                        background: "#fafafa",
                        fontSize: 12,
                    }}
                    onClick={() =>
                        event.push({
                            name: "",
                            link: "",
                            image: null,
                        })
                    }
                >
                    <p style={{ margin: 0 }}>+ Add Row</p>
                </CustomButton>
            </div>
        </>
    );
};
