import React, { ReactChildren, useState, useEffect } from "react";
import { Spin } from "antd";

import { CloseOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { message, Modal, Input, Button, Row, Col } from "antd";

import { ButtonStyle, RemoveBtn, Container, WrapperPreview } from "./styled";

import validateUrl from "../../util/validate-url";

interface Props {
    value: any;
    onChange: any;
    className?: string;
    children?: ReactChildren;
    label?: string;
    placeholder?: string;
}

const UploadVideoLink: React.FC<Props> = ({
    value,
    onChange,
    className,
    children,
    placeholder,
    label,
}) => {
    const [visible, setVisible] = useState(false);
    const [link, setLink] = useState("");

    useEffect(() => {
        setLink(value);
    }, [value]);

    const previewFile = (value) => {
        return (
            <Spin spinning={value.status === "uploading"}>
                <WrapperPreview className={"uploaded-file"}>
                    {value && <embed src={value} />}
                </WrapperPreview>
            </Spin>
        );
    };

    const UploadVideoBtn = () => {
        return (
            <ButtonStyle
                type="dashed"
                className="upload-button"
                onClick={() => {
                    setVisible(true);
                }}
            >
                {children ? (
                    children
                ) : (
                    <div className="upload-wrapper">
                        <PlayCircleOutlined />
                        <h1>Upload Video Link</h1>
                    </div>
                )}
            </ButtonStyle>
        );
    };

    const handleOk = () => {
        if (validateUrl(link)) {
            setVisible(false);
            onChange(link);
        } else {
            message.error("invalid video link");
        }
    };

    const handleCancel = () => {
        setLink("");
        setVisible(false);
    };

    return (
        <div>
            <Container className={className}>
                {value && previewFile(value)}

                {!value && UploadVideoBtn()}

                {value && (
                    <RemoveBtn className={"remove-file"} onClick={() => onChange("")}>
                        <CloseOutlined style={{ fontSize: 15 }} />
                    </RemoveBtn>
                )}
            </Container>
            <Modal visible={visible} footer={false} closable={false}>
                <p>{label}</p>
                <Input
                    name="link"
                    value={link}
                    placeholder={placeholder}
                    onChange={(e) => setLink(e.target.value)}
                />

                <Row justify="end" gutter={12} style={{ padding: "15px 0 0" }}>
                    <Col>
                        <Button type={"ghost"} onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Col>
                    <Col>
                        <Button type={"primary"} onClick={handleOk}>
                            Ok
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
};

export default UploadVideoLink;
