import React from "react";
import { Row, Col, Input, Radio, Button, Cascader } from "antd";
import { Container, StyledTable as Table } from "./styled";

interface Props {
    createAccess?: boolean;
    updateAccess?: boolean;
    deleteAccess?: boolean;
    headingText: string;
    labelText: string;
    searching?: any;
    columns: object[];
    dataSource: object[];
    totalData?: number;
    noStatusBtn?: boolean;
    isActive?: boolean;
    overrideActiveLabel?: string[];
    activeNum?: number;
    inactiveNum?: number;
    handleStatusBtn?: (status) => void;
    handleSearch?: (query) => void;
    overrideAddLabel?: string;
    handleAddBtn?: any;
    noAddBtn?: boolean;
    addBtnCascader?: boolean;
    addBtnCascaderOptions?: object[];
    handleSort?: (field: any, order: any) => void;
    handleFilter?: (filters: any) => void;
    handleSelectedRow?: (record) => void;
    currentPageSize?: number;
    handlePageSize?: (pageSize) => void;
    handlePagination?: (page, pageSize) => void;
    loading?: boolean;
}

const CustomTable: React.FC<Props> = ({
    createAccess,
    updateAccess,
    deleteAccess,
    searching,
    headingText,
    labelText,
    columns,
    dataSource,
    totalData,
    noStatusBtn,
    isActive,
    overrideActiveLabel,
    activeNum,
    inactiveNum,
    handleStatusBtn,
    handleSearch,
    noAddBtn,
    overrideAddLabel,
    handleAddBtn,
    addBtnCascader,
    addBtnCascaderOptions,
    handleSort,
    handleFilter,
    handleSelectedRow,
    currentPageSize,
    handlePageSize,
    handlePagination,
    loading,
}) => {
    const headingSpan = (noStatusBtn, noAddBtn) => {
        if (noStatusBtn && noAddBtn) {
            return 18;
        } else if (noStatusBtn) {
            return 14;
        } else if (noAddBtn) {
            return 12;
        } else {
            return 8;
        }
    };

    const dataTotal = (isActive, activeNum, inactiveNum) => {
        if (isActive) {
            if (activeNum) {
                return activeNum;
            }
        } else {
            if (inactiveNum) {
                return inactiveNum;
            }
        }
        return 0;
    };

    const showTotal = (total, range) => {
        return `${range[0]}-${range[1]} of ${total} items`;
    };

    const onStatusChange = (e) => {
        let showActive = false;
        if (e.target.value === "active") {
            showActive = true;
        }
        handleStatusBtn && handleStatusBtn(showActive);
    };

    const onSearch = (query) => {
        handleSearch && handleSearch(query.trim());
    };

    const onChange = (sorter, filters, extra) => {
        let sortOrder: string = "desc";

        if (extra.order && extra.order === "ascend") {
            sortOrder = "asc";
        }

        extra.field && handleSort && handleSort(extra.field, sortOrder);
        filters && handleFilter && handleFilter(filters);
        return;
    };

    const onRow = (record) => {
        return {
            onClick: (e) => {
                if (!updateAccess) return e.preventDefault();
                handleSelectedRow && handleSelectedRow(record);
            },
        };
    };

    const onPageSizeChange = (pageSize) => {
        handlePageSize && handlePageSize(pageSize);
    };

    const onPaginate = (page, pageSize) => {
        handlePagination && handlePagination(page, pageSize);
    };

    const { Search } = Input;

    return (
        <Container>
            <Row gutter={[16, 16]}>
                <Col span={headingSpan(noStatusBtn, noAddBtn)}>
                    <h1>{headingText}</h1>
                </Col>
                {noStatusBtn ? null : (
                    <Col span={6} data-index="status-container">
                        <Radio.Group
                            name="status"
                            defaultValue="active"
                            onChange={onStatusChange}
                            optionType="button"
                        >
                            <Radio.Button value="active" className="status-btn">
                                <span className="btn-text">
                                    <span className="status-text">
                                        {overrideActiveLabel && overrideActiveLabel.length === 2
                                            ? overrideActiveLabel[0]
                                            : "Active"}
                                    </span>
                                    <span
                                        className={isActive ? "status-num selected" : "status-num"}
                                    >
                                        {activeNum ? activeNum : 0}
                                    </span>
                                </span>
                            </Radio.Button>
                            <Radio.Button value="inactive" className="status-btn">
                                <span className="btn-text">
                                    <span className="status-text">
                                        {overrideActiveLabel && overrideActiveLabel.length === 2
                                            ? overrideActiveLabel[1]
                                            : "Inactive"}
                                    </span>
                                    <span
                                        className={!isActive ? "status-num selected" : "status-num"}
                                    >
                                        {inactiveNum ? inactiveNum : 0}
                                    </span>
                                </span>
                            </Radio.Button>
                        </Radio.Group>
                    </Col>
                )}
                <Col span={6}>
                    <Search
                        placeholder={`Search ${labelText}`}
                        onSearch={onSearch}
                        data-index="search-bar"
                        onChange={searching ? (e) => searching(e) : () => {}}
                    />
                </Col>
                {noAddBtn ? null : (
                    <Col span={4}>
                        {addBtnCascader ? (
                            <Cascader
                                options={addBtnCascaderOptions}
                                size="large"
                                onChange={handleAddBtn}
                            >
                                <Button type="primary" data-index="add-btn" block={true}>
                                    Add {labelText}
                                </Button>
                            </Cascader>
                        ) : (
                            <Button
                                type="primary"
                                onClick={handleAddBtn}
                                data-index="add-btn"
                                block={true}
                            >
                                {overrideAddLabel ? overrideAddLabel : `Add ${labelText}`}
                            </Button>
                        )}
                    </Col>
                )}
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        pagination={{
                            total: totalData
                                ? totalData
                                : dataTotal(isActive, activeNum, inactiveNum),
                            defaultPageSize: currentPageSize,
                            showSizeChanger: true,
                            showTotal: showTotal,
                            onShowSizeChange: onPageSizeChange,
                            onChange: onPaginate,
                        }}
                        onChange={onChange}
                        onRow={onRow}
                        className={handleSelectedRow && "onrow-enabled"}
                        data-index="table"
                        loading={loading ? loading : false}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default CustomTable;
