import isEmpty from "lodash/isEmpty";
import moment from "moment";

import { Content } from "../../../entities/Content";
import { LinkedPage } from "./LinkedPage";

export class LinkedContentMapper {
    static map(page: LinkedPage): Content {
        const overviewFields = 
            !isEmpty(page.containers) &&
            !isEmpty(page.containers[0].field_collections) &&
            !isEmpty(page.containers[0].field_collections[0].fields) &&
            page.containers[0].field_collections[0].fields;

        page.type =
            overviewFields &&
            !isEmpty(overviewFields.filter(field => field.name === "CATEGORY")) &&
            overviewFields.filter(field => field.name === "CATEGORY")[0].value ?
            overviewFields.filter(field => field.name === "CATEGORY")[0].value : "No category";

        page.excerpt = 
            overviewFields &&
            !isEmpty(overviewFields.filter(field => field.name === "SHORT-DESCRIPTION")) &&
            overviewFields.filter(field => field.name === "SHORT-DESCRIPTION")[0].value &&
            overviewFields.filter(field => field.name === "SHORT-DESCRIPTION")[0].value;

        page.imgURL = 
            overviewFields &&
            !isEmpty(overviewFields.filter(field => field.name === "Image")) &&
            overviewFields.filter(field => field.name === "Image")[0].value &&
            overviewFields.filter(field => field.name === "Image")[0].value;

        page.date = page.updated_at ? moment(page.updated_at).format("MMM DD, YYYY") : moment(page.created_at).format("MMM DD, YYYY");
        
        delete page.containers;
        delete page.__typename;

        return page;
    }
}