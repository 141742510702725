import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import _ from "lodash";

import { Formik } from "formik";
import { Spin, message } from "antd";

import Forms from "./forms";
import { Context } from "../../../../context";
import { CREATE_PAGE, GET_PAGE, CREATE_CONTAINER } from "../../graphql";
import generateId from "../../../../util/generate-id";
import { validationSchema } from "./constant";

type FieldInfoValueChildTypes = {
    imageGalleryId: string;
    imageGallery?: [];
    videosId: string;
    videos?: [];
    panoramasId: string;
    panoramas?: [];
};

type FieldInfoValueTypes = {
    containerId: string;
    fcId: string;
    fields: string[];
    children: FieldInfoValueChildTypes;
};

type FormValueTypes = {
    careersDefault: string;
    description: string;
    imageGallery: [];
    imagesCaptions: string[];
    videos: string[];
    panoramas: string[];
};

const processImageFields = (res) => {
    let imageFields = [];
    let imageChildren = [];

    if (res.fields) {
        imageFields = res.fields.map((image) => ({
            uid: generateId(),
            caption: image.meta_data.description,
            isChecked: image.meta_data.featured,
            url: image.value,
            keyObj: image.meta_data.key,
            id: image.id,
            status: "done",
        }));

        imageChildren = res.fields.map((image) => ({
            id: image.id,
            value: !_.isEmpty(image.value) ? image.value : null,
            caption: image.meta_data.description,
            isChecked: image.meta_data.featured,
            order: image.order,
        }));
    }

    return { imageFields, imageChildren };
};

const CareersInformation = (): JSX.Element => {
    const history = useHistory();

    const {
        state: { brand: brandData },
    } = useContext(Context);
    const brandId = brandData?.id;
    const brandName = brandData?.name;

    const [createPage] = useMutation(CREATE_PAGE);
    const [createContainer] = useMutation(CREATE_CONTAINER);
    const {
        data: pageData,
        refetch,
        loading,
    } = useQuery(GET_PAGE, {
        skip: !brandId,
        fetchPolicy: "no-cache",
        variables: {
            brand_id: brandId,
            slug: `careers-information-details-${_.lowerCase(brandName)}`,
            tag: `CAREERS-INFORMATION-DETAILS-${_.upperCase(brandName)}`,
        },
    });

    const [loader, setLoader] = useState<boolean>(false);
    const [reinitialize, setReinitialize] = useState<boolean>(false);

    const fieldInfoDefaultValues: FieldInfoValueTypes = {
        containerId: "",
        fcId: "",
        fields: [],
        children: {
            imageGalleryId: "",
            imageGallery: [],
            videosId: "",
            videos: [],
            panoramasId: "",
            panoramas: [],
        },
    };

    const [fieldInfo, setFieldInfo] = useState<FieldInfoValueTypes>(fieldInfoDefaultValues);

    const initialValueDefaultValues: FormValueTypes = {
        careersDefault: "",
        description: "",
        imageGallery: [],
        imagesCaptions: [],
        videos: [""],
        panoramas: [""],
    };

    const [initialValue, setInitialValue] = useState<FormValueTypes>(initialValueDefaultValues);

    useEffect(() => {
        if (!reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        if (pageData && _.has(pageData, "getSpecificBrandPage")) {
            const data = pageData.getSpecificBrandPage.containers;
            const field_collections = data[0].field_collections;
            const fieldItem: any = [];

            const fields: FormValueTypes = {
                careersDefault: "",
                description: "",
                imageGallery: [],
                imagesCaptions: [],
                videos: [""],
                panoramas: [""],
            };

            const children: FieldInfoValueChildTypes = {
                imageGalleryId: "",
                videosId: "",
                panoramasId: "",
            };

            field_collections.forEach((res) => {
                if (res.children) {
                    res.children.forEach((item) => {
                        if (item.name === "IMAGE-GALLERY") {
                            const { imageFields, imageChildren } = processImageFields(item);
                            children["imageGalleryId"] = item.id;
                            fields["imagesCaptions"] = imageFields.map(({ caption }) => caption);
                            fields[_.camelCase(item.name)] = imageFields;
                            children[_.camelCase(item.name)] = imageChildren;
                        } else if (item.name === "VIDEOS" || item.name === "PANORAMAS") {
                            children[`${_.camelCase(item.name)}Id`] = item.id;
                            children[_.camelCase(item.name)] = item.fields.map((f) => ({
                                value: f.value ? f.value : "",
                                id: f.id,
                            }));
                            fields[_.camelCase(item.name)] = item.fields.map((f) => f.value);
                        } else {
                            children[_.camelCase(item.name)] = [];
                        }
                    });
                }

                if (res.fields) {
                    res.fields.forEach((item) => {
                        fields[_.camelCase(item.name)] = item.value;
                        fieldItem.push(item);
                    });
                }
            });

            setFieldInfo({
                containerId: data[0].id,
                fcId: data[0].field_collections[0].id,
                fields: fieldItem,
                children: children,
            });
            setInitialValue(fields);
            setReinitialize(true);
        }
    }, [pageData]);

    const getFieldID = (fields, targetKey) => {
        if (fields) {
            const filter = fields.filter((item: any) => item.name === targetKey);
            if (filter.length > 0) {
                return filter[0].id;
            }
        }
        return null;
    };

    const fieldMapper = (label, name, newValue, order, filter) => {
        const fieldId = getFieldID(filter, name);
        return {
            ...(!_.isEmpty(fieldId) && { id: fieldId }),
            label: label,
            name: name,
            value: !_.isEmpty(newValue) ? newValue : null,
            order: order,
        };
    };

    const imageGalleryMapper = (values, fields) => {
        const imageGalleryItems = values.map((item, index) => {
            return {
                ...(!_.isEmpty(item.id) && { id: item.id }),
                order: index,
                name: "Image",
                label: "Image",
                value: item.keyObj,
                meta_data: {
                    featured: item.isChecked,
                    description: item.caption,
                    key: item.keyObj,
                },
            };
        });
        return imageGalleryItems;
    };

    const linksMapper = (values, fields, name) => {
        const linkItems = values.map((item, index) => {
            const fieldId = fields.length - 1 > index ? fields[index].id : null;
            return {
                ...(!_.isEmpty(fieldId) && { id: fieldId }),
                order: index,
                name: _.upperCase(name),
                label: name,
                value: item,
            };
        });
        return linkItems;
    };

    const containerMapper = (values, page_id) => {
        const filter: any = fieldInfo.fields;
        const containers: any = [
            {
                order: 0,
                ...(!_.isEmpty(fieldInfo.containerId) && { id: fieldInfo.containerId }),
                page_id: page_id,
                name: "CAREERS-INFORMATION",
                field_collections: [
                    {
                        ...(!_.isEmpty(fieldInfo.fcId) && { id: fieldInfo.fcId }),
                        name: "CAREERS-INFORMATION-DETAILS",
                        order: 0,
                        fields: [
                            fieldMapper(
                                "Careers (Default)",
                                "CAREERS-DEFAULT",
                                values.careersDefault,
                                0,
                                filter
                            ),
                            fieldMapper(
                                "Description",
                                "DESCRIPTION",
                                values.description,
                                1,
                                filter
                            ),
                        ],
                        children: [
                            {
                                ...(!_.isEmpty(fieldInfo.children["imageGalleryId"]) && {
                                    id: fieldInfo.children["imageGalleryId"],
                                }),
                                order: 0,
                                name: "IMAGE-GALLERY",
                                fields: imageGalleryMapper(
                                    values.imageGallery,
                                    fieldInfo.children.imageGallery
                                ),
                            },
                            {
                                ...(!_.isEmpty(fieldInfo.children["videosId"]) && {
                                    id: fieldInfo.children["videosId"],
                                }),
                                order: 1,
                                name: "VIDEOS",
                                fields: linksMapper(
                                    values.videos,
                                    fieldInfo.children.videos,
                                    "video"
                                ),
                            },
                            {
                                ...(!_.isEmpty(fieldInfo.children["panoramasId"]) && {
                                    id: fieldInfo.children["panoramasId"],
                                }),
                                order: 2,
                                name: "PANORAMAS",
                                fields: linksMapper(
                                    values.panoramas,
                                    fieldInfo.children.panoramas,
                                    "panorama"
                                ),
                            },
                        ],
                    },
                ],
            },
        ];
        return containers;
    };

    const handleSubmit = async (values) => {
        setLoader(true);
        await handleAddUpdatePage(values);
    };

    const handleAddUpdatePage = async (values) => {
        let update = false;
        let pageId = null;

        if (pageData && _.has(pageData, "getSpecificBrandPage")) {
            pageId = pageData.getSpecificBrandPage.id;
            update = true;
        } else {
            const page = await createPage({
                variables: {
                    brand_id: brandData.id,
                    name: `Careers Information Details - ${brandName}`,
                    tag: `CAREERS-INFORMATION-DETAILS-${brandName}`,
                },
            });
            const {
                addOrUpdateBrandPage: { id: page_id },
            } = page.data;
            pageId = page_id;
        }

        if (pageId) {
            const containers = containerMapper(values, pageId);
            await createContainer({
                variables: {
                    data: {
                        containers: containers,
                    },
                },
            });

            refetch();
            message.success(
                update ? "Update success." : "Careers Information Details successfully created."
            );

            setTimeout(() => {
                setLoader(false);
            }, 1000);
            !update && history.push(`/manage-careers/careers-information-details`);
        }
    };

    return (
        <Spin spinning={loading || loader}>
            <Formik
                initialValues={initialValue}
                validationSchema={validationSchema}
                enableReinitialize={reinitialize}
                onSubmit={(values: FormValueTypes) => {
                    handleSubmit(values);
                }}
                render={(formikBag) => (
                    <Forms
                        {...{
                            formikBag,
                            brandName,
                        }}
                    />
                )}
            />
        </Spin>
    );
};

export default CareersInformation;
