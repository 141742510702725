import React, { useState } from "react";
import { FieldArray } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import CustomButton from "../../../../../../components/CustomButton";
import FeaturedImages from "../../../../../../components/FeaturedImages";
import { SectionWrapper, StyledIFrame } from "./styled";
import MultipleUrlFields from "../../../../../../components/MultipleUrlFields";
import SeoForm from "@/components/SeoForm";

interface Props {
    formikBag: any;
    brandName: string;
}

const Forms = ({ formikBag, brandName }: Props): JSX.Element => {
    const [modalVisible, setModalVisible] = useState(false);
    const [preview, setPreview] = useState("");
    const { values, setFieldValue, submitForm, errors, isValid, dirty } = formikBag;

    const handleClosePreview = () => {
        setPreview("");
        setModalVisible(false);
    };

    const handlePreview = (val) => {
        if (val) {
            setPreview(val);
            setModalVisible(true);
        }
    };

    const renderAmenityList = ({ event, values, errors, setFieldValue }) => {
        return (
            <Col span={24}>
                {values.amenityList.map(({ images, videos, panoramas, imagesCaptions }, key) => (
                    <SectionWrapper key={imagesCaptions}>
                        <FormItem
                            label={"Amenity Name"}
                            name={`amenityList[${key}].amenityName`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name={`amenityList[${key}].amenityName`} />
                        </FormItem>

                        <FormItem
                            name={`amenityList[${key}].images`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <FeaturedImages
                                onChange={(e) => {
                                    const captions = e.map(({ caption }) => caption);
                                    setFieldValue(`amenityList[${key}].imagesCaptions`, captions);
                                    setFieldValue(`amenityList[${key}].images`, e);
                                }}
                                path={"properties-amenities"}
                                fileList={images}
                                captionList={imagesCaptions}
                                validate={true}
                            />
                        </FormItem>

                        <Row gutter={24}>
                            <Col span={12}>
                                <p>Video</p>
                                <MultipleUrlFields
                                    values={videos}
                                    placeholder="Video Link"
                                    name={`amenityList[${key}].videos`}
                                    preview={handlePreview}
                                />
                            </Col>
                            <Col span={12}>
                                <p>Panorama</p>
                                <MultipleUrlFields
                                    values={panoramas}
                                    placeholder="Panorama Link"
                                    name={`amenityList[${key}].panoramas`}
                                    preview={handlePreview}
                                />
                            </Col>
                        </Row>

                        <CloseOutlined
                            className="remove-btn"
                            onClick={() => {
                                event.remove(key);
                            }}
                        />
                    </SectionWrapper>
                ))}
                <CustomButton
                    type="dashed"
                    className="add-row"
                    style={{ width: "100%", marginBottom: 15 }}
                    onClick={() => {
                        event.push({
                            amenityName: "",
                            images: [],
                            imagesCaptions: [],
                            videos: [""],
                            panoramas: [""],
                        });
                    }}
                >
                    <p style={{ margin: 0 }}>+ Add Row</p>
                </CustomButton>
            </Col>
        );
    };

    return (
        <Form>
            <SeoForm category={"Amenities"} />
            <h1>
                <b>Amenities</b> (Default)
            </h1>
            <Row justify="start">
                <Col span={24}>
                    <FormItem
                        name={"amenitiesDefault"}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input name="amenitiesDefault" placeholder="Override Label" />
                    </FormItem>
                </Col>
                <Col span={24}>
                    <FormItem
                        name={"title"}
                        label={"Title"}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input name="title" />
                    </FormItem>
                </Col>

                <Col span={24}>
                    <FormItem
                        label={"Details"}
                        name={"propertyDetails"}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input.TextArea name="propertyDetails" />
                    </FormItem>
                </Col>

                <h1>
                    <b>Amenity List</b>
                </h1>
                <FieldArray
                    name="amenityList"
                    render={(event) => renderAmenityList({ event, values, errors, setFieldValue })}
                />
            </Row>

            <Row justify="end">
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={() => submitForm()}
                        disabled={!dirty || !isValid}
                    >
                        SAVE
                    </CustomButton>
                </Col>
            </Row>

            <Modal
                visible={modalVisible}
                centered
                footer={null}
                bodyStyle={{ padding: 0, height: 400 }}
                onCancel={handleClosePreview}
                width={700}
                closable={false}
                maskClosable={true}
                destroyOnClose={true}
            >
                <StyledIFrame src={preview} width="100%" height="100%"></StyledIFrame>
            </Modal>
        </Form>
    );
};

export default Forms;
