import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { RouteComponentProps } from "react-router-dom";
import { useParams, useHistory } from "react-router-dom";
import { Spin, message } from "antd";
import filter from "lodash/filter";
import Form from "./forms";
import { validationSchema } from "./constant";
import {
    addRole,
    editRole,
    getRoleDetails,
    getRolePermissionList,
    getTenantsList,
    getFeatureList,
} from "../../axios";

export interface ITenantLists {
    name: string;
    id: string;
    tenant: any;
}
export interface IFeatures {
    id: string;
    name: string;
    permissions: IPermissions[];
}
export interface IPermissions {
    id: string;
    name: string;
    description: string;
}

const AddRole: React.FC = (props: RouteComponentProps) => {
    const history = useHistory();
    const [isAdd, setIsAdd] = useState(true);
    const [state, setState] = useState({
        tenant: "",
        name: "",
        description: "",
        isActive: true,
        feature: "",
        featureArr: {},
        permissionIds: {},
    });
    const [tenantsState, setTenantsState] = useState<ITenantLists[]>([]);
    const [featureState, setFeatureState] = useState<IFeatures[]>([]);
    const [permissionState, setPermissionState] = useState<IPermissions[]>([]);

    const [checkPermissions, setCheckPermissions] = useState({});
    const [tenantIdState, setTenantIdState] = useState(null);
    const [reinitialize, setReinitialize] = useState(false);
    const [loader, setLoader] = useState<boolean>(false);
    const { roleId } = useParams();

    useEffect(() => {
        if (reinitialize) {
            setReinitialize(false);
        }
    }, [state, tenantsState, featureState, permissionState]);

    useEffect(() => {
        (async () => {
            setLoader(true);
            // Get Tenant Lists
            const tenantList = await getTenantsList();
            if (tenantList && Array.isArray(tenantList)) {
                setTenantsState(tenantList);
            }

            if (props.toggleIsAdd === false) {
                setIsAdd(false);
                // Get Role Details
                const roleDetails = await getRoleDetails(roleId);
                if (roleDetails) {
                    setState({
                        name: roleDetails.name,
                        isActive: roleDetails.isActive,
                        description: roleDetails.description,
                        tenant: roleDetails?.tenant?.id,
                        feature: "",
                        featureArr: {},
                        permissionIds: {},
                    });
                }
                if (roleDetails && roleDetails.tenant) {
                    setTenantIdState(roleDetails.tenant?.id);
                    await handleBrandChange(roleDetails.tenant?.id, true);
                }

                const permissions = await getRolePermissionList(roleId);
                if (permissions) {
                    const permissionsCheckboxes = {};
                    permissions.forEach((item) => {
                        permissionsCheckboxes[item.id] = true;
                    });
                    setCheckPermissions(permissionsCheckboxes);
                }
            }
            setLoader(false);
        })();
    }, []);

    const handleBrandChange = async (e, isInit = false) => {
        if (!isInit) {
            setLoader(true);
        }
        const featureList = await getFeatureList(e);
        const finalList = await filter(
            featureList,
            (o) => o.name !== "Role Management" && o.name !== "User Management"
        );
        setFeatureState(finalList);
        setPermissionState([]);
        if (!isInit) {
            setLoader(false);
        }
        setReinitialize(true);
    };

    const handleFeatureOnChange = (e) => {
        const find = featureState.filter((item) => item.id === e)[0];
        setPermissionState(find.permissions);
    };

    const handleAddRole = async (values) => {
        setLoader(true);
        const res = await addRole(values);
        if (!res.success) {
           setLoader(false);
           return message.error(res.error.data?.message);
        }
        setTimeout(() => {
            message.success("Role has successfully created.");
            setLoader(false);
        }, 1000);
        history.push(`/manage-roles`);
    };

    const handleEditRole = async (values, roleId) => {
        setLoader(true);
        await editRole(values, roleId);
        setTimeout(() => {
            message.success("Update success");
            setLoader(false);
        }, 1000);
    };

    return (
        <React.Fragment>
            <Spin spinning={loader}>
                <Formik
                    initialValues={{
                        tenant: state.tenant,
                        name: state.name,
                        description: state.description,
                        isActive: state.isActive ? 1 : 2,
                        feature: "",
                        featureArr: {},
                        permissionIds: checkPermissions,
                        tenantId: tenantIdState,
                    }}
                    enableReinitialize={reinitialize}
                    onSubmit={async (values) => {
                        if (isAdd) {
                            await handleAddRole(values);
                        } else {
                            await handleEditRole(values, roleId);
                        }
                    }}
                    validationSchema={validationSchema}
                    render={(formikBag) => (
                        <Form
                            {...{ formikBag }}
                            tenantsList={tenantsState}
                            featureList={featureState}
                            permissionList={permissionState}
                            handleBrandChange={handleBrandChange}
                            handleFeatureOnChange={handleFeatureOnChange}
                        />
                    )}
                />
            </Spin>
        </React.Fragment>
    );
};

export default AddRole;
