import React from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import { StyledEditor } from "./styled";

interface Props {
    data?: string;
    onChange: (value: string) => void;
    toolbar?: string[];
    heading?: boolean;
    className?: string;
}

const Richtext: React.FC<Props> = ({ data, onChange, toolbar, heading, className }) => (
    <StyledEditor className={className}>
        <CKEditor
            editor={ClassicEditor}
            data={data}
            onChange={(event, editor) => {
                const data = editor.getData();
                onChange(data);
            }}
            config={{
                link: {
                    addTargetToExternalLinks: true,
                },
            }}
        />
    </StyledEditor>
);

export default Richtext;
