const worker = () => {
  var i = 0;

  const TIMER = {
    START: 'start',
    RESET: 'reset'
  }

  self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
    if (e.data === TIMER.RESET) {
      i = 0;
    }
    if (e.data === TIMER.START) {
      const timedCount = () => {
        i = i + 1;
        postMessage(i);
        setTimeout(timedCount, 1000);
      }

      timedCount();
    }
  })
}

export default worker;