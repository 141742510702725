import React, { useEffect, useState, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import { GET_PAGES, EDIT_PAGE, REMOVE_PAGE } from "../../graphql";
import { Context } from "../../../../context";

import { Showroom } from "../../../../entities/Showroom";
import { ShowroomMapper } from "../../dto/ShowroomMapper";
import CustomDashboard from "../../../../components/CustomDashboard";

import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { userPermission } from "../../../../util/user-access";
import { cardText } from "../../manage-showroom.utils";

const ShowroomList = (): JSX.Element => {
    const history = useHistory();
    const match = useRouteMatch();

    const {
        state: { brand: brandData, user },
    } = useContext(Context);
    const brandId = brandData?.id;
    const brandName = brandData?.name;
    const permissions = user?.roles[0].permissions;
    const createAccess = userPermission(permissions, "create:showroom");
    const updateAccess = userPermission(permissions, "update:showroom");
    const deleteAccess = userPermission(permissions, "delete:showroom");

    const tag = `SHOWROOM-${brandName}`;

    const [showroomList, setShowroomList] = useState<any>(null);
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [search, setSearch] = useState<string>("");

    const { error, refetch, loading, data } = useQuery(GET_PAGES, {
        skip: !brandId,
        variables: {
            brand_id: brandId,
            tag: tag,
            search: search,
            page: page,
            limit: pageSize,
        },
        fetchPolicy: "no-cache",
    });

    const [loader, setLoader] = useState<boolean>(false);

    const [editPage] = useMutation(EDIT_PAGE);
    const [removePage] = useMutation(REMOVE_PAGE);

    const handleSearch = (query) => {
        setLoader(true);
        query !== search && setSearch(query);
    };

    const handleAddBtn = () => {
        history.push(`${match.path}/add`);
    };

    const handleFeatured = async (featured: boolean | undefined, id: string) => {
        const value = featured ? false : true;

        const { data: res } = await editPage({
            variables: {
                brand_id: brandId,
                tag: tag,
                id: id,
                featured: value,
            },
        });

        if (res.addOrUpdateBrandPage) {
            setLoader(true);
            refetch();
        } else {
            message.error("Something went wrong");
        }
    };

    const handleDelete = (id: string) => {
        const { confirm } = Modal;

        confirm({
            title: "Delete Page?",
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to delete this page?",
            async onOk() {
                try {
                    await removePage({
                        variables: {
                            id: id,
                        },
                    });
                    setLoader(true);
                    message.success("Successfully deleted page");
                    refetch();
                } catch {
                    message.error("Something went wrong");
                } finally {
                    return;
                }
            },
            onCancel() {
                return;
            },
        });
    };

    const handleProperty = (
        menuItem: string,
        slug: string,
        id: string,
        featured: boolean | undefined
    ) => {
        if (menuItem === "edit") {
            history.push(`${match.path}/${slug}`, { pageId: id });
        }
        if (menuItem === "featured") {
            handleFeatured(featured, id);
        }
        if (menuItem === "delete") {
            handleDelete(id);
        }
    };

    const handlePageSize = async (pageSize) => {
        setPageSize(pageSize);
    };

    const handlePagination = async (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    useEffect(() => {
        let isMounted = true;

        setShowroomList([]);

        if (data && data.searchPages) {
            setLoader(true);

            data.searchPages.totalCount && setTotalPages(data.searchPages.totalCount);

            const { pages } = data.searchPages;
            const res = pages.map((info: Showroom) => ShowroomMapper.map(info));

            if (isMounted) {
                setShowroomList(res);
                setLoader(false);
            }
        }

        if (error) {
            message.error("Something went wrong.");
            setLoader(false);
        }

        return () => {
            isMounted = false;
        };
    }, [data]);

    return (
        <CustomDashboard
            createAccess={createAccess}
            updateAccess={updateAccess}
            deleteAccess={deleteAccess}
            dashboard="Showroom"
            handleMenu={handleProperty}
            loading={loading || loader}
            handleAddBtn={handleAddBtn}
            handleSearch={handleSearch}
            data={showroomList}
            cardText={cardText}
            totalPages={totalPages}
            page={page}
            handlePagination={handlePagination}
            pageSize={pageSize}
            handlePageSize={handlePageSize}
        />
    );
};

export default ShowroomList;
