import React from "react";

import { FormItem, Input } from "formik-antd";
import { Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { WrapperForm, MediaContainer } from "./styled";

import { ComponentFormType } from "../content-forms.types";

const ComponentOne = ({
    index,
    setFieldValue,
    fieldsArrayEvent,
    values,
    uploadComplete,
    setUploadStatus,
    brandName,
}: ComponentFormType): JSX.Element => {
    return (
        <WrapperForm>
            <Row gutter={24}>
                <Col span={12}>
                    <MediaContainer
                        value={values.components[index].fields.image}
                        accept={"image/jpeg,image/jpg,image/png"}
                        noteMessage={".jpeg, .jpg, .png (max 50 MB)"}
                        onChange={(e) => {
                            setUploadStatus(e?.status);
                            setFieldValue(`components[${index}].fields.image`, e);
                        }}
                    >
                        <h1>Upload Banner +</h1>
                    </MediaContainer>
                    {brandName === "ALP" && (
                        <FormItem
                            label="Image alt text"
                            name={`components[${index}].fields.image.altText`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name={`components[${index}].fields.image.altText`} />
                        </FormItem>
                    )}
                </Col>
                <Col span={12}>
                    <FormItem
                        label={"Title"}
                        name={`components[${index}].fields.title`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input name={`components[${index}].fields.title`} />
                    </FormItem>
                    <FormItem
                        label={"Description"}
                        name={`components[${index}].fields.description`}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input.TextArea name={`components[${index}].fields.description`} rows={7} />
                    </FormItem>
                </Col>
            </Row>
            <CloseOutlined
                className="remove-btn"
                onClick={() => {
                    uploadComplete !== "uploading" && fieldsArrayEvent.remove(index);
                }}
            />
        </WrapperForm>
    );
};

export default ComponentOne;
