import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import upperCase from "lodash/upperCase";
import lowerCase from "lodash/lowerCase";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";

import { Spin, message } from "antd";

import Form from "./forms";
import { Context } from "../../../../../context";
import { CREATE_PAGE, GET_PAGE, CREATE_CONTENT, REMOVE_MULTI_CONTAINER } from "../../../graphql";

import { validationSchema } from "./constant";
import generateId from "../../../../../util/generate-id";
import fileTranslate from "../../../../../util/file-translate";

interface Props {
    handleRefresh: () => void;
}

const AboutUsForms = ({ handleRefresh }: Props): JSX.Element => {
    const { id } = useParams();

    const {
        state: { pageDetails },
    } = useContext(Context);
    const [getPage, { data: pageData }] = useLazyQuery(GET_PAGE, {
        fetchPolicy: "no-cache",
    });
    const [createPage] = useMutation(CREATE_PAGE);
    const [createContent] = useMutation(CREATE_CONTENT);
    const [removeMultiContainer] = useMutation(REMOVE_MULTI_CONTAINER);

    const [loader, setLoader] = useState(false);
    const [reinitialize, setReinitialize] = useState(false);

    const [fieldInfo, setFieldInfo] = useState<any>({
        title: "",
        body: {
            collectionId: "",
            bodyList: [],
        },
    });
    const [initalValue, setInitialValue] = useState<any>({
        title: "",
        seoTitle: "",
        seoDescription: "",
        HeadingTag: "",
        subHeading: "",
        body: [],
        images: [],
    });

    useEffect(() => {
        if (!reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        if (pageData && has(pageData, "getSpecificBrandPage")) {
            const data = pageData.getSpecificBrandPage.containers[0].field_collections;
            const pageId = pageData.getSpecificBrandPage.id;
            const containers = pageData.getSpecificBrandPage.containers[0].id;
            const fields: any = {
                title: "",
                seoTitle: "",
                seoDescription: "",
                HeadingTag: "",
                subHeading: "",
                body: [],
                images: [],
            };

            fields["title"] = data[0].fields[0].value ? data[0].fields[0].value : "";
            if (data[1].fields) {
                fields["body"] = data[1].fields.map((res) => {
                    return {
                        contentWeb: res.value,
                        contentMobile: "",
                    };
                });
            }
            if (data[2].fields) {
                fields["images"] = data[2].fields.map((res, index) => ({
                    uid: generateId(),
                    url: res.value,
                    keyObj: res.meta_data.key,
                    status: "done",
                    type: res.meta_data.file_type,
                }));
            }
            if (data[3].fields) {
                data[3].fields.forEach((res, index) => {
                    fields["body"][index]["contentMobile"] = res.value;
                });
            }

            fields["seoTitle"] = data[4]?.fields[0].value ? data[4].fields[0].value : "";
            fields["seoDescription"] = data[4]?.fields[1].value ? data[4].fields[1].value : "";
            fields["HeadingTag"] = data[4]?.fields[2].value ? data[4].fields[2].value : "";
            fields["subHeading"] = data[4]?.fields[3].value ? data[4].fields[3].value : "";

            setFieldInfo({
                pageId,
                containers: [containers],
            });
            setInitialValue(fields);
            setReinitialize(true);
        }
    }, [pageData]);

    useEffect(() => {
        const data = pageDetails.payload;
        if (data.brandId) {
            getPage({
                variables: {
                    brand_id: data.brandId,
                    tag: `COMPANY-INFO-${upperCase(data.brandName)}`,
                    slug: `about-us-${lowerCase(data.brandName)}`,
                },
            });
        }
    }, [pageDetails.payload]);

    const handleSubmit = async (values) => {
        setLoader(true);
        const data = pageDetails.payload;

        if (data.brandId && data.brandName && has(pageData, "getSpecificBrandPage")) {
            await handleUpdatePage(values);
            message.success("Update success");
        } else {
            await handleAddPage(values);
            message.success("Page successfully created");
        }

        setTimeout(() => {
            handleRefresh();
            setLoader(false);
        }, 500);
    };

    const bodyMapper = (value) =>
        value.map((fields, fieldIndex) => ({
            order: fieldIndex,
            name: "BODY",
            label: "body",
            value: fields.contentWeb ? fields.contentWeb : null,
        }));

    const bodyMobileMapper = (value) =>
        value.map((fields, fieldIndex) => ({
            order: fieldIndex,
            name: "BODY",
            label: "body",
            value: fields.contentMobile ? fields.contentMobile : null,
        }));

    const imageMapper = (value) =>
        value.map((fields, fieldIndex) => ({
            order: fieldIndex,
            label: fileTranslate(fields.type),
            name: "File",
            value: fields.keyObj,
            meta_data: {
                featured: false,
                description: "",
                key: fields.keyObj,
                file_type: fields.type,
            },
        }));

    const containerMapper = (value, page_id) => {
        const containers: any = {
            order: 0,
            page_id: page_id,
            name: "ABOUT-US-CONTAINER",
            field_collections: [
                {
                    order: 0,
                    name: "ABOUT-US-DEFAULT",
                    fields: [
                        {
                            name: "ABOUT-US-TITLE",
                            label: "about us (default)",
                            value: value.title ? value.title : null,
                            order: 0,
                        },
                    ],
                },
                {
                    order: 1,
                    name: "BODY",
                },
                {
                    order: 2,
                    name: "IMAGES",
                },
                {
                    order: 3,
                    name: "BODY-MOBILE",
                },
                {
                    order: 4,
                    name: "SEO",
                    fields: [
                        {
                            name: "SEO-TITLE",
                            label: "Seo Title",
                            value: value.seoTitle ? value.seoTitle : null,
                            order: 0,
                        },
                        {
                            name: "SEO-DESCRIPTION",
                            label: "Seo Description",
                            value: value.seoDescription ? value.seoDescription : null,
                            order: 1,
                        },
                        {
                            name: "HEADING-TAG",
                            label: "H1 Tag",
                            value: value.HeadingTag ? value.HeadingTag : null,
                            order: 2,
                        },
                        {
                            name: "SUB-HEADING",
                            label: "H2 Tag",
                            value: value.subHeading ? value.subHeading : null,
                            order: 3,
                        },
                    ],
                },
            ],
        };

        if (!isEmpty(value.body)) {
            containers.field_collections[1]["fields"] = bodyMapper(value.body);
        }

        if (!isEmpty(value.images)) {
            containers.field_collections[2]["fields"] = imageMapper(value.images);
        }

        if (!isEmpty(value.body)) {
            containers.field_collections[3]["fields"] = bodyMobileMapper(value.body);
        }

        return [containers];
    };

    const handleAddPage = async (values) => {
        const brandInfo = pageDetails.payload;
        const pageTag = `COMPANY-INFO-${upperCase(brandInfo.brandName)}`;
        const page = await createPage({
            variables: { brand_id: id, name: `About Us - ${brandInfo.brandName}`, tag: pageTag },
        });

        if (true) {
            const {
                addOrUpdateBrandPage: { id: page_id },
            } = page.data;

            const containers = containerMapper(values, page_id);

            await createContent({
                variables: {
                    data: {
                        containers,
                    },
                },
            });
        }
    };

    const handleUpdatePage = async (values) => {
        const page_id = fieldInfo.pageId;
        const containers = containerMapper(values, page_id);

        await createContent({
            variables: {
                data: {
                    containers,
                },
            },
        });
        await removeMultiContainer({
            variables: {
                data: {
                    ids: fieldInfo.containers,
                },
            },
        });
    };

    return (
        <Spin spinning={loader}>
            <Formik
                initialValues={initalValue}
                validationSchema={validationSchema}
                enableReinitialize={reinitialize}
                onSubmit={(values: any) => {
                    handleSubmit(values);
                }}
                render={(formikBag) => (
                    <Form
                        {...{
                            formikBag,
                        }}
                    />
                )}
            />
        </Spin>
    );
};

export default AboutUsForms;
