import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import styled from "styled-components";

import { Breadcrumb } from "antd";
import CompanyInfoList from "./components/company-info-list";
import CompanyInfoForms from "./components/company-info-forms";

export const StyledBreadcrumb = styled(Breadcrumb)`
    background: #fff;
    display: flex;
    padding: 16px 25px;
`;

const ManageCompanyInformation: React.FC = () => {
    let match = useRouteMatch();

    return (
        <>
            <Switch>
                <Route
                    exact
                    path={`${match.path}`}
                    render={(props: any) => <CompanyInfoList {...props} />}
                />
                <Route
                    exact
                    path={`${match.path}/:category/:id`}
                    render={(props: any) => <CompanyInfoForms {...props} />}
                />
            </Switch>
        </>
    );
};

export default ManageCompanyInformation;
