import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { FieldArray } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import CustomButton from "../../../../../components/CustomButton";
import ContentContainer from "../../../../../components/ContentContainer";
import UploadMultipleImage from "../../../../../components/UploadMultipleImage";
import Richtext from "../../../../../components/Richtext";
import { InputWrapper, BodyWrapper } from "./styled";
import { getAcceptedFiles } from "../../../../../util/get-accepted-filetypes";
import { Context } from "../../../../../context";
import SeoForm from "@/components/SeoForm";

interface Props {
    formikBag: any;
}

const renderBodyField = ({ event, values, errors, setFieldValue }) => {
    return (
        <Col span={24} style={{ marginBottom: 25 }}>
            <h4>Body</h4>
            {values.body.map(({ content }, index) => {
                const nameBodyWeb = `body[${index}].contentWeb`;
                const nameBodyMobile = `body[${index}].contentMobile`;

                return (
                    <BodyWrapper>
                        <p className="label">Website view</p>
                        <FormItem
                            name={nameBodyWeb}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <InputWrapper>
                                <Input.TextArea
                                    name={nameBodyWeb}
                                    placeholder="Enter body"
                                    rows={4}
                                />
                            </InputWrapper>
                        </FormItem>

                        <p className="label">Mobile view</p>
                        <InputWrapper>
                            <Richtext
                                heading={true}
                                className={"richtext-about-us-body"}
                                data={values.body[index].contentMobile}
                                onChange={(e) => {
                                    setFieldValue(nameBodyMobile, e);
                                }}
                            />
                        </InputWrapper>
                        <CloseOutlined
                            className="remove-btn"
                            onClick={() => {
                                event.remove(index);
                            }}
                        />
                    </BodyWrapper>
                );
            })}
            <CustomButton
                type="dashed"
                style={{ width: "100%", fontSize: 13, fontWeight: 300 }}
                onClick={() => {
                    event.push({ contentWeb: "", contentMobile: "", id: "" });
                }}
            >
                <p style={{ margin: 0 }}>+ Add Body</p>
            </CustomButton>
        </Col>
    );
};

const Forms: React.SFC<Props> = ({ formikBag }) => {
    const history = useHistory();
    const [modalVisible, setModalVisible] = useState(false);
    const [preview, setPreview] = useState("");
    const { values, submitForm, errors, setFieldValue, dirty, isValid } = formikBag;

    const {
        state: { brand: brandData },
    } = useContext(Context);
    const brandName = brandData?.name;

    const handleClosePreview = () => {
        setPreview("");
        setModalVisible(false);
    };

    const handlePreview = (val) => {
        if (val) {
            setPreview(val);
            setModalVisible(true);
        }
    };

    return (
        <Form>
            <ContentContainer style={{ display: "flex", flexDirection: "column" }}>
                <Row>
                    {brandName === "ALP" ? <SeoForm category={""} brand={brandName} /> : null}
                </Row>
                <Row justify="start">
                    <Col span={24}>
                        <FormItem
                            label={
                                <h1>
                                    <b>About Us </b>(Default)
                                </h1>
                            }
                            name={"title"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name="title" placeholder="Override Label" />
                        </FormItem>
                    </Col>

                    <FieldArray
                        name="body"
                        render={(event) =>
                            renderBodyField({ event, values, errors, setFieldValue })
                        }
                    />

                    <UploadMultipleImage
                        accept={getAcceptedFiles(brandName)}
                        validate={values.images.length <= 3}
                        fileList={values.images}
                        preview={handlePreview}
                        onChange={(e) => {
                            setFieldValue(`images`, e);
                        }}
                    />
                </Row>
            </ContentContainer>

            <Row justify="end" gutter={24} style={{ padding: "0 25px", marginBottom: 25 }}>
                <Col span={3}>
                    <CustomButton
                        disabled={!dirty || !isValid}
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={() => submitForm()}
                    >
                        SAVE
                    </CustomButton>
                </Col>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%" }}
                        onClick={() => history.push(`/manage-company-information`)}
                    >
                        CANCEL
                    </CustomButton>
                </Col>
            </Row>
            <Modal
                visible={modalVisible}
                centered
                footer={null}
                bodyStyle={{ padding: 0, height: 400 }}
                onCancel={handleClosePreview}
                width={700}
                closable={false}
                maskClosable={true}
                destroyOnClose={true}
            >
                <video width="100%" height="100%" style={{ objectFit: "cover" }} controls>
                    <source src={preview} />
                </video>
            </Modal>
        </Form>
    );
};

export default Forms;
