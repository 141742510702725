export const columns = [
    {
        title: "Brand",
        dataIndex: "brand",
        key: "brand",
        filterMultiple: false,
    },
    {
        title: "Page",
        dataIndex: "name",
        key: "name",
        sorter: true,
    },
    {
        title: "Date Created",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
    },
    {
        title: "Date Modified",
        dataIndex: "updated_at",
        key: "updated_at",
        sorter: true,
    },
];
