import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import _ from "lodash";

import Form from "./forms";
import { message } from "antd";

import { Context } from "../../../../../context";
import {
    CREATE_PAGE,
    MODIFY_FIELDS,
    CREATE_PAGE_V2,
    EDIT_PAGE_TITLE,
    REMOVE_FIELD,
} from "../../../graphql";

import { validationSchema, towerContainer, propertyTypeList } from "./constant";
// import validateUrl from "../../../../../util/validate-url";
import generateId from "../../../../../util/generate-id";

import {
    Props,
    FieldInfoType,
    FormValueTypes,
    FieldItemType,
    ImageType,
    CollectionType,
} from "../../../manage-properties.types";
import { INIT_VALUES, INIT_FIELD_INFO_VALUES } from "../../../manage-properties.utils";

const PropertiesOverviewForms = ({ handleRefresh }: Props): JSX.Element => {
    const { propertyType: routePropertyType, slug } = useParams();
    const history = useHistory();

    const {
        state: { propertyDetails, brand: brandData },
    } = useContext(Context);
    const brandId = brandData?.id;
    const brandName = brandData?.name;
    const [createPage] = useMutation(CREATE_PAGE);
    const [createPage2] = useMutation(CREATE_PAGE_V2);
    const [editPageName] = useMutation(EDIT_PAGE_TITLE);
    const [modifyFields, { loading }] = useMutation(MODIFY_FIELDS);
    const [removeField] = useMutation(REMOVE_FIELD);

    const getDefaultPropertyType = () => {
        if (routePropertyType) {
            const find = propertyTypeList.filter((item) => item.slug === routePropertyType);
            const defaultPropertyVal = find && find.length > 0 ? find[0].value : "";
            return defaultPropertyVal;
        }
        return "";
    };

    const [reinitialize, setReinitialize] = useState(false);
    const [manualLoader, setManualLoader] = useState(false);
    const [fieldInfo, setFieldInfo] = useState<FieldInfoType>(INIT_FIELD_INFO_VALUES);
    const [initialValue, setInitialValue] = useState<FormValueTypes>(
        INIT_VALUES(routePropertyType, getDefaultPropertyType)
    );

    useEffect(() => {
        if (!reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        (async () => {
            if (propertyDetails.payload) {
                const data = propertyDetails.payload.mainOverview;

                const fieldItem: FieldItemType[] = [];
                const fields: FormValueTypes = INIT_VALUES(
                    routePropertyType,
                    getDefaultPropertyType
                );

                data.forEach((res) => {
                    const name = _.camelCase(res.name);
                    const keyValue = res?.value?.split("content/", 2)[1] ?? "";

                    if (name === "image") {
                        if (res.order === 1) {
                            const imageObj: ImageType = {
                                keyObj: res.meta_data.key ? res.meta_data.key : keyValue,
                                url: res.value,
                                uid: generateId(),
                                status: "done",
                            };

                            if (brandName === "ALP") {
                                imageObj.altText = res.meta_data.alt_text;
                            }

                            fields["image"] = imageObj;
                        }
                        if (brandName === "AMAIA") {
                            if (res.name === "Image" && res.order === 12) {
                                res.name = "Image2";
                                if (res.value) {
                                    fields["image2"] = {
                                        keyObj: res.meta_data.key ? res.meta_data.key : keyValue,
                                        url: res.value,
                                        uid: generateId(),
                                        status: "done",
                                    };
                                }
                            }
                        }
                    } else {
                        fields[_.camelCase(res.name)] = res.value;
                    }

                    fieldItem.push(res);
                });

                setFieldInfo({
                    fields: fieldItem,
                    containerId: propertyDetails.payload.mainOverviewId,
                });

                setInitialValue(fields);
                setReinitialize(true);
            }
        })();
    }, [propertyDetails]);

    const handleSubmit = async (values) => {
        setManualLoader(true);
        if (!slug) {
            await handleCreateProperty(values);
        } else {
            await handleUpdateProperty(values);
        }
        setManualLoader(false);
    };

    const handleCreateProperty = async (values) => {
        try {
            const createPageData = await createPage({
                variables: {
                    brand_id: brandId,
                    name: values.projectName,
                    tag: `PROPERTIES-${brandName}`,
                },
            });

            if (createPageData) {
                const {
                    addOrUpdateBrandPage: { id: page_id, slug: pageSlug },
                } = createPageData.data;

                const overviewFields: any = [
                    {
                        order: 0,
                        label: "Overview (Default)",
                        name: "OVERVIEW-DEFAULT",
                        value: !_.isEmpty(values.overviewDefault) ? values.overviewDefault : null,
                    },
                    {
                        order: 1,
                        label: "Logo",
                        name: "Image",
                        value: !_.isEmpty(values.image.keyObj) ? values.image.keyObj : "",
                        meta_data:
                            !_.isEmpty(values.image.keyObj) &&
                            !_.isEmpty(values.image.altText) &&
                            brandName === "ALP"
                                ? {
                                      key: values.image.keyObj,
                                      alt_text: values.image.altText ?? values.projectName,
                                  }
                                : !_.isEmpty(values.image.keyObj)
                                ? {
                                      key: values.image.keyObj,
                                  }
                                : null,
                    },
                    {
                        order: 2,
                        label: "Project Name",
                        name: "PROJECT-NAME",
                        value: !_.isEmpty(values.projectName) ? values.projectName : null,
                    },
                    {
                        order: 3,
                        label: "Property Type",
                        name: "PROPERTY-TYPE",
                        value: !_.isEmpty(values.propertyType) ? values.propertyType : null,
                    },
                    {
                        order: 4,
                        label: "Property Price",
                        name: "PROPERTY-PRICE",
                        value: !_.isEmpty(values.propertyPrice) ? values.propertyPrice : null,
                    },
                    {
                        order: 5,
                        label: "Property Price Range (Lowest)",
                        name: "PROPERTY-PRICE-RANGE-LOWEST",
                        value: !_.isEmpty(values.propertyPriceRangeLowest)
                            ? values.propertyPriceRangeLowest
                            : null,
                    },
                    {
                        order: 6,
                        label: "Property Price Range (Highest)",
                        name: "PROPERTY-PRICE-RANGE-HIGHEST",
                        value: !_.isEmpty(values.propertyPriceRangeHighest)
                            ? values.propertyPriceRangeHighest
                            : null,
                    },
                    {
                        order: 7,
                        label: "Property Size (Lot Area)",
                        name: "PROPERTY-SIZE-LOT-AREA",
                        value: !_.isEmpty(values.propertySizeLotArea)
                            ? values.propertySizeLotArea
                            : null,
                    },
                    {
                        order: 8,
                        label: "Property Size (Floor Area)",
                        name: "PROPERTY-SIZE-FLOOR-AREA",
                        value: !_.isEmpty(values.propertySizeFloorArea)
                            ? values.propertySizeFloorArea
                            : null,
                    },
                    {
                        order: 9,
                        label: "Terrain",
                        name: "TERRAIN",
                        value: !_.isEmpty(values.terrain) ? values.terrain : null,
                    },
                    {
                        order: 10,
                        label: "location",
                        name: "LOCATION",
                        value: !_.isEmpty(values.location) ? values.location : null,
                    },
                    brandName === "AVIDA" && {
                        order: 11,
                        label: "Available Unit Link/Lot Button",
                        name: "UNIT-LINK-BTN",
                        value: !_.isEmpty(values.unitLinkBtn) ? values.unitLinkBtn : null,
                    },
                    brandName === "AVIDA" && {
                        order: 12,
                        label: "Gtm Code",
                        name: "GTM-CODE",
                        value: !_.isEmpty(values.gtmCode) ? values.gtmCode : null,
                    },
                    brandName === "ALP" && {
                        order: 11,
                        label: "Sub Category",
                        name: "SUBCATEGORY",
                        value: !_.isEmpty(values.subcategory) ? values.subcategory : null,
                    },
                    ...(brandName === "AMAIA"
                        ? [
                              {
                                  order: 11,
                                  label: "LTS and Approval No.",
                                  name: "LTS-AND-APPROVAL-NO",
                                  value: !_.isEmpty(values.ltsAndApprovalNo)
                                      ? values.ltsAndApprovalNo
                                      : "",
                              },
                              values.image2
                                  ? {
                                        order: 12,
                                        label: "Map Image",
                                        name: "Image",
                                        value: !_.isEmpty(values.image2.keyObj)
                                            ? values.image2.keyObj
                                            : null,
                                        meta_data: !_.isEmpty(values.image2.keyObj)
                                            ? {
                                                  key: values.image2.keyObj,
                                              }
                                            : null,
                                    }
                                  : null,
                          ]
                        : []),
                ].filter((item) => item !== false && item !== null);

                const containers = [
                    {
                        order: 0,
                        page_id,
                        name: `${values.projectName} Overview Default Container`,
                        field_collections: [
                            {
                                order: 0,
                                name: "OVERVIEW-FC",
                                fields: overviewFields,
                            },
                        ],
                    },
                    {
                        order: 1,
                        page_id,
                        name: `${values.projectName} Units`,
                        field_collections: towerContainer,
                    },
                ];

                await createPage2({
                    variables: {
                        data: {
                            containers: containers,
                        },
                    },
                });

                setManualLoader(false);
                message.success("Project has successfully created");
                history.push(`/manage-properties/${pageSlug}`, { pageId: page_id });
            }
        } catch (error) {
            const errorObj = error as Error;

            const msg =
                errorObj.message === "Page name is already exists!"
                    ? "Page name is already exists!"
                    : "Something went wrong!";
            message.error(msg);
        }
    };

    const translateImageUpload = (val, data) => ({
        id: data.id,
        label: data.label,
        name: "Image",
        value: val.keyObj,
        order: data.order,
        meta_data: {
            featured: false,
            description: "",
            key: val.keyObj,
            alt_text: brandName === "ALP" ? val.altText : "",
        },
    });

    const handleUpdateProperty = async (values) => {
        try {
            const toUpdateFields: any = [];
            const toDeleteFields: any = [];

            Object.keys(values).map((key, index) => {
                const filter = fieldInfo.fields.filter(
                    (item: any) => _.camelCase(item.name) === key
                );

                if (!_.isEmpty(filter)) {
                    const data: any = filter[0];

                    if (key !== "image" && key !== "image2") {
                        let val = !_.isEmpty(values[key]) ? values[key] : null;
                        if (key === "ltsAndApprovalNo") {
                            val = !_.isEmpty(values[key]) ? values[key] : "";
                        }

                        toUpdateFields.push({
                            id: data.id,
                            label: data.label,
                            name: data.name,
                            value: val,
                            order: data.order,
                        });
                    } else {
                        if (values[key]) {
                            const res = translateImageUpload(values[key], data);

                            if (res) {
                                toUpdateFields.push(res);
                            }
                        } else if (brandName === "AMAIA" && key === "image2") {
                            toDeleteFields.push(data.id);
                        }
                    }
                } else {
                    if (key === "image" && values[key].keyObj) {
                        const imageObj: CollectionType = {
                            collection_id: fieldInfo.containerId,
                            label: "Logo",
                            name: "Image",
                            value: { keyObj: values[key].keyObj },
                            order: 1,
                        };

                        if (brandName === "ALP") {
                            imageObj.value = { ...imageObj.value, altText: values[key].altText };
                        }

                        toUpdateFields.push(imageObj);
                    } else if (brandName === "AMAIA") {
                        if (key === "image2" && values[key]) {
                            toUpdateFields.push({
                                collection_id: fieldInfo.containerId,
                                label: "Map Image",
                                name: "Image",
                                value: values[key].keyObj,
                                meta_data: values[key] ? { key: values[key].keyObj } : null,
                                order: 12,
                            });
                        }
                        if (key === "ltsAndApprovalNo") {
                            toUpdateFields.push({
                                collection_id: fieldInfo.containerId,
                                order: 11,
                                label: "LTS and Approval No.",
                                name: "LTS-AND-APPROVAL-NO",
                                value: values[key],
                            });
                        }
                        if (key === "unitLinkBtn") {
                            toUpdateFields.push({
                                collection_id: fieldInfo.containerId,
                                order: 13,
                                label: "Available Unit Link/Lot Button",
                                name: "UNIT-LINK-BTN",
                                value: values[key],
                            });
                        }
                    } else if (brandName === "ALP") {
                        if (key === "subcategory") {
                            toUpdateFields.push({
                                collection_id: fieldInfo.containerId,
                                order: 11,
                                label: "Sub Category",
                                name: "subcategory",
                                value: values[key],
                            });
                        }
                    } else if (brandName === "AVIDA") {
                        if (key === "unitLinkBtn") {
                            toUpdateFields.push({
                                collection_id: fieldInfo.containerId,
                                order: 11,
                                label: "Available Unit Link/Lot Button",
                                name: "UNIT-LINK-BTN",
                                value: values[key],
                            });
                        }
                        if (key === "gtmCode") {
                            toUpdateFields.push({
                                collection_id: fieldInfo.containerId,
                                order: 12,
                                label: "Gtm Code",
                                name: "GTM-CODE",
                                value: values[key],
                            });
                        }
                    }
                }
            });

            const projectName: FieldItemType[] = fieldInfo.fields.filter(
                (item: FieldItemType) => item.name === "PROJECT-NAME"
            );

            if (propertyDetails) {
                const pageId = propertyDetails.payload.unitData.page_id;
                if (projectName[0]?.value !== values.projectName) {
                    const {
                        data: {
                            addOrUpdateBrandPage: { slug },
                        },
                    } = await handleUpdatePageName(values.projectName, pageId);
                    history.push(`/manage-properties/${slug}`, { pageId: pageId });
                }
            }

            await modifyFields({
                variables: {
                    data: {
                        fields: toUpdateFields,
                    },
                },
            });

            if (toDeleteFields.length > 0) {
                removeField({
                    variables: {
                        data: {
                            ids: toDeleteFields,
                        },
                    },
                });
            }

            message.success("Update Success");
            handleRefresh();
        } catch (error) {
            const errorObj = error as Error;

            const msg =
                errorObj.message === "Page name is already exists!"
                    ? "Page name is already exists!"
                    : "Something went wrong!";
            message.error(msg);
        }
    };

    const handleUpdatePageName = async (title, page_id) => {
        const page = await editPageName({
            variables: {
                brand_id: brandId,
                id: page_id,
                name: title,
                tag: `PROPERTIES-${brandName}`,
            },
        });
        return page;
    };

    return (
        <Formik
            initialValues={initialValue}
            validationSchema={validationSchema}
            enableReinitialize={reinitialize}
            onSubmit={(values: FormValueTypes) => {
                handleSubmit(values);
            }}
        >
            {(formikBag) => (
                <Form
                    {...{
                        formikBag,
                        loading: loading || manualLoader,
                        brandName,
                    }}
                    getDefaultPropertyType={getDefaultPropertyType}
                />
            )}
        </Formik>
    );
};

export default PropertiesOverviewForms;
