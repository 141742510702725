import styled from "styled-components";

import UploadSingleFile from "../../../../components/UploadSingleFile";
import { Card } from "antd";

export const UploadImage = styled(UploadSingleFile)`
    display: block;
    margin-bottom: 15px;
    width: 100%;
    height: 500px;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .ant-upload,
    .ant-btn,
    .ant-spin-nested-loading,
    .ant-spin-container,
    .uploaded-file {
        display: block;
        width: 100%;
        height: 100%;
    }

    .ant-upload, .ant-btn-dashed {
        background-color: transparent;
        color: #00714b;
        border-color: #00714b;
    }

`;

export const styledCard = styled(Card)`
    .ant-card-body {
        display: flex;
        margin: 0;
        padding: 0;
        height: 150px;

        .ant-card-meta {
            flex: 3;
            margin: 0;
            padding: 15px 30px;
            background-color: #F2F2F2;

            .ant-card-meta-detail {
                display: flex;
                flex-direction: column;
                height: 100%;

                .ant-card-meta-description {
                    flex: 1;

                    .description {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                    }
                }
            }
        }
    }
`;

export const ImageContainer = styled.div`
    flex: 1;

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
`;

export const LoadingText = styled.div`
    position: absolute;
    bottom: 1px;
    left: 14px;
    font-size: 12px;
`;