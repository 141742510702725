import styled from "styled-components";
import UploadSingleFile from "../../components/UploadSingleFile";

export const Title = styled.h1`
    margin-left: 24px;
    margin-top: 24px;
    font-weight: bold;
`;

export const StyledUploadImage = styled(UploadSingleFile)`
    width: 100%;
    height: 180px;
    display: block;
    overflow: hidden;
    background: #fff;

    .ant-upload {
        display: block;

        .upload-button {
            height: 220px;
            width: 100%;
            display: block;
        }
    }

    .upload-button {
        height: 220px;
        width: 100%;
        display: block;
    }

    .uploaded-file {
        height: 220px;
        width: 100%;

        img {
            height: 220px;
            width: 100%;
        }
    }

    .remove-file {
        top: 6px;
        right: 10px;
    }
`;
