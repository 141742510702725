import isEmpty from "lodash/isEmpty";
import kebabCase from "lodash/kebabCase";
import toUpper from "lodash/toUpper";
import moment from "moment";
import { getThumbnail } from "../../../util/get-thumbnail";

import { Content } from "../../../entities/Content";

const FieldsMapper = (data) => {
    const fields: any = [];

    Object.keys(data).forEach((fieldKey, fieldIndex) => {
        const field = {
            order: fieldIndex,
            label: fieldKey,
            name: toUpper(kebabCase(fieldKey)),
        };

        if (!fieldKey.includes("image") && fieldKey !== "videos") {
            field["value"] = data[fieldKey] ? data[fieldKey] : null;
            fields.push(field);
        } else if (fieldKey === "videos") {
            if (!isEmpty(data[fieldKey])) {
                data[fieldKey].forEach((vid, vidIndex) => {
                    const vidField = {
                        name: "File",
                        label: "video",
                        order: vidIndex + fieldIndex,
                        value: vid.keyObj,
                        meta_data: {
                            featured: vid.isChecked,
                            description: "",
                            key: vid.keyObj,
                            file_type: vid.type,
                            file_name: vid.name,
                        },
                    };
                    fields.push(vidField);
                });
            }
        } else if (fieldKey.includes("image")) {
            if (data[fieldKey]) {
                field["name"] = "File";
                field["value"] = data[fieldKey].keyObj;
                field["meta_data"] = {
                    featured: false,
                    description: "",
                    key: data[fieldKey].keyObj,
                    file_type: data[fieldKey].type,
                    file_name: data[fieldKey].name,
                    alt_text: data[fieldKey].altText,
                };
                fields.push(field);
            }
        }
    });

    return fields;
};

export const ComponentMapper = (data) => {
    const result = data.map((component, componentIndex) => {
        const componentData = {
            order: componentIndex,
            name: toUpper(kebabCase(component.type)),
            fields: FieldsMapper(component.fields),
        };

        return componentData;
    });

    return result;
};

export class ContentMapper {
    static map(content: Content): Content {
        content.date = content.updated_at
            ? moment(content.updated_at).format("MMM DD, YYYY")
            : moment(content.created_at).format("MMM DD, YYYY");

        const overviewFields =
            !isEmpty(content.containers) &&
            !isEmpty(content.containers[0]) &&
            !isEmpty(
                content.containers[0].field_collections.filter((fc) => fc.name === "OVERVIEW")
            ) &&
            !isEmpty(
                content.containers[0].field_collections.filter((fc) => fc.name === "OVERVIEW")[0]
                    .fields
            ) &&
            content.containers[0].field_collections.filter((fc) => fc.name === "OVERVIEW")[0]
                .fields;

        content.excerpt =
            overviewFields &&
            !isEmpty(overviewFields.filter((field) => field.name === "SHORT-DESCRIPTION")) &&
            overviewFields.filter((field) => field.name === "SHORT-DESCRIPTION")[0].value &&
            overviewFields.filter((field) => field.name === "SHORT-DESCRIPTION")[0].value;

        content.videoURL =
            overviewFields &&
            !isEmpty(overviewFields.filter((field) => field.name === "VIDEO-LINK")) &&
            overviewFields.filter((field) => field.name === "VIDEO-LINK")[0].value &&
            overviewFields.filter((field) => field.name === "VIDEO-LINK")[0].value &&
            getThumbnail(overviewFields.filter((field) => field.name === "VIDEO-LINK")[0].value);

        content.imgURL = content.videoURL
            ? content.videoURL
            : overviewFields &&
              !isEmpty(overviewFields.filter((field) => field.name === "Image")) &&
              overviewFields.filter((field) => field.name === "Image")[0].value &&
              overviewFields.filter((field) => field.name === "Image")[0].value;

        delete content.containers;
        delete content.__typename;

        return content;
    }
}
