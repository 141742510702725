import { useHistory, useParams } from "react-router-dom";

import React, { useContext, useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import _ from "lodash";
import upperCase from "lodash/upperCase";
import lowerCase from "lodash/lowerCase";

import ContentContainer from "@/components/ContentContainer";
import CustomButton from "@/components/CustomButton";
import SeoForm from "@/components/SeoForm";

import { Context } from "../../../../../context";

import { CREATE_PAGE, CREATE_CONTENT, GET_PAGE, REMOVE_MULTI_CONTAINER } from "../../../graphql";

import { Formik } from "formik";
import { Spin, message, Form } from "antd";
import * as yup from "yup";
import { Row, Col } from "antd";

const validationSchema = yup.object().shape({
    seoHomeTitle: yup.string(),
    seoHomeDescription: yup.string(),
    homeHeadingTag: yup.string(),
});

function SeoTagsForm({ category, brandName, handleRefresh }) {
    const history = useHistory();
    const { id } = useParams();

    const {
        state: { pageDetails },
    } = useContext(Context);

    const [initalValue, setInitialValue] = useState<object>({
        seoHomeTitle: "",
        seoHomeDescription: "",
        seoContactTitle: "",
        seoContactDescription: "",
        seoSalesTitle: "",
        seoSalesDescription: "",
        seoPropertiesTitle: "",
        seoMapTitle: "",
        seoMapDescription: "",
    });
    const [createPage] = useMutation(CREATE_PAGE);
    const [createContent] = useMutation(CREATE_CONTENT);
    const [removeMultiContainer] = useMutation(REMOVE_MULTI_CONTAINER);

    const [reinitialize, setReinitialize] = useState(false);
    const [loader, setLoader] = useState(false);
    const [fieldInfo, setFieldInfo] = useState<any>(null);
    const [getPage, { data: pageData }] = useLazyQuery(GET_PAGE, {
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (!reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        const data = pageDetails.payload;
        if (data.brandId) {
            getPage({
                variables: {
                    brand_id: data.brandId,
                    tag: `COMPANY-INFO-${upperCase(data.brandName)}`,
                    slug: `seo-tags-${lowerCase(data.brandName)}`,
                },
            });
        }
    }, [pageDetails.payload, getPage]);

    useEffect(() => {
        if (pageData && !_.isEmpty(pageData.getSpecificBrandPage)) {
            const data = pageData.getSpecificBrandPage.containers[0].field_collections;

            const containers = pageData.getSpecificBrandPage.containers
                ? pageData.getSpecificBrandPage.containers
                : null;
            const containerIds = containers ? containers.map(({ id }) => id) : [];

            let fields: any = {
                seoHomeTitle: "",
                seoHomeDescription: "",
                seoContactTitle: "",
                seoContactDescription: "",
                seoSalesTitle: "",
                seoSalesDescription: "",
                seoPropertiesTitle: "",
                seoMapTitle: "",
                seoMapDescription: "",
            };

            if (data[0].fields) {
                fields["seoHomeTitle"] = data[0].fields[0]?.value || "";
                fields["seoHomeDescription"] = data[0].fields[1]?.value || "";
                fields["seoContactTitle"] = data[0].fields[2]?.value || "";
                fields["seoContactDescription"] = data[0].fields[3]?.value || "";
                fields["seoSalesTitle"] = data[0].fields[4]?.value || "";
                fields["seoSalesDescription"] = data[0].fields[5]?.value || "";
                fields["seoPropertiesTitle"] = data[0].fields[6]?.value || "";
                fields["seoPropertiesDescription"] = data[0].fields[7]?.value || "";
                fields["seoMapTitle"] = data[0].fields[8]?.value || "";
                fields["seoMapDescription"] = data[0].fields[9]?.value || "";
            }

            setFieldInfo({
                pageId: pageData.getSpecificBrandPage.id,
                containerId: containerIds,
                seoHomeTitle: fields.seoHomeTitle,
                seoHomeDescription: fields.seoHomeDescription,
                seoContactTitle: fields.seoContactTitle,
                seoContactDescription: fields.seoContactDescription,
                seoSalesTitle: fields.seoSalesTitle,
                seoSalesDescription: fields.seoSalesDescription,
                seoPropertiesTitle: fields.seoPropertiesTitle,
                seoPropertiesDescription: fields.seoPropertiesDescription,
                seoMapTitle: fields.seoMapTitle,
                seoMapDescription: fields.seoMapDescription,
            });
            setInitialValue(fields);
            setReinitialize(true);
        }
    }, [pageData]);

    const handleSubmit = async (values) => {
        setLoader(true);

        const data = pageDetails.payload;

        if (data.brandId && data.brandName && fieldInfo) {
            await handleUpdatePage(values);
        } else {
            await handleAddPage(values);
        }

        setTimeout(() => {
            handleRefresh();
            setLoader(false);
        }, 500);
    };

    const handleAddPage = async (values) => {
        const brandInfo = pageDetails.payload;
        const pageTag = `COMPANY-INFO-${upperCase(brandInfo.brandName)}`;
        const page = await createPage({
            variables: {
                brand_id: id,
                name: `Seo Tags - ${brandInfo.brandName}`,
                tag: pageTag,
            },
        });

        if (page) {
            const {
                addOrUpdateBrandPage: { id: page_id },
            } = page.data;

            const containerData = {
                page_id,
                name: `Seo Tags Container - ${brandInfo.brandName}`,
                order: 0,
                field_collections: [
                    {
                        name: "Seo Tags FC",
                        order: 0,
                        fields: [
                            {
                                order: 0,
                                label: "Seo Home Title",
                                name: "SEO-HOME-TITLE",
                                value: values.seoHomeTitle ? values.seoHomeTitle : "",
                            },
                            {
                                order: 1,
                                label: "Seo Home Description",
                                name: "SEO-HOME-DESCRIPTION",
                                value: values.seoHomeDescription ? values.seoHomeDescription : "",
                            },
                            {
                                order: 2,
                                label: "Home h1 Tag",
                                name: "HOME-HEADING-TAG",
                                value: values.homeHeadingTag ? values.homeHeadingTag : "",
                            },
                        ],
                    },
                ],
            };

            const container = await createContent({
                variables: {
                    data: {
                        containers: [containerData],
                    },
                },
            });

            container && message.success("SAVED!");
        }
    };

    const handleUpdatePage = async (values) => {
        const brandInfo = pageDetails.payload;

        const containerData = {
            page_id: fieldInfo.pageId,
            name: `Seo Tags Container - ${brandInfo.brandName}`,
            order: 0,
            field_collections: [
                {
                    name: "Seo Tags FC",
                    order: 0,
                    fields: [
                        {
                            order: 0,
                            label: "Seo Home Title",
                            name: "SEO-HOME-TITLE",
                            value: values.seoHomeTitle ? values.seoHomeTitle : "",
                        },
                        {
                            order: 1,
                            label: "Seo Home Description",
                            name: "SEO-HOME-DESCRIPTION",
                            value: values.seoHomeDescription ? values.seoHomeDescription : "",
                        },
                        {
                            order: 2,
                            label: "Seo Contact Title",
                            name: "SEO-CONTACT-TITLE",
                            value: values.seoContactTitle ? values.seoContactTitle : "",
                        },
                        {
                            order: 3,
                            label: "Seo Contact Description",
                            name: "SEO-CONTACT-DESCRIPTION",
                            value: values.seoContactDescription ? values.seoContactDescription : "",
                        },
                        {
                            order: 4,
                            label: "Seo Sales Title",
                            name: "SEO-SALES-TITLE",
                            value: values.seoSalesTitle ? values.seoSalesTitle : "",
                        },
                        {
                            order: 5,
                            label: "Seo Sales Description",
                            name: "SEO-SALES-DESCRIPTION",
                            value: values.seoSalesDescription ? values.seoSalesDescription : "",
                        },
                        {
                            order: 6,
                            label: "Seo Properties Title",
                            name: "SEO-PROPERTIES-TITLE",
                            value: values.seoPropertiesTitle ? values.seoPropertiesTitle : "",
                        },
                        {
                            order: 7,
                            label: "Seo Properties Description",
                            name: "SEO-PROPERTIES-DESCRIPTION",
                            value: values.seoPropertiesDescription
                                ? values.seoPropertiesDescription
                                : "",
                        },
                        {
                            order: 8,
                            label: "Seo Map Title",
                            name: "SEO-MAP-TITLE",
                            value: values.seoMapTitle ? values.seoMapTitle : "",
                        },
                        {
                            order: 9,
                            label: "Seo Map Description",
                            name: "SEO-MAP-DESCRIPTION",
                            value: values.seoMapDescription ? values.seoMapDescription : "",
                        },
                    ],
                },
            ],
        };

        const container = await createContent({
            variables: {
                data: {
                    containers: [containerData],
                },
            },
        });

        if (!_.isEmpty(fieldInfo.containerId)) {
            await removeMultiContainer({
                variables: {
                    data: {
                        ids: fieldInfo.containerId,
                    },
                },
            });
        }

        container && message.success("Update success.");
    };
    return (
        <Spin spinning={loader}>
            <Formik
                initialValues={initalValue}
                validationSchema={validationSchema}
                enableReinitialize={reinitialize}
                onSubmit={(values: any) => {
                    handleSubmit(values);
                }}
                render={({ dirty, isValid, submitForm }) => (
                    <Form>
                        <ContentContainer>
                            <Row style={{ width: "100%" }}>
                                <Col span={24}>
                                    <h1>Home Page</h1>
                                </Col>
                                <Col span={24}>
                                    <SeoForm category={category} showHeading={false} />
                                </Col>
                            </Row>
                        </ContentContainer>
                        <ContentContainer>
                            <Row style={{ width: "100%" }}>
                                <Col span={24}>
                                    <h1>Contact Us Page</h1>
                                </Col>
                                <Col span={24}>
                                    <SeoForm category={"Contact"} showHeading={false} />
                                </Col>
                            </Row>
                        </ContentContainer>
                        <ContentContainer>
                            <Row style={{ width: "100%" }}>
                                <Col span={24}>
                                    <h1>After Sales Page</h1>
                                </Col>
                                <Col span={24}>
                                    <SeoForm category={"Sales"} showHeading={false} />
                                </Col>
                            </Row>
                        </ContentContainer>
                        <ContentContainer>
                            <Row style={{ width: "100%" }}>
                                <Col span={24}>
                                    <h1>Properties Page</h1>
                                </Col>
                                <Col span={24}>
                                    <SeoForm category={"Properties"} showHeading={false} />
                                </Col>
                            </Row>
                        </ContentContainer>
                        <ContentContainer>
                            <Row style={{ width: "100%" }}>
                                <Col span={24}>
                                    <h1>Map View Page</h1>
                                </Col>
                                <Col span={24}>
                                    <SeoForm category={"Map"} showHeading={false} />
                                </Col>
                            </Row>
                        </ContentContainer>
                        <Row
                            justify="end"
                            gutter={24}
                            style={{ padding: "0 25px", marginBottom: 25 }}
                        >
                            <Col span={3}>
                                <CustomButton
                                    disabled={!dirty || !isValid}
                                    style={{ width: "100%", marginRight: 15 }}
                                    type="primary"
                                    onClick={() => submitForm()}
                                >
                                    SAVE
                                </CustomButton>
                            </Col>
                            <Col span={3}>
                                <CustomButton
                                    style={{ width: "100%" }}
                                    onClick={() => history.push(`/manage-company-information`)}
                                >
                                    CANCEL
                                </CustomButton>
                            </Col>
                        </Row>
                    </Form>
                )}
            />
        </Spin>
    );
}

export default SeoTagsForm;
