import React from "react";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import findIndex from "lodash/findIndex";
import has from "lodash/has";

import { Col, Row, Upload, Spin, message } from "antd";
import { CloseOutlined, PlayCircleOutlined } from "@ant-design/icons";

import { convertFileToBase64 } from "../../util/convert-to-base64";

import { StyledCard, StyledFeaturedCheckbox, AddBtn, DeleteBtn } from "./styled";

const BASE_URL = `${process.env.REACT_APP_CONTENT_URL}/uploads/videos`;

interface Props {
    fileList: any;
    onChange?: any;
    onDeleteImage?: any;
    validate?: boolean;
    path?: string;
    sizeLimit?: number;
    accept?: string;
}

const FeaturedVideos = ({
    fileList,
    onChange,
    onDeleteImage,
    validate,
    path,
    sizeLimit,
    accept,
}: Props): JSX.Element => {
    const deleteItem = (key: number, list: any) => {
        const newList: any = [];

        list.forEach((res, index) => {
            if (index !== key) newList.push(res);
        });

        const findCheck = newList.filter((res) => res.isChecked === true);

        if (isEmpty(findCheck) && !isEmpty(newList)) {
            newList[0].isChecked = true;
        }

        onChange(newList);
    };

    const handleChecked = (value: number) => {
        const newList = fileList.map((res, key) => {
            if (key === value) {
                res["isChecked"] = true;
                return res;
            } else {
                res["isChecked"] = false;
                return res;
            }
        });
        onChange(newList);
    };

    const handleOnChange = async ({ file, fileList: list }) => {
        const limit = !!sizeLimit ? sizeLimit : 49;
        const isLt2M = file.size / 1024 / 1024 < limit;

        if (file.status !== "uploading" && has(file, "response")) {
            const base64 = await convertFileToBase64(file.originFileObj);
            const index = await findIndex(list, (e) => e.uid === file.uid);
            list[index]["keyObj"] = file.response.key;
            list[index]["thumbUrl"] = base64;
            onChange(list);
        }

        if (file.status === "uploading" && isLt2M) {
            const index = await findIndex(list, (e) => e.uid === file.uid);
            list[index]["isChecked"] = list.length === 1 && true;
            list[index]["thumbUrl"] = "";
            list[index]["keyObj"] = "";
            list[index]["id"] = "";
            list[index]["url"] = "";
            onChange(list);
        }
    };

    const handleRequest = (option) => {
        const formData = new FormData();
        const dir = path ? `${path}/` : "";
        formData.append("file", option.file);
        formData.append("filename", `${dir}${option.file.uid}${option.file.name}`);

        axios({
            method: "POST",
            url: BASE_URL,
            data: formData,
        })
            .then((res) => {
                option.onSuccess(res.data, option.file);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleBeforeUpload = (file) => {
        // Check file type
        const fileTypes = ["video/3gpp", "video/3gp", "video/mp4", "image/gif"];
        if (!fileTypes.includes(file.type)) {
            message.error("You can only upload 3GP/MP4 file type");
            return false;
        }

        const limit = !!sizeLimit ? sizeLimit : 49;
        const isLt2M = file.size / 1024 / 1024 < limit;

        if (!isLt2M) {
            message.error("File is too big");
            return false;
        }
        return file;
    };

    const renderItems = (list) =>
        list.map((item, index) => (
            <Col key={`feature_images_${index}`}>
                <Spin spinning={item.status === "uploading"}>
                    <StyledCard
                        key={index}
                        checked={item.isChecked}
                        cover={
                            <div className="featuredCont">
                                {(item.url || item.thumbUrl) && (
                                    <video
                                        src={item.url || item.thumbUrl}
                                        className="video-content"
                                    />
                                )}

                                <StyledFeaturedCheckbox
                                    name="featuredCheck"
                                    className="checkbox"
                                    checked={item.isChecked}
                                    onChange={() => handleChecked(index)}
                                >
                                    Featured
                                </StyledFeaturedCheckbox>
                            </div>
                        }
                        extra={
                            <DeleteBtn type="link" onClick={(e) => deleteItem(index, list)}>
                                <CloseOutlined
                                    className={`remove-btn ${
                                        item.isChecked ? "active-checked" : ""
                                    }`}
                                />
                            </DeleteBtn>
                        }
                    />
                </Spin>
            </Col>
        ));

    return (
        <Row gutter={16}>
            {renderItems(fileList)}
            <Upload
                action={BASE_URL}
                showUploadList={false}
                customRequest={handleRequest}
                onChange={handleOnChange}
                beforeUpload={handleBeforeUpload}
                accept={accept ? accept : ".mp4"}
                fileList={fileList}
            >
                {validate && (
                    <Col>
                        <AddBtn type="dashed" className="add-btn">
                            <PlayCircleOutlined />
                            Upload Video
                        </AddBtn>
                    </Col>
                )}
            </Upload>
        </Row>
    );
};

export default FeaturedVideos;
