import React from "react";

import { FormItem, Input } from "formik-antd";
import { CloseOutlined } from "@ant-design/icons";

import { WrapperForm, MediaContainer } from "./styled";
import RichtextWithImage from "../../../../../components/Richtext";
import { ComponentFormType } from "../content-forms.types";

const ComponentFourteen = ({
    index,
    setFieldValue,
    fieldsArrayEvent,
    values,
    uploadComplete,
    setUploadStatus,
    brandName,
}: ComponentFormType): JSX.Element => {
    return (
        <WrapperForm>
            <MediaContainer
                accept={"image/jpeg,image/jpg,image/png"}
                value={values.components[index].fields.image}
                noteMessage={".jpeg, .jpg, .png (max 50 MB)"}
                onChange={(e) => {
                    setUploadStatus(e?.status);
                    setFieldValue(`components[${index}].fields.image`, e);
                }}
            >
                <h1>Upload Banner +</h1>
            </MediaContainer>
            {brandName === "ALP" && (
                <FormItem
                    label="Image alt text"
                    name={`components[${index}].fields.image.altText`}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input name={`components[${index}].fields.image.altText`} />
                </FormItem>
            )}
            <FormItem
                label={"Image Link"}
                name={`components[${index}].fields.title`}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Input name={`components[${index}].fields.title1`} />
            </FormItem>
            <FormItem
                label={"Title"}
                name={`components[${index}].fields.title`}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Input name={`components[${index}].fields.title`} />
            </FormItem>
            <FormItem
                label={"Description"}
                name={`components[${index}].fields.description`}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <RichtextWithImage
                    toolbar={["heading", "bold", "italic", "link", "insertTable"]}
                    data={values.components[index].fields.description}
                    onChange={(value: string) =>
                        setFieldValue(`components[${index}].fields.description`, value, false)
                    }
                />
            </FormItem>
            <CloseOutlined
                className="remove-btn"
                onClick={() => {
                    uploadComplete !== "uploading" && fieldsArrayEvent.remove(index);
                }}
            />
        </WrapperForm>
    );
};

export default ComponentFourteen;
