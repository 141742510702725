import * as Yup from "yup";

const phoneRegex = /^(09|639)\d{9}$/;
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Enter First Name!"),
    middleName: Yup.string(),
    lastName: Yup.string().required("Enter Last Name!"),
    mobileNumber: Yup.string()
        .matches(phoneRegex, "Phone No. is invalid")
        .required("Enter Mobile Number!"),
    email: Yup.string().matches(emailRegex, "Email is invalid").required("Enter Email Address"),
    brand: Yup.string().required("Select Brand"),
    role: Yup.string().required("Select Role"),
    status: Yup.string(),
    userStatus: Yup.boolean().nullable(),
    reason: Yup.string().when("status", {
        is: (val) => val !== "Pending",
        then: Yup.string().when("userStatus", {
            is: false,
            then: Yup.string().required("Enter Reason"),
            otherwise: Yup.string().nullable(),
        }),
        otherwise: Yup.string().nullable(),
    }),
});

export const columns = [
    {
        title: "Reference #",
        dataIndex: "id",
    },
    {
        title: "Date",
        dataIndex: "createdAt",
        sorter: true,
    },
    {
        title: "Activity",
        dataIndex: "activity",
        sorter: true,
    },
];
