import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import _ from "lodash";

import { Formik } from "formik";
import { Spin, message } from "antd";

import Forms from "./forms";

import {
    CREATE_PAGE,
    EDIT_PAGE_TITLE,
    GET_PAGE,
    CREATE_CONTAINER,
    REMOVE_MULTIPLE_FC,
} from "../../graphql";
import { Context } from "../../../../context";

import { ComponentMapper } from "../../dto/ContentMapper";
import generateId from "../../../../util/generate-id";
import { validationSchema, componentList } from "../../manage-content.utils";
import { userPermission } from "../../../../util/user-access";
import PageNotFound from "../../../../components/PageNotFound";

import {
    ComponentType,
    FieldInfoTypes,
    FormValueType,
    NewFieldObjectTypes,
    VidListType,
} from "../../manage-content.types";
import {
    INIT_VALUES,
    INIT_FIELD_INFO_VALUES,
    imageMapper,
    tagMapper,
    fieldMapper,
} from "../../manage-content.utils";

const ContentForms = (): JSX.Element => {
    const history = useHistory();
    const params = useParams();

    const {
        state: { brand: brandData, user },
    } = useContext(Context);
    const brandId = brandData?.id;
    const brandName: string = brandData?.name;

    const permissions = user?.roles[0].permissions;
    const newsAccess = userPermission(permissions, "manage:content-news");
    const eventsAccess = userPermission(permissions, "manage:content-events");
    const announcementAccess = userPermission(permissions, "manage:content-announcement");
    const destinationAccess = userPermission(permissions, "manage:content-destinations");
    const neighborhoodAccess = userPermission(permissions, "manage:content-neighborhood");
    const sustainabilityAccess = userPermission(permissions, "manage:content-sustainability");
    const hbgAccess = userPermission(permissions, "manage:content-home-buying-guide");
    const blogsAccess = userPermission(permissions, "manage:content-blogs");
    const vlogsAccess = userPermission(permissions, "manage:content-vlogs");

    const contentTypeList = [
        {
            label: "News",
            slug: "news",
            value: "news",
            access: newsAccess,
        },
        {
            label: "Events",
            slug: "events",
            value: "events",
            access: eventsAccess,
        },
        {
            label: "Announcements",
            slug: "announcements",
            value: "announcements",
            access: announcementAccess,
        },
        {
            label: "Destinations",
            slug: "destinations",
            value: "destinations",
            access: destinationAccess,
        },
        {
            label: "Neighborhood",
            slug: "neighborhood",
            value: "neighborhood",
            access: neighborhoodAccess,
        },
        {
            label: "Sustainability",
            slug: "sustainability",
            value: "sustainability",
            access: sustainabilityAccess,
        },
        {
            label: "Home Buying Guide",
            slug: "home-buying-guide",
            value: "home buying guide",
            access: hbgAccess,
        },
        {
            label: "Blogs",
            slug: "blogs",
            value: "blogs",
            access: blogsAccess,
        },
        {
            label: "Vlogs",
            slug: "vlogs",
            value: "vlogs",
            access: vlogsAccess,
        },
    ];

    const listOfOptions = _.filter(contentTypeList, "access");
    const [createPage] = useMutation(CREATE_PAGE);
    const [editPage] = useMutation(EDIT_PAGE_TITLE);
    const [createContainer] = useMutation(CREATE_CONTAINER);
    const [removeFC] = useMutation(REMOVE_MULTIPLE_FC);

    const location = useLocation();

    const {
        data: pageData,
        refetch,
        loading,
    } = useQuery(GET_PAGE, {
        skip: !params.slug || !brandId,
        fetchPolicy: "no-cache",
        variables: {
            page_id: location?.state?.pageId || "",
        },
    });

    const [loader, setLoader] = useState(false);
    const [reinitialize, setReinitialize] = useState(false);

    const getDefaultContentType = () => {
        if (params.contentType) {
            const find = listOfOptions.filter((item) => item.slug === params.contentType);
            const defaultContentVal = find && find.length > 0 ? find[0].value : "";
            return defaultContentVal;
        }
        return "";
    };

    const [fieldInfo, setFieldInfo] = useState<FieldInfoTypes>(INIT_FIELD_INFO_VALUES);

    const [initialValue, setInitialValue] = useState<FormValueType>(
        INIT_VALUES(params.contentType, getDefaultContentType)
    );

    useEffect(() => {
        if (!reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        if (params.slug && _.has(pageData, "getSpecificBrandPageById")) {
            const data = pageData.getSpecificBrandPageById.containers;

            const topContainer = data[0];
            const fieldItem: ComponentType[] = [];
            const components: ComponentType[] = [];
            const fields: FormValueType = INIT_VALUES(params.contentType, getDefaultContentType);

            topContainer.field_collections.forEach((fieldItems) => {
                if (fieldItems.fields || fieldItems.children) {
                    if (fieldItems.name === "TAGS") {
                        fields["tags"] = fieldItems.fields.map((tag) => tag.value);
                        fieldItems.fields.forEach((tag) => fieldItem.push(tag));
                    } else if (fieldItems.name === "COMPONENTS") {
                        fields["components"] = fieldItems.children.map((fc) => {
                            const componentField = _.find(componentList, [
                                "type",
                                _.lowerCase(fc.name),
                            ]);

                            const newFieldObj: NewFieldObjectTypes = {
                                type: componentField.type,
                                fields: {},
                            };

                            const vidList: VidListType[] = [];

                            Object.keys(componentField.fields).forEach((key) => {
                                const hit = fc.fields.find((f) => key === _.camelCase(f.label));

                                if (key !== "videos") {
                                    if (!_.isEmpty(hit)) {
                                        if (_.camelCase(hit.label).includes("image")) {
                                            newFieldObj["fields"][_.camelCase(hit.label)] = {
                                                keyObj: hit.meta_data.key,
                                                url: hit.value,
                                                uid: generateId(),
                                                status: "done",
                                                type: hit.meta_data.file_type,
                                                altText: hit.meta_data.alt_text,
                                            };
                                        } else {
                                            newFieldObj["fields"][_.camelCase(hit.label)] =
                                                hit.value ? hit.value : "";
                                        }
                                    } else if (key.includes("image")) {
                                        newFieldObj["fields"][key] = null;
                                    }
                                } else {
                                    fc.fields.forEach((vid, index) => {
                                        if (index >= 2) {
                                            vidList.push({
                                                keyObj: vid.meta_data.key,
                                                url: vid.value,
                                                uid: generateId(),
                                                status: "done",
                                                type: vid.meta_data.file_type,
                                                name: vid.meta_data.file_name,
                                                isChecked: vid.meta_data.featured,
                                                thumbUrl: "",
                                            });
                                        }
                                    });
                                }
                            });

                            if (
                                componentField.type === "component 11" ||
                                componentField.type === "component 12"
                            ) {
                                if (!_.isEmpty(vidList)) {
                                    newFieldObj["fields"]["videos"] = vidList;
                                } else {
                                    newFieldObj["fields"]["videos"] = [];
                                }
                            }

                            components.push(fc.id);
                            return newFieldObj;
                        });
                    } else {
                        fieldItems.fields.forEach((items) => {
                            const name = _.camelCase(items.name);
                            if (items.name === "HEADING-TAG") {
                                fields["HeadingTag"] = items.value;
                            } else if (items.name === "BLOGS-IN-NEIGHBORHOOD") {
                                fields["blogsInNeighborhood"] = items.value
                                    .split("#")
                                    .filter((item) => item);
                            } else if (items.name === "BLOGS-IN-PROPERTY") {
                                fields["blogsInProperty"] = items.value
                                    .split("#")
                                    .filter((item) => item);
                            } else if (items.name === "NEIGHBORHOOD-IN-PROPERTY") {
                                fields["neighborhoodInProperty"] = items.value
                                    .split("#")
                                    .filter((item) => item);
                            } else if (name === "image") {
                                fields["image"] = {
                                    keyObj: items.meta_data.key,
                                    url: items.value,
                                    uid: generateId(),
                                    status: "done",
                                    altText: items.meta_data.alt_text,
                                };
                            } else {
                                fields[_.camelCase(items.name)] = items.value;
                            }
                            fieldItem.push(items);
                        });
                    }
                }
            });

            setFieldInfo({
                fields: fieldItem,
                containerId: data[0].id,
                overviewFcId: data[0].field_collections[0].id,
                componentFcId: data[0].field_collections[1].id,
                tagFcId: data[0].field_collections[2].id,
                components,
            });

            setInitialValue(fields);

            setTimeout(() => {
                setLoader(false);
            }, 1000);
            setReinitialize(true);
        }
    }, [pageData]);

    const containerMapper = (values, page_id) => {
        const filter: ComponentType[] = fieldInfo.fields;
        const containers: any = [
            {
                order: 0,
                ...(!_.isEmpty(fieldInfo.containerId) && { id: fieldInfo.containerId }),
                page_id: page_id,
                name: "CONTENT",
                field_collections: [
                    {
                        ...(!_.isEmpty(fieldInfo.overviewFcId) && { id: fieldInfo.overviewFcId }),
                        name: "OVERVIEW",
                        order: 0,
                        fields: [
                            fieldMapper(
                                "Content (Default)",
                                "CONTENT-DEFAULT",
                                values.contentDefault,
                                0,
                                filter
                            ),
                            imageMapper(values.image, filter),
                            fieldMapper("Video Link", "VIDEO-LINK", values.videoLink, 2, filter),
                            fieldMapper("Category", "CATEGORY", values.category, 3, filter),
                            fieldMapper(
                                "Sub-Category",
                                "SUBCATEGORY",
                                values.subcategory,
                                4,
                                filter
                            ),
                            fieldMapper("Location", "LOCATION", values.location, 5, filter),
                            fieldMapper(
                                "Date Published",
                                "DATE-PUBLISHED",
                                values.datePublished,
                                6,
                                filter
                            ),
                            fieldMapper("Title", "TITLE", values.title, 7, filter),
                            fieldMapper("Subtitle", "SUBTITLE", values.subtitle, 8, filter),
                            fieldMapper(
                                "Short Description",
                                "SHORT-DESCRIPTION",
                                values.shortDescription,
                                9,
                                filter
                            ),
                            fieldMapper("Longitude", "LONGITUDE", values.longitude, 10, filter),
                            fieldMapper("Latitude", "LATITUDE", values.latitude, 11, filter),
                            fieldMapper("seo Title", "SEO-TITLE", values.seoTitle, 12, filter),
                            fieldMapper(
                                "seo Description",
                                "SEO-DESCRIPTION",
                                values.seoDescription,
                                13,
                                filter
                            ),
                            fieldMapper("H1 Tag", "HEADING-TAG", values.HeadingTag, 14, filter),
                            brandName === "ALP"
                                ? fieldMapper(
                                      "H2 Tag",
                                      "SUB-HEADING",
                                      values.subHeading,
                                      15,
                                      filter
                                  )
                                : null,
                            brandName === "AVIDA" && params.contentType === "neighborhood"
                                ? fieldMapper(
                                      "Neighborhood In Property",
                                      "NEIGHBORHOOD-IN-PROPERTY",
                                      values.neighborhoodInProperty.join("#"),
                                      14,
                                      filter
                                  )
                                : null,
                            brandName === "AVIDA" && params.contentType === "blogs"
                                ? fieldMapper(
                                      "Neighborhood In Property",
                                      "BLOGS-IN-PROPERTY",
                                      values.blogsInProperty.join("#"),
                                      14,
                                      filter
                                  )
                                : null,
                            brandName === "AVIDA" && params.contentType === "blogs"
                                ? fieldMapper(
                                      "Neighborhood",
                                      "BLOGS-IN-NEIGHBORHOOD",
                                      values.blogsInNeighborhood.join("#"),
                                      15,
                                      filter
                                  )
                                : null,
                        ].filter((item) => item !== null),
                    },
                    {
                        ...(!_.isEmpty(fieldInfo.componentFcId) && { id: fieldInfo.componentFcId }),
                        name: "COMPONENTS",
                        order: 1,
                        ...(!_.isEmpty(values.components) && {
                            children: ComponentMapper(values.components),
                        }),
                    },
                    {
                        ...(!_.isEmpty(fieldInfo.tagFcId) && { id: fieldInfo.tagFcId }),
                        name: "TAGS",
                        order: 2,
                        fields: [...tagMapper(values, filter)],
                    },
                ],
            },
        ];

        return containers;
    };

    const handleUpdatePageName = async (title, page_id) => {
        const page = await editPage({
            variables: {
                brand_id: brandId,
                id: page_id,
                name: title,
                tag: `CONTENT-${brandName}`,
            },
        });
        return page;
    };

    const handleSubmit = async (values) => {
        setLoader(true);
        await handleAddUpdatePage(values);
    };

    const handleAddUpdatePage = async (values) => {
        try {
            let update = false;
            let pageId = null;
            if (pageData && _.has(pageData, "getSpecificBrandPageById")) {
                pageId = pageData.getSpecificBrandPageById.id;
                update = true;
            } else {
                const page = await createPage({
                    variables: {
                        brand_id: brandData.id,
                        name: values.title,
                        tag: `CONTENT-${brandName}`,
                    },
                });
                const {
                    addOrUpdateBrandPage: { id: page_id },
                } = page.data;
                pageId = page_id;
            }

            if (pageId) {
                const containers = containerMapper(values, pageId);

                if (!_.isEmpty(fieldInfo.components)) {
                    removeFC({
                        variables: {
                            data: {
                                ids: fieldInfo.components,
                            },
                        },
                    });
                }

                if (update) {
                    const filter = fieldInfo?.fields.filter(
                        (item: ComponentType) => item.name === "TITLE"
                    );

                    if (filter[0]?.value !== values.title) {
                        const {
                            data: {
                                addOrUpdateBrandPage: { slug },
                            },
                        } = await handleUpdatePageName(values.title, pageId);
                        history.push(`/manage-content/${params.contentType}/${slug}`, {
                            pageId: pageId,
                        });
                    } else refetch();
                }

                await createContainer({
                    variables: {
                        data: {
                            containers: containers,
                        },
                    },
                });

                update && refetch();
                message.success(update ? "Update success." : "Content successfully created.");
                setTimeout(() => {
                    setLoader(false);
                }, 1000);
                !update && history.push(`/manage-content`);
            }
        } catch (error) {
            const errorObj = error as Error;

            const msg =
                errorObj.message === "Page name is already exists!"
                    ? "Page name is already exists!"
                    : "Something went wrong upon updating!";
            message.error(msg);
            setLoader(false);
        }
    };

    const filteredRoute = _.filter(listOfOptions, {
        slug: params.contentType,
    });

    return (
        <Spin spinning={loading || loader}>
            {!filteredRoute.length ? (
                <PageNotFound />
            ) : (
                <Formik
                    initialValues={initialValue}
                    validationSchema={validationSchema}
                    enableReinitialize={reinitialize}
                    onSubmit={(values: FormValueType) => handleSubmit(values)}
                >
                    {(formikBag) => (
                        <Forms
                            {...{
                                formikBag,
                            }}
                        />
                    )}
                </Formik>
            )}
        </Spin>
    );
};

export default ContentForms;
