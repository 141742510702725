import React, { useState } from "react";

import { FieldArray } from "formik";
import { Form, FormItem, Input } from "formik-antd";
import { Row, Col } from "antd";
import _ from "lodash";

import CustomButton from "../../../components/CustomButton";
import ContentContainer from "../../../components/ContentContainer";
import ModalComponent from "./modal-components";
import ComponentTemplate from "./component-template";
import { FormProps } from "../types";

const Forms = ({ formikBag, createAccess, formLabel }: FormProps): JSX.Element => {
    const { submitForm, dirty, isValid, values, setFieldValue } = formikBag;

    const [modalComponent, setModalComponent] = useState(false);

    const handleModalComponent = (component, push) => {
        push(component);
        setModalComponent(false);
    };

    const renderComponents = ({ event, values, setFieldValue }) => {
        return (
            <>
                {values?.components?.map((component, index) =>
                    ComponentTemplate(component, index, setFieldValue, event, values)
                )}

                <CustomButton
                    type="dashed"
                    className="add-section"
                    style={{ width: "100%", fontSize: 13, fontWeight: 300 }}
                    onClick={() => {
                        setModalComponent(true);
                    }}
                >
                    <p style={{ margin: 0 }}>+ Add Section</p>
                </CustomButton>
                <ModalComponent
                    visible={modalComponent}
                    onChange={handleModalComponent}
                    push={event.push}
                    onCancel={() => setModalComponent(false)}
                />
            </>
        );
    };

    return (
        <Form>
            <ContentContainer style={{ display: "flex", flexDirection: "column" }}>
                <Row justify="start">
                    <Col span={24}>
                        <FormItem
                            label={<h1>{formLabel || `<b>Home Buying Guide </b>(Default)`}</h1>}
                            name={"homeBuyingGuideDefault"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name={_.camelCase(formLabel)} placeholder="Main Label" />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FieldArray
                            name="components"
                            render={(event) => renderComponents({ event, values, setFieldValue })}
                        />
                    </Col>
                </Row>
            </ContentContainer>

            <Row justify="end" gutter={24} style={{ padding: "0 25px 25px", marginBottom: 25 }}>
                <Col span={3}>
                    <CustomButton
                        disabled={!dirty || !isValid}
                        style={{
                            width: "100%",
                            marginRight: 15,
                            display: !createAccess ? "none" : "block",
                        }}
                        type="primary"
                        onClick={() => submitForm()}
                    >
                        SAVE
                    </CustomButton>
                </Col>
            </Row>
        </Form>
    );
};

export default Forms;
