import styled from "styled-components";
import { Radio, Table } from "antd";

export const Container = styled.div`
    width: 100%;
    margin: 0 auto;

    .ant-radio-group {
        width: 100%;

        .status-btn {
            width: 50%;

            & > span {
                display: block;
            }
        }
    }

    .btn-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        overflow: hidden;
    }

    .status-num {
        display: inline-block;
        padding: 0 10px;
        height: 1.3em;
        line-height: 1.3em;
        border-radius: 8px;
        background-color: #aaa;
        color: white;

        &.selected {
            background-color: #00714b;
        }
    }    
`;

export const StyledTable = styled(Table)`
    overflow-x: auto;

    &.onrow-enabled {
        .ant-table-row {
            cursor: pointer;
        }
    }

    .ant-pagination {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .ant-pagination-total-text {
        margin-right: auto;
    }


`;
