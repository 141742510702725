import find from "lodash/find";
import includes from "lodash/includes";

import { routes } from "../context/routers";

export const getHomeLink = (permissions: any) => {
    if (permissions) {
        const scope = permissions.map(({ scope }) => scope);

        const firstMatch: any = find(routes, ({ access }) => {
            return includes(scope, access);
        });

        return firstMatch.path;
    }
};
