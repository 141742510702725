import { v4 } from "uuid";
import _ from "lodash";

export type ComponentType = {
    id: string;
    label: string;
    meta_data: ImageType | null;
    name: string;
    order: number;
    value: string | null;
};

export type FieldInfoTypes = {
    fields: ComponentType[];
    containerId: string;
    overviewFcId: string;
    components?: ComponentType[];
};

export interface ImageType {
    keyObj?: string;
    url?: string;
    uid?: string;
    status?: string;
    altText?: string;
    key?: string;
    file_type?: string | null;
    file_name?: string | null;
    featured?: boolean;
    alt_text?: string | null;
}

export const INIT_FIELD_INFO_VALUES = {
    fields: [],
    containerId: "",
    overviewFcId: "",

    tagFcId: "",
};

const getFieldID = (fields, targetKey): string => {
    if (fields) {
        const filter = fields.filter((item: ComponentType) => item.name === targetKey);

        if (filter.length > 0) {
            return filter[0].id;
        }
    }
    return v4();
};

export const fieldMapper = (label, name, newValue, order, filter) => {
    const fieldId = getFieldID(filter, name);
    return {
        ...(!_.isEmpty(fieldId) && { id: fieldId }),
        label: label,
        name: name,
        value: !_.isEmpty(newValue) ? newValue : null,
        order: order,
    };
};

export type FormValueType = {
    award: string;
    year: string;
    organization: string;
    image: ImageType | null;
};

export const INIT_VALUES = {
    award: "",
    year: "",
    organization: "",
    image: null,
};

export const imageMapper = (image, filter) => {
    const fieldId = getFieldID(filter, "Image");

    if (!image) {
        return null;
    }

    return {
        ...(!_.isEmpty(fieldId) && { id: fieldId }),
        order: 1,
        label: "Logo",
        name: "Image",
        value: image.keyObj,
        meta_data: {
            featured: false,
            description: "",
            key: image.keyObj,
            alt_text: image.altText,
        },
    };
};

export const cardText = [
    {
        htmlEl: "h2",
        varName: "award",
    },
    {
        htmlEl: "span",
        varName: "organization",
    },
    {
        htmlEl: "span",
        className: "small",
        varName: "year",
    },
];
