import _ from "lodash";
import {
    FormValueType,
    FieldInfoType,
    FieldInfoChildrenType,
    FileType,
    FieldItemType,
} from "./unit-tabs-types";
import generateId from "../../../../../util/generate-id";

export const INIT_VALUES: FormValueType = {
    propertyStatus: "",
    propertyTitle: "",
    propertyDetails: "",
    seoOverviewTitle: "",
    seoOverviewDescription: "",
    subHeading: "",
    overviewHeadingTag: "",
    imagesGallery: [],
    imagesCaptions: [],
    videos: [""],
    panoramas: [""],
    documentTitle: "",
    file: null,
    description: "",
    tags: [""],
};

export const INIT_CHILDREN: FieldInfoChildrenType = {
    imagesGalleryId: "",
    imagesGallery: [],
    videosId: "",
    videos: [],
    panoramasFCId: "",
    panoramas: [],
    unitsFloorPlansId: "",
    unitsFloorPlans: [],
};

export const INIT_FIELDS: FileType = {
    uid: "",
    url: "",
    keyObj: "",
    id: "",
    status: "",
    name: "",
    type: "",
};

export const INIT_FIELD_INFO: FieldInfoType = {
    fields: [],
    children: INIT_CHILDREN,
    fileId: "",
    fcId: "",
    parentId: "",
};

export const imageMapper = (values): FieldItemType =>
    values.map((item, index) => ({
        order: index,
        name: "Image",
        label: "Image",
        value: item.keyObj,
        meta_data: {
            featured: item.isChecked,
            description: item.caption,
            key: item.keyObj,
            alt_text: item.altText,
        },
    }));

export const textLinkMapper = (values, label): FieldItemType =>
    values.map((item, index) => ({
        order: index,
        name: _.upperCase(label),
        label: label,
        value: item,
    }));

export const fieldMapper = (values, label): FieldItemType => {
    return values.map((value, index) => ({
        order: index,
        label: label,
        name: _.startCase(label),
        value: value,
    }));
};

export const processImageFields = (res) => {
    let imageFields = [];
    let imageChildren = [];

    if (res.fields) {
        imageFields = res.fields.map((f) => ({
            uid: generateId(),
            caption: f.meta_data.description,
            isChecked: f.meta_data.featured,
            url: f.value,
            keyObj: f.meta_data.key,
            id: f.id,
            status: "done",
            altText: f.meta_data.alt_text,
        }));

        imageChildren = res.fields.map((f) => ({
            id: f.id,
            value: !_.isEmpty(f.value) ? f.value : null,
            caption: f.meta_data.description,
            isChecked: f.meta_data.featured,
            order: f.order,
            altText: f.meta_data.alt_text,
        }));
    }

    return { imageFields, imageChildren };
};
