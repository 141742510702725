import { Form, FormItem, Input } from "formik-antd";
import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../../../context";
import { DatePicker, Select } from "antd";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { usageTypeList } from "./constant";

import { Row, Col, Spin, Button, message } from "antd";
import { convertFileToBase64 } from "../../../../util/convert-to-base64";
import CustomButton from "../../../../components/CustomButton";
import axios from "axios";
import { UploadStyled as Upload, UploadList, ErrorMessage } from "./styled";
import { BASE_URL, SUPPORTED_FORMATS, FILE_SIZE_LIMIT } from "./constant";
import moment from "moment";

const { Option } = Select;
function ContractForm({ handleSlider, formikBag }) {
    const { submitForm, values, setFieldValue, resetForm, dirty, isValid, errors } = formikBag;
    const { id } = useParams();
    const INTERNAL_SERVER_ERROR = 500;
    const UNPROCESSABLE_ENTITY = 422;
    const SCAN_ERROR_STATUS = 403;
    const BAD_REQUEST = 400;
    const {
        state: { brand: brandData },
    } = useContext(Context);

    const {
        dispatch: { storeIsUploading },
    } = useContext(Context);

    const brandName = brandData?.name;

    const [isBroken, setIsBroken] = useState<boolean>(false);
    const [isNotScanned, setIsNotScanned] = useState<boolean>(false);

    const handleRequest = (option) => {
        setIsBroken(false);
        setIsNotScanned(false);
        let formData = new FormData();
        formData.append("file", option.file);

        axios({
            method: "POST",
            url: BASE_URL,
            data: formData,
        })
            .then((res) => {
                const { data } = res;
                option.onSuccess(res.data, option.file);

                if (data.scanErrorStatus && data.scanErrorStatus === SCAN_ERROR_STATUS) {
                    setIsNotScanned(true);
                }
                setFieldValue("file_link", res.data.key);
            })
            .catch((err) => {
                const { status } = err.response;

                if (status === BAD_REQUEST) {
                    const { data } = err.response;
                    message.error(data.message);
                }

                if (status === INTERNAL_SERVER_ERROR) {
                    message.error("Oops, Something went wrong");
                }

                if (status === UNPROCESSABLE_ENTITY) {
                    setIsBroken(true);
                    message.error("Upload Failed, File is infected, Please upload another file.");
                }
            });
    };

    const handleOnChange = async ({ file, fileList: list }) => {
        const limit = FILE_SIZE_LIMIT;
        const isLt2M = file.size / 1024 / 1024 < limit;

        if (file.status !== "uploading" && _.has(file, "response")) {
            const base64 = await convertFileToBase64(file.originFileObj);
            file["keyObj"] = file.response.key;
            file["thumbUrl"] = base64;
            setFieldValue("file", file);
            setFieldValue("file_name", file.name);
            storeIsUploading(false);
        }

        if (file.status === "uploading") {
            file["thumbUrl"] = "";
            file["keyObj"] = "";
            file["url"] = "";
            setFieldValue("file", file);
        }
    };

    useEffect(() => {
        console.log(values);
    }, [values]);

    return (
        <Form>
            <div>
                <h4>Document Name</h4>
                <Input name="name" />
            </div>
            <br />
            <div>
                <h4>Usage Type</h4>
                <Select
                    defaultValue="Residential Condo"
                    style={{ width: "100%" }}
                    onChange={(value) => setFieldValue("usage_type", value)}
                    value={values.usage_type}
                    disabled={id ? true : false}
                >
                    {usageTypeList.map((val) => {
                        return <Option value={val}>{val}</Option>;
                    })}
                </Select>
            </div>
            <br />
            <div>
                <h4>Document Type</h4>
                <Select
                    defaultValue="CTS"
                    style={{ width: "100%" }}
                    disabled={id ? true : false}
                    onChange={(value) => setFieldValue("type", value)}
                    value={values.type}
                >
                    <Option value="CTS">CTS</Option>
                    <Option value="DOAS">DOAS</Option>
                </Select>
            </div>
            <br />
            <div>
                <h4>Version</h4>
                <DatePicker
                    value={values.version ? moment(values.version) : undefined}
                    style={{ width: "100%" }}
                    onChange={(date, dateString) => setFieldValue("version", dateString)}
                />
            </div>
            <br />
            <div>
                <h4>File Name</h4>
                <Input name="file_alias" placeholder="File Name here... " />
            </div>
            <br />
            <FormItem
                label={"Attachments"}
                name={"file.name"}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                {values.file.name || values.file_name ? (
                    <Spin spinning={values.file.status !== "done" && !isBroken}>
                        <UploadList
                            color="#F2F2F2"
                            style={{ margin: 0, marginBottom: 15 }}
                            onClose={() => setFieldValue("file", null)}
                            closable
                        >
                            <span className="file-name">
                                {values.file.name || values.file_name}
                            </span>
                        </UploadList>
                        {/* {isBroken && (
                            <ErrorMessage>
                                The file is infected, Saving this attachment is disabled.{" "}
                            </ErrorMessage>
                        )}
                        {isNotScanned && (
                            <ErrorMessage>
                                File was uploaded but was not scanned, Please contact your system
                                administrator.{" "}
                            </ErrorMessage>
                        )} */}
                    </Spin>
                ) : null}
                <Upload
                    action={BASE_URL}
                    customRequest={handleRequest}
                    onChange={(event) => handleOnChange(event)}
                    accept={SUPPORTED_FORMATS}
                    showUploadList={false}
                >
                    <Button type="dashed" block>
                        Browse files
                    </Button>
                </Upload>
            </FormItem>

            <Row justify={"space-between"}>
                <Col span={11}>
                    <CustomButton
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        disabled={!dirty || !isValid}
                        onClick={() => submitForm()}
                    >
                        SAVE
                    </CustomButton>
                </Col>
                <Col span={11}>
                    <CustomButton
                        style={{ width: "100%" }}
                        onClick={() => {
                            resetForm();
                            handleSlider();
                        }}
                    >
                        CANCEL
                    </CustomButton>
                </Col>
            </Row>
        </Form>
    );
}

export default ContractForm;
