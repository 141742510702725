import client, { AxiosResponse, AxiosError } from "axios";

const { REACT_APP_IAM_URL, REACT_APP_IAM_CLIENT_ID, REACT_APP_IAM_CLIENT_SECRET, REACT_APP_IAM_REDIRECT_URL } = process.env;

export const getAccessToken = async (code: string): Promise<AxiosResponse | AxiosError> => {
    return client
        .post(`${REACT_APP_IAM_URL}/oauth/token`, {
            grantType: "authorization_code",
            clientId: REACT_APP_IAM_CLIENT_ID,
            clientSecret: REACT_APP_IAM_CLIENT_SECRET,
            provider: "azure",
            redirect_uri: REACT_APP_IAM_REDIRECT_URL,
            code,
        })
        .then((response: AxiosResponse) => {
            const {
                data: { data },
            } = response;
            localStorage.setItem("ACCESS_TOKEN", data.accessToken);
            localStorage.setItem("REFRESH_TOKEN", data.refreshToken);

            return data;
        })
        .catch((error: AxiosError) => {
            throw new Error(error.response?.data.message);
        });
};
