import React, { useState, useEffect, useContext } from "react";
import isEmpty from "lodash/isEmpty";

import { Formik } from "formik";
import { Row, Col, Card, Avatar, Spin, message } from "antd";

import { useParams, useHistory } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import Button from "../../../../components/CustomButton";

import CustomDrawer from "../../../../components/CustomDrawer";
import CustomTable from "../../../../components/CustomTable";
import Form from "../../components/user-forms";
import { LayoutContainer as Container, StyledUserDetails } from "./styled";

import { Context } from "../../../../context";
import { columns, validationSchema } from "./constant";
import { userDetails, updateUser, getRole, getBrand, getUserActivityLogs } from "../../axios";
import { UserActivity } from "../../../../entities/UserActivity";
import { UserActivityPaginationResponse } from "../../dto/UserActivityPaginationResponse";

const UserDetails: React.FC = () => {
    const { userId } = useParams();
    const history = useHistory();

    const {
        state: { userDetails: details },
    } = useContext(Context);

    const [data, setData] = useState<UserActivity[]>([]);
    const [sortBy, setSortBy] = useState<string>("createdAt");
    const [sortOrder, setSortOrder] = useState<string>("desc");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentPageSize, setCurrentPageSize] = useState<number>(10);
    const [search, setSearch] = useState<string>("");

    const [showSlider, setShowSlider] = useState<boolean>(false);
    const [initialFormValue, setInitialFormValue] = useState({});
    const [roleList, setRoleList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [loaderTable, setLoaderTable] = useState(false);
    const [meta, setMeta] = useState<any>();
    const [state, setState] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        createdAt: "",
        id: "",
        email: "",
        contactNumber: "",
        brandName: "",
        roleName: "",
        status: "",
        imgPath: "",
    });

    // Activity Logs Search
    const handleSearch = (query) => {
        setSearch(query);
    };

    const handleSort = (field, order) => {
        setSortOrder(order);
        setSortBy(field);
    };

    const handlePageSize = async (pageSize) => {
        setCurrentPageSize(pageSize);
    };

    const handlePagination = async (page, pageSize) => {
        setCurrentPage(page);
        setCurrentPageSize(pageSize);
    };

    const handleGetActivities = async (): Promise<void> => {
        try {
            const { data: activities, meta: metaData }: UserActivityPaginationResponse =
                await getUserActivityLogs(
                    {
                        page: currentPage,
                        limit: currentPageSize,
                        q: search,
                        sortBy: sortBy,
                        order: sortOrder,
                    },
                    userId
                );

            setData(activities);
            setMeta(metaData);
            setLoaderTable(false);
        } catch (error) {
            setTimeout(() => {
                setLoaderTable(false);
            }, 1000);
            message.error("Something went wrong on getting User Activity Logs!");
        }
    };

    useEffect(() => {
        if (!data.length) {
            setLoaderTable(true);
        }
        (async () => {
            if (!isEmpty(state.id)) {
                await handleGetActivities();
            }
        })();
    }, [currentPage, currentPageSize, search, sortBy, sortOrder, userId, details.payload]);

    const handleGetUserData = async (): Promise<void> => {
        try {
            setLoader(true);
            const userData = await userDetails(userId);
            const brand = await getBrand();
            await handleGetActivities();
            setBrandList(brand);
            if (userData) {
                handleSelectTenant(userData.tenants[0].id);
                setState(userData);
                setInitialFormValue({
                    firstName: userData.firstName,
                    middleName: userData.middleName,
                    lastName: userData.lastName,
                    mobileNumber: userData.contactNumber,
                    email: userData.email,
                    brand: userData.tenants[0].id,
                    role: userData.roles[0].id,
                    userStatus: userData.isActive,
                    status: userData.status,
                    reason: userData.reason,
                });
            }
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        } catch (error) {
            console.log(error);
            setTimeout(() => {
                setLoader(false);
            }, 1000);
            message.error("Something went wrong!");
            history.push("/manage-users");
        }
    };

    const handleSlider = () => {
        setShowSlider(!showSlider);
    };

    const handleSelectTenant = async (value: string) => {
        if (value) {
            const role = await getRole(value);
            setRoleList(role);
        }
    };

    const handleSubmit = async (formValues: object) => {
        setLoader(true);
        handleSlider();

        const userData = await updateUser(userId, formValues);
        if (userData) {
            setState(userData);
            setInitialFormValue({
                firstName: userData.firstName,
                middleName: userData.middleName,
                lastName: userData.lastName,
                mobileNumber: userData.contactNumber,
                email: userData.email,
                brand: userData.tenants[0].id,
                role: userData.roles[0].id,
                userStatus: userData.isActive,
                status: userData.status,
            });
            message.success("Update success");
            await handleGetUserData();
            setLoader(false);
        }
    };

    useEffect(() => {
        (async () => {
            await handleGetUserData();
        })();
    }, []);

    return (
        <React.Fragment>
            <Spin spinning={loader}>
                <Container>
                    <Row gutter={16}>
                        <Col span={24} md={7}>
                            <Card>
                                <StyledUserDetails>
                                    <div className="profile">
                                        {state.imgPath ? (
                                            <Avatar src={state.imgPath} size={85} />
                                        ) : (
                                            <Avatar icon={<UserOutlined />} size={85} />
                                        )}
                                        <div>
                                            <h1 className="user-name" style={{ marginTop: 7 }}>
                                                {state.firstName && `${state.firstName} `}
                                                {state.middleName && `${state.middleName} `}
                                                {state.lastName && `${state.lastName}`}
                                            </h1>
                                            <p>Created: {state.createdAt}</p>
                                        </div>
                                    </div>
                                    <div className="userDetails">
                                        <p>UserID#: {state.id}</p>
                                        <p>Email: {state.email}</p>
                                        <p>Contact #: {state.contactNumber}</p>
                                        <p>Brand: {state.brandName}</p>
                                        <p>Role: {state.roleName}</p>
                                        <p>Login Status: {state.status}</p>
                                        <Button type="primary" onClick={handleSlider}>
                                            Update Profile
                                        </Button>
                                    </div>
                                </StyledUserDetails>
                            </Card>
                        </Col>
                        <Col span={24} md={17}>
                            <Card>
                                <CustomTable
                                    headingText="Activities"
                                    labelText="Activities"
                                    handleSearch={handleSearch}
                                    columns={columns}
                                    dataSource={data}
                                    handleSort={handleSort}
                                    currentPageSize={currentPageSize}
                                    handlePageSize={handlePageSize}
                                    handlePagination={handlePagination}
                                    totalData={meta?.pagination?.total}
                                    loading={loaderTable}
                                    noAddBtn={true}
                                    noStatusBtn={true}
                                />
                            </Card>
                        </Col>
                    </Row>

                    <CustomDrawer visible={showSlider} title={"Update User"} onClose={handleSlider}>
                        <Formik
                            initialValues={initialFormValue}
                            validationSchema={validationSchema}
                            enableReinitialize={!isEmpty(initialFormValue) && !isEmpty(roleList)}
                            onSubmit={({
                                firstName,
                                middleName,
                                lastName,
                                email,
                                brand,
                                role,
                                mobileNumber,
                                userStatus,
                                reason,
                            }: any) => {
                                const formValues = {
                                    firstName,
                                    middleName,
                                    lastName,
                                    email,
                                    contactNumber: mobileNumber,
                                    isActive: userStatus,
                                    password: "",
                                    tenantId: brand,
                                    roleId: role,
                                    reason: userStatus ? "" : reason,
                                };

                                handleSubmit(formValues);
                            }}
                            render={(formikBag) => (
                                <Form
                                    {...{
                                        formikBag,
                                        handleSlider,
                                        roleList,
                                        brandList,
                                        formType: "UPDATE",
                                        handleSelectTenant,
                                        loading: loader,
                                    }}
                                />
                            )}
                        />
                    </CustomDrawer>
                </Container>
            </Spin>
        </React.Fragment>
    );
};

export default UserDetails;
