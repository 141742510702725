import styled from "styled-components";

import { Checkbox } from "antd";

export const DropdownWrapper = styled.div`
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: -1px 2px 7px rgba(0, 0, 0, 0.2);
    padding: 5px;
    display: flex;
    flex-direction: column;
    max-height: 150px;
    overflow: scroll;

    p {
        margin: 0;
    }

    .divider-day {
        margin: 0;
    }
`;

export const CheckboxStyled = styled(Checkbox)`
    margin-left: 0 !important;
    padding: 4px 2px;

    &:hover {
        background: #d9d9d9;
    }
`;
