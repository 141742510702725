import styled from "styled-components";

export const Title = styled.h1`
    margin-left: 24px;
    margin-top: 24px;
    font-weight: bold;
`;

export const StyledIFrame = styled.embed`
    border: 0;
`;
