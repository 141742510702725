import React from "react";
import styled from "styled-components";

import { Layout } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

const { Header } = Layout;

const StyledHeader = styled(Header)`
    background: #fff;
    display: flex;
    border-bottom: 1px solid #ddd;
`;

const SiderButton = styled.div`
    cursor: pointer;
    font-size: 20px;
    padding: 0 25px;
`;

interface Props {
    collapsed: boolean;
    toggle: () => void;
}

const LayoutHeader: React.FC<Props> = ({ collapsed, toggle }) => (
    <StyledHeader style={{ padding: 0 }}>
        <SiderButton onClick={() => toggle()}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </SiderButton>
    </StyledHeader>
);

export default LayoutHeader;
