import styled from "styled-components";
import { Button } from "antd";

export const Container = styled.div`
    position: relative;
    height: 100vh;
    background-color: #F1F2F6;

    .login-bg-image {
      border-width: 0px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1440px;
      height: 750px;
    }
`;

export const Content = styled.main`
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 25.5%;
`;

export const Logo = styled.img`
    display: block;
    margin: 0 auto;
    width: 272px;
    height: 61px;
`;

export const Title = styled.span`
    display: block;
    font-size: 14px;
    text-align: center;
    color: rgba(127, 127, 127, 0.847058823529412);
`;

export const LoginBtn = styled(Button)`
    padding: 0 50px;
    width: 100%;
    margin-top: 15%;
    text-align: center;
    border-radius: 4px;
    font-size: 16px;
    height: 40px;
`;

export const Copyright = styled.span`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    width: 100%;
    color: rgba(127, 127, 127, 0.847058823529412);
    text-align: center;
`;

export const Version = styled.span`
    position: absolute;
    bottom: 0px;
    display: flex;
    font-size: 12px;
    width: 100%;
    color: rgba(127, 127, 127, 0.847058823529412);
    justify-content: flex-end;
`;
