import React from "react";

import { FieldArray } from "formik";
import { Input, FormItem } from "formik-antd";
import { Row, Col } from "antd";
import { EyeFilled, PlusCircleFilled, DeleteFilled } from "@ant-design/icons";

import { StyledButton } from "./styled";

interface Props {
    values: string[];
    placeholder: string;
    name: string;
    preview?: (values: string) => void;
}

const MultipleUrlFields = ({ values, placeholder, name, preview }: Props): JSX.Element => {
    return (
        <FieldArray
            name={name}
            render={(event) => (
                <>
                    {values.map((res, index) => (
                        <FormItem
                            key={`${name}[${index}]`}
                            name={`${name}[${index}]`}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Row gutter={12}>
                                <Col span={18}>
                                    <Input name={`${name}[${index}]`} placeholder={placeholder} />
                                </Col>
                                <Col span={6}>
                                    <StyledButton
                                        onClick={() => {
                                            preview && res && preview(res);
                                        }}
                                    >
                                        <EyeFilled />
                                    </StyledButton>
                                    <StyledButton onClick={() => event.push("")}>
                                        <PlusCircleFilled />
                                    </StyledButton>
                                    <StyledButton
                                        onClick={() => {
                                            values.length > 1 && event.remove(index);
                                            values.length === 1 && event.replace(0, "");
                                        }}
                                    >
                                        <DeleteFilled />
                                    </StyledButton>
                                </Col>
                            </Row>
                        </FormItem>
                    ))}
                </>
            )}
        />
    );
};

export default MultipleUrlFields;
