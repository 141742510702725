import * as yup from "yup";
import moment from "moment";
import _ from "lodash";

const imageSchema = yup
    .array()
    .of(
        yup.object().shape({
            status: yup.string().matches(/done/, { message: "file is still uploading" }),
        })
    )
    .nullable();

export const validationSchema = yup.object().shape({
    percentage: yup
        .number()
        .nullable()
        .positive("Percentage must be a positive number.")
        .typeError("Percentage must be a number."),
    startDate: yup.mixed().test("valid-date", "Please enter a valid date.", (val) => {
        return !_.isEmpty(val) ? moment(val, "MM/YYYY").isValid() : true;
    }),
    constructionEndDate: yup
        .mixed()
        .test("valid-date", "Please enter a valid date.", (val) => {
            return !_.isEmpty(val) ? moment(val, "MM/YYYY").isValid() : true;
        })
        .when("startDate", (startDate, schema) => {
            return schema.test({
                test: (constructionEndDate) => {
                    const constructionStartDate = moment(startDate, "MM/YYYY");
                    const endDate = moment(constructionEndDate, "MM/YYYY");
                    return constructionStartDate.isAfter(endDate) === true ? false : true;
                },
                message: "Construction End Date must be later than Construction Start Date.",
            });
        }),
    details: yup.string().nullable().max(3000, "Details exceeds 3000 characters."),
    imageGallery: imageSchema,
});
