import userListAction from "../feature/manage-users/action";
import companyInfoAction from "../feature/manage-company-information/action";
import propertyAction from "../feature/manage-properties/action";

const initialAction = {
  storeUser: (dispatch) => {
    return (data) => {
      dispatch({ type: 'STORE_USER', payload: data });
    };
  },
  removeUser: (dispatch) => {
    return () => {
      dispatch({ type: 'REMOVE_USER' });
    };
  },
  storeBrand: (dispatch) => {
      return (data) => {
          dispatch({ type: "STORE_BRAND", payload: data });
      };
  },
  storeAllBrand: (dispatch) => {
      return (data) => {
          dispatch({ type: "STORE_ALL_BRAND", payload: data });
      };
  },
  storeIsUploading: (dispatch) => {
      return (data) => {
          dispatch({ type: "STORE_IS_UPLOAD", payload: data });
      };
  }
}


export default {
    ...initialAction,
    ...userListAction,
    ...companyInfoAction,
    ...propertyAction,
};
