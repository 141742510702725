import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import { Formik } from "formik";
import { Spin, message } from "antd";
import _ from "lodash";

import { Context } from "../../../../context";
import { userPermission } from "../../../../util/user-access";
import { validationSchema } from "../../utils";
import generateId from "../../../../util/generate-id";

import { CREATE_PAGE, GET_PAGE, CREATE_CONTAINER, REMOVE_MULTIPLE_FC } from "../../graphql";

import Form from "../form";

import { componentList, initialContainersData, ComponentMapper } from "../../utils";
import { InitialValueTypes } from "../../types";

export default function PaymentChannel(): JSX.Element {
    const formLabel = "Payment Channel";
    const sectionName = "PAYMENT-CHANNEL";
    const history = useHistory();

    const {
        state: { brand: brandData, user },
    } = useContext(Context);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialValue, setInitialValue] = useState<InitialValueTypes>({
        homeBuyingGuideDefault: "",
        components: [],
    });
    const [reinitialize, setReinitialize] = useState<boolean>(false);
    const [fieldInfo, setFieldInfo] = useState<any>({
        fields: [],
        containerId: "",
        fcId: "",
    });

    const brandId = brandData?.id;
    const brandName = brandData?.name;
    const permissions = user?.roles[0].permissions;
    const createAccess = userPermission(permissions, "create:hbg");
    const updateAccess = userPermission(permissions, "update:hbg");
    const deleteAccess = userPermission(permissions, "delete:hbg");
    const pageTag = `BUYERS-GUIDE-${_.upperCase(brandName)}`;

    const [createPage] = useMutation(CREATE_PAGE);
    const [createContainer] = useMutation(CREATE_CONTAINER);
    const [removeFC] = useMutation(REMOVE_MULTIPLE_FC);

    const {
        data: pageData,
        refetch,
        // isLoading,
    } = useQuery(GET_PAGE, {
        skip: !brandId,
        fetchPolicy: "no-cache",
        variables: {
            brand_id: brandId,
            slug: `buyers-guide-${_.lowerCase(brandName)}`,
            tag: pageTag,
        },
    });

    const getFieldID = (fields, targetKey) => {
        if (fields) {
            const filter = fields.filter((item: any) => item.name === targetKey);
            if (filter.length > 0) {
                return filter[0].id;
            }
        }
        // ? need to generate unique id?
        return null;
    };

    const containerMapper = (values: InitialValueTypes, page_id: string) => {
        const filter: any = fieldInfo.fields;
        const fieldId = getFieldID(filter, sectionName);

        const containers: any = [
            {
                order: 0,
                ...(!_.isEmpty(fieldInfo.containerId) && { id: fieldInfo.containerId }),
                page_id: page_id,
                name: "BUYERS-GUIDE",
                field_collections: [
                    {
                        ...(!_.isEmpty(fieldInfo.fcId) && { id: fieldInfo.fcId }),
                        name: sectionName,
                        order: 0,
                        fields: [
                            {
                                ...(!_.isEmpty(fieldId) && { id: fieldId }),
                                label: formLabel,
                                name: sectionName,
                                value: !_.isEmpty(values.homeBuyingGuideDefault)
                                    ? values.homeBuyingGuideDefault
                                    : null,
                                order: 0,
                            },
                        ],
                        children: !_.isEmpty(values.components)
                            ? ComponentMapper(values.components, fieldInfo.fcId)
                            : [],
                    },
                ],
            },
        ];
        return containers;
    };

    const handleSubmit = async (values: InitialValueTypes) => {
        let update = false;
        let pageId = null;
        setIsLoading(true);

        try {
            if (pageData && _.has(pageData, "getSpecificBrandPage")) {
                pageId = pageData.getSpecificBrandPage.id;
                update = true;
            } else {
                const page = await createPage({
                    variables: {
                        brand_id: brandId,
                        name: `Buyers Guide - ${brandName}`,
                        tag: pageTag,
                    },
                });

                const {
                    addOrUpdateBrandPage: { id: page_id },
                } = page.data;

                pageId = page_id;
            }

            if (pageId) {
                const containers = containerMapper(values, pageId);

                if (!_.isEmpty(fieldInfo.components)) {
                    removeFC({
                        variables: {
                            data: {
                                ids: fieldInfo.components,
                            },
                        },
                    });
                }

                await createContainer({
                    variables: {
                        data: {
                            containers: containers,
                        },
                    },
                });

                update && refetch();

                message.success(
                    update ? "Update success." : `${formLabel} guide successfully created.`
                );

                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);

                history.push(history.location.pathname);
            }
        } catch (error) {
            refetch();

            setTimeout(() => {
                setIsLoading(false);
            }, 1000);

            message.error("Something went wrong!");
        }
    };

    useEffect(() => {
        if (!reinitialize) {
            setReinitialize(false);
        }

        if (pageData && _.has(pageData, "getSpecificBrandPage")) {
            const { getSpecificBrandPage } = pageData;
            let data;

            setIsLoading(true);

            let isContainerExisting = getSpecificBrandPage.containers.findIndex((container) =>
                container.field_collections.some((fc) => fc.name === sectionName)
            );

            if (getSpecificBrandPage.containers.length < 4 && isContainerExisting === -1) {
                data = [
                    ...getSpecificBrandPage.containers,
                    ...initialContainersData(getSpecificBrandPage.id, sectionName, formLabel),
                ];

                isContainerExisting = data.findIndex((container) =>
                    container.field_collections.some((fc) => fc.name === sectionName)
                );
            } else {
                data = getSpecificBrandPage.containers;
            }

            const field_collections: any = data[isContainerExisting]?.field_collections || [];

            const fieldItem: any = [];
            const components: any = [];
            const fields: any = {
                homeBuyingGuideDefault: "",
                components: [],
            };

            field_collections?.forEach((res) => {
                if (res?.children) {
                    fields.components = res?.children.map((item) => {
                        const createNewFieldObj = (fc, componentField) => {
                            const newFieldObj = {
                                type: componentField.type,
                                fields: {},
                            };

                            Object.keys(componentField.fields).forEach((key) => {
                                if (key === "videos") return;

                                const match = fc.fields.find((f) => key === _.camelCase(f.label));

                                if (!_.isEmpty(match)) {
                                    if (_.camelCase(match.label).includes("image")) {
                                        newFieldObj.fields[_.camelCase(match.label)] = {
                                            keyObj: match.meta_data.key,
                                            url: match.value,
                                            uid: generateId(),
                                            status: "done",
                                            type: match.meta_data.file_type,
                                        };
                                    } else {
                                        newFieldObj.fields[_.camelCase(match.label)] =
                                            match.value || "";
                                    }
                                } else if (key.includes("image")) {
                                    newFieldObj.fields[key] = null;
                                }
                            });

                            return newFieldObj;
                        };

                        const componentField = _.find(componentList, [
                            "type",
                            _.lowerCase(item.name),
                        ]);

                        const newFieldObj = createNewFieldObj(item, componentField);

                        components.push(item.id);
                        return newFieldObj;
                    });
                }

                if (res.fields) {
                    res.fields.forEach((item) => {
                        fields[_.camelCase(item.name)] = item.value;
                        fieldItem.push(item);
                    });
                }
            });

            setFieldInfo({
                fields: fieldItem,
                containerId: data[isContainerExisting].id,
                fcId: data[isContainerExisting].field_collections[0]?.id,
                components,
            });

            setInitialValue(fields);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
            setReinitialize(true);
        }
    }, [reinitialize, pageData]);

    return (
        <Spin spinning={isLoading}>
            <Formik
                initialValues={initialValue}
                validationSchema={validationSchema}
                enableReinitialize={reinitialize}
                onSubmit={async (values: InitialValueTypes) => {
                    await handleSubmit(values);
                }}
            >
                {(formikBag) => (
                    <Form
                        {...{
                            formLabel,
                            formikBag,
                            createAccess,
                            updateAccess,
                            deleteAccess,
                        }}
                    />
                )}
            </Formik>
        </Spin>
    );
}
