import { gql } from "@apollo/client";

export const GET_CONTRACT_ENTRY = gql`
    query searchCtsDoas($id: String!) {
        findContractDocument(id: $id) {
            id
            name
            type
            usage_type
            file {
                name
                file_link
                version
                file_alias
                type
                usage_type
            }
        }
    }
`;

export const GET_FILE_LIST = gql`
    query getLinkedUploadFilesByContractEntryId($id: String!) {
        getLinkedUploadFilesByContractEntryId(contractDocumentEntryID: $id) {
            id
            file {
                name
                file_link
                version
                file_alias
                type
                usage_type
            }
            created_at
            updated_at
        }
    }
`;

export const FIND_ENTRY = gql`
    query FilterContractDocuments($name: String!) {
        filterContractDocuments(ctsDoasSearchInput: { name: $name }) {
            id
            created_at
            updated_at
            deleted_at
            name
            type
            usage_type
            file {
                name
                file_link
                version
                file_alias
                type
                usage_type
            }
        }
    }
`;

export const UPLOAD_CONTRACT_ENTRY = gql`
    mutation uploadCtsDoas(
        $name: String!
        $file: ContractDocumenPointerInput!
        $type: ContractType!
        $usage_type: String!
    ) {
        uploadCtsDoas(
            createCtsDoasInput: { name: $name, type: $type, usage_type: $usage_type, file: $file }
        ) {
            id
            name
            type
            usage_type
            file {
                name
                file_link
            }
        }
    }
`;

export const GET_CONTRACT_LIST = gql`
    query SearchContractDocuments($name: String!, $pageSize: Float!, $page: Float!) {
        searchContractDocuments(
            ctsDoasSearchInput: { name: $name, pageSize: $pageSize, page: $page }
        ) {
            total_count
            contract_documents {
                id
                created_at
                updated_at
                name
                type
                usage_type
                file {
                    name
                    file_link
                    version
                    file_alias
                    type
                    usage_type
                }
            }
        }
    }
`;

export const DELETE_ENTRY = gql`
    mutation deleteContractDocument($id: String!) {
        removeCtsDoas(id: $id)
    }
`;

export const UPDATE_ENTRY = gql`
    mutation updateCtsDoas(
        $id: String!
        $name: String!
        $file: ContractDocumenPointerInput!
        $type: ContractType!
        $usage_type: String!
    ) {
        updateCtsDoas(
            updateCtsDoasInput: {
                id: $id
                name: $name
                type: $type
                usage_type: $usage_type
                file: $file
            }
        ) {
            id
            name
            type
            usage_type
            file {
                name
                file_link
            }
        }
    }
`;
