import React from "react";

import { FormItem, Input } from "formik-antd";
import { CloseOutlined } from "@ant-design/icons";

import Richtext from "../../../../components/Richtext";

import { WrapperForm } from "../styled";
import { ComponentPropTypes } from "../../types";

const ComponentOne = ({
    index,
    setFieldValue,
    fieldsArrayEvent,
    values,
}: ComponentPropTypes): JSX.Element => {
    return (
        <WrapperForm>
            <FormItem
                label="Header"
                name={`components[${index}].fields.subtitle`}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Input name={`components[${index}].fields.subtitle`} placeholder="Header" />
            </FormItem>
            <FormItem
                label={"Body"}
                name={`components[${index}].fields.body`}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Richtext
                    heading={true}
                    data={values?.components[index]?.fields.text}
                    onChange={(value: string) =>
                        setFieldValue(`components[${index}].fields.text`, value)
                    }
                />
            </FormItem>
            <CloseOutlined
                className="remove-btn"
                onClick={() => {
                    fieldsArrayEvent.remove(index);
                }}
            />
        </WrapperForm>
    );
};

export default ComponentOne;
