import * as yup from "yup";

const imageSchema = yup.object().shape({
    status: yup.string().matches(/done/, { message: "file is still uploading" }),
});

const landmarksContentsSchema = yup.array().of(
    yup.object().shape({
        images: yup.array().of(imageSchema).nullable(),
    })
);

export const validationSchema = yup.object().shape({
    title: yup.string(),
    landmarksContents: landmarksContentsSchema,
});

export const checkboxList = [
    { label: "Bars", value: "Bars" },
    { label: "Bus Stop", value: "Bus Stop" },
    { label: "Churches", value: "Churches" },
    { label: "Fire Station", value: "Fire Station" },
    { label: "Gym", value: "Gym" },
    { label: "Hospitals", value: "Hospitals" },
    { label: "Parks", value: "Parks" },
    { label: "Police Station", value: "Police Station" },
    { label: "Restaurant", value: "Restaurant" },
    { label: "Schools", value: "Schools" },
    { label: "Shopping Malls", value: "Shopping Malls" },
    { label: "Others", value: "Others" },
];

export const locationRegion = [
    {
        label: "Metro Manila",
        value: "Metro Manila",
    },
    {
        label: "Luzon",
        value: "Luzon",
    },
    {
        label: "Visayas",
        value: "Visayas",
    },
    {
        label: "Mindanao",
        value: "Mindanao",
    },
];

export const defaultFields = {
    locationDefault: "",
    locationSpecific: "",
    locationBrief: "",
    region: "",
    longitude: "",
    latitude: "",
    seoLocationTitle: "",
    seoLocationDescription: "",
    locationHeadingTag: "",
    subHeading: "",
    landmarkdsDefault: "",
    locationImages: [],
    locationImagesCaptions: [],
    videos: [""],
    panoramas: [""],
    landmarksContents: [
        {
            establishmentName: "",
            distance: "",
            location: "",
            latitude: "",
            longitude: "",
            body: "",
            images: [],
            imagesCaption: [],
        },
    ],
};
