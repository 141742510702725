import styled from "styled-components";

import { Checkbox } from "formik-antd";

export const CheckboxGroup = styled(Checkbox.Group)`
    .ant-checkbox-group-item {
        width: 15%;
        font-size: 1.17em;
        margin-bottom: 15px;
    }
`;

export const LabelTitle = styled.h1`
    font-weight: 400;

    b {
        font-weight: 600;
    }
`;

export const DynamicFormContainer = styled.div`
    .add-row {
        background: #fafafa;
        margin-bottom: 25px;

        p {
            font-size: 13px;
            font-weight: 300;
        }
    }
`;

export const ContentWrapper = styled.div`
    border: 1px solid #ddd;
    padding: 15px 25px 25px;
    border-radius: 5px;
    position: relative;
    margin-bottom: 15px;

    & > .remove-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
`;

export const StyledIFrame = styled.embed`
    border: 0;
`;