import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../../../../context";
import generateId from "../../../../../util/generate-id";

import { Formik } from "formik";
import { validationSchema } from "../constant";

import { Spin, message } from "antd";
import Forms from "./forms";

import { useQuery, useMutation } from "@apollo/client";
import { GET_LINKS, ADD_LINKS, REMOVE_LINKS } from "../../../graphql";

import _ from "lodash";

import { FormValueTypes } from "../../../manage-links.types";
import { INIT_LINKS_VALUE, containerMapper } from "../../../manage-links.utils";

const SocialMediaLinksForms = (): JSX.Element => {
    const [loader, setLoader] = useState(false);
    const [linksIds, setLinksId] = useState<string[]>([]);
    const [reinitialize, setReinitialize] = useState(false);

    const [initialValue, setInitialValue] = useState<FormValueTypes>(INIT_LINKS_VALUE);

    const {
        state: { brand: brandData },
    } = useContext(Context);
    const brandId = brandData?.id;
    const brandName = brandData?.name;

    const { refetch, data: linksData } = useQuery(GET_LINKS, {
        skip: !brandId,
        variables: {
            brandId: brandId,
        },
        fetchPolicy: "no-cache",
        partialRefetch: true,
    });

    const [addLinks] = useMutation(ADD_LINKS);
    const [removeLinks] = useMutation(REMOVE_LINKS);

    const handleUpdateLinks = async (links) => {
        const containers = await containerMapper(links, brandId, "social_media");

        if (!_.isEmpty(linksIds)) {
            await removeLinks({
                variables: {
                    data: {
                        ids: linksIds,
                    },
                },
            });
        }

        const content = await addLinks({
            variables: containers,
        });
        return content;
    };

    const handleSubmit = async (values) => {
        try {
            setLoader(true);
            await handleUpdateLinks(values.links);
            await refetch();

            message.success("Links successfully added");
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        } catch (err) {
            message.error("Something went wrong!");
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    };

    useEffect(() => {
        if (reinitialize) setReinitialize(false);
    }, [reinitialize]);

    useEffect(() => {
        if (!brandId || !brandName) {
            setLoader(true);
        } else {
            setLoader(false);
        }
    }, [brandName, brandId]);

    useEffect(() => {
        if (linksData && linksData.getLinks) {
            setLoader(true);
            const data = linksData.getLinks;

            const tempFields: FormValueTypes = {
                links: [],
            };

            const listId: string[] = [];

            data.length >= 1
                ? data.map((res) => {
                      if (res.category === "social_media") {
                          tempFields.links.push({
                              name: res.name,
                              link: res.link,
                              image: res.image
                                  ? {
                                        keyObj: res.imageKey,
                                        url: res.image,
                                        uid: generateId(),
                                        status: "done",
                                        // ! SET INITIAL VALUE COMING FROM res.alt_text
                                        altText: res.image.alt_text ?? _.lowerCase(res.name),
                                    }
                                  : null,
                          });
                          listId.push(res.id);
                      }
                  })
                : tempFields.links.push({
                      name: "",
                      link: "",
                      image: null,
                  });

            setLinksId(listId);
            if (tempFields.links.length !== 0) {
                setInitialValue(tempFields);
            }

            setTimeout(() => {
                setLoader(false);
            }, 1000);

            setReinitialize(true);
        }
    }, [linksData]);

    return (
        <Spin spinning={loader}>
            <Formik
                initialValues={initialValue}
                validationSchema={validationSchema}
                enableReinitialize={reinitialize}
                onSubmit={(values: FormValueTypes) => handleSubmit(values)}
            >
                {(formikBag) => (
                    <Forms
                        {...{
                            formikBag,
                        }}
                    />
                )}
            </Formik>
        </Spin>
    );
};

export default SocialMediaLinksForms;
