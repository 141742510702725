import styled from "styled-components";

export const TagWrapper = styled.div`
    margin-bottom: 25px;

    h3 {
        font-size: 14px;
        color: #000;
        font-weight: 400;
    }

    .wrapper {
        padding: 15px;
        border: 1px solid #d3d3d3;
        width: 100%;
        border-radius: 3px;
    }
`;

export const RichtextStyled = styled.div`
    margin-bottom: 20px;

    .ck.ck-reset.ck-editor.ck-rounded-corners {
        .ck.ck-toolbar.ck-toolbar_grouping {
            .ck.ck-button {
                font-size: 8px !important;
            }
        }

        .ck.ck-editor__main {
            .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
                height: 100px;
            }
        }
    }
`;
