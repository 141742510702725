import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { useQuery } from "@apollo/client";

import { FieldArray } from "formik";
import { Form, FormItem, Input, AutoComplete } from "formik-antd";
import { Row, Col, DatePicker } from "antd";
import SeoForm from "@/components/SeoForm";
import CustomButton from "../../../../components/CustomButton";
import CustomSelect from "../../../../components/CustomSelect";
import ContentContainer from "../../../../components/ContentContainer";
import CustomTag from "../../../../components/CustomTag";
import ModalComponent from "./modal-components";
import ComponentTemplate from "./component-template";

import { Title, StyledUploadImage } from "./styled";
import { Context } from "../../../../context";
import { QUERY_LOCATION_AC, QUERY_LOCATION_GEOCODE } from "../../graphql";
import { userPermission } from "../../../../util/user-access";
import { useLocation } from "react-router-dom";
import { getUploadNote } from "../../../../util/get-upload-note";
import { getAcceptedFiles } from "../../../../util/get-accepted-filetypes";
import { PropType } from "../../manage-content.types";

const Forms = ({ formikBag }: PropType): JSX.Element => {
    const [modalComponent, setModalComponent] = useState(false);
    const history = useHistory();
    const params = useParams();
    const title = params.slug ? "Edit" : "Add";

    const { values, submitForm, errors, setFieldValue, isValid, dirty } = formikBag;

    const [locationAC, setlocationAC] = useState("");
    const [locationSearchable, setlocationSearchable] = useState("");
    const [placeId, setPlaceId] = useState("");
    const [options, setOptions] = useState<{ value: string }[]>([]);

    const {
        state: { brand: brandData, user },
    } = useContext(Context);
    const brandName = brandData?.name;
    const brandId = brandData?.id;

    const location = useLocation();
    const isVideoBannerAllowed = params?.contentType === "neighborhood" && brandName === "AVIDA";
    const isAddingNewContent = location.pathname.includes("add");

    const permissions = user?.roles[0].permissions;
    const newsAccess = userPermission(permissions, "manage:content-news");
    const eventsAccess = userPermission(permissions, "manage:content-events");
    const announcementAccess = userPermission(permissions, "manage:content-announcement");
    const destinationAccess = userPermission(permissions, "manage:content-destinations");
    const neighborhoodAccess = userPermission(permissions, "manage:content-neighborhood");
    const sustainabilityAccess = userPermission(permissions, "manage:content-sustainability");
    const hbgAccess = userPermission(permissions, "manage:content-home-buying-guide");
    const blogsAccess = userPermission(permissions, "manage:content-blogs");
    const vlogsAccess = userPermission(permissions, "manage:content-vlogs");
    const contentTypeList = [
        {
            label: "News",
            slug: "news",
            value: "news",
            access: newsAccess,
        },
        {
            label: "Events",
            slug: "events",
            value: "events",
            access: eventsAccess,
        },
        {
            label: "Announcements",
            slug: "announcements",
            value: "announcements",
            access: announcementAccess,
        },
        {
            label: "Destinations",
            slug: "destinations",
            value: "destinations",
            access: destinationAccess,
        },
        {
            label: "Neighborhood",
            slug: "neighborhood",
            value: "neighborhood",
            access: neighborhoodAccess,
        },
        {
            label: "Sustainability",
            slug: "sustainability",
            value: "sustainability",
            access: sustainabilityAccess,
        },
        {
            label: "Home Buying Guide",
            slug: "home-buying-guide",
            value: "home buying guide",
            access: hbgAccess,
        },
        {
            label: "Blogs",
            slug: "blogs",
            value: "blogs",
            access: blogsAccess,
        },
        {
            label: "Vlogs",
            slug: "vlogs",
            value: "vlogs",
            access: vlogsAccess,
        },
    ];
    const listOfOptions = _.filter(contentTypeList, "access");

    const throttled = useRef(_.throttle((newValue) => setlocationSearchable(newValue), 4000));
    useEffect(() => throttled.current(locationAC), [locationAC]);
    const { data: locationData, error: locationDataError } = useQuery(QUERY_LOCATION_AC, {
        skip: !locationSearchable,
        variables: {
            brand_id: brandId,
            location: locationSearchable,
        },
    });

    useEffect(() => {
        const onCompleted = (locationData) => {
            if (!locationData) {
                setOptions([]);
            } else {
                const mappedData = locationData.getLocations.places.map((res) => {
                    return { value: res.name };
                });
                setOptions(mappedData);
            }
        };
        const onError = (locationDataError) => {
            console.log(locationDataError, "QUERY ERROR");
        };
        if (onCompleted || onError) {
            if (onCompleted && !locationDataError) {
                onCompleted(locationData);
            } else if (onError && locationDataError) {
                onError(locationDataError);
            }
        }
    }, [locationData, locationDataError]);

    const { data: geoCodeData, error: geoCodeError } = useQuery(QUERY_LOCATION_GEOCODE, {
        skip: !placeId,
        variables: {
            brand_id: brandId,
            place_id: placeId,
        },
    });
    useEffect(() => {
        const onCompleted = (geoCodeData) => {
            if (geoCodeData) {
                setFieldValue("latitude", geoCodeData.getLocationGeocode.latitude.toString());
                setFieldValue("longitude", geoCodeData.getLocationGeocode.longitude.toString());
            }
        };
        const onError = (geoCodeError) => {
            console.log(geoCodeError, "QUERY ERROR");
        };
        if (onCompleted || onError) {
            if (onCompleted && !geoCodeError) {
                onCompleted(geoCodeData);
            } else if (onError && geoCodeError) {
                onError(geoCodeError);
            }
        }
    }, [geoCodeData, geoCodeError]);

    const handleModalComponent = (component, push) => {
        push(component);
        setModalComponent(false);
    };

    const onSelect = (selectedData: string) => {
        const selectedLocationData = _.find(locationData.getLocations.places, {
            name: selectedData,
        });
        setPlaceId(selectedLocationData.place_id);
    };

    const [uploadComplete, setUploadStatus] = useState("");

    const renderComponents = ({ event, values, setFieldValue }) => {
        return (
            <>
                {values.components.map((component, index) =>
                    ComponentTemplate(
                        component,
                        index,
                        setFieldValue,
                        event,
                        values,
                        uploadComplete,
                        setUploadStatus,
                        brandName
                    )
                )}

                <CustomButton
                    type="dashed"
                    className="add-section"
                    style={{ width: "100%", fontSize: 13, fontWeight: 300 }}
                    onClick={() => {
                        setModalComponent(true);
                    }}
                >
                    <p style={{ margin: 0 }}>+ Add Row</p>
                </CustomButton>
                <ModalComponent
                    visible={modalComponent}
                    onChange={handleModalComponent}
                    push={event.push}
                    onCancel={() => setModalComponent(false)}
                />
            </>
        );
    };

    return (
        <Form>
            <Title>{`${title} Content`}</Title>
            <ContentContainer style={{ display: "flex", flexDirection: "column", padding: "30px" }}>
                <SeoForm category="" brand={brandName} />
                <Row justify="start">
                    <Col span={24}>
                        <FormItem
                            label={
                                <h1>
                                    <b>Content</b> (Default)
                                </h1>
                            }
                            name={"contentDefault"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name="contentDefault" placeholder="Override Label" />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <StyledUploadImage
                            accept={getAcceptedFiles(brandName, isVideoBannerAllowed)}
                            value={values.image}
                            noteMessage={getUploadNote(brandName, isVideoBannerAllowed)}
                            onChange={(e) => {
                                setFieldValue("image", e);
                            }}
                        >
                            <h1>Upload Banner +</h1>
                        </StyledUploadImage>
                        {errors.image ? (
                            <div style={{ color: "#ff4d4f" }}>{errors.image.keyObj}</div>
                        ) : null}
                    </Col>
                    {brandName === "ALP" ? (
                        <Col span={24}>
                            <FormItem
                                label="Image Alt Text"
                                name="image.altText"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input name="image.altText" disabled={isAddingNewContent} />
                            </FormItem>
                        </Col>
                    ) : null}

                    <Col span={24}>
                        <FormItem
                            label={"or Video Link"}
                            name={"videoLink"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name={"videoLink"} disabled={isAddingNewContent} />
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={12}>
                        <FormItem
                            label={"Category"}
                            name={"category"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <CustomSelect
                                name={"category"}
                                placeholder={"Select Category"}
                                options={listOfOptions}
                                onChange={(newVal) => {
                                    newVal !== "sustainability" &&
                                        newVal !== "blogs" &&
                                        setFieldValue("subcategory", "");
                                }}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            label={"Sub Category (If Applicable)"}
                            name={"subcategory"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input
                                name="subcategory"
                                disabled={
                                    !["sustainability", "blogs"].includes(values.category)
                                        ? true
                                        : false
                                }
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={24}>
                        <FormItem
                            label={"Location"}
                            name={"location"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <AutoComplete
                                name="location"
                                value={locationAC || values.location}
                                options={options}
                                onSelect={onSelect}
                                onChange={(e) => setlocationAC(e)}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            label={"Longitude"}
                            name={"longitude"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name="longitude" disabled />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            label={"Latitude"}
                            name={"latitude"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name="latitude" disabled />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem
                            label={"Date Published"}
                            name={"datePublished"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <DatePicker
                                style={{ width: "100%" }}
                                format={"MM/DD/YYYY"}
                                onChange={(date, dateString) =>
                                    setFieldValue("datePublished", dateString)
                                }
                                value={
                                    !_.isEmpty(values.datePublished)
                                        ? moment(values.datePublished, "MM/DD/YYYY")
                                        : null
                                }
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <FormItem
                            label={"Title"}
                            name={"title"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name={"title"} />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem
                            label={"Sub-Title"}
                            name={"subtitle"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input name={"subtitle"} />
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <FormItem
                            label={"Short Description"}
                            name={"shortDescription"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <Input.TextArea name={"shortDescription"} />
                        </FormItem>
                    </Col>
                </Row>
            </ContentContainer>
            <ContentContainer>
                <Col span={24}>
                    <h1 style={{ fontSize: "20px" }}>Choose Components</h1>
                    <p style={{ marginBottom: "30px" }}>
                        Choose the components that make up your article
                    </p>
                    <FieldArray
                        name="components"
                        render={(event) => renderComponents({ event, values, setFieldValue })}
                    />
                </Col>
            </ContentContainer>

            {params.contentType === "blogs" && brandName === "AVIDA" ? (
                <ContentContainer>
                    <Col span={24} style={{ display: "grid", gap: "10px" }}>
                        <Col span={24}>
                            <h1>Blogs in Neighborhood</h1>
                            <Col
                                span={24}
                                style={{
                                    border: "1px solid #d7d7d7",
                                    borderRadius: "3px",
                                    padding: "24px",
                                }}
                            >
                                <CustomTag name="blogsInNeighborhood" />
                            </Col>
                        </Col>
                        <Col span={24}>
                            <h1>Blogs in Property</h1>
                            <Col
                                span={24}
                                style={{
                                    border: "1px solid #d7d7d7",
                                    borderRadius: "3px",
                                    padding: "24px",
                                }}
                            >
                                <CustomTag name="blogsInProperty" />
                            </Col>
                        </Col>
                    </Col>
                </ContentContainer>
            ) : null}

            {params.contentType === "neighborhood" && brandName === "AVIDA" ? (
                <>
                    <ContentContainer>
                        <Col span={24}>
                            <h1>Neighborhood In Property</h1>
                            <Col
                                span={24}
                                style={{
                                    border: "1px solid #d7d7d7",
                                    borderRadius: "3px",
                                    padding: "24px",
                                }}
                            >
                                <CustomTag name="neighborhoodInProperty" />
                            </Col>
                        </Col>
                    </ContentContainer>
                </>
            ) : null}

            <ContentContainer>
                <Col span={24}>
                    <h1>Tags</h1>
                    <Col
                        span={24}
                        style={{
                            border: "1px solid #d7d7d7",
                            borderRadius: "3px",
                            padding: "24px",
                        }}
                    >
                        <CustomTag name="tags" />
                    </Col>
                </Col>
            </ContentContainer>
            <Row justify="end" gutter={24} style={{ padding: "0 25px 25px 25px" }}>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%" }}
                        onClick={() => history.push(`/manage-content`)}
                    >
                        CANCEL
                    </CustomButton>
                </Col>
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={() => submitForm()}
                        disabled={!dirty || !isValid}
                    >
                        SAVE
                    </CustomButton>
                </Col>
            </Row>
        </Form>
    );
};

export default Forms;
