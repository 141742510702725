import React, { useState } from "react";

import { Form, FormItem, Input } from "formik-antd";
import { FieldArray } from "formik";
import { Row, Col, Popconfirm, Modal } from "antd";
import { CloseOutlined, PlusCircleFilled, DeleteFilled } from "@ant-design/icons";
import SeoForm from "@/components/SeoForm";
import CustomButton from "../../../../../../components/CustomButton";
import FeaturedImages from "../../../../../../components/FeaturedImages";
import MultipleUrlFields from "../../../../../../components/MultipleUrlFields";
import Richtext from "../../../../../../components/Richtext";

import { SectionWrapper, ActionButton, StyledIFrame } from "./styled";

interface Props {
    formikBag: any;
    brandName: string;
}

const Forms = ({ formikBag, brandName }: Props): JSX.Element => {
    const { values, setFieldValue, submitForm, errors, isValid, dirty } = formikBag;
    const [modalVisible, setModalVisible] = useState(false);
    const [preview, setPreview] = useState("");

    const handleClosePreview = () => {
        setPreview("");
        setModalVisible(false);
    };

    const handlePreview = (val) => {
        if (val) {
            setPreview(val);
            setModalVisible(true);
        }
    };

    const convertLength = (e, lengthName) => {
        const { value } = e.target;
        if (value) {
            if (lengthName === "ft") {
                const converted = value / 10.763910417;
                return converted.toFixed(0);
            } else {
                const converted = value * 10.763910417;
                return converted.toFixed(0);
            }
        }
        return null;
    };

    const renderAreaNameDetails = ({ event, values, sectionKey }) => {
        return (
            <>
                {values.area.map((item, key) => (
                    <Row key={`area_${sectionKey}_${key}`} gutter={10}>
                        <Col span={10}>
                            <FormItem
                                label={"Area Name"}
                                name={`unitsFloorPlans[${sectionKey}].area[${key}].areaName`}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input
                                    name={`unitsFloorPlans[${sectionKey}].area[${key}].areaName`}
                                />
                            </FormItem>
                        </Col>
                        <Col span={5}>
                            <FormItem
                                label={"In sqm"}
                                name={`unitsFloorPlans[${sectionKey}].area[${key}].inSqm`}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input
                                    name={`unitsFloorPlans[${sectionKey}].area[${key}].inSqm`}
                                    suffix="sqm"
                                    onChange={(e) => {
                                        setFieldValue(
                                            `unitsFloorPlans[${sectionKey}].area[${key}].inSqft`,
                                            convertLength(e, "m")
                                        );
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={5}>
                            <FormItem
                                label={"In sqft"}
                                name={`unitsFloorPlans[${sectionKey}].area[${key}].inSqft`}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input
                                    name={`unitsFloorPlans[${sectionKey}].area[${key}].inSqft`}
                                    suffix="sqft"
                                    onChange={(e) => {
                                        setFieldValue(
                                            `unitsFloorPlans[${sectionKey}].area[${key}].inSqm`,
                                            convertLength(e, "ft")
                                        );
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={4} style={{ paddingTop: "2.5rem" }}>
                            <ActionButton
                                onClick={() =>
                                    event.push({
                                        id: "",
                                        areaName: null,
                                        inSqm: null,
                                        inSqft: null,
                                    })
                                }
                            >
                                <PlusCircleFilled />
                            </ActionButton>
                            <Popconfirm
                                title="Are you sure you want to delete?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => {
                                    values.area.length > 1 && event.remove(key);
                                    values.area.length === 1 &&
                                        event.replace(0, {
                                            areaName: null,
                                            inSqm: null,
                                            inSqft: null,
                                        });
                                }}
                            >
                                <ActionButton>
                                    <DeleteFilled />
                                </ActionButton>
                            </Popconfirm>
                        </Col>
                    </Row>
                ))}
            </>
        );
    };

    const renderSection = ({ event, values }) => {
        return (
            <>
                {values.unitsFloorPlans.map((item, key) => (
                    <SectionWrapper key={`unitFloorPlans_${key}`}>
                        <CloseOutlined
                            className="remove-btn section-remove-btn"
                            onClick={() => {
                                event.remove(key);
                            }}
                        />
                        <Col span={24}>
                            <FormItem
                                label={"Unit Plan / Floor Plan"}
                                name={`unitsFloorPlans[${key}].unitPlanFloorPlan`}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input name={`unitsFloorPlans[${key}].unitPlanFloorPlan`} />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem
                                label={"Details"}
                                name={`unitsFloorPlans[${key}].details`}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Richtext
                                    toolbar={["heading", "bold", "italic", "link", "insertTable"]}
                                    data={item.details}
                                    onChange={(value: string) =>
                                        setFieldValue(
                                            `unitsFloorPlans[${key}].details`,
                                            value,
                                            false
                                        )
                                    }
                                />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem
                                label={"Total Area"}
                                name={`unitsFloorPlans[${key}].totalArea`}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input name={`unitsFloorPlans[${key}].totalArea`} suffix="sqm" />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FieldArray
                                name={`unitsFloorPlans[${key}].area`}
                                render={(event) =>
                                    renderAreaNameDetails({ event, values: item, sectionKey: key })
                                }
                            />
                        </Col>
                        <Col span={24}>
                            <FeaturedImages
                                onChange={(e) => {
                                    const imagesCaptions = e.map(({ caption }) => caption);
                                    const imagesAltTexts = e.map(({ altText }) => altText);
                                    setFieldValue(
                                        `unitsFloorPlans[${key}].imagesCaptions`,
                                        imagesCaptions
                                    );
                                    setFieldValue(
                                        `unitsFloorPlans[${key}].images.altText`,
                                        imagesAltTexts
                                    );
                                    setFieldValue(`unitsFloorPlans[${key}].imageGallery`, e);
                                }}
                                path={"properties-floor-plan"}
                                fileList={item.imageGallery}
                                captionList={item.imagesCaptions}
                                validate={true}
                                imageAltText={brandName === "ALP"}
                            />
                            {errors.unitsFloorPlans &&
                            errors.unitsFloorPlans.length >= key + 1 &&
                            errors.unitsFloorPlans[key]?.imageGallery
                                ? errors.unitsFloorPlans[key].imageGallery.map((item, i) => {
                                      return (
                                          <>
                                              {item?.keyObj && (
                                                  <div
                                                      key={`floorInner_error_${i}`}
                                                      style={{ color: "#ff4d4f" }}
                                                  >
                                                      {item.keyObj}
                                                  </div>
                                              )}
                                          </>
                                      );
                                  })
                                : null}
                        </Col>
                    </SectionWrapper>
                ))}

                <CustomButton
                    type="dashed"
                    className="add-section"
                    style={{ width: "100%", fontSize: 13, fontWeight: 300 }}
                    onClick={() => {
                        event.push({
                            unitPlanFloorPlan: "",
                            details: "",
                            totalArea: "",
                            area: [
                                {
                                    areaName: "",
                                    inSqm: "",
                                    inSqft: "",
                                },
                            ],
                            imageGallery: [],
                            imagesCaptions: [],
                        });
                    }}
                >
                    <p style={{ margin: 0 }}>+ Add Row</p>
                </CustomButton>
            </>
        );
    };

    return (
        <Form>
            <SeoForm category={"FloorPlan"} brand={brandName} />
            <Row justify="start">
                <Col span={24}>
                    <FormItem
                        label={
                            <h1>
                                <b>Unit & Floor Plans </b>(Default)
                            </h1>
                        }
                        name={"unitFloorPlansDefault"}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input name="unitFloorPlansDefault" placeholder="Override Label" />
                    </FormItem>
                </Col>
                <Col span={24}>
                    <Row gutter={10}>
                        <Col span={12}>
                            <FormItem
                                label={"Unit Size Range (Lowest)"}
                                name={"unitSizeRangeLowest"}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input name="unitSizeRangeLowest" suffix="sqm" />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"Unit Size Range (Highest)"}
                                name={"unitSizeRangeHighest"}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input name="unitSizeRangeHighest" suffix="sqm" />
                            </FormItem>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={10}>
                        <Col span={12}>
                            <FormItem
                                label={"No. of Floors (if applicable)"}
                                name={"noOfFloors"}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input name="noOfFloors" />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem
                                label={"No. of Residential Units (if applicable)"}
                                name={"noOfResidentialUnits"}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input name="noOfResidentialUnits" />
                            </FormItem>
                        </Col>
                        {brandName === "ALP" && (
                            <Col span={24}>
                                <FormItem
                                    label={"Body"}
                                    name={"body"}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <Richtext
                                        toolbar={[
                                            "heading",
                                            "bold",
                                            "italic",
                                            "link",
                                            "insertTable",
                                        ]}
                                        data={values.body}
                                        onChange={(value: string) =>
                                            setFieldValue("body", value, false)
                                        }
                                    />
                                </FormItem>
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col span={24} style={{ marginBottom: "25px" }}>
                    <FieldArray
                        name="unitsFloorPlans"
                        render={(event) => renderSection({ event, values })}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <FormItem
                        label={
                            <h1>
                                <b>Image Gallery of Unit & Floor Plans </b>(Default)
                            </h1>
                        }
                        name={"imageGalleryDefault"}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input name="imageGalleryDefault" placeholder="Override Label" />
                    </FormItem>
                </Col>
                <Col span={24}>
                    <FeaturedImages
                        onChange={(e) => {
                            const imagesCaptions = e.map(({ caption }) => caption);
                            const imagesAltTexts = e.map(({ altText }) => altText);
                            setFieldValue(`imagesCaptions`, imagesCaptions);
                            setFieldValue(`images.altText`, imagesAltTexts);
                            setFieldValue("imageGallery", e);
                        }}
                        path={"properties-floor-plan"}
                        fileList={values.imageGallery}
                        captionList={values.imagesCaptions}
                        validate={true}
                        imageAltText={brandName === "ALP"}
                    />
                    {errors.imageGallery
                        ? errors.imageGallery.map((item, i) => {
                              return (
                                  item?.keyObj && (
                                      <div key={`floor_error_${i}`} style={{ color: "#ff4d4f" }}>
                                          {item.keyObj}
                                      </div>
                                  )
                              );
                          })
                        : null}
                </Col>
                <Col span={24} style={{ marginTop: "24px" }}>
                    <h3>Virtual Tour</h3>

                    <Row gutter={24}>
                        <Col span={12}>
                            <p>Video</p>
                            <MultipleUrlFields
                                values={values.videos}
                                placeholder="Video Link"
                                name="videos"
                                preview={handlePreview}
                            />
                        </Col>
                        <Col span={12}>
                            <p>Panorama</p>
                            <MultipleUrlFields
                                values={values.panoramas}
                                placeholder="Panorama Link"
                                name="panoramas"
                                preview={handlePreview}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="end">
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={() => submitForm()}
                        disabled={!dirty || !isValid}
                    >
                        SAVE
                    </CustomButton>
                </Col>
            </Row>
            <Modal
                visible={modalVisible}
                centered
                footer={null}
                bodyStyle={{ padding: 0, height: 400 }}
                onCancel={handleClosePreview}
                width={700}
                closable={false}
                maskClosable={true}
                destroyOnClose={true}
            >
                <StyledIFrame src={preview} width="100%" height="100%"></StyledIFrame>
            </Modal>
        </Form>
    );
};

export default Forms;
