import React from "react";
import { useParams } from "react-router-dom";

import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, Modal, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import CustomButton from "../../../components/CustomButton";
import CustomSelect from "../../../components/CustomSelect";
import ContentContainer from "../../../components/ContentContainer";
import Richtext from "../../../components/Richtext";

import { formatCurrency } from "../../../util/format-currency";
import { StyledUploadImage, Title } from "../styled";

const { confirm } = Modal;

interface Props {
    formikBag: any;
    loading?: boolean;
}

const Forms: React.SFC<Props> = ({ formikBag, loading }) => {
    const { slug } = useParams();
    const { values, submitForm, errors, setFieldValue, isValid, dirty } = formikBag;

    const title = slug ? "Edit" : "Add";

    const showConfirm = (onSubmitForm) => {
        const onClickHandle = onSubmitForm;
        confirm({
            title: `Are you sure you want to ${slug ? "save" : "publish"}?`,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                onClickHandle();
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    return (
        <Form>
            <Spin spinning={loading}>
                <Title>{`${title} Award`}</Title>
                <ContentContainer style={{ display: "flex", flexDirection: "column" }}>
                    <Row gutter={20}>
                        <Col md={5} span={24}>
                            <StyledUploadImage
                                accept={"image/jpeg,image/jpg,image/png"}
                                value={values.image}
                                noteMessage={".jpeg, .jpg, .png (max 50 MB)"}
                                onChange={(e) => {
                                    setFieldValue("image", e);
                                }}
                                children={<h1>+ Upload Image</h1>}
                            />
                            {errors.image ? (
                                <>
                                    {typeof errors.image === "string" ? (
                                        <div style={{ color: "#ff4d4f" }}>{errors.image}</div>
                                    ) : (
                                        <div style={{ color: "#ff4d4f" }}>
                                            {errors.image.keyObj}
                                        </div>
                                    )}
                                </>
                            ) : null}
                        </Col>
                        <Col md={19} span={24}>
                            <Row justify="start">
                                <Col span={24}>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <FormItem
                                                label={"Award"}
                                                name={"award"}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input
                                                    name="award"
                                                    suffix={<span style={{ color: "red" }}>*</span>}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem
                                                label={"project"}
                                                name={"project"}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input
                                                    name="project"
                                                    suffix={<span style={{ color: "red" }}>*</span>}
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>

                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <FormItem
                                                label={"year"}
                                                name={"year"}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input
                                                    name="year"
                                                    suffix={<span style={{ color: "red" }}>*</span>}
                                                />
                                            </FormItem>
                                        </Col>
                                        <Col span={12}>
                                            <FormItem
                                                label={"organization"}
                                                name={"organization"}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input
                                                    name="organization"
                                                    suffix={<span style={{ color: "red" }}>*</span>}
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ContentContainer>
                <Row justify="end" style={{ margin: "0 24px" }}>
                    <Col span={3}>
                        <CustomButton
                            style={{ width: "100%", marginBottom: "24px" }}
                            type="primary"
                            disabled={!dirty || !isValid}
                            onClick={() => showConfirm(submitForm)}
                        >
                            {slug ? "Save" : "Publish"}
                        </CustomButton>
                    </Col>
                </Row>
            </Spin>
        </Form>
    );
};

export default Forms;
