import React from "react";
import { Select } from "formik-antd";

interface Props {
    name: string;
    placeholder: string;
    options?: any[];
    defaultValue?: string;
    onChange?: any;
    onSelect?: any;
    disabled?: boolean;
}

const CustomSelect: React.FC<Props> = ({ name, placeholder, options, defaultValue, onChange, onSelect, disabled }) => {
    const renderOptions = () => {
        if (options !== undefined)
            return options.map(({ label, value }, key: number) => (
                <Select.Option key={key} value={value}>
                    {label}
                </Select.Option>
            ));
    };

    return (
        <Select
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={onChange && onChange}
            onSelect={onSelect && onSelect}
            disabled={disabled}
        >
            {renderOptions()}
        </Select>
    );
};

export default CustomSelect;
