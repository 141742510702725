import React, { useState, ReactNode, useEffect } from "react";
import styled from "styled-components";

import { Layout } from "antd";
import ScreenLoader from "../ScreenLoader";

import Navigation from "./navigation";
import Header from "./header";

import Breadcrumbs from "./breadcrumbs";

const { Content } = Layout;

const NewLayout = styled(Layout)`
    height: 100vh;
    overflow: hidden;
`;

interface Props {
    children: ReactNode;
}

const MainLayout: React.FC<Props> = ({ children }) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(true);

    useEffect(() => {
        const timer = setTimeout(() => {
          setLoader(false);
        }, 4000);
        return () => clearTimeout(timer);
      }, []);

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    return (
        <NewLayout data-index="layout">
          {
            !loader ?
              <>
                <Navigation collapsed={collapsed} />
                <Layout>
                    <Header collapsed={collapsed} toggle={toggle} />
                    <Content>
                        <Breadcrumbs />
                        {children}
                    </Content>
                </Layout>
              </> : <ScreenLoader visible={true} />
          }
        </NewLayout>
    );
};

export default MainLayout;
