import styled from "styled-components";

export const StyledForm = styled.div`
    background: #fff;
    position: relative;
    margin-bottom: 40px;
    width: 100%;

    span {
        width: 1700px;
        height: 20px;
        position: absolute;
        background: #eef2f6;
        z-index: 9999;
        top: 100%;
        left: -35px;
    }
`;
