import styled from "styled-components";

export const HeaderStyle = { background: "#00714b", color: "#fff", borderRadius: 0 };

export const HeaderTitle = styled.h3`
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
`;
