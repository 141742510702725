export default {
    storePropertiesUnitId: (dispatch) => {
        return (data) => {
            dispatch({ type: "UNIT_ID", payload: data });
        };
    },
    updatePropertyUnit: (dispatch) => {
        return (data) => {
            dispatch({ type: "PROPERTY_UNIT_FORMS_UPDATE", payload: data });
        };
    },
};
