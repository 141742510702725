import isEmpty from "lodash/isEmpty";

import { Showroom } from "../../../entities/Showroom";

export class ShowroomMapper {
    static map(showroom: Showroom): Showroom {
        const showroomFC =
            !isEmpty(showroom.containers) && // SHOWROOM-CONTAINER
            !isEmpty(showroom.containers[0].field_collections) && // SHOWROOM-FC
            !isEmpty(showroom.containers[0].field_collections[0].children) && // SHOWROOM-FC CHILDREN
            showroom.containers[0].field_collections[0].children; // SHOWROOM-LIST & IMAGES

        const showroomList = 
            showroomFC &&
            !isEmpty(showroomFC.filter(fc => fc.name === "SHOWROOM-LIST")) &&
            showroomFC.filter(fc => fc.name === "SHOWROOM-LIST")[0];

        const showroomItemFields =
            showroomList &&
            !isEmpty(showroomList.children) && // SHOWROOM-ITEM
            !isEmpty(showroomList.children[0].fields) && // FIRST SHOWROOM-ITEM FIELDS
            showroomList.children[0].fields;

        showroomItemFields && showroomItemFields.forEach(field => {
            if (field.name && field.value) {
                if (field.name === "TITLE") {
                    showroom.title = field.value;
                } else if (field.name === "ADDRESS") {
                    showroom.address = field.value;
                }
            }
        });

        const imagesFC =
            showroomFC &&
            !isEmpty(showroomFC.filter(fc => fc.name === "IMAGES")) &&
            showroomFC.filter(fc => fc.name === "IMAGES")[0];

        const imageFields =
            imagesFC &&
            !isEmpty(imagesFC.fields) &&
            imagesFC.fields;
        
        const featuredImage =
            imageFields &&
            !isEmpty(imageFields.filter(field => field.meta_data.featured === true)) &&
            imageFields.filter(field => field.meta_data.featured === true)[0].value &&
            imageFields.filter(field => field.meta_data.featured === true)[0].value;
        
        showroom.imgURL = featuredImage ? featuredImage : imageFields && imageFields[0];

        delete showroom.containers;

        return showroom;
    }
}