export default {
    UNIT_ID: (state, action) => {
        return {
            ...state,
            propertyDetails: action.payload,
        };
    },
    PROPERTY_UNIT_FORMS_UPDATE: (state, action) => {
        return {
            ...state,
            propertyUnitForms: action.payload,
        };
    },
};
