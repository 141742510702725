import React, { useState } from "react";

import { Tabs } from "antd";

import { StyledTabs } from "./styled";

import SocialMediaLinksForms from "./social-media";
import ExternalLinksForms from "./external";

const { TabPane } = Tabs;

const UnitTabsForms = () => {
    const [activeTab, setUnitActiveTab] = useState<any>();

    const renderTabs = (activeUnitTab) =>
        tabs.map(({ tabName, component }, key) => (
            <TabPane tab={tabName} key={key}>
                {component}
            </TabPane>
        ));

    const tabs = [
        {
            tabName: "Social Media",
            component: <SocialMediaLinksForms />,
        },
        {
            tabName: "External Links",
            component: <ExternalLinksForms />,
        },
    ];

    return (
        <div>
            <StyledTabs
                defaultActiveKey="0"
                tabBarGutter={0}
                tabBarStyle={{
                    backgroundColor: "white",
                    padding: "0 25px",
                }}
            >
                {renderTabs(activeTab)}
            </StyledTabs>
        </div>
    );
};

export default UnitTabsForms;
