import axios from "axios";

const {
    REACT_APP_IAM_URL,
    REACT_APP_IAM_CLIENT_ID,
    REACT_APP_IAM_CLIENT_SECRET,
} = process.env;

export default async () => {
    const refreshToken = localStorage.getItem("REFRESH_TOKEN");
    try {
        const res = await axios({
            method: "POST",
            url: `${REACT_APP_IAM_URL}/oauth/token`,
            data: {
                grantType: "refresh_token",
                clientId: REACT_APP_IAM_CLIENT_ID,
                clientSecret: REACT_APP_IAM_CLIENT_SECRET,
                refreshToken: refreshToken,
            },
        });
        localStorage.setItem("ACCESS_TOKEN", res.data.data.accessToken);
        localStorage.setItem("REFRESH_TOKEN", res.data.data.refreshToken);
        return res.data.data.accessToken;
    } catch (error) {
        console.log(error);
        localStorage.removeItem("ACCESS_TOKEN");
        localStorage.removeItem("REFRESH_TOKEN");
    }
};
