import * as yup from "yup";

export const validationSchema = yup.object().shape({
    tenant: yup.string().required("Brand is required."),
    name: yup.string().required("Role Name is required."),
    description: yup.string().required("Role Description is required."),
    isActive: yup.string().required("Role Status is required."),
    // feature: yup.string().required("Feature is required."),
});

export const roleStatusList = [
    {
        label: "Active",
        value: 1,
    },
    {
        label: "Inactive",
        value: 2,
    },
];
