import { Document } from "../../../entities/Document";
import moment from "moment";

export class DocumentMapper {
    static map(document: Document): Document {
        
        document.created_at = document.created_at ? moment(document.created_at).format("MMM DD, YYYY") : null;
        delete document.__typename;
        return document;
    }
}