import React, { useContext, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import { Tabs, Spin } from "antd";

import { Context } from "../../../../context";
import AboutUsForms from "./abouts-us-forms";
import FactsForms from "./facts-forms";
import MissionVisionForms from "./mission-vision-forms";
import TermsAndConditionsForms from "./terms-and-condition-forms";
import PrivacyPolicyForms from "./privacy-policy-forms";
import AwardsForm from "./awards-forms";
import PageNotFound from "../../../../components/PageNotFound";

import { userPermission } from "../../../../util/user-access";
import filter from "lodash/filter";

import { GET_BRAND } from "../../graphql";
import SeoTagsForm from "./seo-tags-form";
import FooterLinksForm from "./footer-links";

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
    margin-bottom: 25px;

    .ant-tabs-nav-list > .ant-tabs-tab {
        padding: 12px 30px;
    }

    .ant-tabs-content {
        flex-direction: column;
    }
`;

const TitleWrapper = styled.div`
    margin: 15px 24px 0;

    h1 {
        margin: 0;
        font-weight: 800;
    }
`;

const CompanyInfoForms: React.FC = () => {
    const params = useParams();
    const { location, push } = useHistory();

    const [getBrandName, { loading, data: brandInfo }] = useLazyQuery(GET_BRAND, {
        fetchPolicy: "no-cache",
    });
    const {
        state: {
            user,
            brand: { name },
        },
        dispatch: { pageDetails },
    } = useContext(Context);

    const permissions = user?.roles[0].permissions;

    const aboutUsAccess = userPermission(permissions, "manage:company-information-about-us");
    const termsAccess = userPermission(
        permissions,
        "manage:company-information-terms-and-conditions"
    );
    const privacyPolicyAccess = userPermission(
        permissions,
        "manage:company-information-privacy-and-policy"
    );
    const factsAccess = userPermission(permissions, "manage:company-information-facts");
    const awardsAccess = userPermission(permissions, "manage:company-information-awards");
    const missionsVissionAccess = userPermission(
        permissions,
        "manage:company-information-mission-and-vision"
    );

    const handleGetPage = useCallback(() => {
        getBrandName({
            variables: {
                id: params.id,
            },
        });
        // eslint-disable-next-line
    }, [params.id]);

    useEffect(() => {
        if (params.id && brandInfo) {
            pageDetails({
                type: "PAGE_DETAILS",
                payload: {
                    brandId: brandInfo.getBrand.id,
                    brandName: brandInfo.getBrand.name,
                },
            });
        }

        return () => {
            pageDetails({
                type: "PAGE_DETAILS",
                payload: {
                    brandId: "",
                    brandName: "",
                },
            });
        };
    }, [params.id, brandInfo]);

    useEffect(() => {
        handleGetPage();
    }, [handleGetPage]);

    const tabs = [
        {
            tabName: "About Us",
            component: <AboutUsForms handleRefresh={handleGetPage} />,
            key: "about-us",
            access: aboutUsAccess,
        },
        {
            tabName: "Seo Tags",
            component: (
                <SeoTagsForm handleRefresh={handleGetPage} category={"Home"} brandName={name} />
            ),
            key: "seo-tags",
            access: ["AVIDA", "ALISI", "ALP"].includes(name),
        },
        {
            tabName: "Footer Link",
            component: (
                <FooterLinksForm handleRefresh={handleGetPage} category={"Home"} brandName={name} />
            ),
            key: "footer-links",
            access: ["AMAIA"].includes(name),
        },
        {
            tabName: "Facts",
            component: <FactsForms handleRefresh={handleGetPage} />,
            key: "facts",
            access: factsAccess,
        },
        {
            tabName: "Mission & Vision",
            component: <MissionVisionForms handleRefresh={handleGetPage} />,
            key: "mission-vision",
            access: missionsVissionAccess,
        },
        {
            tabName: "Awards",
            component: <AwardsForm handleRefresh={handleGetPage} />,
            key: "awards",
            access: awardsAccess,
        },
        {
            tabName: "Terms and Conditions",
            component: <TermsAndConditionsForms handleRefresh={handleGetPage} />,
            key: "terms-and-conditions",
            access: termsAccess,
        },
        {
            tabName: "Privacy Policy",
            component: <PrivacyPolicyForms handleRefresh={handleGetPage} />,
            key: "privacy-policy",
            access: privacyPolicyAccess,
        },
    ];

    const filteredTabs = filter(tabs, "access");
    const renderTabs = () =>
        filteredTabs.map(({ tabName, component, key }) => (
            <TabPane tab={tabName} key={key}>
                {location.state && (
                    <TitleWrapper>
                        <h1>{location.state.tenant.name} Company Information</h1>
                    </TitleWrapper>
                )}

                {component}
            </TabPane>
        ));

    const pageAccess = filter(filteredTabs, { key: params.category, access: true });

    return (
        <Spin spinning={loading}>
            <StyledTabs
                activeKey={params.category}
                tabBarGutter={0}
                tabBarStyle={{ backgroundColor: "white", padding: "15px 25px 0" }}
                onTabClick={(e) => {
                    push(`/manage-company-information/${e}/${params.id}`);
                }}
            >
                {renderTabs()}
            </StyledTabs>
            {!pageAccess.length ? <PageNotFound /> : <div />}
        </Spin>
    );
};

export default CompanyInfoForms;
