import styled from "styled-components";

export const InputWrapper = styled.div`
    position: relative;

    .remove-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
`;

export const BodyWrapper = styled.div`
    position: relative;
    padding: 20px 15px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;

    .richtext-about-us-body {
        .ck.ck-reset.ck-editor.ck-rounded-corners {
            .ck.ck-editor__main {
                .ck.ck-content {
                    height: 90px;
                }
            }
        }
    }

    .label {
        margin-bottom: 5px;
        font-weight: 500;
        color: #000;
    }

    .remove-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
`;
