import { gql } from "@apollo/client";

export const GET_COMPONENT_CATEGORY = gql`
    query {
        getAllFieldCategories {
            id
            name
        }
    }
`;

export const GET_PAGES = gql`
    query getPages($brand_id: String!, $search: String!, $tag: String, $page: Int, $limit: Int) {
        searchPages(brandId: $brand_id, search: $search, tag: $tag, page: $page, limit: $limit) {
            pages {
                id
                name
                tag
                slug
                featured
                containers {
                    id
                    name
                    field_collections {
                        id
                        name
                        children {
                            id
                            name
                            fields {
                                name
                                value
                                meta_data {
                                    featured
                                    alt_text
                                }
                            }
                            children {
                                id
                                name
                                fields {
                                    name
                                    value
                                }
                                children {
                                    name
                                    fields {
                                        name
                                        value
                                    }
                                }
                            }
                        }
                        fields {
                            name
                            value
                        }
                    }
                }
            }
            totalCount
        }
    }
`;

export const CREATE_PAGE = gql`
    mutation createPage($brand_id: String!, $name: String!, $tag: String!) {
        addOrUpdateBrandPage(brand_id: $brand_id, tag: $tag, item: { name: $name }) {
            id
            name
            brand_id
            slug
            tag
        }
    }
`;

export const EDIT_PAGE = gql`
    mutation editPage($brand_id: String!, $id: String!, $featured: Boolean, $tag: String!) {
        addOrUpdateBrandPage(
            brand_id: $brand_id
            tag: $tag
            item: { id: $id, featured: $featured }
        ) {
            id
            featured
        }
    }
`;

export const REMOVE_PAGE = gql`
    mutation removePage($id: String!) {
        removePage(id: $id)
    }
`;

export const GET_PROPERTY = gql`
    query getPage($brand_id: String!, $slug: String!, $tag: String!) {
        getSpecificBrandPage(brand_id: $brand_id, slug: $slug, tag: $tag) {
            id
            name
            tag
            slug
            brand {
                id
                name
            }
            containers {
                name
                id
                page_id
                order
                field_collections {
                    name
                    id
                    order
                    children {
                        id
                        name
                        order
                        children {
                            id
                            name
                            order
                            children {
                                id
                                name
                                fields {
                                    id
                                    name
                                    label
                                    name
                                    value
                                    meta_data {
                                        description
                                        featured
                                        alt_text
                                    }
                                }
                            }
                            fields {
                                name
                                label
                                value
                                id
                                order
                                meta_data {
                                    description
                                    featured
                                    alt_text
                                }
                            }
                        }
                        fields {
                            name
                            label
                            value
                            id
                            order
                        }
                    }
                    fields {
                        name
                        label
                        value
                        id
                        order
                    }
                }
            }
        }
    }
`;

export const CREATE_CONTAINER = gql`
    mutation createContainer($data: UpdateContainerInput!) {
        addOrUpdateContainers(containerData: $data) {
            id
            page_id
            name
            order
        }
    }
`;

export const CREATE_FC = gql`
    mutation createFC($name: String!, $container_id: String!, $order: Float!) {
        createFC(collectionData: { name: $name, container_id: $container_id, order: $order }) {
            id
            name
            container_id
        }
    }
`;

export const CREATE_MULTI_FC = gql`
    mutation createMultipleFC($data: UpdateFieldCollectionPageInput!) {
        addUpdateMultipleFieldCollection(collectionData: $data) {
            id
            name
            container {
                id
                name
                field_collections {
                    name
                    id
                }
            }
        }
    }
`;

export const MODIFY_FIELDS = gql`
    mutation modifyFields($data: UpdateFieldCategoryPageInput!) {
        addOrUpdateFields(fieldCategoryData: $data) {
            id
            name
            label
            value
            order
        }
    }
`;

export const REMOVE_FIELD = gql`
    mutation removeField($data: DeleteFieldCategoryInput!) {
        removeFieldCategories(removeFieldData: $data)
    }
`;

export const GET_CONTAINER = gql`
    query getContainer($id: String!) {
        getContainer(id: $id) {
            name
            id
            field_collections {
                id
                name
                order
                fields {
                    id
                    name
                    label
                    name
                    value
                    order
                }
                children {
                    id
                    name
                    children {
                        id
                        name
                        fields {
                            id
                            name
                            label
                            name
                            value
                        }
                    }
                    fields {
                        id
                        name
                        label
                        name
                        value
                    }
                }
            }
        }
    }
`;

export const GET_ALL_CONTAINERS = gql`
    query allContainer {
        getAllContainers {
            id
            name
            deleted_at
        }
    }
`;

export const DELETE_CONTAINER = gql`
    mutation deleteContainer($id: String!) {
        removeContainer(id: $id)
    }
`;

export const DELETE_FC = gql`
    mutation deleteFC($id: String!) {
        removeFC(id: $id)
    }
`;

export const BRANDS = gql`
    query getAllbrands {
        getAllBrands {
            name
            id
        }
    }
`;

export const GET_PAGE = gql`
    query getPage($page_id: String!) {
        getSpecificBrandPageById(id: $page_id) {
            id
            name
            slug
            tag
            containers {
                id
                name
                field_collections {
                    id
                    name
                    children {
                        id
                        name
                        order
                        fields {
                            id
                            name
                            value
                            order
                            meta_data {
                                key
                                description
                                featured
                                alt_text
                            }
                        }
                        children {
                            id
                            name
                            order
                            fields {
                                id
                                name
                                value
                                order
                            }
                            children {
                                id
                                name
                                order
                                fields {
                                    id
                                    name
                                    value
                                    order
                                }
                            }
                        }
                    }
                    fields {
                        id
                        name
                        value
                        order
                        meta_data {
                            key
                            description
                            alt_text
                        }
                    }
                }
            }
        }
    }
`;

export const CREATE_CONTENT = gql`
    mutation addOrUpdateNested($data: CreateUpdateContainerNestedInput!) {
        addOrUpdateContainersFCFields(containerData: $data) {
            id
        }
    }
`;

export const REMOVE_MULTIPLE_FC = gql`
    mutation removeMultiFC($data: DeleteFieldCollectionInput!) {
        removeMultipleFieldCollection(removeFCData: $data)
    }
`;

export const EDIT_PAGE_TITLE = gql`
    mutation editPage($brand_id: String!, $id: String!, $name: String, $tag: String!) {
        addOrUpdateBrandPage(brand_id: $brand_id, tag: $tag, item: { id: $id, name: $name }) {
            id
            name
            brand_id
            slug
            tag
        }
    }
`;
