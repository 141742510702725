import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { FormsDefaultValueTypes, ImageContainerType } from "./manage-showroom.types";

import * as yup from "yup";

const imageSchema = yup.object().shape({
    status: yup.string().matches(/done/, { message: "file is still uploading" }),
});

const listSchemaSpecialChar = yup.object().shape({
    telHotline: yup.string().matches(/^[0-9+() -]*$/, "Please Enter Valid Telephone Hotline"),
    telNumber: yup.string().matches(/^[0-9+() -]*$/, "Please Enter Valid Telephone Number"),
    email: yup.string().email("Please enter valid email"),
});

const listSchema = yup.object().shape({
    telHotline: yup.number().typeError("Please Enter Valid Telephone Hotline"),
    telNumber: yup.number().typeError("Please Enter Valid Telephone Number"),
    email: yup.string().email("Please enter valid email"),
});

export const validationSchema = yup.object().shape({
    title: yup.string().required(),
    images: yup.array().of(imageSchema).nullable(),
    list: yup.array().of(listSchema),
});

export const validationSchemaSpecialChar = yup.object().shape({
    title: yup.string().required(),
    images: yup.array().of(imageSchema).nullable(),
    list: yup.array().of(listSchemaSpecialChar),
});

export const rangeDay = [
    {
        value: "sunday",
        label: "Sunday",
    },
    {
        value: "monday",
        label: "Monday",
    },
    {
        value: "tuesday",
        label: "Tuesday",
    },
    {
        value: "wednesday",
        label: "Wednesday",
    },
    {
        value: "thursday",
        label: "Thursday",
    },
    {
        value: "friday",
        label: "Friday",
    },
    {
        value: "saturday",
        label: "Saturday",
    },
];

export const cardText = [
    {
        htmlEl: "h2",
        varName: "name",
    },
    {
        htmlEl: "span",
        varName: "title",
    },
    {
        htmlEl: "span",
        varName: "address",
    },
];

export const INIT_VALUES: FormsDefaultValueTypes = {
    label: "",
    title: "",
    seoShowroomTitle: "",
    seoShowroomDescription: "",
    list: [
        {
            title: "",
            rangeDay: [],
            timeStartRange: null,
            timeEndRange: null,
            address: "",
            telHotline: "",
            telNumber: "",
            email: "",
        },
    ],
    images: [],
    imagesCaptions: [],
};

const dayMapper = (value) =>
    value.map((day, index) => ({
        order: index,
        name: "DAY",
        label: "day",
        value: day,
    }));

export const listMapper = (value) =>
    value.map((item, itemIndex) => ({
        name: "SHOWROOM-ITEM",
        order: itemIndex,
        children: [
            {
                name: "RANGE-OF-DAY",
                order: 0,
                ...(!isEmpty(item.rangeDay) ? { fields: dayMapper(item.rangeDay) } : {}),
            },
        ],
        fields: [
            {
                label: "title",
                name: "TITLE",
                value: item.title ? item.title : null,
                order: 0,
            },
            {
                label: "address",
                name: "ADDRESS",
                value: item.address ? item.address : null,
                order: 1,
            },
            {
                label: "telHotline",
                name: "TELEPHONE-HOTLINE",
                value: item.telHotline ? item.telHotline : null,
                order: 2,
            },
            {
                label: "telNumber",
                name: "TELEPHONE-NUMBER",
                value: item.telNumber ? item.telNumber : null,
                order: 3,
            },
            {
                label: "email",
                name: "EMAIL",
                value: item.email ? item.email : null,
                order: 4,
            },
            {
                label: "timeRangeStart",
                name: "TIME-RANGE-START",
                value: item.timeStartRange ? moment(item.timeStartRange).format("LT") : null,
                order: 5,
            },
            {
                label: "timeRangeEnd",
                name: "TIME-RANGE-END",
                value: item.timeEndRange ? moment(item.timeEndRange).format("LT") : null,
                order: 6,
            },
        ],
    }));

export const imageMapper = (values): ImageContainerType[] =>
    values.map((image, imageIndex) => ({
        order: imageIndex,
        label: "image",
        name: "Image",
        value: image.keyObj,
        meta_data: {
            featured: image.isChecked,
            description: image.caption ? image.caption : "",
            key: image.keyObj,
            alt_text: image.altText,
        },
    }));
