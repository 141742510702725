const fileTranslate = (value) => {
    let type = "file";

    if (value.includes("image")) type = "image";
    if (value.includes("audio")) type = "audio";
    if (value.includes("video")) type = "video";
    if (value.includes("application")) type = "document";

    return type;
};

export default fileTranslate;
