import React from "react";

import ComponentOne from "./ComponentOne";
import ComponentTwo from "./ComponentTwo";

const ComponentTemplate = (
    component,
    index,
    setFieldValue,
    fieldsArrayEvent,
    values
): JSX.Element | string => {
    switch (component.type) {
        case "component 1":
            return (
                <ComponentOne
                    index={index}
                    setFieldValue={setFieldValue}
                    fieldsArrayEvent={fieldsArrayEvent}
                    values={values}
                />
            );
        case "component 2":
            return (
                <ComponentTwo
                    index={index}
                    setFieldValue={setFieldValue}
                    fieldsArrayEvent={fieldsArrayEvent}
                    values={values}
                />
            );
        default:
            return "";
    }
};

export default ComponentTemplate;
