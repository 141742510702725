import styled from "styled-components";
import UploadSingleFile from "../../../../../../components/UploadSingleFile";

export const StyledIFrame = styled.embed`
    border: 0;
`;

export const UploadSingleFileStyled = styled(UploadSingleFile)`
    height: 150px;
    width: 100%;
    display: block;
    overflow: hidden;
    background: #fff;

    .ant-upload {
        display: block;

        .upload-button {
            min-height: 150px;
            width: 100%;
        }
    }

    .label-button {
        display: flex;
        flex-direction: column;

        svg {
            font-size: 55px;
        }

        p {
            font-size: 18px;
        }

        h5 {
            color: #d3d3d3;
        }
    }
`;
