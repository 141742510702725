import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import _ from "lodash";

import { Tabs } from "antd";

import { StyledTabs } from "./styled";
import UnitOverviewForms from "../unit-tab-forms/overview";
import LocationsForms from "../unit-tab-forms/locations";
import AmenitiesForms from "../unit-tab-forms/amenities";
import UnitConstructionPlansForms from "../unit-tab-forms/construction-plans";
import UnitFloorPlansForms from "../unit-tab-forms/floor-plans";
import UnitTabs from "../../../../../components/UnitTabs";

import { Context } from "../../../../../context";

import { CREATE_MULTI_FC, REMOVE_MULTIPLE_FC } from "../../../graphql";
import { towerContainer } from "./constanst";
import { userPermission } from "../../../../../util/user-access";

const { TabPane } = Tabs;

const UnitTabsForms = ({ handleRefresh }: { handleRefresh: () => void }): JSX.Element => {
    const [createMultipleFC] = useMutation(CREATE_MULTI_FC);
    const [removeMultiFC] = useMutation(REMOVE_MULTIPLE_FC);
    const {
        state: { propertyDetails, user, brand: brandData },
        dispatch: { updatePropertyUnit },
    } = useContext(Context);

    const [unitTabs, setUnitTabsState] = useState([
        {
            id: "",
            tabName: "New Unit",
        },
    ]);
    const [activeTab, setUnitActiveTab] = useState<any>();
    const [pageData, setPageData] = useState<any>([]);
    const permissions = user?.roles[0].permissions;

    const overviewAccess = userPermission(permissions, "manage:property-overview");
    const locationAccess = userPermission(permissions, "manage:property-location");
    const amenitiesAccess = userPermission(permissions, "manage:property-amenities");
    const floorPlansAccess = userPermission(permissions, "manage:property-unit-and-floor-plans");
    const constructionUpdateAccess = userPermission(
        permissions,
        "manage:property-construction-updates"
    );

    const tabs = [
        {
            tabName: "Overview",
            component: <UnitOverviewForms handleRefresh={handleRefresh} />,
            access: overviewAccess,
        },
        {
            tabName: "Locations & Landmarks",
            component: <LocationsForms handleRefresh={handleRefresh} />,
            access: locationAccess,
        },
        {
            tabName: "Amenities",
            component: <AmenitiesForms handleRefresh={handleRefresh} />,
            access: amenitiesAccess,
        },
        {
            tabName: "Floor Plans",
            component: <UnitFloorPlansForms handleRefresh={handleRefresh} />,
            access: floorPlansAccess,
        },
        {
            tabName: "Construction Updates",
            component: <UnitConstructionPlansForms handleRefresh={handleRefresh} />,
            access: constructionUpdateAccess,
        },
    ];
    const filteredTabs = _.filter(tabs, "access");
    const renderTabs = (activeUnitTab) =>
        filteredTabs.map(({ tabName, component }, key) => (
            <TabPane tab={tabName} key={key}>
                {React.cloneElement(component, {
                    activeUnitTab: activeUnitTab,
                })}
            </TabPane>
        ));

    const createUnitTab = async (tabKey) => {
        const unitId = propertyDetails.payload.unitId;
        const lengthTab = unitTabs.length;
        const unitTemplate = towerContainer;
        unitTemplate[0]["container_id"] = unitId;
        unitTemplate[0]["order"] = lengthTab;

        const {
            data: { addUpdateMultipleFieldCollection: unitTabFCData },
        } = await createMultipleFC({
            variables: {
                data: {
                    collections: unitTemplate,
                },
            },
        });
        const tabId = unitTabFCData[0].id;

        return tabId;
    };

    const editUnitTab = async (targetId, tabName, tabKey) => {
        const {
            data: { addUpdateMultipleFieldCollection: unitTabFCData },
        } = await createMultipleFC({
            variables: {
                data: {
                    collections: [
                        {
                            id: targetId,
                            name: tabName,
                        },
                    ],
                },
            },
        });
        const tabId = unitTabFCData[0].id;
        return tabId;
    };

    const onAddNewTab = async (newList, key) => {
        const tabId = await createUnitTab(key);
        newList[key].id = tabId;
        setUnitTabsState(newList);
    };

    const onEditTab = async (newList, key) => {
        const target = unitTabs[key];
        if (target) {
            await editUnitTab(target.id, target.tabName, key);
        }
        setUnitTabsState(newList);
    };

    const onRemoveTab = async (newList, key) => {
        const target = unitTabs[key];
        if (target) {
            await removeMultiFC({ variables: { data: { ids: [target.id] } } });
        }

        const reorderLists = newList.map((item, index) => ({ ...item, order: index }));
        setUnitTabsState(reorderLists);
        await createMultipleFC({
            variables: {
                data: {
                    collections: reorderLists.map((item, index) => ({
                        id: item.id,
                        name: item.tabName,
                        order: index,
                    })),
                },
            },
        });
    };

    const handleTabChange = async (val, actionType, key) => {
        if (actionType === "add") {
            await onAddNewTab(val, key);
        } else if (actionType === "edit") {
            await onEditTab(val, key);
        } else if (actionType === "remove") {
            await onRemoveTab(val, key);
        } else {
            setUnitTabsState(val);
        }
        handleRefresh();
    };

    const handleTabClick = (tabKey) => {
        const target = unitTabs[tabKey];
        setUnitActiveTab(target);
    };

    const showUnitTab = () => {
        return (
            propertyDetails &&
            ["1", "2", "5", "8", "Condominium", "Office", "Townhouse", "House and Lot"].includes(
                propertyDetails.payload.propertyType
            )
        );
    };

    useEffect(() => {
        if (activeTab && pageData) {
            const data = pageData[activeTab.order];

            let tabs = {
                overviewDetails: [],
                locationsLandmarks: [],
                amenities: [],
                constructionPlans: [],
                floorPlans: [],
            };

            if (data) {
                tabs = {
                    overviewDetails: data.children ? data.children[0] : [],
                    locationsLandmarks: data.children ? data.children[1] : [],
                    amenities: data.children ? data.children[2] : [],
                    floorPlans: data.children ? data.children[3] : [],
                    constructionPlans: data.children ? data.children[4] : [],
                };
            }

            updatePropertyUnit({
                type: "PROPERTY_UNIT_FORMS_UPDATE",
                payload: {
                    ...tabs,
                },
            });
        }
    }, [activeTab, pageData]);

    useEffect(() => {
        (async () => {
            if (propertyDetails.payload) {
                const data = propertyDetails.payload;
                if (_.has(data, "unitData") && data.unitData.field_collections) {
                    const tab = data.unitData.field_collections
                        .sort((a, b) => a.order - b.order)
                        .map((res, i) => ({ id: res.id, tabName: res.name, order: res.order }));

                    setUnitTabsState(tab);
                    if (tab.length > 0) {
                        const active = activeTab ? activeTab.order : 0;
                        setUnitActiveTab(tab[active]);
                    }
                    setPageData(data.unitData.field_collections);
                }
            }
        })();
    }, [propertyDetails]);

    return (
        <div>
            {showUnitTab() && (
                <UnitTabs
                    unitTabs={unitTabs}
                    onChange={handleTabChange}
                    onTabClick={handleTabClick}
                />
            )}
            <StyledTabs
                defaultActiveKey="0"
                tabBarGutter={0}
                tabBarStyle={{
                    backgroundColor: "white",
                    padding: "15px 25px 0",
                    margin: "0 25px 0",
                }}
            >
                {renderTabs(activeTab)}
            </StyledTabs>
        </div>
    );
};

export default UnitTabsForms;
