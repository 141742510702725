import { gql } from "@apollo/client";

export const GET_CAREER_LIST = gql`
    query getFilteredCareers(
        $brand_id: String!
        $search: String
        $is_visible: Boolean
        $employment_type: String
        $location: String
        $page: Int
        $limit: Int
        $sort: String!
    ) {
        getFilteredCareers(
            brand_id: $brand_id
            limit: $limit
            page: $page
            search: $search
            filter: {
                is_visible: $is_visible
                employment_type: $employment_type
                location: $location
            }
            sort: { title: $sort }
        ) {
            careers {
                id
                title
                job_description
                employmentType {
                    name
                }
                location
            }
            total_visible
            total_hidden
            location_filter_opts
        }
    }
`;

export const GET_EMPLOYMENT_TYPES = gql`
    query getEmploymentTypes($brand_id: String!) {
        getEmploymentTypes(brand_id: $brand_id) {
            id
            name
            description
        }
    }
`;

export const GET_SPECIFIC_EMPLOYMENT_TYPES = gql`
    query getSpecificEmploymentType($id: String!) {
        getEmploymentType(id: $id) {
            id
            name
            description
        }
    }
`;

export const MODIFY_EMPLOYMENT_TYPE = gql`
    mutation modifyEmploymentType($id: String, $name: String!, $description: String!) {
        addOrUpdateEmploymentType(
            employmentTypeData: { id: $id, name: $name, description: $description }
        ) {
            id
            name
            description
        }
    }
`;

export const REMOVE_EMPLOYMENT_TYPE = gql`
    mutation removeEmploymentType($data: DeleteEmploymentTypeInput!) {
        removeEmploymentTypes(removeEmploymentTypes: $data)
    }
`;

export const GET_CAREER = gql`
    query getCareer($id: String!) {
        getCareer(id: $id) {
            id
            name
            title
            location
            job_description_label
            job_description
            qualifications_label
            careers_heading_tag
            seo_careers_title
            seo_careers_description
            qualifications
            tags
            isVisible
            apply_before_at
            employmentType {
                id
                name
                description
            }
        }
    }
`;

export const GET_PAGE = gql`
    query getPage($brand_id: String!, $slug: String!, $tag: String!) {
        getSpecificBrandPage(brand_id: $brand_id, slug: $slug, tag: $tag) {
            id
            name
            slug
            tag
            containers {
                id
                name
                field_collections {
                    id
                    name
                    children {
                        id
                        name
                        order
                        fields {
                            id
                            label
                            name
                            value
                            order
                            meta_data {
                                key
                                description
                                featured
                            }
                        }
                    }
                    fields {
                        id
                        label
                        name
                        value
                        order
                        meta_data {
                            key
                            file_type
                        }
                    }
                }
            }
        }
    }
`;

export const MODIFY_CAREER = gql`
    mutation modifyCareer($data: SaveCareerInput!) {
        addOrUpdateCareer(careerData: $data) {
            id
            name
        }
    }
`;

export const CREATE_PAGE = gql`
    mutation createPage($brand_id: String!, $name: String!, $tag: String!) {
        addOrUpdateBrandPage(brand_id: $brand_id, tag: $tag, item: { name: $name }) {
            id
            name
            brand_id
            slug
        }
    }
`;

export const CREATE_CONTAINER = gql`
    mutation addOrUpdateNested($data: CreateUpdateContainerNestedInput!) {
        addOrUpdateContainersFCFields(containerData: $data) {
            id
        }
    }
`;
