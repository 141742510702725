import React, { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";
import _ from "lodash";

import { Spin, message } from "antd";
import ContentContainer from "../../../../../../components/ContentContainer";
import Forms from "./form";

import { Context } from "../../../../../../context";
import { MODIFY_FIELDS, REPLACE_FIELD_COLLECTION } from "../../../../graphql";
import { validationSchema } from "./constant";
import generateId from "../../../../../../util/generate-id";

import { imageMapper, fieldMapper } from "../unit-tabs-utils";
import { Props, FormValueType, FieldInfoType } from "./amenities.types";
import { INIT_VALUES, INIT_FIELD_INFO } from "./amenities.utils";

const UnitAmenitiesForms = ({ handleRefresh }: Props): JSX.Element => {
    const {
        state: { propertyUnitForms, brand: brandData },
    } = useContext(Context);
    const brandName = brandData.brandName;
    const [initialValues, setIntialValues] = useState<FormValueType>(INIT_VALUES);

    const [fieldInfo, setFieldInfo] = useState<FieldInfoType>(INIT_FIELD_INFO);

    const [updateForms, { loading: updateFormsLoading }] = useMutation(MODIFY_FIELDS);
    const [replaceFCFields] = useMutation(REPLACE_FIELD_COLLECTION);
    const [reinitialize, setReinitialize] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        const data = propertyUnitForms.payload.amenities;

        if (_.has(data, "fields") && data.fields) {
            setLoading(true);
            const fieldItems: any = [];
            const fields: any = {
                amenitiesDefault: "",
                title: "",
                propertyDetails: "",
                seoAmenitiesTitle: "",
                seoAmenitiesDescription: "",
                amenitiesHeadingTag: "",
                amenityList: [
                    {
                        amenityName: "",
                        images: [],
                        videos: [""],
                        panoramas: [""],
                    },
                ],
            };
            const amenityID = data.id ? data.id : "";
            const amenityList: any = [];

            data.fields.forEach((res) => {
                fieldItems.push(res);
                fields[_.camelCase(res.name)] = res.value ? res.value : "";
            });

            if (!_.isEmpty(data.children)) {
                const fieldList: any = [];
                data.children.forEach((f) => {
                    const data = {
                        amenityName: f.fields && f.fields[0].value ? f.fields[0].value : "",
                        images: [],
                        imagesCaptions: [],
                        videos: [""],
                        panoramas: [""],
                    };

                    if (!_.isEmpty(f.children)) {
                        f.children.forEach((child, index) => {
                            if (!_.isEmpty(child.fields)) {
                                if (index === 0) {
                                    data["images"] = child.fields.map((img) => ({
                                        uid: generateId(),
                                        caption:
                                            img.meta_data.description && img.meta_data.description,
                                        isChecked: img.meta_data.featured && img.meta_data.featured,
                                        url: img.value && img.value,
                                        keyObj: img.meta_data.key && img.meta_data.key,
                                        status: "done",
                                    }));
                                    data["imagesCaptions"] = child.fields.map(
                                        (img) => img.meta_data.description
                                    );
                                } else {
                                    const vals = child.fields.map(({ value }) => value);
                                    index === 1
                                        ? (data["videos"] = vals)
                                        : (data["panoramas"] = vals);
                                }
                            }
                        });
                    }

                    amenityList.push(f.id);
                    fieldList.push(data);
                });
                fields["amenityList"] = fieldList;
            }
            setFieldInfo({
                fields: fieldItems,
                amenityID,
                amenityList,
            });
            setIntialValues(fields);
            setReinitialize(true);
        }
        setLoading(false);
    }, [propertyUnitForms.payload]);

    const handleSubmit = async (values) => {
        const amenityList: any = [];
        const toUpdateFields: any = [];

        setLoading(true);
        Object.keys(values).map(async (key, index) => {
            const filter = fieldInfo.fields.filter((item: any) => _.camelCase(item.name) === key);
            if (!_.isEmpty(filter)) {
                const data: any = filter[0];
                toUpdateFields.push({
                    id: data.id,
                    label: data.label,
                    name: data.name,
                    value: values[key],
                    order: index,
                });
            } else {
                if (key === "seoAmenitiesTitle") {
                    toUpdateFields.push({
                        label: "Seo Title",
                        name: "SEO-AMENITIES-TITLE",
                        value: values[key],
                        order: 3,
                        collection_id: fieldInfo.amenityID,
                    });
                }
                if (key === "seoAmenitiesDescription") {
                    toUpdateFields.push({
                        label: "Seo Description",
                        name: "SEO-AMENITIES-DESCRIPTION",
                        value: values[key],
                        order: 4,
                        collection_id: fieldInfo.amenityID,
                    });
                }
                if (key === "amenitiesHeadingTag") {
                    toUpdateFields.push({
                        label: "H1 Tag",
                        name: "AMENITIES-HEADING-TAG",
                        value: values[key],
                        order: 5,
                        collection_id: fieldInfo.amenityID,
                    });
                }
            }
            if (key === "amenityList") {
                values[key].forEach((formItem, formItemIndex) => {
                    const imageAsFields =
                        !_.isEmpty(formItem.images) && imageMapper(formItem.images);
                    const videoAsFields =
                        !_.isEmpty(formItem.videos) && fieldMapper(formItem.videos, "video");
                    const panoramaAsFields =
                        !_.isEmpty(formItem.panoramas) &&
                        fieldMapper(formItem.panoramas, "panorama");

                    const data = {
                        order: formItemIndex,
                        parentId: fieldInfo.amenityID,
                        name: "AMENITY",
                        children: [
                            {
                                order: 0,
                                name: "IMAGES",
                                fields: imageAsFields ? imageAsFields : [],
                            },
                            {
                                order: 1,
                                name: "VIDEOS",
                                fields: videoAsFields ? videoAsFields : [],
                            },
                            {
                                order: 2,
                                name: "PANORAMAS",
                                fields: panoramaAsFields ? panoramaAsFields : [],
                            },
                        ],
                        fields: [
                            {
                                order: 0,
                                label: "Amenity Name",
                                name: "AMENITY-NAME",
                                value: formItem.amenityName ? formItem.amenityName : null,
                            },
                        ],
                    };

                    amenityList.push(data);
                });
            }
        });

        await replaceFCFields({
            variables: {
                collectionData: {
                    collections: amenityList,
                },
                oldFcIds: {
                    ids: fieldInfo.amenityList,
                },
            },
        });

        await updateForms({
            variables: {
                data: {
                    fields: toUpdateFields,
                },
            },
        });

        handleRefresh();
        message.success("Update Success");
    };

    return (
        <ContentContainer
            style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "1rem",
                position: "relative",
            }}
        >
            <Spin spinning={updateFormsLoading || loading}>
                {!reinitialize && (
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={reinitialize}
                        validationSchema={validationSchema}
                        onSubmit={(values: FormValueType) => {
                            handleSubmit(values);
                        }}
                    >
                        {(formikBag) => (
                            <Forms
                                {...{
                                    formikBag,
                                    brandName,
                                }}
                            />
                        )}
                    </Formik>
                )}
            </Spin>
        </ContentContainer>
    );
};

export default UnitAmenitiesForms;
