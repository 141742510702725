import React from "react";
import { useField } from "formik";
import { Tag } from "antd";

import { StyledInputTag } from "./styled";

interface Props {
    name: any;
}

const CustomTag: React.FC<Props> = ({ name }) => {
    const [field, meta, helpers] = useField(name);
    const { value } = meta;
    const { setValue } = helpers;

    return (
        <>
            {(value || [])?.map((item, key) => (
                <Tag
                    key={`tags_${key}`}
                    color="#00714b"
                    closable
                    onClose={(e) => {
                        e.preventDefault();
                        const newTags = value.filter((item, index) => key !== index);
                        setValue(newTags);
                    }}
                >
                    {item}
                </Tag>
            ))}
            <StyledInputTag
                onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                        const set = new Set(
                            [...value, e.target.value].filter((item) => item.trim() !== "")
                        );
                        setValue(Array.from(set));
                        e.target.value = "";
                    }
                }}
                onBlur={(e) => {
                    const set = new Set(
                        [...value, e.target.value].filter((item) => item.trim() !== "")
                    );
                    setValue(Array.from(set));
                    e.target.value = "";
                }}
            />
        </>
    );
};

export default CustomTag;
