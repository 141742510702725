import * as yup from "yup";

const imageSchema = yup
    .array()
    .of(
        yup.object().shape({
            status: yup.string().matches(/done/, { message: "file is still uploading" }),
        })
    )
    .nullable();

export const validationSchema = yup.object().shape({
    imageGallery: imageSchema,
});
