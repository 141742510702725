import React, { useEffect } from "react";

import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, Spin } from "antd";

import CustomButton from "../../../../components/CustomButton";
import CustomSelect from "../../../../components/CustomSelect";

import { userStatusList } from "./constant";

interface Props {
    formikBag: any;
    handleSlider: () => void;
    formType?: string;
    roleList?: any;
    brandList?: any;
    handleSelectTenant: (values: string) => any;
    handleResetForm?: (val: any) => void;
    loading: boolean;
}

const Forms: React.FC<Props> = ({
    formikBag,
    handleSlider,
    formType,
    roleList,
    brandList,
    handleSelectTenant,
    handleResetForm,
    loading,
}) => {
    const { submitForm, values, setFieldValue, resetForm } = formikBag;

    useEffect(() => {
        setFieldValue("role", "");
        handleSelectTenant(values.brand);
    }, [values.brand]);

    useEffect(() => {
        setFieldValue("role", values.role);
    }, []);

    return (
        <Spin spinning={loading}>
            <Form>
                <FormItem
                    label={"First Name"}
                    name={"firstName"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input name="firstName" placeholder="Enter First Name" />
                </FormItem>
                <FormItem
                    label={"Middle Name"}
                    name={"middleName"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input name="middleName" placeholder="Enter Middle Name" />
                </FormItem>
                <FormItem
                    label={"Last Name"}
                    name={"lastName"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input name="lastName" placeholder="Enter Last Name" />
                </FormItem>
                <FormItem
                    label={"Mobile Number"}
                    name={"mobileNumber"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input name="mobileNumber" placeholder="Enter Mobile Number" />
                </FormItem>
                <FormItem
                    label={"Email Address"}
                    name={"email"}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Input name="email" placeholder="Enter Email Address" />
                </FormItem>

                <Row>
                    <Col span={12}>
                        <FormItem
                            label={"Assign Brand"}
                            name={"brand"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ marginRight: 15 }}
                        >
                            <CustomSelect
                                name={"brand"}
                                placeholder={"Select Brand"}
                                options={brandList}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            label={"Assign Role"}
                            name={"role"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <CustomSelect
                                name={"role"}
                                placeholder={"Select Role"}
                                options={roleList}
                            />
                        </FormItem>
                    </Col>
                </Row>

                {formType === "UPDATE" && values.status !== "Pending" && (
                    <div>
                        <FormItem
                            label={"Role Status"}
                            name={"userStatus"}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        >
                            <CustomSelect
                                name={"userStatus"}
                                placeholder={"Select Status"}
                                options={userStatusList}
                            />
                        </FormItem>

                        {values.status !== "Pending" && !values.userStatus && (
                            <div>
                                <FormItem
                                    name={"reason"}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                >
                                    <Input.TextArea
                                        name={"reason"}
                                        placeholder={"Reason to deativation"}
                                        rows={3}
                                    />
                                </FormItem>

                                <p>
                                    Note: The user will receive an email to be verified before
                                    he/she can login.
                                </p>
                            </div>
                        )}
                    </div>
                )}

                <Row justify={"space-between"}>
                    <Col span={11}>
                        <CustomButton
                            style={{ width: "100%", marginRight: 15 }}
                            type="primary"
                            onClick={() => submitForm()}
                        >
                            SAVE
                        </CustomButton>
                    </Col>
                    <Col span={11}>
                        <CustomButton
                            style={{ width: "100%" }}
                            onClick={() => {
                                handleResetForm && handleResetForm(resetForm);
                                handleSlider();
                            }}
                        >
                            CANCEL
                        </CustomButton>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default Forms;
