import * as yup from "yup";

const imageSchema = yup.object().shape({
    status: yup.string().matches(/done/, { message: "file is still uploading" }),
});

const sectionSchema = yup
    .object()
    .shape({
        images: yup.array().of(imageSchema).nullable(),
    })
    .nullable();

export const validationSchema = yup.object().shape({
    title: yup.string(),
    vision: sectionSchema,
    mission: sectionSchema,
});
