import React, { useEffect, useState, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import kebabCase from "lodash/kebabCase";
import lowerCase from "lodash/lowerCase";
import filter from "lodash/filter";

import { GET_PAGES, EDIT_PAGE, REMOVE_PAGE } from "../../graphql";
import { Context } from "../../../../context";

import { Content } from "../../../../entities/Content";
import { ContentMapper } from "../../dto/ContentMapper";
import CustomDashboard from "../../../../components/CustomDashboard";
import PageNotFound from "../../../../components/PageNotFound";

import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { cardText } from "../../manage-content.utils";
import { userPermission } from "../../../../util/user-access";

const ContentList = (): JSX.Element => {
    const history = useHistory();
    const match = useRouteMatch();

    const {
        state: { brand: brandData, user },
    } = useContext(Context);
    const brandId = brandData?.id;
    const brandName = brandData?.name;
    const permissions = user?.roles[0].permissions;
    const createAccess = userPermission(permissions, "create:content");
    const updateAccess = userPermission(permissions, "update:content");
    const deleteAccess = userPermission(permissions, "delete:content");

    const newsAccess = userPermission(permissions, "manage:content-news");
    const eventsAccess = userPermission(permissions, "manage:content-events");
    const announcementAccess = userPermission(permissions, "manage:content-announcement");
    const destinationAccess = userPermission(permissions, "manage:content-destinations");
    const neighborhoodAccess = userPermission(permissions, "manage:content-neighborhood");
    const sustainabilityAccess = userPermission(permissions, "manage:content-sustainability");
    const hbgAccess = userPermission(permissions, "manage:content-home-buying-guide");
    const blogsAccess = userPermission(permissions, "manage:content-blogs");
    const vlogsAccess = userPermission(permissions, "manage:content-vlogs");
    const tabList = [
        {
            label: "News",
            key: "news",
            access: newsAccess,
        },
        {
            label: "Events",
            key: "events",
            access: eventsAccess,
        },
        {
            label: "Announcements",
            key: "announcements",
            access: announcementAccess,
        },
        {
            label: "Destinations",
            key: "destinations",
            access: destinationAccess,
        },
        {
            label: "Neighborhood",
            key: "neighborhood",
            access: neighborhoodAccess,
        },
        {
            label: "Sustainability",
            key: "sustainability",
            access: sustainabilityAccess,
        },
        {
            label: "Home Buying Guide",
            key: "home buying guide",
            access: hbgAccess,
        },
        {
            label: "Blogs",
            key: "blogs",
            access: blogsAccess,
        },
        {
            label: "Vlogs",
            key: "vlogs",
            access: vlogsAccess,
        },
    ];
    const filteredTabs = filter(tabList, "access");
    const [contentList, setContentList] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<string>(filteredTabs[0]?.key);
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [search, setSearch] = useState<string>("");
    const { error, refetch, loading, data } = useQuery(GET_PAGES, {
        skip: !brandId,
        variables: {
            brand_id: brandId,
            tag: `CONTENT-${brandName}`,
            search: search,
            page: page,
            limit: pageSize,
            type: activeTab,
        },
        fetchPolicy: "no-cache",
    });

    const [editPage] = useMutation(EDIT_PAGE);
    const [removePage] = useMutation(REMOVE_PAGE);
    const [loader, setLoader] = useState<boolean>(false);

    const handleTabs = (activeKey) => {
        if (activeKey !== activeTab) {
            setContentList([]);
            setActiveTab(activeKey);
            setSearch("");
        }
    };

    const handleSearch = (query) => {
        query !== search && setSearch(query);
    };

    const handleAddBtn = () => {
        const contentType = kebabCase(lowerCase(activeTab));
        history.push(`${match.path}/add/${contentType}`);
    };

    const handleFeatured = async (featured: boolean | undefined, id: string) => {
        const value = featured ? false : true;

        const { data: res } = await editPage({
            variables: {
                brand_id: brandId,
                tag: `CONTENT-${brandName}`,
                id: id,
                featured: value,
            },
        });

        if (res.addOrUpdateBrandPage) {
            refetch();
        } else {
            message.error("Something went wrong");
        }
    };

    const handleDelete = (id: string) => {
        const { confirm } = Modal;

        confirm({
            title: "Delete Page?",
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to delete this page?",
            async onOk() {
                try {
                    await removePage({
                        variables: {
                            id: id,
                        },
                    });
                    setLoader(true);
                    refetch();
                    message.success("Successfully deleted page");
                } catch {
                    message.error("Something went wrong");
                } finally {
                    return;
                }
            },
            onCancel() {
                return;
            },
        });
    };

    const handleContent = (
        menuItem: string,
        slug: string,
        id: string,
        featured: boolean | undefined
    ) => {
        if (menuItem === "edit") {
            history.push(`${match.path}/${kebabCase(activeTab)}/${slug}`, { pageId: id });
        }
        if (menuItem === "featured") {
            handleFeatured(featured, id);
        }
        if (menuItem === "delete") {
            handleDelete(id);
        }
    };

    const handlePageSize = async (pageSize) => {
        setPageSize(pageSize);
    };

    const handlePagination = async (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    useEffect(() => {
        setContentList([]);

        if (data && data.searchPages) {
            data.searchPages.totalCount && setTotalPages(data.searchPages.totalCount);

            const { pages } = data.searchPages;
            const res = pages.map((info: Content) => Object.assign(ContentMapper.map({ ...info })));
            setContentList(res);
        }

        if (error) {
            message.error("Something went wrong.");
        }

        setLoader(false);
    }, [data, error]);

    return !activeTab ? (
        <PageNotFound />
    ) : (
        <CustomDashboard
            createAccess={createAccess}
            updateAccess={updateAccess}
            deleteAccess={deleteAccess}
            dashboard="Content"
            handleMenu={handleContent}
            loading={loading || loader}
            handleAddBtn={handleAddBtn}
            handleSearch={handleSearch}
            handleTabs={handleTabs}
            tabList={filteredTabs}
            data={contentList}
            cardText={cardText}
            totalPages={totalPages}
            page={page}
            handlePagination={handlePagination}
            pageSize={pageSize}
            handlePageSize={handlePageSize}
        />
    );
};

export default ContentList;
