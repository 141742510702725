import * as yup from "yup";

const linkSchema = yup.object().shape({
    name: yup.string().required("This is required"),
    link: yup.string().required("This is required"),
});

export const validationSchema = yup.object().shape({
    links: yup.array().of(linkSchema),
});
