export const getAcceptedFiles = (brandName: string, isVideoAllowed?: boolean | undefined) => {
    const validImageFileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

    const avidaAdditionalTypes = [
        "image/webp",
        // TODO: To add this when supported by gatsby-transformer-sharp
        // 'image/avif'
    ];

    if (brandName === "AVIDA") {
        validImageFileTypes.push(...avidaAdditionalTypes);
    }

    if (isVideoAllowed) {
        validImageFileTypes.push("video/mp4");
    }

    const validFileExtensions = validImageFileTypes.join(",");
    return validFileExtensions;
};
