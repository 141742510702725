import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import EmailForms from "./components/email";

const ManageCareers: React.FC = () => {
    const match = useRouteMatch();
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={`${match.path}`}
                    render={(props: any) => <EmailForms {...props} />}
                />
            </Switch>
        </>
    );
};

export default ManageCareers;
