import { gql } from "@apollo/client";

export const GET_LINKED_PAGES = gql`
    query getLinkedPages($brand_id: String!, $tag: String!, $filter: PageFilter) {
        searchPages(
            brandId: $brand_id
            tag: $tag
            page: 1
            search: ""
            filters: $filter
            withoutHomeBanner: true
        ) {
            pages {
                id
                created_at
                updated_at
                deleted_at
                name
                brand_id
                slug
                tag
                featured
                alternative_featured
                published
                containers {
                    id
                    created_at
                    name
                    field_collections {
                        id
                        name
                        created_at
                        updated_at
                        fields {
                            id
                            name
                            label
                            value
                        }
                    }
                }
            }
            totalCount
        }
    }
`;

export const GET_BANNERS_LIST = gql`
    query getBannerList(
        $brand_id: String!
        $page: Int
        $limit: Int
        $search: String
        $link_type: String
        $category: String
    ) {
        getFilteredHomeBanners(
            brand_id: $brand_id
            page: $page
            limit: $limit
            search: $search
            link_type: $link_type
            category: $category
        ) {
            home_banners {
                page_id
                id
                title
                link_type
                category
                link_type
                description
                image
                meta_data {
                    key
                    alt_text
                }
                video_link
                updated_at
                page {
                    slug
                }
            }
            total_count
        }
    }
`;

export const GET_BANNER = gql`
    query getHomeBanner($id: String!) {
        getHomeBanner(id: $id) {
            id
            link_type
            category
            image
            video_link
            seo_banner_title
            seo_banner_description
            banner_heading_tag
            meta_data {
                key
                alt_text
            }
            title
            description
            description_mobile
            page {
                id
                name
                tag
                slug
                updated_at
                created_at
                containers {
                    field_collections {
                        name
                        children {
                            name
                            fields {
                                name
                                value
                            }
                        }
                        fields {
                            name
                            value
                        }
                    }
                }
            }
        }
    }
`;

export const CREATE_OR_EDIT_BANNER = gql`
    mutation addOrUpdateBanner($data: SaveHomeBannerInput!) {
        addOrUpdateHomeBanner(homeBannerData: $data) {
            id
            brand_id
            page_id
            link_type
            category
            title
            description
            seo_banner_title
            seo_banner_description
            banner_heading_tag
            description_mobile
            image
            meta_data {
                key
                alt_text
            }
            video_link
        }
    }
`;

export const REMOVE_HOME_BANNERS = gql`
    mutation removeHomeBanners($id: [String!]!) {
        removeHomeBanners(removeHomeBanners: { ids: $id })
    }
`;
