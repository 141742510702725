import { User } from "../../../entities/User";
import moment from "moment";

export class UserMapper {
    static map(user: User): User {
        user.role = user.roles && user.roles.length ? user.roles[0].name : "";
        user.brand = user.tenants && user.tenants.length ? user.tenants[0].name : "";
        user.createdAt = user.createdAt ? moment(user.createdAt).format("MMM DD, YYYY") : null;
        user.updatedAt = user.updatedAt ? moment(user.updatedAt).format("MMM DD, YYYY") : null;
        user.status = user.verifiedAt ? "Verified" : "Pending";

        delete user.roles;
        delete user.tenants;
        
        return user;
    }
}
