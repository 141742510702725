import { gql } from "@apollo/client";

export const GET_LINKS = gql`
    query getLinks($brandId: String!) {
        getLinks(brandId: $brandId) {
            id
            brand {
                id
                name
            }
            name
            link
            image
            category
            imageKey
        }
    }
`;

export const ADD_LINKS = gql`
    mutation addManyLinks($brandId: String!, $createManyLinksInput: CreateManyLinksInput!) {
        addManyLinks(brandId: $brandId, createManyLinksInput: $createManyLinksInput) {
            id
            brand {
                id
                name
            }
            link
            image
            category
        }
    }
`;

export const REMOVE_LINKS = gql`
    mutation massRemoveLinkRecords($data: RemoveLinksInput!) {
        massRemoveLinkRecords(removeLinksInput: $data)
    }
`;
