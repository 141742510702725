import React, { useContext, useEffect, useState } from "react";
import { Upload, Spin, message } from "antd";
import has from "lodash/has";
import axios from "axios";
import { PlusOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { convertFileToBase64 } from "../../util/convert-to-base64";
import {
    ButtonStyle,
    RemoveBtn,
    Container,
    WrapperPreview,
    FileListStyled,
    BrokenImageContainer,
    ImageNotScannedContainer,
    ErrorMessage,
} from "./styled";
import { Context } from "../../context";
import { getAcceptedFiles } from "../../util/get-accepted-filetypes";

const INTERNAL_SERVER_ERROR = 500;
const UNPROCESSABLE_ENTITY = 422;
const SCAN_ERROR_STATUS = 403;
const BAD_REQUEST = 400;
let BASE_URL = `${process.env.REACT_APP_CONTENT_URL}`;

interface Props {
    value: any;
    onChange: (value: string | null) => void;
    path?: string;
    className?: string;
    renameAddStr?: string;
    accept?: string;
    sizeLimit?: number;
    children?: React.ReactNode;
    noteMessage?: string;
    showUploadList?: boolean;
    noteMessageStyle?: React.CSSProperties;
}

const UploadSingleFile = ({
    value,
    onChange,
    path,
    className,
    accept,
    sizeLimit,
    children,
    noteMessage,
    showUploadList,
    noteMessageStyle,
}: Props): JSX.Element => {
    const [sourceImage, setSourceImage] = useState("");
    const [isBroken, setIsBroken] = useState(false);
    const [isNotScanned, setIsNotScanned] = useState(false);
    const {
        state: { brand: brandData },
    } = useContext(Context);
    const brandName = brandData?.name;

    useEffect(() => {
        if (value) {
            setSourceImage(value.url || value.thumbUrl);
        }
    }, [value]);

    const imgOrVidElelement = (value) => {
        const defaultRegExp = /\.(gif|jpe?g|png|gif)$/i;
        const avidaRegExp = /\.(gif|jpe?g|png|gif|webp)$/i;

        const regExp = brandName === "AVIDA" ? avidaRegExp : defaultRegExp;
        const imgTypePattern = new RegExp(regExp);

        const imgFileTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

        if (brandName === "AVIDA") {
            const avidaAdditionalTypes = [
                "image/webp",
                // , "image/avif"
            ];
            imgFileTypes.push(...avidaAdditionalTypes);
        }

        if (brandName === "ALISI" || brandName === "AVIDA") {
            return (
                <>
                    {imgTypePattern.test(sourceImage) || imgFileTypes.includes(value.type) ? (
                        <img src={sourceImage} alt="img" />
                    ) : (
                        <video className="video-banner" src={sourceImage} controls />
                    )}
                </>
            );
        }

        return <img src={sourceImage} alt="img" />;
    };

    const imagePreview = (value) => {
        if (isBroken) {
            value.status = "infected";
        }

        return (
            <Spin spinning={value.status === "uploading"}>
                <WrapperPreview className={"uploaded-file"}>
                    {(value.url || value.thumbUrl) &&
                        (isNotScanned ? (
                            <ImageNotScannedContainer>
                                {imgOrVidElelement(value)}
                                <p>
                                    File was uploaded but was not scanned, Please contact your
                                    system administrator.
                                </p>
                            </ImageNotScannedContainer>
                        ) : (
                            imgOrVidElelement(value)
                        ))}

                    {isBroken && (
                        <BrokenImageContainer>
                            {imgOrVidElelement(value)}
                            <p>The file is infected, Saving this image is disabled.</p>
                        </BrokenImageContainer>
                    )}
                </WrapperPreview>
            </Spin>
        );
    };

    const handleOnChange = async ({ file, fileList: list }) => {
        const limit = !!sizeLimit ? sizeLimit : 49;
        const isLt2M = file.size / 1024 / 1024 < limit;

        if (file.status !== "uploading" && has(file, "response")) {
            const base64 = await convertFileToBase64(file.originFileObj);
            file["keyObj"] = file.response.key;
            file["thumbUrl"] = base64;
            onChange(file);
        }

        if (file.status === "uploading" && isLt2M) {
            file["thumbUrl"] = "";
            file["keyObj"] = "";
            file["url"] = "";
            onChange(file);
        }
    };

    const UploadImage = () => {
        const node = (
            <ButtonStyle type="dashed" className="upload-button">
                {children ? (
                    children
                ) : (
                    <div>
                        <PlusOutlined />
                        <h1>Upload</h1>
                    </div>
                )}
                {isBroken && showUploadList && (
                    <ErrorMessage>
                        The file is infected, Saving this attachment is disabled.{" "}
                    </ErrorMessage>
                )}
                {isNotScanned && showUploadList && (
                    <ErrorMessage>
                        File was uploaded but was not scanned, Please contact your system
                        administrator.{" "}
                    </ErrorMessage>
                )}
            </ButtonStyle>
        );

        if (value && showUploadList) {
            if (isBroken) {
                value.status = "infected";
            }
            return <Spin spinning={value.status === "uploading"}>{node}</Spin>;
        }

        return node;
    };

    const handleBeforeUpload = (file) => {
        // Check file type
        const uploadedFile = accept || "";
        const fileTypesAccepted = getAcceptedFiles(brandName);
        let errMessageFile;
        let fileTypes;

        const imageOnly = uploadedFile.includes("image") && !uploadedFile.includes("video");
        const imageWithVideo = uploadedFile.includes("image") && uploadedFile.includes("video");

        if (imageOnly) {
            fileTypes = [...fileTypesAccepted.split(",")];
            errMessageFile = fileTypes
                .map((file) => {
                    return file.replace("image/", "").toUpperCase();
                })
                .join("/");
            BASE_URL = `${process.env.REACT_APP_CONTENT_URL}/uploads/images`;
        }

        if (imageWithVideo) {
            fileTypes = [...fileTypesAccepted.split(","), "video/mp4"];
            errMessageFile = "JPG/JPEG/PNG/GIF/MP4";
            BASE_URL = `${process.env.REACT_APP_CONTENT_URL}/upload/objects`;
        }

        if (uploadedFile.includes("pdf")) {
            fileTypes = [
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ];
            errMessageFile = "PDF/DOC/DOCS";
            BASE_URL = `${process.env.REACT_APP_CONTENT_URL}/uploads/documents`;
        }

        if (!fileTypes.includes(file.type)) {
            message.error(`You can only upload ${errMessageFile} file type`);
            return false;
        }

        const limit = !!sizeLimit ? sizeLimit : 49;
        const isLt2M = file.size / 1024 / 1024 < limit;

        if (!isLt2M) {
            message.error("File is too big");
            return false;
        }
        return file;
    };

    const handleRequest = (option) => {
        setIsBroken(false);
        setIsNotScanned(false);
        const formData = new FormData();
        const dir = path ? `${path}/` : "";
        const fileName = `${dir}${option.file.uid}${option.file.name}`;
        formData.append("file", option.file);
        // formData.append("filename", fileName);

        axios({
            method: "POST",
            url: BASE_URL,
            data: formData,
        })
            .then((res) => {
                const { data } = res;
                option.onSuccess(res.data, option.file);

                if (data.scanErrorStatus && data.scanErrorStatus === SCAN_ERROR_STATUS) {
                    setIsNotScanned(true);
                }
            })
            .catch((err) => {
                const { status } = err.response;

                if (status === BAD_REQUEST) {
                    const { data } = err.response;
                    message.error(data.message);
                }

                if (status === INTERNAL_SERVER_ERROR) {
                    message.error("Oops, Something went wrong");
                }

                if (status === UNPROCESSABLE_ENTITY) {
                    setIsBroken(true);
                    message.error("Upload Failed, File is infected, Please upload another file.");
                }
            });
    };

    const renderList = () => {
        let element: any;
        if (value && value.status !== "uploading") {
            element = (
                <FileListStyled>
                    <h4>{value.name}</h4>
                    <a onClick={() => onChange(null)}>
                        <DeleteOutlined />
                    </a>
                </FileListStyled>
            );
        }
        return element;
    };

    return (
        <div style={{ marginBottom: 15 }}>
            <Container className={className}>
                {!showUploadList && value && imagePreview(value)}

                <Upload
                    action={BASE_URL}
                    showUploadList={showUploadList ? true : false}
                    customRequest={handleRequest}
                    onChange={(event) => handleOnChange(event)}
                    beforeUpload={handleBeforeUpload}
                    accept={accept}
                >
                    {!value && UploadImage()}
                    {value && showUploadList && UploadImage()}
                </Upload>

                {((!showUploadList && value && (value.thumbUrl || value.url)) || isBroken) && (
                    <RemoveBtn className={"remove-file"} onClick={() => onChange(null)}>
                        <CloseOutlined style={{ fontSize: 15 }} />
                    </RemoveBtn>
                )}
            </Container>
            {noteMessage && (
                <p
                    style={{
                        fontWeight: 400,
                        fontSize: 10,
                        color: "rgb(127, 127, 127)",
                        ...noteMessageStyle,
                    }}
                >
                    {noteMessage}
                </p>
            )}
            {showUploadList && renderList()}
        </div>
    );
};

export default UploadSingleFile;
