import React, { useEffect, useState, useContext } from "react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import kebabCase from "lodash/kebabCase";
import lowerCase from "lodash/lowerCase";
import filter from "lodash/filter";
import find from "lodash/find";

import { GET_PAGES, EDIT_PAGE, ALISI_FEATURED_UPDATE, REMOVE_PAGE } from "../../graphql";
import { Context } from "../../../../context";

import { Property } from "../../../../entities/Property";
import { PropertyMapper } from "../../dto/PropertyMapper";
import CustomDashboard from "../../../../components/CustomDashboard";
import { NoPropertyAccess } from "./styled";

import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { tabList, cardText } from "../../manage-properties.utils";
import { userPermission } from "../../../../util/user-access";

import { Filter } from "../../manage-properties.types";

const PropertiesList = (): JSX.Element => {
    const history = useHistory();
    const searchParams = useLocation().search;
    const queryParams = new URLSearchParams(searchParams);
    const paramField = queryParams.get("brand");
    const match = useRouteMatch();
    const {
        state: { brand: brandData, user, allBrands: filteredBrands },
    } = useContext(Context);
    const uBrandId = brandData?.id;
    const uBrandName = brandData?.name;
    const permissions = user?.roles[0].permissions;
    const newTablist = filter(tabList, (e) => {
        const grant = userPermission(permissions, e.scope);
        return grant && e;
    });

    const urlParam = paramField && find(filteredBrands, { name: paramField });
    const onLoadBrand = !paramField ? undefined : urlParam;
    const createAccess = userPermission(permissions, "create:property");
    const updateAccess = userPermission(permissions, "update:property");
    const deleteAccess = userPermission(permissions, "delete:property");
    const [propertiesList, setPropertiesList] = useState<any>(null);
    const [selectList, setSelectList] = useState<Filter[]>([]);
    const [activeTab, setActiveTab] = useState<string>(newTablist.length && newTablist[0].key);
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [status, setStatus] = useState<string>("");
    const [brandId, setBrandId] = useState<string>(!onLoadBrand ? uBrandId : onLoadBrand.id);
    const [brandName, setBrandName] = useState<string>(uBrandName);
    const [location, setLocation] = useState<string>("");
    const [search, setSearch] = useState<string>("");

    useEffect(() => {
        if (paramField) {
            setBrandName(paramField);
            setBrandId(urlParam.id);
        }
        // eslint-disable-next-line
    }, [paramField]);

    const constructBrands = {
        brands: filteredBrands,
        selectedBrandName: brandName,
    };
    const { error, refetch, loading, data } = useQuery(GET_PAGES, {
        skip: !brandId,
        variables: {
            brand_id: brandId,
            tag: `PROPERTIES-${brandName}`,
            page: page,
            limit: pageSize,
            search: search,
            status: status,
            location: location,
            type: activeTab,
        },
        fetchPolicy: "no-cache",
        partialRefetch: true,
    });

    const [loader, setLoader] = useState<boolean>(false);

    const [editPage] = useMutation(EDIT_PAGE);
    const [alisiFeaturedUpdate] = useMutation(ALISI_FEATURED_UPDATE);

    const [removePage] = useMutation(REMOVE_PAGE);

    const handleBrandSelect = (name) => {
        const newBrand = find(filteredBrands, { name });
        setBrandId(newBrand.id);
        setBrandName(newBrand.name);
        history.push(`/manage-properties?brand=${newBrand.name}`);
    };

    const handleTabs = (activeKey) => {
        if (activeKey !== activeTab) {
            setPropertiesList([]);
            setSelectList([]);
            setSearch("");
            setActiveTab(activeKey);
        }
    };

    const handleSearch = (query) => {
        query !== search && setSearch(query);
    };

    const handleStatus = (selection) => {
        selection === "All" ? setStatus("") : setStatus(selection);
    };

    const handleLocation = (selection) => {
        selection === "All" ? setLocation("") : setLocation(selection);
    };

    const handleAddBtn = () => {
        const propertyType = kebabCase(lowerCase(activeTab));

        history.push(`${match.path}/add/${propertyType}`);
    };

    const handleFeatured = async (featured: boolean | undefined, id: string) => {
        const value = featured ? false : true;
        const constructVariables = () => {
            if (uBrandName !== "ALISI") {
                return {
                    variables: {
                        brand_id: brandId,
                        tag: `PROPERTIES-${brandName}`,
                        id: id,
                        featured: value,
                    },
                };
            }
            return {
                variables: {
                    brand_id: brandId,
                    tag: `PROPERTIES-${brandName}`,
                    id: id,
                    alternative_featured: value,
                },
            };
        };
        const { data: res } =
            uBrandName !== "ALISI"
                ? await editPage(constructVariables())
                : await alisiFeaturedUpdate(constructVariables());

        if (res.addOrUpdateBrandPage) {
            refetch();
        } else {
            message.error("Something went wrong");
        }
    };

    const handleDelete = (id: string) => {
        const { confirm } = Modal;

        confirm({
            title: "Delete Page?",
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure you want to delete this page?",
            async onOk() {
                try {
                    await removePage({
                        variables: {
                            id: id,
                        },
                    });
                    setLoader(true);
                    refetch();
                    message.success("Successfully deleted page");
                } catch {
                    message.error("Something went wrong");
                } finally {
                    return;
                }
            },
            onCancel() {
                return;
            },
        });
    };

    const handleProperty = (
        menuItem: string,
        slug: string,
        id: string,
        featured: boolean | undefined
    ) => {
        if (menuItem === "edit") {
            history.push(`${match.path}/${slug}`, { pageId: id });
        }
        if (menuItem === "featured") {
            handleFeatured(featured, id);
        }
        if (menuItem === "delete") {
            handleDelete(id);
        }
    };

    const handlePageSize = async (pageSize) => {
        setPageSize(pageSize);
    };

    const handlePagination = async (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    useEffect(() => {
        setPropertiesList([]);

        if (data) {
            const statusSelect: Filter = {
                name: "status",
                customOptions: undefined,
                handle: handleStatus,
            };

            const locationsSelect: Filter = {
                name: "location",
                customOptions: undefined,
                handle: handleLocation,
            };

            if (data.searchPages) {
                const { pages } = data.searchPages;
                const res = pages.map((info: Property) =>
                    Object.assign([], PropertyMapper.map({ ...info }))
                );
                setPropertiesList(res);

                data.searchPages.totalCount && setTotalPages(data.searchPages.totalCount);
            }

            if (data.getPropertyStatusFilters) {
                const { getPropertyStatusFilters } = data;
                const res = getPropertyStatusFilters.map(({ status }) => ({
                    label: status,
                    value: status,
                }));

                statusSelect.customOptions = res;
            }

            if (data.getPropertyLocationFilters) {
                const { getPropertyLocationFilters } = data;
                const res = getPropertyLocationFilters.map(({ location }) => ({
                    label: location,
                    value: location,
                }));

                locationsSelect.customOptions = res;
            }

            setSelectList([statusSelect, locationsSelect]);
        }

        if (error) {
            message.error("Something went wrong");
        }

        setLoader(false);
    }, [data, error]);

    return !newTablist.length ? (
        <NoPropertyAccess>No Property Access Please Contact the Administrator</NoPropertyAccess>
    ) : (
        <CustomDashboard
            createAccess={createAccess}
            updateAccess={updateAccess}
            deleteAccess={deleteAccess}
            brandSelections={uBrandName !== "ALISI" ? null : constructBrands}
            handleBrandSelect={handleBrandSelect}
            dashboard="Properties"
            handleMenu={handleProperty}
            loading={loading || loader}
            filters={selectList}
            handleAddBtn={handleAddBtn}
            handleSearch={handleSearch}
            handleTabs={handleTabs}
            tabList={newTablist}
            data={propertiesList}
            cardText={cardText}
            totalPages={totalPages}
            page={page}
            handlePagination={handlePagination}
            pageSize={pageSize}
            handlePageSize={handlePageSize}
        />
    );
};

export default PropertiesList;
