import React from "react";

import { FormOutlined } from "@ant-design/icons";
import { Route } from "react-router-dom";
import styled from "styled-components";
import { userRoutePermission } from "../../util/user-access";
import SampleFeature from "../../feature/sample-feature";
import ManageUsers from "../../feature/manage-users";
import ManageRoles from "../../feature/manage-roles";
import ManageCompanyInformation from "../../feature/manage-company-information";
import ManageProperties from "../../feature/manage-properties";
import ManageShowroom from "../../feature/manage-showroom";
import ManageContent from "../../feature/manage-content";
import ManageGallery from "../../feature/manage-gallery";
import ManageHeroBanner from "../../feature/manage-hero-banner";
import ManageCareers from "../../feature/manage-careers";
import ManageLinks from "../../feature/manage-links";
import ManageDocuments from "../../feature/manage-mdor-documents";
import ManageContracts from "../../feature/manage-contracts";
import ManageEmail from "../../feature/manage-email";
import ManageHomeBuyingGuide from "../../feature/manage-home-buying-guide";
import PageNotFound from "../../components/PageNotFound";
import ManageAwards from "../../feature/manage-awards";

// export const PageNotFound = styled.div`
//     height: 100%;
//     font-size: 5vh;
//     text-align: center;
//     padding-top: 10%;
// `;

const BASE_URL = "/";

export const routes = [
    {
        name: "MANAGE_USERS",
        path: `${BASE_URL}manage-users`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageUsers />,
        access: "*",
    },
    {
        name: "MANAGE_ROLES",
        path: `${BASE_URL}manage-roles`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageRoles />,
        access: "*",
    },
    {
        name: "MANAGE_COMPANY_INFORMATION",
        path: `${BASE_URL}manage-company-information`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageCompanyInformation />,
        access: "read:company-information",
    },
    {
        name: "MANAGE_PROPERTIES",
        path: `${BASE_URL}manage-properties`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageProperties />,
        access: "read:property",
    },
    {
        name: "MANAGE_AWARDS",
        path: `${BASE_URL}manage-awards`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageAwards />,
        access: "read:awards_content",
    },
    {
        name: "MANAGE_SHOWROOM",
        path: `${BASE_URL}manage-showroom`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageShowroom />,
        access: "read:showroom",
    },
    {
        name: "MANAGE_CONTENT",
        path: `${BASE_URL}manage-content`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageContent />,
        access: "read:content",
    },
    {
        name: "MANAGE_HERO_BANNER",
        path: `${BASE_URL}manage-hero-banner`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageHeroBanner />,
        access: "read:banner",
    },
    {
        name: "MANAGE_GALLERY",
        path: `${BASE_URL}manage-gallery`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageGallery />,
        access: "read:gallery",
    },
    {
        name: "MANAGE_CAREERS",
        path: `${BASE_URL}manage-careers`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageCareers />,
        access: "read:careers",
    },
    {
        name: "MANAGE_LINKS",
        path: `${BASE_URL}manage-links`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageLinks />,
        access: "read:links",
    },
    {
        name: "MANAGE_EMAIL",
        path: `${BASE_URL}manage-email`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageEmail />,
        access: "read:email",
    },
    {
        name: "MANAGE_HOME_BUYING_GUIDE",
        path: `${BASE_URL}manage-home-buying-guide`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageHomeBuyingGuide />,
        access: "read:hbg",
    },
    {
        name: "MANAGE_MDDOR_DOCUMENTS",
        path: `${BASE_URL}manage-mddor-documents`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageDocuments />,
        access: "read:mdor",
    },
    {
        name: "MANAGE_CONTRACTS",
        path: `${BASE_URL}manage-contracts`,
        exact: false,
        icon: <FormOutlined />,
        component: <ManageContracts />,
        access: "read:contract_document_entry",
    },
    {
        name: "INVALID",
        path: "*",
        exact: false,
        icon: <FormOutlined />,
        component: <PageNotFound />,
        access: "invalid",
    },
];

export const getRoute = (permissions: any) => {
    return routes.map(({ name, path, exact, component, access }) => {
        const accessible = access && userRoutePermission(permissions, access);
        return (
            <Route
                key={name}
                exact={exact}
                path={path}
                render={() => (accessible ? component : <PageNotFound />)}
            />
        );
    });
};
