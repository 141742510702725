import React, { useState } from "react";
import { Form, FormItem, Input } from "formik-antd";
import { Row, Col, Modal } from "antd";

import { CloudUploadOutlined } from "@ant-design/icons";

import CustomSelect from "../../../../../../components/CustomSelect";
import CustomButton from "../../../../../../components/CustomButton";
import FeaturedImages from "../../../../../../components/FeaturedImages";
import { StyledIFrame, UploadSingleFileStyled } from "./styled";
import SeoForm from "@/components/SeoForm";
import { propertyStatusList } from "./constant";
import MultipleUrlFields from "../../../../../../components/MultipleUrlFields";
import CustomTag from "@/components/CustomTag";

interface Props {
    formikBag: any;
    brandName?: string;
}

const Forms = ({ formikBag, brandName }: Props): JSX.Element => {
    const [modalVisible, setModalVisible] = useState(false);
    const [preview, setPreview] = useState("");
    const { values, setFieldValue, submitForm, isValid, dirty } = formikBag;

    const handleClosePreview = () => {
        setPreview("");
        setModalVisible(false);
    };

    const handlePreview = (val) => {
        if (val) {
            setPreview(val);
            setModalVisible(true);
        }
    };

    return (
        <Form>
            <SeoForm category="Overview" brand={brandName} />
            <h1>Overview Details</h1>
            <Row justify="start">
                <Col span={24}>
                    <FormItem
                        label={"Property Status"}
                        name={"propertyStatus"}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <CustomSelect
                            name={"propertyStatus"}
                            placeholder={"Select Property Status"}
                            options={propertyStatusList}
                        />
                    </FormItem>
                </Col>
                <Col span={24}>
                    <FormItem
                        label={"Title"}
                        name={"propertyTitle"}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input name="propertyTitle" />
                    </FormItem>
                </Col>
                <Col span={24}>
                    <FormItem
                        label={"Property Details (Property Information)"}
                        name={"propertyDetails"}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input.TextArea name="propertyDetails" />
                    </FormItem>
                </Col>

                {brandName === "AVIDA" ? (
                    <>
                        <Col span={24}>
                            <FormItem
                                label={"Property Banner Description"}
                                name={"description"}
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                            >
                                <Input name="description" />
                            </FormItem>
                        </Col>

                        <Col span={24}>
                            <h1>Tags</h1>
                            <Col
                                span={24}
                                style={{
                                    border: "1px solid #d7d7d7",
                                    borderRadius: "3px",
                                    padding: "24px",
                                    marginBottom: "24px",
                                }}
                            >
                                <CustomTag name="tags" />
                            </Col>
                        </Col>
                    </>
                ) : null}

                <Col span={24} style={{ marginBottom: 25 }}>
                    <h3>Image Gallery</h3>
                    <FeaturedImages
                        onChange={(e) => {
                            const imagesCaptions = e.map(({ caption }) => caption);
                            const imagesAltTexts = e.map(({ altText }) => altText);
                            setFieldValue(`imagesCaptions`, imagesCaptions);
                            setFieldValue(`images.altText`, imagesAltTexts);
                            setFieldValue("imagesGallery", e);
                        }}
                        path={"properties-overview"}
                        captionList={values.imagesCaptions}
                        fileList={values.imagesGallery}
                        validate={true}
                        isVideoAllowed={true}
                        imageAltText={brandName === "ALP"}
                    />
                </Col>

                <Col span={24}>
                    <h3>Virtual Tour</h3>

                    <Row gutter={24}>
                        <Col span={12}>
                            <p>Video</p>
                            <MultipleUrlFields
                                values={values.videos}
                                placeholder="Video Link"
                                name="videos"
                                preview={handlePreview}
                            />
                        </Col>
                        <Col span={12}>
                            <p>Panorama</p>
                            <MultipleUrlFields
                                values={values.panoramas}
                                placeholder="Panorama Link"
                                name="panoramas"
                                preview={handlePreview}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <FormItem
                label={"Document Title"}
                name={"documentTitle"}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
            >
                <Input name="documentTitle" />
            </FormItem>

            <UploadSingleFileStyled
                value={values.file}
                onChange={(e) => setFieldValue("file", e)}
                showUploadList={true}
                accept={".pdf"}
            >
                <div className={"label-button"}>
                    <CloudUploadOutlined style={{ fontSize: 34 }} />
                    <p>Upload</p>
                    <h5>.pdf (max 50 MB)</h5>
                </div>
            </UploadSingleFileStyled>
            <br />

            <Row justify="end">
                <Col span={3}>
                    <CustomButton
                        style={{ width: "100%", marginRight: 15 }}
                        type="primary"
                        onClick={() => submitForm()}
                        disabled={!dirty || !isValid}
                    >
                        SAVE
                    </CustomButton>
                </Col>
            </Row>
            <Modal
                visible={modalVisible}
                centered
                footer={null}
                bodyStyle={{ padding: 0, height: 400 }}
                onCancel={handleClosePreview}
                width={700}
                closable={false}
                // closeIcon={<button>click</button>}
                maskClosable={true}
                destroyOnClose={true}
            >
                <StyledIFrame src={preview} width="100%" height="100%"></StyledIFrame>
            </Modal>
        </Form>
    );
};

export default Forms;
