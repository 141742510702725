/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { Formik } from "formik";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Context } from "../../../../context";

import { Spin, message } from "antd";

import { validationSchema } from "./constant";
import { useQuery, useMutation } from "@apollo/client";

import {
    CREATE_PAGE,
    CREATE_CONTENT,
    GET_PAGE,
    EDIT_PAGE_TITLE,
    REMOVE_FIELD,
} from "../../graphql";

import Forms from "./forms";

import _ from "lodash";

import generateId from "../../../../util/generate-id";

import {
    FormValueTypes,
    ImageContainerType,
    ContainerType,
    ImagesType,
} from "../../manage-gallery.types";

import { INIT_VALUES } from "../../manage-gallery.utils";
import { imageMapper, linksMapper } from "../../manage-gallery.utils";

const GalleryForms = (): JSX.Element => {
    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const [initialValues, setInitialValues] = useState<FormValueTypes>(INIT_VALUES);

    const {
        state: { brand: brandData },
    } = useContext(Context);

    const brandId = brandData?.id;
    const brandName = brandData?.name;

    const tag = `GALLERY-${brandName}`;

    const { data: getPage } = useQuery(GET_PAGE, {
        skip: !brandId,
        fetchPolicy: "no-cache",
        variables: {
            page_id: location?.state?.pageId || "",
        },
    });

    const [loader, setLoader] = useState(false);

    const [pageDetails, setPageDetails] = useState<null | any>(null);

    const [imagesFieldsId, setImagesFieldsId] = useState<string[]>([]);
    const [videosFieldsId, setVideosFieldsId] = useState<string[]>([]);
    const [panoramasFieldsId, setPanoramasFieldsId] = useState<string[]>([]);

    const [createPage] = useMutation(CREATE_PAGE);
    const [createContent] = useMutation(CREATE_CONTENT);
    const [editPage] = useMutation(EDIT_PAGE_TITLE);
    const [removeFields] = useMutation(REMOVE_FIELD);

    const [reinitialize, setReinitialize] = useState(false);

    const handleAddPage = async (title) => {
        const page = await createPage({
            variables: {
                brand_id: brandId,
                tag: tag,
                name: title,
            },
        });

        return [page];
    };

    const handleUpdatePageName = async (title, page_id) => {
        const page = await editPage({
            variables: {
                tag: tag,
                brand_id: brandId,
                id: page_id,
                name: title,
            },
        });

        return [page];
    };

    const containerMapper = (values, page_id) => {
        let imagesAsContainer: ImageContainerType[] = [];
        let videosAsContainer: ContainerType[] = [];
        let panoramasAsContainer: ContainerType[] = [];

        const videos: ContainerType[] = [];
        values.videos.map((link: ContainerType) => {
            if (link) {
                videos.push(link);
            }
        });

        const panoramas: ContainerType[] = [];
        values.panoramas.map((link: ContainerType) => {
            if (link) {
                panoramas.push(link);
            }
        });

        if (!_.isEmpty(values.images)) {
            imagesAsContainer = imageMapper(values.images);
        }
        if (!_.isEmpty(videos)) {
            videosAsContainer = linksMapper(values.videos, "video");
        }
        if (!_.isEmpty(panoramas)) {
            panoramasAsContainer = linksMapper(values.panoramas, "panorama");
        }

        const containers: any = {
            order: 0,
            page_id: page_id,
            name: "GALLERY-CONTAINER",
            field_collections: [
                {
                    name: "GALLERY-FC",
                    order: 0,
                    children: [
                        {
                            name: "IMAGES",
                            order: 0,
                            fields: imagesAsContainer && imagesAsContainer,
                        },
                        {
                            name: "VIDEOS",
                            order: 1,
                            fields: videosAsContainer && videosAsContainer,
                        },
                        {
                            name: "PANORAMAS",
                            order: 2,
                            fields: panoramasAsContainer && panoramasAsContainer,
                        },
                    ],
                    fields: [
                        {
                            label: "Gallery (Default)",
                            name: "GALLERY-DEFAULT",
                            value: values.label ? values.label : null,
                            order: 0,
                        },
                        {
                            label: "title",
                            name: "GALLERY-TITLE",
                            value: values.title ? values.title : null,
                            order: 1,
                        },
                    ],
                },
            ],
        };

        if (params.slug && pageDetails) {
            containers["id"] = pageDetails.containers.id;
            containers.field_collections[0]["id"] = pageDetails.containers.field_collections.id;

            // IMAGES
            containers.field_collections[0].children[0]["id"] =
                pageDetails.containers.field_collections.children[0].id;

            // VIDEOS
            containers.field_collections[0].children[1]["id"] =
                pageDetails.containers.field_collections.children[1].id;

            // PANORAMAS
            containers.field_collections[0].children[2]["id"] =
                pageDetails.containers.field_collections.children[2].id;

            // SHOW-ROOM-DEFAULT or LABEL
            containers.field_collections[0].fields[0]["id"] =
                pageDetails.containers.field_collections.fields[0].id;

            // SHOW-ROOM-TITLE
            containers.field_collections[0].fields[1]["id"] =
                pageDetails.containers.field_collections.fields[1].id;
        }

        return [containers];
    };

    const handleUpdatePage = async (values, page_id) => {
        const containers = await containerMapper(values, page_id);

        if (!_.isEmpty(imagesFieldsId)) {
            await removeFields({
                variables: {
                    data: {
                        ids: imagesFieldsId,
                    },
                },
            });
        }

        if (!_.isEmpty(videosFieldsId)) {
            await removeFields({
                variables: {
                    data: {
                        ids: videosFieldsId,
                    },
                },
            });
        }

        if (!_.isEmpty(panoramasFieldsId)) {
            await removeFields({
                variables: {
                    data: {
                        ids: panoramasFieldsId,
                    },
                },
            });
        }

        const content = await createContent({
            variables: {
                data: {
                    containers,
                },
            },
        });

        return [content];
    };

    const handleSubmit = async (values) => {
        setLoader(true);

        if (params.slug && pageDetails) {
            try {
                const content = await handleUpdatePage(values, pageDetails.id);
                if (pageDetails.name !== values.title) {
                    const newPage = await handleUpdatePageName(values.title, pageDetails.id);
                }
                message.success("Gallery successfully updated!");
                setTimeout(() => {
                    setLoader(false);
                }, 1000);
                history.push(`/manage-gallery`, { pageId: pageDetails.id });
            } catch (err) {
                const errorObj = err as Error;

                const msg =
                    errorObj.message === "Page name is already exists!"
                        ? "Page name is already exists!"
                        : "Something went wrong upon updating!";
                message.error(msg);
                setTimeout(() => {
                    setLoader(false);
                }, 1000);
            }
        } else {
            try {
                const page = await handleAddPage(values.title);

                const {
                    addOrUpdateBrandPage: { id },
                } = page[0].data;

                const content = await handleUpdatePage(values, id);

                message.success("Gallery successfully created!");
                setTimeout(() => {
                    setLoader(false);
                }, 1000);
                history.push(`/manage-gallery`);
            } catch (err) {
                const errorObj = err as Error;

                const msg =
                    errorObj.message === "Page name is already exists!"
                        ? "Page name is already exists!"
                        : "Something went wrong upon updating!";
                message.error(msg);
                setTimeout(() => {
                    setLoader(false);
                }, 1000);
            }
        }
    };

    useEffect(() => {
        if (!reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        if (!brandId || !brandName) {
            setLoader(true);
        } else {
            setLoader(false);
        }
    }, [brandName, brandId]);

    useEffect(() => {
        if (params.slug) {
            setLoader(true);
            if (getPage) {
                const data = getPage.getSpecificBrandPageById;

                const field_collections = data.containers[0].field_collections[0];
                const { fields, children } = field_collections;
                setPageDetails({
                    id: data.id,
                    name: data.name,
                    containers: {
                        id: data.containers[0].id,
                        field_collections: {
                            id: field_collections.id,
                            children: [
                                {
                                    order: 0,
                                    id: children && children[0].id,
                                },
                                {
                                    order: 1,
                                    id: children && children[1].id,
                                },
                                {
                                    order: 2,
                                    id: children && children[2].id,
                                },
                            ],
                            fields: [
                                {
                                    order: 0,
                                    id: fields[0].id,
                                },
                                {
                                    order: 1,
                                    id: fields[1].id,
                                },
                            ],
                        },
                    },
                });

                const tempFields: FormValueTypes = {
                    label: fields[0].value ? fields[0].value : "",
                    title: fields[1].value ? fields[1].value : "",
                    images: [],
                    imagesCaptions: [],
                    videos: [],
                    panoramas: [],
                };

                const images = children && children[0].fields;
                const videos = children && children[1].fields;
                const panoramas = children && children[2].fields;

                images &&
                    images.forEach((res) => {
                        const tempImage: ImagesType = {
                            itemId: generateId(),
                            caption: res.meta_data.description,
                            isChecked: res.meta_data.featured,
                            url: res.value,
                            keyObj: res.meta_data.key,
                            id: res.id,
                            status: "done",
                        };

                        if (brandName === "ALP") {
                            tempImage.altText = res.meta_data.alt_text;
                        }

                        imagesFieldsId.push(res.id);

                        tempFields.images.push(tempImage);
                        tempFields.imagesCaptions.push(res.meta_data.description);
                    });

                videos
                    ? videos.map((res) => {
                          videosFieldsId.push(res.id);
                          tempFields.videos.push(res.value);
                      })
                    : tempFields.videos.push("");

                panoramas
                    ? panoramas.map((res) => {
                          panoramasFieldsId.push(res.id);
                          tempFields.panoramas.push(res.value);
                      })
                    : tempFields.panoramas.push("");

                setInitialValues(tempFields);
                setTimeout(() => {
                    setLoader(false);
                }, 2000);
                setReinitialize(true);
            }
        }
    }, [getPage]);

    return (
        <Spin spinning={loader}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={reinitialize}
                onSubmit={(values: FormValueTypes) => handleSubmit(values)}
            >
                {(formikBag) => (
                    <Forms
                        {...{
                            formikBag,
                            brandName,
                        }}
                    />
                )}
            </Formik>
        </Spin>
    );
};

export default GalleryForms;
