import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { Formik } from "formik";
import Forms from "./forms";
import _ from "lodash";

import { validationSchema } from "./constant";

import { GET_BANNER, CREATE_OR_EDIT_BANNER } from "../../graphql";
import { Context } from "../../../../context";

import { Property } from "../../../../entities/Property";
import { Content } from "../../../../entities/Content";
import { LinkedPropertyMapper } from "../../dto/LinkedPropertyMapper";
import { LinkedContentMapper } from "../../dto/LinkedContentMapper";

import { Spin, message } from "antd";

import generateId from "../../../../util/generate-id";
import { FormValueTypes, FormDataTypes, ImageType } from "../../manage-hero-banner.types";
import { INIT_VALUES } from "../../manage-hero-banner.utls";

const HeroBannerForms = (): JSX.Element => {
    const history = useHistory();
    const params = useParams();

    const {
        state: { brand: brandData },
    } = useContext(Context);
    const brandId = brandData?.id;
    const brandName = brandData?.name;

    const [loader, setLoader] = useState(false);

    const [initialValues, setInitialValues] = useState<FormValueTypes>(INIT_VALUES);

    const [reinitialize, setReinitialize] = useState(true);

    const [addOrUpdateBanner] = useMutation(CREATE_OR_EDIT_BANNER);

    const { data, error } = useQuery(GET_BANNER, {
        skip: !brandId || !params.id,
        fetchPolicy: "no-cache",
        partialRefetch: true,
        variables: { id: params.id },
    });

    const handleSubmit = async (values) => {
        setLoader(true);

        const data: FormDataTypes = {
            id: params.id,
            brand_id: brandId,
            page_id: values.linkedPage.id,
            link_type: values.linkType,
            category: values.linkCategory,
            title: values.bannerTitle && values.bannerTitle.trim(),
            description: values.excerpt && values.excerpt.trim(),
            seo_banner_title: values.seoBannerTitle,
            seo_banner_description: values.seoBannerDescription,
            banner_heading_tag: values.bannerHeadingTag,
            description_mobile: values.excerptMobile && values.excerptMobile.trim(),
            image: values.imgURL && values.imgURL.keyObj ? values.imgURL.keyObj : null,
            meta_data:
                values.imgURL && values.imgURL.keyObj
                    ? {
                          key: values.imgURL.keyObj,
                          alt_text: values.imgURL.altText,
                      }
                    : null,
            video_link: values.videoURL && values.videoURL.trim(),
        };

        !params.id && delete data.id;

        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const res = await addOrUpdateBanner({
                variables: {
                    data,
                },
            });
            message.success("Successfully saved banner.");
        } catch (error) {
            message.error("Something went wrong.");
            console.log(error);
        } finally {
            setLoader(false);
            history.push(`/manage-hero-banner`);
        }
    };

    useEffect(() => {
        if (!reinitialize) {
            setReinitialize(false);
        }
    }, [reinitialize]);

    useEffect(() => {
        if (data && data.getHomeBanner) {
            setLoader(true);
            const { getHomeBanner } = data;
            const { page } = getHomeBanner;

            const linkType = page && page.tag && page.tag.replace(`-${brandName}`, "");

            const res = linkType && [page];

            const linkedPage: string[] | boolean =
                res && linkType === "PROPERTIES"
                    ? res.map((info: Property) =>
                          Object.assign(LinkedPropertyMapper.map({ ...info }))
                      )
                    : linkType === "CONTENT"
                    ? res.map((info: Content) =>
                          Object.assign(LinkedContentMapper.map({ ...info }))
                      )
                    : [];

            const image: ImageType = getHomeBanner.image &&
                getHomeBanner.meta_data &&
                getHomeBanner.meta_data.key && {
                    keyObj: getHomeBanner.meta_data.key,
                    url: getHomeBanner.image,
                    uid: generateId(),
                    status: "done",
                    altText: getHomeBanner?.meta_data.alt_text,
                };

            setInitialValues({
                linkedPage: !_.isEmpty(linkedPage) && linkedPage[0],
                linkType: getHomeBanner.link_type && getHomeBanner.link_type,
                linkCategory: getHomeBanner.category && getHomeBanner.category,
                imgURL: image ? image : null,
                videoURL: getHomeBanner.video_link,
                bannerTitle: getHomeBanner.title,
                excerpt: getHomeBanner.description,
                seoBannerTitle: getHomeBanner.seo_banner_title
                    ? getHomeBanner.seo_banner_title
                    : "",
                seoBannerDescription: getHomeBanner.seo_banner_description
                    ? getHomeBanner.seo_banner_description
                    : "",
                bannerHeadingTag: getHomeBanner.banner_heading_tag
                    ? getHomeBanner.banner_heading_tag
                    : "",
                excerptMobile: getHomeBanner.description_mobile
                    ? getHomeBanner.description_mobile
                    : "",
            });

            setReinitialize(true);
        }

        if (error) {
            message.error("Page not found.");
            history.push(`/manage-hero-banner`);
        }

        setTimeout(() => {
            setLoader(false);
        }, 1000);
    }, [data, error]);

    return (
        <Spin spinning={loader}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={reinitialize}
                onSubmit={(values: FormValueTypes) => handleSubmit(values)}
            >
                {(formikBag) => (
                    <Forms
                        {...{
                            formikBag,
                        }}
                    />
                )}
            </Formik>
        </Spin>
    );
};

export default HeroBannerForms;
