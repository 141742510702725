import styled from "styled-components";
import UploadSingleFile from "../../../../../components/UploadSingleFile";
import UploadVideoLink from "../../../../../components/UploadVideoLink";

export const WrapperForm = styled.div`
    background: rgba(242, 242, 242, 1);
    border-width: 1px;
    border-style: solid;
    border-color: rgba(217, 217, 217, 1);
    border-radius: 2px;
    padding: 25px 35px;
    margin-bottom: 25px;
    position: relative;

    .remove-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
`;

export const MediaContainerLeft = styled(UploadSingleFile)`
    margin-top: 235px;
    height: 500px;
    width: 100%;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;

    .ant-upload {
        display: block;

        .upload-button {
            height: 500px;
            width: 100%;
            display: block;
            border-radius: 10px;
        }
    }

    .upload-button {
        height: 500px;
        width: 100%;
        display: block;
        border-radius: 10px;
    }

    .uploaded-file {
        height: 500px;
        width: 100%;

        img {
            height: 500px;
            width: 100%;
        }
    }

    .remove-file {
        top: 6px;
        right: 10px;
    }
`;

export const MediaContainer = styled(UploadSingleFile)`
    height: 300px;
    width: 100%;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;

    .ant-upload {
        display: block;

        .upload-button {
            height: 300px;
            width: 100%;
            display: block;
            border-radius: 10px;
        }
    }

    .upload-button {
        height: 300px;
        width: 100%;
        display: block;
        border-radius: 10px;
    }

    .uploaded-file {
        height: 300px;
        width: 100%;

        img {
            height: 300px;
            width: 100%;
        }
    }

    .remove-file {
        top: 6px;
        right: 10px;
    }
`;

export const MediaVideoContainer = styled(UploadVideoLink)`
    height: 300px;
    width: 100%;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;

    .upload-button {
        height: 300px;
        width: 100%;
        display: block;
        border-radius: 10px;
    }

    .uploaded-file {
        height: 300px;
        width: 100%;

        embed {
            height: 300px;
            width: 100%;
        }
    }

    .remove-file {
        top: 6px;
        right: 10px;
    }
`;

export const PreviewFeatureVideo = styled.div`
    height: 300px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    border: 1px dashed #00714b;
    border-width: 1px;
    display: flex;
    justify-content: center;
    align-items: center;

    video {
        height: 300px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
        border: 0;
        overflow: hidden;
    }

    .anticon.anticon-play-circle {
        font-size: 60px;
        color: #00714b;
    }
`;
