import { ContainerType, FormValueTypes, ImageContainerType } from "./manage-gallery.types";

export const INIT_VALUES: FormValueTypes = {
    label: "",
    title: "",
    images: [],
    imagesCaptions: [],
    videos: [""],
    panoramas: [""],
};

export const imageMapper = (values): ImageContainerType[] =>
    values.map((image, imageIndex) => ({
        order: imageIndex,
        label: "image",
        name: "Image",
        value: image.keyObj,
        meta_data: {
            featured: image.isChecked,
            description: image.caption ? image.caption : "",
            key: image.keyObj,
            alt_text: image.altText,
        },
    }));

export const linksMapper = (values, label): ContainerType[] =>
    values.map((link, linkIndex) => ({
        order: linkIndex,
        label: label,
        name: label.toUpperCase(),
        value: link,
    }));
